import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/service/session.service';
declare var $;

@Component({
  selector: 'app-fam-memorial',
  templateUrl: './memorial.component.html',
  styleUrls: ['./memorial.component.scss']
})
export class MemorialComponent implements OnInit {


  seko_info = this.sessionSvc.get('soke_seko_info');
  constructor(
    private sessionSvc: SessionService,
  ) { }

  ngOnInit(): void {
    this.toggleSlider();
  }

  toggleSlider() {
    setTimeout(() => {
      $('#flexslider-image').flexslider({
        animation: 'fade',
        controlNav: true,
        animationLoop: true,
        slideshow: false,
        slideshowSpeed: 2000,
        animationSpeed: 600,
        smoothHeight: false,
        pauseOnHover: false,
        directionNav: true,
        pauseOnAction: false,
        pausePlay: false,
        prevText: '',
        nextText: ''
      });
    }, 500);
  }
}

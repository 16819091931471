import { Component, OnInit } from '@angular/core';
import { HttpClientService } from 'src/app/service/http-client.service';
import { SessionService } from 'src/app/service/session.service';
import { LoaderService } from 'src/app/service/loader.service';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import { FaqGetRequest, FaqGetResponse } from 'src/app/interfaces/faq';
declare var $;

@Component({
  selector: 'app-fam-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqFamComponent implements OnInit {
  Const = CommonConstants;
  Utils = Utils;

  seko_info = this.sessionSvc.get('soke_seko_info');
  faq_list: Array<FaqGetResponse> = null;
  is_loading = false;

  constructor(
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
  ) { }

  async ngOnInit() {
    this.is_loading = true;
    await this.getInquiryList();
    this.is_loading = false;
  }

  async getInquiryList() {
    if (!this.seko_info) {
      return;
    }
    const request = new FaqGetRequest();
    request.company = this.seko_info.seko_company.id;
    await this.httpClientService.getFaqList(request).then((response) => {
      Utils.log(response);
      this.faq_list = response;

    }).catch(error => {
    });
  }

}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { LoaderService } from 'src/app/service/loader.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { LoginPostRequest } from 'src/app/interfaces/login';
import { SessionService } from 'src/app/service/session.service';
import { StaffService } from 'src/app/service/staff.service';

import { BaseService } from 'src/app/service/base.service';
import { Utils } from 'src/app/const/func-util';

declare var $;
@Component({
  selector: 'app-com-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  @ViewChild('companyCodeEm', { static: false }) companyCodeEm: ElementRef;
  @ViewChild('loginIdEm', { static: false }) loginIdEm: ElementRef;
  @ViewChild('passwordEm', { static: false }) passwordEm: ElementRef;

  store_code: string = null;

  public errorMessage: any = {};

  previousUrl = 'foc/top';
  companyCode = null;
  loginId = null;
  password = null;
  save_company_info = false;
  foc_compnay_login_info = this.sessionSvc.get('foc_compnay_login_info', true);
  shaking = false;
  is_IE = false;
  constructor(
    private loaderSvc: LoaderService,
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private staffSvc: StaffService,
    private baseSvc: BaseService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.sessionSvc.clear('staff_login_info');
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent.indexOf('msie') > 0 || userAgent.indexOf('trident') > 0) {
      this.sessionSvc.save('message', { type: 'warning', title: '本システムはIE非対応です。他のブラウザーをご利用ください。', force: true });
      this.is_IE = true;
      return;
    } else if (userAgent.indexOf('edge') > 0) {
      this.sessionSvc.save('message', { type: 'warning', title: '本システムは旧Edge非対応です。他のブラウザーをご利用ください。', force: true });
      this.is_IE = true;
      return;
    }
    this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
      if (params.has('previousUrl')) {
        this.previousUrl = params.get('previousUrl');
      }
    });
    if (this.foc_compnay_login_info) {
      this.companyCode = this.foc_compnay_login_info.companyCode;
      this.loginId = this.foc_compnay_login_info.loginId;
      this.save_company_info = this.foc_compnay_login_info.save_company_info;
    }
    setTimeout(() => {
      $('.ui.checkbox').checkbox();
    }, 100);
  }

  checkLoginButtonState(): boolean {
    return !!this.store_code;
  }

  onKeyUp(event) {
    if (event.keyCode === 13) {
      this.login();
    }
  }
  login() {
    if (this.is_IE) {
      return;
    }
    if (!this.validate()) {
      this.shake_form();
      return;
    }
    this.saveLoginInfo();
    const loginRequest = new LoginPostRequest();
    loginRequest.company_code = this.companyCode;
    loginRequest.login_id = this.loginId;
    loginRequest.password = this.password;
    this.loaderSvc.call();
    this.httpClientService
      .postLogin(loginRequest)
      .then(async (response) => {
        Utils.log(response);
        if (document.execCommand) {
          document.execCommand('ClearAuthenticationCache', false);
        }
        const login_info = { token: response, staff: null };
        this.sessionSvc.save('staff_login_info', login_info);
        await this.httpClientService.getLoginStaff().then(async (staffResponse) => {
          Utils.log(staffResponse);
          login_info.staff = staffResponse;
          this.sessionSvc.save('staff_login_info', login_info);
          if (await this.getMaster(login_info.staff.base)) {
            this.router.navigate([this.previousUrl]);
          } else {
            this.sessionSvc.clearAll();
          }
          this.loaderSvc.dismiss();
        }).catch(error => {
          this.loginError('スタッフ情報の取得が失敗しました。');
        });
      })
      .catch((error) => {
        if (error && error.detail) {
          this.loginError('会社コード、ログインID、パスワードの組合せが正しくありません。');
        }
        this.loaderSvc.dismiss();
      });

  }
  async getMaster(base) {
    let ret = true;
    let company = null;
    await this.baseSvc.cacheData().then(res => {
      if (!res) {
        this.loginError('拠点情報の取得が失敗しました。');
        ret = false;
      }
      company = Utils.getLoginCompany();
      if (!company) {
        this.loginError('拠点情報の取得が失敗しました。');
        ret = false;
      }

    });
    await this.staffSvc.getData(company.id).then((response) => {
      Utils.log(response);
    }).catch(error => {
      this.loginError('スタッフ情報の取得が失敗しました。');
      ret = false;
    });
    if (!ret) {
      return ret;
    }

    await this.httpClientService.getHoyoStyleList().then((response) => {
      Utils.log(response);
      this.sessionSvc.save('hoyo_style_list', response);
    }).catch(error => {
      this.loginError('法要形式情報の取得が失敗しました。');
      ret = false;
    });
    if (!ret) {
      return ret;
    }

    await this.httpClientService.getSekoStyleList().then((response) => {
      Utils.log(response);
      this.sessionSvc.save('seko_style_list', response);
    }).catch(error => {
      this.loginError('施行形式情報の取得が失敗しました。');
      ret = false;
    });
    if (!ret) {
      return ret;
    }

    await this.httpClientService.getScheduleList().then((response) => {
      Utils.log(response);
      this.sessionSvc.save('schedule_list', response);
    }).catch(error => {
      this.loginError('日程マスタ情報の取得が失敗しました。');
      ret = false;
    });

    await this.httpClientService.getFuhoSampleList().then((response) => {
      Utils.log(response);
      this.sessionSvc.save('fuho_sample_list', response);
    }).catch(error => {
      this.loginError('訃報サンプル情報の取得が失敗しました。');
      ret = false;
    });

    await this.httpClientService.getHoyoSampleMasterList().then((response) => {
      Utils.log(response);
      this.sessionSvc.save('hoyo_sample_mst_list', response);
    }).catch(error => {
      this.loginError('法要案内文サンプル情報の取得が失敗しました。');
      ret = false;
    });


    await this.httpClientService.getServiceList().then((response) => {
      Utils.log(response);
      this.sessionSvc.save('service_list', response);

    }).catch(error => {
      this.loginError('サービス情報の取得が失敗しました。');
      ret = false;
    });

    await this.httpClientService.getTaxList().then((response) => {
      Utils.log(response);
      this.sessionSvc.save('tax_list', response);

    }).catch(error => {
      this.loginError('税率情報の取得が失敗しました。');
      ret = false;
    });

    await this.httpClientService.getHenreihinParameter().then((response) => {
      Utils.log(response);
      this.sessionSvc.save('henrei_param', response);

    }).catch(error => {
      this.loginError('返礼品パラメータ情報の取得が失敗しました。');
      ret = false;
    });
    return ret;

  }
  validate() {
    if (!this.companyCode) {
      this.companyCodeEm.nativeElement.focus();
      return false;
    }
    if (!this.loginId) {
      this.loginIdEm.nativeElement.focus();
      return false;
    }
    if (!this.password) {
      this.passwordEm.nativeElement.focus();
      return false;
    }
    return true;
  }
  shake_form() {
    this.shaking = true;
    setTimeout(() => {
      this.shaking = false;
    }, 300);
    // $('.login_form')
    //   .transition('shake')
  }

  loginError(title, msg = null) {
    const message = {
      type: 'error',
      title: title,
      msg: msg
    };
    this.sessionSvc.save('message', message);
    this.loaderSvc.dismiss();

  }

  saveLoginInfo() {
    if (this.save_company_info) {
      this.foc_compnay_login_info = {
        companyCode: this.companyCode,
        loginId: this.loginId,
        save_company_info: this.save_company_info
      };
      this.sessionSvc.save('foc_compnay_login_info', this.foc_compnay_login_info, true);
    } else {
      this.sessionSvc.clear('foc_compnay_login_info', true);

    }
  }
}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { LoaderService } from 'src/app/service/loader.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { SokeLoginPostRequest } from 'src/app/interfaces/login';
import { SessionService } from 'src/app/service/session.service';
import { Utils } from 'src/app/const/func-util';

declare var $;

@Component({
  selector: 'app-fam-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class SokeLoginComponent implements OnInit {

  login_id = null;
  password = null;
  previousUrl = 'soke/seko';
  message = null;
  constructor(
    private loaderSvc: LoaderService,
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.sessionSvc.clear('soke_login_info');
    const userAgent = window.navigator.userAgent.toLowerCase();
    this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
      if (params.has('id')) {
        this.login_id = params.get('id');
      }
      if (params.has('previousUrl')) {
        this.previousUrl = params.get('previousUrl');
      }
    });
  }

  onKeyUp(event) {
    if (event.keyCode === 13) {
      this.login();
    }
  }
  login() {
    if (!this.invalidData()) {
      return;
    }
    const loginRequest = new SokeLoginPostRequest();
    loginRequest.seko = this.login_id;
    loginRequest.password = this.password;
    this.loaderSvc.call();
    this.httpClientService
      .sokeLogin(loginRequest)
      .then(async (response) => {
        Utils.log(response);
        if (document.execCommand) {
          document.execCommand('ClearAuthenticationCache', false);
        }
        const login_info = { login_id: this.login_id, token: response };
        this.sessionSvc.save('soke_login_info', login_info);
        this.loaderSvc.dismiss();
        this.router.navigate([this.previousUrl]);
      })
      .catch((error) => {
        this.message = 'ご葬家ID、パスワードが正しくありません。';
        this.loaderSvc.dismiss();
      });

  }

  invalidData() {
    $('.input').attr('err-msg', null);
    let ret = true;
    if (!this.login_id) {
      $('.input.login_id').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.password) {
      $('.input.password').attr('err-msg', '*必須項目です');
      ret = false;
    }
    return ret;
  }

  isErrorField(element) {
    return !!element.getAttribute('err-msg');
  }

  resetPassComnfirm() {
    this.message = null;
    if (!this.login_id) {
      this.message = '葬家IDを入力してください。';
      return;
    }
    $('#msg-confirm').modal({
      closable: false,
      centered: false,
      detachable: false,
      transition: 'fade',
    }).modal('show');
  }

  cancelConfirm() {
    $('#msg-confirm').modal('hide');
  }

  resetPass() {
    $('#msg-confirm').modal('hide');
    this.httpClientService.approveSokeSite(this.login_id).then((response) => {
      Utils.log(response);
      this.message = '新しいパスワードはご登録のメールアドレス宛に送信しました。';

    }).catch(error => {
      this.message = 'パスワードリセット処理が失敗しました。葬家IDが正しいかご確認ください。';
    });
  }

}

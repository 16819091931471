import { Component, OnInit } from '@angular/core';
import { HttpClientService } from 'src/app/service/http-client.service';
import { SessionService } from 'src/app/service/session.service';
import { LoaderService } from 'src/app/service/loader.service';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import { MessagePutRequest } from 'src/app/interfaces/order';
declare var $;

@Component({
  selector: 'app-fam-homei',
  templateUrl: './homei.component.html',
  styleUrls: ['./homei.component.scss']
})
export class HomeiComponent implements OnInit {
  Const = CommonConstants;
  Utils = Utils;

  seko_info = this.sessionSvc.get('soke_seko_info');
  homei_data_all = null;
  homei_list = null;
  homei_filter = {
    kumotsu: this.haveService(this.Const.SERVICE_ID_KUMOTSU),
    chobun: this.haveService(this.Const.SERVICE_ID_CHOBUN),
    msg: this.haveService(this.Const.SERVICE_ID_MESSAGE),
    koden: this.haveService(this.Const.SERVICE_ID_KODEN)
  };
  selected_homei = null;
  henreihin_sum = 0;
  message = null;
  constructor(
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
  ) { }

  async ngOnInit() {
    await this.getHomeichoList();
  }

  async getHomeichoList() {
    if (!this.seko_info) {
      return;
    }
    await this.httpClientService.getHomeicho(this.seko_info.id).then((response) => {
      Utils.log(response);
      this.homei_data_all = response;
      this.filterHomeiList();
      Utils.log(this.homei_list);

    }).catch(error => {
    });
  }

  filterHomeiList() {
    this.homei_list = new Array();
    this.henreihin_sum = 0;
    if (!this.homei_data_all || !this.homei_data_all.length) {
      return;
    }
    for (const homei_data of this.homei_data_all) {
      if (homei_data.cancel_ts) {
        continue;
      }
      for (const homei_detail of homei_data.details) {
        let okurinushi_name = '';
        if (homei_detail.chobun) {
          okurinushi_name = homei_detail.chobun.okurinushi_name;
        } else if (homei_detail.kumotsu) {
          okurinushi_name = homei_detail.kumotsu.okurinushi_name;
          if (homei_detail.kumotsu.henrei_kumotsu) {
            this.henreihin_sum += homei_detail.kumotsu.henrei_kumotsu.henreihin_price;
          }
        } else if (homei_detail.koden) {
          if (homei_detail.koden.henrei_koden) {
            this.henreihin_sum += homei_detail.koden.henrei_koden.henreihin_price;
          }
        }
        if ((!this.homei_filter.chobun && homei_detail.item.service.id === this.Const.SERVICE_ID_CHOBUN) ||
          (!this.homei_filter.kumotsu && homei_detail.item.service.id === this.Const.SERVICE_ID_KUMOTSU) ||
          (!this.homei_filter.koden && homei_detail.item.service.id === this.Const.SERVICE_ID_KODEN) ||
          (!this.homei_filter.msg && homei_detail.item.service.id === this.Const.SERVICE_ID_MESSAGE)) {
          continue;
        }
        this.homei_list.push({
          entry_id: homei_data.id,
          entry_ts: homei_data.entry_ts,
          entry_name: homei_data.entry_name,
          entry_zip_code: this.zipCode(homei_data.entry_zip_code),
          entry_prefecture: homei_data.entry_prefecture,
          entry_address_1: homei_data.entry_address_1,
          entry_address_2: homei_data.entry_address_2,
          entry_address_3: homei_data.entry_address_3,
          entry_tel: homei_data.entry_tel,
          okurinushi_name: okurinushi_name,
          entry_detail_id: homei_detail.id,
          service_id: homei_detail.item.service.id,
          service_name: homei_detail.item.service.name,
          item_name: homei_detail.item_name,
          price: homei_detail.item_unit_price,
          koden: homei_detail.koden,
          kumotsu: homei_detail.kumotsu,
          message: homei_detail.message,
        });

      }
    }
    setTimeout(() => {
      $('.accordion').accordion();
      $('.ui.checkbox').checkbox();
    }, 100);
  }

  checkHomeiHaveData(service_id) {
    if (!this.homei_data_all || !this.homei_data_all.length) {
      return false;
    }
    for (const homei_data of this.homei_data_all) {
      const filter = homei_data.details.filter(v => v.item.service.id === service_id);
      if (filter && filter.length > 0) {
        return true;
      }
    }
    return false;
  }

  saveMsgConfirm(homei) {
    if (!homei) {
      return;
    }
    this.selected_homei = homei;
    $('#msg-confirm').modal({
      closable: false,
      centered: false,
      detachable: false,
      transition: 'fade',
    }).modal('show');
  }

  cancelConfirm() {
    $('#msg-confirm').modal('hide');
    setTimeout(() => {
      this.selected_homei = null;
    }, 500);
  }

  saveMsgData() {
    if (!this.selected_homei) {
      return;
    }
    $('#msg-confirm').modal('hide');
    const putData = new MessagePutRequest();
    putData.release_status = this.selected_homei.message.release_status;
    this.httpClientService.updateMessage(this.selected_homei.entry_detail_id, putData)
      .then((response) => {
        setTimeout(() => {
          this.selected_homei = null;
        }, 500);
      }).catch(error => {
        this.message = '公開区分の更新が失敗しました。';
        $('#message-popup').modal({
          closable: false,
          centered: false,
          detachable: false,
          transition: 'fade',
        }).modal('show');
        this.getHomeichoList();
      });
  }

  zipCode(zip_code: string) {
    if (!zip_code || zip_code.length !== 7) {
      return zip_code;
    }
    return zip_code.substring(0, 3) + '-' + zip_code.substring(3, 7);
  }
  getClassName(service_id) {
    if (!service_id) {
      return '';
    }
    switch (service_id) {
      case this.Const.SERVICE_ID_CHOBUN:
        return 'chobun';
      case this.Const.SERVICE_ID_KUMOTSU:
        return 'kumotsu';
      case this.Const.SERVICE_ID_KODEN:
        return 'koden';
      case this.Const.SERVICE_ID_MESSAGE:
        return 'msg';
      default:
        return '';
    }

  }

  downloadHomeiPdf(service_id) {
    this.message = null;
    if (!this.checkHomeiHaveData(service_id)) {
      this.message = '出力対象データがございません。';
      $('#message-popup').modal({
        closable: false,
        centered: false,
        detachable: false,
        transition: 'fade',
      }).modal('show');
      return;
    }
    let func_name = null;
    let title_name = null;
    switch (service_id) {
      case this.Const.SERVICE_ID_KUMOTSU:
        func_name = 'downloadHomeiKumotsuPdf';
        title_name = '芳名帳(供花供物)';
        break;
      case this.Const.SERVICE_ID_CHOBUN:
        func_name = 'downloadHomeiChobunPdf';
        title_name = '芳名帳(弔文)';
        break;
      case this.Const.SERVICE_ID_MESSAGE:
        func_name = 'downloadHomeiMsgPdf';
        title_name = '芳名帳(追悼MSG)';
        break;
      case this.Const.SERVICE_ID_KODEN:
        func_name = 'downloadHomeiKodenPdf';
        title_name = '香典帳';
        break;
    }
    if (!func_name) {
      return;
    }
    this.httpClientService[func_name](this.seko_info.id)
      .then(async (response) => {
        this.homeichoStreamDownload(response, title_name);
      })
      .catch(error => {
        this.message = title_name + 'PDFの出力が失敗しました。';
        $('#message-popup').modal({
          closable: false,
          centered: false,
          detachable: false,
          transition: 'fade',
        }).modal('show');
      });
  }

  downloadMsgPdf() {
    if (!this.checkHomeiHaveData(this.Const.SERVICE_ID_MESSAGE)) {
      this.message = '出力対象データがございません。';
      $('#message-popup').modal({
        closable: false,
        centered: false,
        detachable: false,
        transition: 'fade',
      }).modal('show');
      return;
    }
    this.httpClientService.downloadMsgPdf(this.seko_info.id)
      .then(async (response) => {
        this.homeichoStreamDownload(response, '追悼メッセージ');
      })
      .catch(error => {
        this.message = '追悼メッセージPDFの出力が失敗しました。';
        $('#message-popup').modal({
          closable: false,
          centered: false,
          detachable: false,
          transition: 'fade',
        }).modal('show');
      });

  }

  homeichoStreamDownload(stream, title_name) {
    const blob = new Blob([stream]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    const file_name = title_name + '.pdf';

    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveOrOpenBlob(blob, file_name);
    } else {
      link.href = url;
      link.download = file_name;
      link.click();
    }
  }
  closePopup() {
    $('#message-popup').modal('hide');
    setTimeout(() => {
      this.message = null;
    }, 500);
  }

  haveService(service_id) {
    if (!this.seko_info || !this.seko_info.services || !this.seko_info.services.length) {
      return false;
    }

    for (const service of this.seko_info.services) {
      if (service.hall_service.id === service_id) {
        return true;
      }
    }
    return false;
  }
}

import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { LoaderService } from 'src/app/service/loader.service';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import { CompanySokeMenuPostRequest } from 'src/app/interfaces/soke-menu';
declare var $;


@Component({
  selector: 'app-com-soke-menu',
  templateUrl: './soke-menu.component.html',
  styleUrls: ['./soke-menu.component.scss']
})
export class SokeMenuComponent implements OnInit {

  Const = CommonConstants;

  login_company = Utils.getLoginCompany();
  companyCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  company_list = Utils.getCompanyList();
  company_id = null;

  menu_list = null;
  item_list = null;
  master_loaded = false;

  processing = false;

  constructor(
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
  ) {
    this.loaderSvc.call();
  }

  async ngOnInit() {
    this.initControl();
    await this.getSokeMenuList();
    this.master_loaded = true;
    await this.getData(this.company_id);
  }

  initControl() {
    if (this.company_list) {
      this.companyCombo.values = this.company_list.map(value => ({ name: value.base_name, value: value.id, data: value }));
      if (this.login_company) {
        if (this.login_company.base_type === CommonConstants.BASE_TYPE_COMPANY) {
          this.company_id = this.login_company.id;
        } else {
          this.company_id = this.company_list[0].id;
        }
      }
    }
  }

  async getSokeMenuList() {
    await this.httpClientService.getSokeMenuList().then((response) => {
      Utils.log(response);
      this.menu_list = response;

    }).catch(error => {
    });
  }

  async getData(company_id) {
    if (!this.loaderSvc.isLoading) {
      this.loaderSvc.call();
    }
    await this.httpClientService.getBase(company_id).then((response) => {
      Utils.log(response);
      this.item_list = response.soke_menus;
      this.resetSelectedItems();
      this.setSelectedItems();
      this.loaderSvc.dismiss();

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }
  resetSelectedItems() {
    for (const menu of this.menu_list) {
      menu.selected = true;
    }
  }
  setSelectedItems() {
    if (!this.item_list || !this.item_list.length) {
      return;
    }
    for (const menu of this.menu_list) {
      if (this.item_list) {
        const selected_item = this.item_list.find(v => v.id === menu.id);
        if (selected_item) {
          menu.selected = true;
        } else {
          menu.selected = false;
        }
      }
    }
  }
  selectItem(item) {
    item.selected = !item.selected;
  }
  companyChange(event) {
    if (!event || !event.data) {
      return;
    }
    this.getData(event.data.id);
  }

  saveData() {
    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }
    this.processing = true;
    const postData = this.getPostData();
    this.loaderSvc.call();
    this.httpClientService.addCompanySokeMenu(this.company_id, postData)
      .then(async (response) => {
        Utils.log(response);
        this.sessionSvc.save('message', { type: 'info', title: '葬家専用ページメニュー一覧を保存しました。' });
        this.getData(this.company_id);
        this.loaderSvc.dismiss();
        this.processing = false;
      })
      .catch(error => {
        this.sessionSvc.save('message', { type: 'error', title: '葬家専用ページメニュー一覧の保存が失敗しました。' });
        this.loaderSvc.dismiss();
        this.processing = false;
      });

  }
  getPostData() {
    const postRequest = new CompanySokeMenuPostRequest();
    postRequest.soke_menu_ids = new Array();
    for (const menu of this.menu_list) {
      if (menu.selected) {
        postRequest.soke_menu_ids.push(menu.id);
      }
    }

    return postRequest;
  }

}

import { Component, OnInit, ViewChild, ElementRef, Inject, LOCALE_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/pages/common/confirm-dialog/confirm-dialog.component';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { formatDate } from '@angular/common';
import { LoaderService } from 'src/app/service/loader.service';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import { CalendarOptionsDate } from 'src/app/components/com-calendar/com-calendar.component';
import { AdvertiseGetRequest, AdvertiseGetResponse, AdvertisePostRequest } from 'src/app/interfaces/advertise';
declare var $;

@Component({
  selector: 'app-com-advertise',
  templateUrl: './advertise.component.html',
  styleUrls: ['./advertise.component.scss']
})
export class AdvertiseComponent implements OnInit {
  @ViewChild('imageFileUpload', { static: false }) imageFileUpload: ElementRef;
  @ViewChild('linkFileUpload', { static: false }) linkFileUpload: ElementRef;

  Const = CommonConstants;
  calendarOptionDate: CalendarOptionsDate = new CalendarOptionsDate();

  login_company = Utils.getLoginCompany();
  companyCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  departCombo = { values: [], clearable: true, showOnFocus: false, forceSelection: false };
  hallCombo = { values: [], clearable: true, showOnFocus: false, forceSelection: false };
  inputDepartCombo = { values: [], clearable: true, showOnFocus: false, forceSelection: false };
  inputHallCombo = { values: [], clearable: true, showOnFocus: false, forceSelection: false };
  company_list = Utils.getCompanyList();
  company_id = null;
  company_name = null;
  depart_id = null;
  hall_id = null;

  item_list = null;

  item_edit: AdvertiseGetResponse = null;
  edit_type = 1;

  message = null;
  errField = new Map();

  page_per_count = 15;
  pagination = {
    pages: new Array(),
    current: 0
  };

  processing = false;
  load_error = false;
  constructor(
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    private dialog: MatDialog,
    @Inject(LOCALE_ID) private locale: string,
  ) {
    this.loaderSvc.call();
  }

  async ngOnInit() {
    this.initControl();
    await this.getData(this.company_id);
  }

  initControl() {
    if (this.company_list) {
      this.companyCombo.values = this.company_list.map(value => ({ name: value.base_name, value: value.id, data: value }));
      if (this.login_company) {
        if (this.login_company.base_type === CommonConstants.BASE_TYPE_COMPANY) {
          this.company_id = this.login_company.id;
          this.company_name = this.login_company.base_name;
          this.departCombo.values = Utils.GetDepartList(this.login_company);
        } else {
          this.company_id = this.company_list[0].id;
          this.company_name = this.company_list[0].base_name;
          this.departCombo.values = Utils.GetDepartList(this.company_list[0]);
        }
      }
    }
  }

  async getData(company_id) {
    this.load_error = false;
    const request = new AdvertiseGetRequest();
    request.company = company_id;
    if (!this.loaderSvc.isLoading) {
      this.loaderSvc.call();
    }
    await this.httpClientService.getAdvertiseList(request).then((response) => {
      Utils.log(response);
      this.item_list = response;
      this.calcPagination();
      this.loaderSvc.dismiss();

    }).catch(error => {
      if (error instanceof ProgressEvent) {
        this.sessionSvc.save('message', { type: 'warning', title: '広告ブロックの拡張機能をOFFにしてください。' });
      }
      this.load_error = true;
      this.loaderSvc.dismiss();
    });
  }
  calcPagination() {
    this.pagination.pages = new Array();
    this.pagination.current = 0;
    if (!this.item_list || !this.item_list.length) {
      return;
    }
    const count = Math.ceil(this.item_list.length / this.page_per_count);
    for (let i = 1; i <= count; i++) {
      this.pagination.pages.push(i);
    }
    this.pagination.current = 1;
  }

  companyChange(event, departComboEm, hallComboEm) {
    this.processing = true;
    this.departCombo.values = [];
    this.hallCombo.values = [];
    this.depart_id = null;
    this.hall_id = null;
    if (event && event.data) {
      this.departCombo.values = Utils.GetDepartList(event.data);
    }
    departComboEm.clear();
    departComboEm.initComponent();
    hallComboEm.clear();
    hallComboEm.initComponent();
    if (event && event.data) {
      this.getData(event.data.id);
    }
    this.processing = false;
  }

  departChange(event, hallComboEm) {
    if (this.processing) {
      return;
    }
    this.processing = true;
    this.hallCombo.values = [];
    this.hall_id = null;
    if (event && event.data) {
      this.hallCombo.values = Utils.GetHallList(event.data);
    }
    hallComboEm.clear();
    hallComboEm.initComponent();
    if (event && event.data) {
      this.getData(event.data.id);
    } else {
      this.getData(this.company_id);
    }
    this.processing = false;
  }

  hallChange(event) {
    if (this.processing) {
      return;
    }
    if (event && event.data) {
      this.getData(event.data.id);
    } else {
      this.getData(this.depart_id);
    }
  }

  inputDepartChange(event, hallComboEm) {
    if (this.processing) {
      return;
    }
    this.inputHallCombo.values = [];
    this.item_edit.hall_id = null;
    if (event && event.data) {
      this.inputHallCombo.values = Utils.GetHallList(event.data);
    }
    hallComboEm.clear();
    hallComboEm.initComponent();
  }

  showData(event, item = null) {
    this.processing = true;
    this.message = null;
    this.inputDepartCombo.values = [];
    this.inputHallCombo.values = [];
    if (this.login_company.base_type === CommonConstants.BASE_TYPE_COMPANY) {
      this.inputDepartCombo.values = Utils.GetDepartList(this.login_company);
    } else {
      const company = this.company_list.find(v => v.id === this.company_id);
      if (company) {
        this.inputDepartCombo.values = Utils.GetDepartList(company);
      }
    }
    if (!item) {
      this.edit_type = 1;
      this.item_edit = new AdvertiseGetResponse();
      this.item_edit.company = this.company_id;
      this.item_edit.depart_id = this.depart_id;
      this.item_edit.hall_id = this.hall_id;
    } else {
      this.edit_type = 2;
      if (event.target.classList.contains('operation') ||
        event.target.classList.contains('icon') ||
        event.target.classList.contains('url_link')) {
        return;
      }
      this.item_edit = JSON.parse(JSON.stringify(item));
      const base_type = Utils.getBaseType(this.item_edit.company);
      if (base_type === CommonConstants.BASE_TYPE_DEPART) {
        this.item_edit.depart_id = this.item_edit.company;
      } else if (base_type === CommonConstants.BASE_TYPE_PLACE_SELF
        || base_type === CommonConstants.BASE_TYPE_PLACE_OTHER) {
        this.item_edit.hall_id = this.item_edit.company;
        this.item_edit.depart_id = Utils.getParentBaseId(this.item_edit.company);
      }
    }
    if (this.item_edit.depart_id) {
      const depart = this.inputDepartCombo.values.find(v => v.data.id === this.item_edit.depart_id);
      if (depart) {
        this.inputHallCombo.values = Utils.GetHallList(depart.data);
      }
    }

    this.processing = false;
    $('#item-edit').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }
  closeItem() {
    setTimeout(() => {
      this.item_edit = null;
    }, 100);
  }
  saveData() {
    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }
    this.processing = true;
    if (!this.dataValidate()) {
      this.processing = false;
      return;
    }
    const postData = this.getPostData();
    this.loaderSvc.call();
    if (postData.id) {
      this.httpClientService.updateAdvertise(postData.id, postData)
        .then(async (response) => {
          Utils.log(response);
          this.sessionSvc.save('message', { type: 'info', title: '広告情報を保存しました。' });
          if (this.hall_id) {
            this.getData(this.hall_id);
          } else if (this.depart_id) {
            this.getData(this.depart_id);
          } else {
            this.getData(this.company_id);
          }
          this.loaderSvc.dismiss();
          this.processing = false;
        })
        .catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: '広告情報の保存が失敗しました。' });
          this.loaderSvc.dismiss();
          this.processing = false;
        });
    } else {
      this.httpClientService.addAdvertise(postData)
        .then(async (response) => {
          Utils.log(response);
          this.sessionSvc.save('message', { type: 'info', title: '広告情報を保存しました。' });
          if (this.hall_id) {
            this.getData(this.hall_id);
          } else if (this.depart_id) {
            this.getData(this.depart_id);
          } else {
            this.getData(this.company_id);
          }
          this.loaderSvc.dismiss();
          this.processing = false;
        })
        .catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: '広告情報の保存が失敗しました。' });
          this.loaderSvc.dismiss();
          this.processing = false;
        });
    }
    $('#item-edit').modal('hide');
    setTimeout(() => {
      this.item_edit = null;
    }, 100);

  }
  dataValidate() {
    const msgs = new Array();
    this.message = null;
    if (!this.item_edit.banner_file && !this.item_edit.banner_file_stream) {
      msgs.push('バナー画像');
    }
    if (!this.item_edit.begin_date) {
      msgs.push('掲載開始日');
    }
    if (!this.item_edit.end_date) {
      msgs.push('掲載終了日');
    }

    if (msgs.length > 0) {
      this.message = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: msgs.join(',')
      };
      return false;
    }

    return true;
  }

  getPostData(): AdvertisePostRequest {
    const postRequest = new AdvertisePostRequest();
    postRequest.id = this.item_edit.id;
    if (this.item_edit.hall_id) {
      postRequest.company = this.item_edit.hall_id;
    } else if (this.item_edit.depart_id) {
      postRequest.company = this.item_edit.depart_id;
    } else {
      postRequest.company = this.company_id;
    }
    if (this.item_edit.banner_file_stream) {
      postRequest.banner_file = this.item_edit.banner_file_stream;
    }
    postRequest.url = this.item_edit.url;
    postRequest.begin_date = formatDate(this.item_edit.begin_date, 'yyyy-MM-dd', this.locale);
    postRequest.end_date = formatDate(this.item_edit.end_date, 'yyyy-MM-dd', this.locale);
    if (this.item_edit.link_file_stream) {
      postRequest.link_file = this.item_edit.link_file_stream;
    } else if (!this.item_edit.link_file) {
      postRequest.link_file = null;
    }


    return postRequest;
  }

  pageTo(pagination, page_num) {
    pagination.current = page_num;
  }

  closeMessage() {
    this.message = null;
  }

  deleteData(id) {
    this.sessionSvc.clear('message');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: '広告情報を削除します、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.loaderSvc.call();
        this.httpClientService.deleteAdvertise(id)
          .then((response) => {
            Utils.log(response);
            this.sessionSvc.save('message', { type: 'info', title: '広告情報を削除しました。' });
            this.getData(this.company_id);
          })
          .catch(error => {
            this.sessionSvc.save('message', { type: 'error', title: '広告情報の削除が失敗しました。' });
            this.loaderSvc.dismiss();
          });
      }
    });
  }

  onDragOverImage(event) {
    event.preventDefault();
  }

  onDropImage(event) {
    event.preventDefault();
    if (!event.dataTransfer || !event.dataTransfer.files) {
      return;
    }
    this.addImage(event.dataTransfer.files);
  }

  addImage(files) {
    if (!files || !files.length) {
      return;
    }
    this.message = null;
    if (files.length !== 1) {
      this.message = { type: 'warning', title: '一つのファイルを指定してください。' };
      return;

    }

    if (!files[0].type.includes('image')) {
      this.message = { type: 'warning', title: '画像ファイルを指定してください。' };
      return;
    }
    if (files[0].size > 1 * 1024 * 1024) {
      this.message = { type: 'warning', title: '画像ファイルサイズは1MBまで登録可能です。' };
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      this.item_edit.banner_file_stream = e['target']['result'];
    };

    reader.readAsDataURL(files[0]);

  }

  selectFile() {
    const fileUpload = this.imageFileUpload.nativeElement;
    fileUpload.accept = 'image/*';
    fileUpload.multiple = false;

    fileUpload.onchange = () => {
      this.addImage(fileUpload.files);
      fileUpload.value = '';
    };
    fileUpload.click();
  }

  clearImage() {
    this.item_edit.banner_file = null;
    this.item_edit.banner_file_stream = null;
  }
  selectLinkFile() {
    const fileUpload = this.linkFileUpload.nativeElement;
    fileUpload.accept = '.pdf';

    fileUpload.onchange = () => {
      this.addLinkFile(fileUpload.files);
      fileUpload.value = '';
    };
    fileUpload.click();
  }

  addLinkFile(files) {
    if (!files || !files.length) {
      return;
    }
    this.message = null;
    if (!files[0].name.includes('.pdf')) {
      this.message = { type: 'warning', title: 'PDFファイルを指定してください。' };
      return;
    }
    this.item_edit.link_file_name = files[0].name;
    const reader = new FileReader();
    reader.onload = (e) => {
      this.item_edit.link_file_stream = e['target']['result'];
    };

    reader.readAsDataURL(files[0]);
  }

  clearLinkFile() {
    this.item_edit.link_file_name = null;
    this.item_edit.link_file_stream = null;
    this.item_edit.link_file = null;
  }

}

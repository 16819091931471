

export class SalesGetRequest {
  company: number;
  sales_yymm: string;
}
export class SalesGetResponse {
  company_name: string;
  confirm_staff_name: string;
  confirm_ts: Date;
}
export class InvoiceGatherPostRequest {
  sales_yymm: string;
}
export class InvoiceGatherPostResponse {
}

export class InvoicePutRequest {
}
export class InvoicePutResponse {
}


export class InvoiceDeleteResponse {
}


export class InquiryPostRequest {
  recipient_address: string;
  sender_name: string;
  sender_kana: string;
  sender_tel: string;
  sender_address: string;
  mail_body: string;
  fuho_num: string;
}

export class InquiryPostResponse {
}

export class InquiryToCompanyPostRequest {
  name: string;
  kana: string;
  tel: string;
  mail_address: string;
  content: string;
  fuho_num: string;
}

export class InquiryToCompanyPostResponse {
}


export class SupplierGetRequest {
  company_id: number;
}

export class SupplierBase {
  id?: number;
  company: number;
  name: string;
  tel: string;
  fax: string;
  zip_code: string;
  address: string;
  mail_address: string;
  order_mail_send_flg: boolean;
  order_mail_send_type: number;

}
export class SupplierGetResponse extends SupplierBase {
  selected?: boolean;
  default?: boolean;
}
export class SupplierPostRequest extends SupplierBase {
}
export class SupplierPostResponse {
}

export class SupplierPutRequest extends SupplierBase {
}
export class SupplierPutResponse {
}


export class SupplierDeleteResponse {
}

import { CommonConstants } from 'src/app/const/const';
import { SessionService } from 'src/app/service/session.service';
import { SekoGetResponse, HoyoGetResponse } from 'src/app/interfaces/seko';
import Encoding from 'encoding-japanese';
export class Utils {
  static log(...parameters: any[]) {
    const sessionSvc = new SessionService();
    const debug_log = sessionSvc.get('debug_log');
    if (debug_log) {
      console.log(...parameters);
    }
  }

  static getWeekName(date: Date) {
    if (!date) {
      return '';
    }
    const week = ['日', '月', '火', '水', '木', '金', '土'];
    return week[date.getDay()];
  }

  static getAge(birthday: Date, calcDay: Date) {
    if (!birthday || !calcDay) {
      return null;
    }
    const calcDayYearBirthday = new Date(calcDay.getFullYear(), birthday.getMonth(), birthday.getDate());
    let age = calcDay.getFullYear() - birthday.getFullYear();
    age = calcDay < calcDayYearBirthday ? age - 1 : age;
    return age >= 0 ? age : null;
  }

  static isBetweenTerm(begin_dt: string, end_dt: string) {
    if (!begin_dt && !end_dt) {
      return true;
    }
    const now = new Date();
    let begin_date = new Date();
    let end_date = new Date();
    begin_date.setDate(begin_date.getDate() - 1);
    end_date.setDate(end_date.getDate() + 1);
    if (begin_dt) {
      begin_date = new Date(begin_dt);
      begin_date.setHours(0);
      begin_date.setMinutes(0);
      begin_date.setSeconds(0);
      begin_date.setDate(begin_date.getDate());
    }
    if (end_dt) {
      end_date = new Date(end_dt);
      end_date.setHours(0);
      end_date.setMinutes(0);
      end_date.setSeconds(0);
      end_date.setDate(end_date.getDate() + 1);
    }
    return (begin_date <= now && now < end_date);
  }

  static calcTax(in_tax_price: number, tax_pct: number, round_type: number) {
    if (!in_tax_price || !tax_pct || !round_type) {
      return 0;
    }
    const calc_tax = in_tax_price * tax_pct / (100 + tax_pct);
    switch (round_type) {
      case CommonConstants.CALC_TYPE_FLOOR:
        return Math.floor(calc_tax);
      case CommonConstants.CALC_TYPE_CEIL:
        return Math.ceil(calc_tax);
      case CommonConstants.CALC_TYPE_ROUND:
        return Math.round(calc_tax);
      default:
        return 0;
    }
  }

  static getLoginCompany() {
    const sessionSvc = new SessionService();
    const base_list = sessionSvc.get('base_list');
    const login_info = sessionSvc.get('staff_login_info');
    if (!base_list || !login_info.staff.base) {
      return null;
    }
    let company = null;
    switch (login_info.staff.base.base_type) {
      case CommonConstants.BASE_TYPE_ADMIN:
      case CommonConstants.BASE_TYPE_COMPANY:
        company = login_info.staff.base;
        break;
      case CommonConstants.BASE_TYPE_DEPART:
        if (!login_info.staff.base.parent) {
          return null;
        }
        company = login_info.staff.base.parent;
        break;
      case CommonConstants.BASE_TYPE_PLACE_SELF:
      case CommonConstants.BASE_TYPE_PLACE_OTHER:
        if (!login_info.staff.base.parent || !login_info.staff.base.parent.parent) {
          return null;
        }
        company = login_info.staff.base.parent.parent;
        break;
    }
    let login_company = null;
    for (const base of base_list) {
      if (base.id === company.id) {
        login_company = base;
        break;
      }
      for (const child of base.children) {
        if (child.id === company.id) {
          login_company = child;
          break;
        }
      }
    }
    return login_company;
  }

  static getStaffList(base_id) {
    const sessionSvc = new SessionService();
    const staff_list = sessionSvc.get('staff_list');
    if (staff_list && staff_list.length) {
      const data = staff_list.filter(e => e.key === base_id);
      if (data && data.length) {
        return data[0].value;
      }
    }
    return null;

  }

  static checkCompanyCode(company_code) {
    if (!company_code) {
      return false;
    }
    const sessionSvc = new SessionService();
    const base_list = sessionSvc.get('base_list');
    if (!base_list) {
      return true;
    }
    for (const base of base_list) {
      if (base.company_code === company_code.trim()) {
        return false;
      }
      if (base.base_type === CommonConstants.BASE_TYPE_ADMIN) {
        for (const company of base.children) {
          if (company.company_code === company_code.trim()) {
            return false;
          }
        }

      }
    }
    return true;

  }

  static getCompanyId(base_id) {

    const sessionSvc = new SessionService();
    const base_list = sessionSvc.get('base_list');
    if (!base_list || !base_id) {
      return 0;
    }
    for (const base of base_list) {
      if (base.id === base_id) {
        return base.id;
      }
      let company_id = 0;
      for (const company of base.children) {
        if (company.id === base_id) {
          return company.id;
        }
        company_id = company.id;
        for (const depart of company.children) {
          if (depart.id === base_id) {
            return company_id;
          }
          for (const hall of depart.children) {
            if (hall.id === base_id) {
              return company_id;
            }
          }
        }
      }
    }
    return 0;
  }

  static getBaseType(base_id) {

    const sessionSvc = new SessionService();
    const base_list = sessionSvc.get('base_list');
    if (!base_list || !base_id) {
      return 0;
    }
    for (const base of base_list) {
      if (base.id === base_id) {
        return base.base_type;
      }
      for (const company of base.children) {
        if (company.id === base_id) {
          return company.base_type;
        }
        for (const depart of company.children) {
          if (depart.id === base_id) {
            return depart.base_type;
          }
          for (const hall of depart.children) {
            if (hall.id === base_id) {
              return hall.base_type;
            }
          }
        }
      }
    }
    return 0;
  }

  static getParentBaseId(base_id) {

    const sessionSvc = new SessionService();
    const base_list = sessionSvc.get('base_list');
    if (!base_list || !base_id) {
      return 0;
    }
    for (const base of base_list) {
      if (base.id === base_id) {
        return null;
      }
      for (const company of base.children) {
        if (company.id === base_id) {
          return base.id;
        }
        for (const depart of company.children) {
          if (depart.id === base_id) {
            return company.id;
          }
          for (const hall of depart.children) {
            if (hall.id === base_id) {
              return depart.id;
            }
          }
        }
      }
    }
    return 0;
  }

  static getDepartBaseId(base_id) {

    const sessionSvc = new SessionService();
    const base_list = sessionSvc.get('base_list');
    if (!base_list || !base_id) {
      return 0;
    }
    for (const base of base_list) {
      if (base.id === base_id) {
        return base.id;
      }
      for (const company of base.children) {
        if (company.id === base_id) {
          return company.id;
        }
        for (const depart of company.children) {
          if (depart.id === base_id) {
            return depart.id;
          }
          for (const hall of depart.children) {
            if (hall.id === base_id) {
              return depart.id;
            }
          }
        }
      }
    }
    return 0;
  }

  static getServiceList(base_id) {

    const sessionSvc = new SessionService();
    const base_list = sessionSvc.get('base_list');
    if (!base_list || !base_id) {
      return null;
    }
    let ret_service = null;
    for (const base of base_list) {
      if (base.id === base_id) {
        return null;
      }
      for (const company of base.children) {
        if (ret_service) {
          break;
        }
        if (company.id === base_id) {
          ret_service = company.services;
          break;
        }
        for (const depart of company.children) {
          if (ret_service) {
            break;
          }
          if (depart.id === base_id) {
            ret_service = depart.services;
            break;
          }
          for (const hall of depart.children) {
            if (hall.id === base_id) {
              ret_service = depart.services;
              break;
            }
          }
        }
      }
    }
    if (ret_service && ret_service.length) {
      ret_service.sort((a, b) => a.id - b.id);
    }
    return ret_service;
  }

  static getCompanyList() {
    const login_company = this.getLoginCompany();
    if (login_company) {
      if (login_company.base_type === CommonConstants.BASE_TYPE_ADMIN) {
        return login_company.children;
      } else {
        return [login_company];
      }
    } else {
      return null;
    }
  }

  static getSekoBaseList(base) {
    if (!base) {
      return null;
    }
    let ret: Array<any> = null;
    switch (base.base_type) {
      case CommonConstants.BASE_TYPE_ADMIN:
        ret = new Array();
        if (base.children) {
          base.children.forEach(value => {
            ret = ret.concat(this.getSekoBaseList(value));
          });
        }
        break;
      case CommonConstants.BASE_TYPE_COMPANY:
        ret = new Array();
        if (base.children) {
          base.children.forEach(value => {
            ret = ret.concat(this.getSekoBaseList(value));
          });
        }
        break;
      case CommonConstants.BASE_TYPE_DEPART:
        ret = new Array();
        ret.push({ name: base.base_name, value: base.id, data: base });
        if (base.children) {
          base.children.forEach(value => {
            if (value.base_type === CommonConstants.BASE_TYPE_PLACE_SELF) {
              ret.push({ name: value.base_name, value: value.id, data: value });
            }
          });
        }
        break;
    }
    return ret;
  }

  static GetDepartList(company) {
    if (!company || company.base_type !== CommonConstants.BASE_TYPE_COMPANY) {
      return null;
    }
    return company.children.map(value => {
      return { name: value.base_name, value: value.id, data: value };
    });
  }

  static GetHallList(depart) {
    if (!depart || depart.base_type !== CommonConstants.BASE_TYPE_DEPART) {
      return null;
    }
    return depart.children.map(value => {
      return { name: value.base_name, value: value.id, data: value };
    });
  }

  static GetAllHallList(base) {
    if (!base) {
      return null;
    }
    let ret: Array<any> = null;
    switch (base.base_type) {
      case CommonConstants.BASE_TYPE_ADMIN:
        ret = new Array();
        if (base.children) {
          base.children.forEach(value => {
            ret = ret.concat(this.GetAllHallList(value));
          });
        }
        break;
      case CommonConstants.BASE_TYPE_COMPANY:
        ret = new Array();
        if (base.children) {
          base.children.forEach(value => {
            ret = ret.concat(this.GetAllHallList(value));
          });
        }
        break;
      case CommonConstants.BASE_TYPE_DEPART:
        ret = base.children.map(value => {
          return { name: value.base_name, value: value.id, data: value };
        });
        break;
    }
    return ret;
  }

  static getAllBaseList(base) {
    if (!base) {
      return null;
    }
    let ret: Array<any> = null;
    switch (base.base_type) {
      case CommonConstants.BASE_TYPE_ADMIN:
        ret = new Array();
        if (base.children) {
          base.children.forEach(value => {
            ret = ret.concat(this.getAllBaseList(value));
          });
        }
        break;
      case CommonConstants.BASE_TYPE_COMPANY:
        ret = new Array();
        ret.push({ name: base.base_name, value: base.id, data: base });
        if (base.children) {
          base.children.forEach(value => {
            ret = ret.concat(this.getAllBaseList(value));
          });
        }
        break;
      case CommonConstants.BASE_TYPE_DEPART:
        ret = new Array();
        ret.push({ name: base.base_name, value: base.id, data: base });
        if (base.children) {
          base.children.forEach(value => {
            ret.push({ name: value.base_name, value: value.id, data: value });
          });
        }
        break;
    }
    return ret;
  }

  static getOrderStatusName(status_id) {
    switch (status_id) {
      case CommonConstants.ORDER_STATUS_ID_NONE:
        return CommonConstants.ORDER_STATUS_NONE;
      case CommonConstants.ORDER_STATUS_ID_UNORDER:
        return CommonConstants.ORDER_STATUS_UNORDER;
      case CommonConstants.ORDER_STATUS_ID_ORDERED:
        return CommonConstants.ORDER_STATUS_ORDERED;
      case CommonConstants.ORDER_STATUS_ID_CONFIRMED:
        return CommonConstants.ORDER_STATUS_CONFIRMED;
      default:
        return '';
    }
  }

  static getReleaseStatusName(status_id) {
    switch (status_id) {
      case CommonConstants.RELEASE_STATUS_ID_NG:
        return CommonConstants.RELEASE_STATUS_NG;
      case CommonConstants.RELEASE_STATUS_ID_OK:
        return CommonConstants.RELEASE_STATUS_OK;
      default:
        return '';
    }
  }

  static getAfOrderStatusName(status_id) {
    switch (status_id) {
      case CommonConstants.AF_ORDER_STATUS_ID_NEGOTIATING:
        return CommonConstants.AF_ORDER_STATUS_NEGOTIATING;
      case CommonConstants.AF_ORDER_STATUS_ID_ORDERED:
        return CommonConstants.AF_ORDER_STATUS_ORDERED;
      case CommonConstants.AF_ORDER_STATUS_ID_FAILURED:
        return CommonConstants.AF_ORDER_STATUS_FAILURED;
      default:
        return '';
    }
  }

  static formatPhoneNum(num: string) {
    const groups = [];
    if (!Number(num) || num.length < 8) {
      return num;
    }
    groups[5] = {
      '01564': 1,
      '01558': 1,
      '01586': 1,
      '01587': 1,
      '01634': 1,
      '01632': 1,
      '01547': 1,
      '05769': 1,
      '04992': 1,
      '04994': 1,
      '01456': 1,
      '01457': 1,
      '01466': 1,
      '01635': 1,
      '09496': 1,
      '08477': 1,
      '08512': 1,
      '08396': 1,
      '08388': 1,
      '08387': 1,
      '08514': 1,
      '07468': 1,
      '01655': 1,
      '01648': 1,
      '01656': 1,
      '01658': 1,
      '05979': 1,
      '04996': 1,
      '01654': 1,
      '01372': 1,
      '01374': 1,
      '09969': 1,
      '09802': 1,
      '09912': 1,
      '09913': 1,
      '01398': 1,
      '01377': 1,
      '01267': 1,
      '04998': 1,
      '01397': 1,
      '01392': 1,
    };
    groups[4] = {
      '0768': 2,
      '0770': 2,
      '0772': 2,
      '0774': 2,
      '0773': 2,
      '0767': 2,
      '0771': 2,
      '0765': 2,
      '0748': 2,
      '0747': 2,
      '0746': 2,
      '0826': 2,
      '0749': 2,
      '0776': 2,
      '0763': 2,
      '0761': 2,
      '0766': 2,
      '0778': 2,
      '0824': 2,
      '0797': 2,
      '0796': 2,
      '0555': 2,
      '0823': 2,
      '0798': 2,
      '0554': 2,
      '0820': 2,
      '0795': 2,
      '0556': 2,
      '0791': 2,
      '0790': 2,
      '0779': 2,
      '0558': 2,
      '0745': 2,
      '0794': 2,
      '0557': 2,
      '0799': 2,
      '0738': 2,
      '0567': 2,
      '0568': 2,
      '0585': 2,
      '0586': 2,
      '0566': 2,
      '0564': 2,
      '0565': 2,
      '0587': 2,
      '0584': 2,
      '0581': 2,
      '0572': 2,
      '0574': 2,
      '0573': 2,
      '0575': 2,
      '0576': 2,
      '0578': 2,
      '0577': 2,
      '0569': 2,
      '0594': 2,
      '0827': 2,
      '0736': 2,
      '0735': 2,
      '0725': 2,
      '0737': 2,
      '0739': 2,
      '0743': 2,
      '0742': 2,
      '0740': 2,
      '0721': 2,
      '0599': 2,
      '0561': 2,
      '0562': 2,
      '0563': 2,
      '0595': 2,
      '0596': 2,
      '0598': 2,
      '0597': 2,
      '0744': 2,
      '0852': 2,
      '0956': 2,
      '0955': 2,
      '0954': 2,
      '0952': 2,
      '0957': 2,
      '0959': 2,
      '0966': 2,
      '0965': 2,
      '0964': 2,
      '0950': 2,
      '0949': 2,
      '0942': 2,
      '0940': 2,
      '0930': 2,
      '0943': 2,
      '0944': 2,
      '0948': 2,
      '0947': 2,
      '0946': 2,
      '0967': 2,
      '0968': 2,
      '0987': 2,
      '0986': 2,
      '0985': 2,
      '0984': 2,
      '0993': 2,
      '0994': 2,
      '0997': 2,
      '0996': 2,
      '0995': 2,
      '0983': 2,
      '0982': 2,
      '0973': 2,
      '0972': 2,
      '0969': 2,
      '0974': 2,
      '0977': 2,
      '0980': 2,
      '0979': 2,
      '0978': 2,
      '0920': 2,
      '0898': 2,
      '0855': 2,
      '0854': 2,
      '0853': 2,
      '0553': 2,
      '0856': 2,
      '0857': 2,
      '0863': 2,
      '0859': 2,
      '0858': 2,
      '0848': 2,
      '0847': 2,
      '0835': 2,
      '0834': 2,
      '0833': 2,
      '0836': 2,
      '0837': 2,
      '0846': 2,
      '0845': 2,
      '0838': 2,
      '0865': 2,
      '0866': 2,
      '0892': 2,
      '0889': 2,
      '0887': 2,
      '0893': 2,
      '0894': 2,
      '0897': 2,
      '0896': 2,
      '0895': 2,
      '0885': 2,
      '0884': 2,
      '0869': 2,
      '0868': 2,
      '0867': 2,
      '0875': 2,
      '0877': 2,
      '0883': 2,
      '0880': 2,
      '0879': 2,
      '0829': 2,
      '0550': 2,
      '0228': 2,
      '0226': 2,
      '0225': 2,
      '0224': 2,
      '0229': 2,
      '0233': 2,
      '0237': 2,
      '0235': 2,
      '0234': 2,
      '0223': 2,
      '0220': 2,
      '0192': 2,
      '0191': 2,
      '0187': 2,
      '0193': 2,
      '0194': 2,
      '0198': 2,
      '0197': 2,
      '0195': 2,
      '0238': 2,
      '0240': 2,
      '0260': 2,
      '0259': 2,
      '0258': 2,
      '0257': 2,
      '0261': 2,
      '0263': 2,
      '0266': 2,
      '0265': 2,
      '0264': 2,
      '0256': 2,
      '0255': 2,
      '0243': 2,
      '0242': 2,
      '0241': 2,
      '0244': 2,
      '0246': 2,
      '0254': 2,
      '0248': 2,
      '0247': 2,
      '0186': 2,
      '0185': 2,
      '0144': 2,
      '0143': 2,
      '0142': 2,
      '0139': 2,
      '0145': 2,
      '0146': 2,
      '0154': 2,
      '0153': 2,
      '0152': 2,
      '0138': 2,
      '0137': 2,
      '0125': 2,
      '0124': 2,
      '0123': 2,
      '0126': 2,
      '0133': 2,
      '0136': 2,
      '0135': 2,
      '0134': 2,
      '0155': 2,
      '0156': 2,
      '0176': 2,
      '0175': 2,
      '0174': 2,
      '0178': 2,
      '0179': 2,
      '0184': 2,
      '0183': 2,
      '0182': 2,
      '0173': 2,
      '0172': 2,
      '0162': 2,
      '0158': 2,
      '0157': 2,
      '0163': 2,
      '0164': 2,
      '0167': 2,
      '0166': 2,
      '0165': 2,
      '0267': 2,
      '0250': 2,
      '0533': 2,
      '0422': 2,
      '0532': 2,
      '0531': 2,
      '0436': 2,
      '0428': 2,
      '0536': 2,
      '0299': 2,
      '0294': 2,
      '0293': 2,
      '0475': 2,
      '0295': 2,
      '0297': 2,
      '0296': 2,
      '0495': 2,
      '0438': 2,
      '0466': 2,
      '0465': 2,
      '0467': 2,
      '0478': 2,
      '0476': 2,
      '0470': 2,
      '0463': 2,
      '0479': 2,
      '0493': 2,
      '0494': 2,
      '0439': 2,
      '0268': 2,
      '0480': 2,
      '0460': 2,
      '0538': 2,
      '0537': 2,
      '0539': 2,
      '0279': 2,
      '0548': 2,
      '0280': 2,
      '0282': 2,
      '0278': 2,
      '0277': 2,
      '0269': 2,
      '0270': 2,
      '0274': 2,
      '0276': 2,
      '0283': 2,
      '0551': 2,
      '0289': 2,
      '0287': 2,
      '0547': 2,
      '0288': 2,
      '0544': 2,
      '0545': 2,
      '0284': 2,
      '0291': 2,
      '0285': 2,
      '0120': 3,
      '0570': 3,
      '0800': 3,
      '0990': 3,
    };
    groups[3] = {
      '020': 3,
      '070': 3,
      '080': 3,
      '090': 3,
      '099': 3,
      '054': 3,
      '058': 3,
      '098': 3,
      '095': 3,
      '097': 3,
      '052': 3,
      '053': 3,
      '011': 3,
      '096': 3,
      '049': 3,
      '015': 3,
      '048': 3,
      '072': 3,
      '084': 3,
      '028': 3,
      '024': 3,
      '076': 3,
      '023': 3,
      '047': 3,
      '029': 3,
      '075': 3,
      '025': 3,
      '055': 3,
      '026': 3,
      '079': 3,
      '082': 3,
      '027': 3,
      '078': 3,
      '077': 3,
      '083': 3,
      '022': 3,
      '086': 3,
      '089': 3,
      '045': 3,
      '044': 3,
      '092': 3,
      '046': 3,
      '017': 3,
      '093': 3,
      '059': 3,
      '073': 3,
      '019': 3,
      '087': 3,
      '042': 3,
      '018': 3,
      '043': 3,
      '088': 3,
      '050': 4,
    };
    groups[2] = {
      '04': 4,
      '03': 4,
      '06': 4,
    };
    groups[6] = {
      'aa': 4,
      'bb': 4,
    };

    let ret = '';

    groups.forEach((data, i) => {
      const area = num.slice(0, i);

      if (data[area]) {
        const tmp1 = num.slice(0, i);
        const tmp2 = num.slice(i, i + data[area]);
        const tmp3 = num.slice(i + data[area]);

        ret = tmp1 + '-' + tmp2 + '-' + tmp3;
      }
    });

    return ret;

  }

  static formatSekoDateList(sekoList: SekoGetResponse[]) {
    if (!sekoList) {
      return;
    }
    for (const seko of sekoList) {
      this.formatSekoDate(seko);
    }
  }

  static formatSekoDate(seko: SekoGetResponse) {
    if (!seko) {
      return;
    }
    if (seko.seko_date) {
      seko.seko_date = new Date(seko.seko_date + 'T00:00+0900');
    }
    if (seko.death_date) {
      seko.death_date = new Date(seko.death_date + 'T00:00+0900');
    }
    if (seko.fuho_site_end_date) {
      seko.fuho_site_end_date = new Date(seko.fuho_site_end_date + 'T00:00+0900');
    }
    if (seko.kojin) {
      for (const kojin of seko.kojin) {
        if (kojin.birth_date) {
          kojin.birth_date = new Date(kojin.birth_date + 'T00:00+0900');
        }
        if (kojin.birth_date) {
          kojin.death_date = new Date(kojin.death_date + 'T00:00+0900');
        }
      }
    }
    if (seko.schedules) {
      for (const schedule of seko.schedules) {
        if (schedule.schedule_date) {
          schedule.schedule_date = new Date(schedule.schedule_date + 'T00:00+0900');
        }
        if (schedule.begin_time) {
          schedule.begin_time = new Date('1900-01-01T' + schedule.begin_time + '+0900');
        }
        if (schedule.end_time) {
          schedule.end_time = new Date('1900-01-01T' + schedule.end_time + '+0900');
        }
      }
    }
    if (seko.videos) {
      for (const video of seko.videos) {
        if (video.live_begin_ts) {
          video.live_begin_ts = new Date(video.live_begin_ts);
        }
        if (video.live_end_ts) {
          video.live_end_ts = new Date(video.live_end_ts);
        }
        if (video.delivery_end_ts) {
          video.delivery_end_ts = new Date(video.delivery_end_ts);
        }
      }
    }
    if (seko.hoyo_seko) {
      for (const hoyo of seko.hoyo_seko) {
        if (hoyo.hoyo_planned_date) {
          hoyo.hoyo_planned_date = new Date(hoyo.hoyo_planned_date + 'T00:00+0900');
        }
        if (hoyo.hoyo_activity_date) {
          hoyo.hoyo_activity_date = new Date(hoyo.hoyo_activity_date + 'T00:00+0900');
        }
        if (hoyo.hoyo_site_end_date) {
          hoyo.hoyo_site_end_date = new Date(hoyo.hoyo_site_end_date + 'T00:00+0900');
        }
        if (hoyo.begin_time) {
          hoyo.begin_time = new Date('1900-01-01T' + hoyo.begin_time + '+09:00');
        }
        if (hoyo.end_time) {
          hoyo.end_time = new Date('1900-01-01T' + hoyo.end_time + '+09:00');
        }
        if (hoyo.schedules) {
          for (const schedule of hoyo.schedules) {
            if (schedule.schedule_date) {
              schedule.schedule_date = new Date(schedule.schedule_date + 'T00:00+0900');
            }
            if (schedule.begin_time) {
              schedule.begin_time = new Date('1900-01-01T' + schedule.begin_time + '+09:00');
            }
            if (schedule.end_time) {
              schedule.end_time = new Date('1900-01-01T' + schedule.end_time + '+09:00');
            }
          }
        }
      }
    }
  }

  static formatHoyoDate(hoyo: HoyoGetResponse) {
    if (!hoyo) {
      return;
    }
    if (hoyo.hoyo_planned_date) {
      hoyo.hoyo_planned_date = new Date(hoyo.hoyo_planned_date);
    }
    if (hoyo.hoyo_activity_date) {
      hoyo.hoyo_activity_date = new Date(hoyo.hoyo_activity_date);
    }
    if (hoyo.hoyo_site_end_date) {
      hoyo.hoyo_site_end_date = new Date(hoyo.hoyo_site_end_date);
    }
    if (hoyo.begin_time) {
      hoyo.begin_time = new Date('1900-01-01T' + hoyo.begin_time + '+09:00');
    }
    if (hoyo.end_time) {
      hoyo.end_time = new Date('1900-01-01T' + hoyo.end_time + '+09:00');
    }
    if (hoyo.schedules) {
      for (const schedule of hoyo.schedules) {
        if (schedule.schedule_date) {
          schedule.schedule_date = new Date(schedule.schedule_date);
        }
        if (schedule.begin_time) {
          schedule.begin_time = new Date('1900-01-01T' + schedule.begin_time + '+09:00');
        }
        if (schedule.end_time) {
          schedule.end_time = new Date('1900-01-01T' + schedule.end_time + '+09:00');
        }
      }
    }
  }

  static getYearDisplay(
    date: Date, wareki_list: Array<any> = null, convert_flg: boolean = false, kanji: boolean = false
  ): string {
    if (!date) {
      return '';
    }
    let warekiYear = null;
    if (!wareki_list || !wareki_list.length || !convert_flg) {
      warekiYear = date.getFullYear() + '年';
      if (kanji) {
        warekiYear = this.numbersToKanji(date.getFullYear()) + '年';
      } else {
        warekiYear = date.getFullYear() + '年';
      }
      return warekiYear;
    }
    for (const wareki of wareki_list) {
      if (date >= wareki.begin_date && date <= wareki.end_date) {
        if (kanji) {
          warekiYear = wareki.name + this.numbersToKanji(date.getFullYear() - wareki.minus_years) + '年';
        } else {
          warekiYear = wareki.name + (date.getFullYear() - wareki.minus_years) + '年';
        }
        break;
      }
    }
    return warekiYear;
  }
  static getEpsilonErrMsg(msg: string) {
    if (!msg) {
      return null;
    }
    const start_index = msg.indexOf('err_detail');
    if (start_index >= 0) {
      const end_index = msg.indexOf('"', start_index + 12);
      if (end_index > start_index + 12) {
        return msg.substring(start_index + 12, end_index);
      }
    }
    if (msg.indexOf('<!DOCTYPE html>') >= 0) {
      return '内部エラー発生';
    }
    return msg;
  }
  static exportCsv(file_name: string, data: string, encoding = 'sjis') {
    if (!file_name || !data) {
      return null;
    }
    const unicodeList = [];
    for (let i = 0; i < data.length; i += 1) {
      unicodeList.push(data.charCodeAt(i));
    }

    // 変換処理の実施
    let blob = null;
    if (encoding === 'sjis') {
      const shiftJisCodeList = Encoding.convert(unicodeList, 'sjis', 'unicode');
      const uInt8List = new Uint8Array(shiftJisCodeList);
      blob = new Blob([uInt8List], { type: 'text/csv' });
    } else {
      // CSV ファイルは `UTF-8 BOM有り` で出力する
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      blob = new Blob([bom, data], { type: 'text/csv' });

    }
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveOrOpenBlob(blob, file_name);
    } else {
      link.href = url;
      link.download = file_name;
      link.click();
    }
  }

  static numbersToKanji(num, is_wareki = false) {
    if (num > 99 || num < 0) {
      return '';
    }
    if (num === 0) {
      return '零';
    }
    if (is_wareki && num === 1) {
      return '元';
    }
    let ret = '';
    const kanjiNums = ['', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
    const kanjiNames = ['十', '百', '千', '万', '億', '兆', '京'];
    const exponents = [1, 2, 3, 4, 8, 12, 16];
    const exponentsLen = exponents.length;
    for (let i = exponentsLen; i >= 0; --i) {
      const bias = Math.pow(10, exponents[i]);
      if (num >= bias) {
        const top = Math.floor(num / bias);
        if (top >= 10) {
          ret += this.numbersToKanji(top);
        } else {
          if (top === 1 && exponents[i] <= 3) {
            // ※先頭の数字が1、かつ指数が3 (千の位) 以下の場合のみ『一』をつけない
          } else {
            ret += kanjiNums[top];
          }
        }
        ret += kanjiNames[i];
        num -= top * bias;
      }
    }
    ret += kanjiNums[num];
    return ret;
  }

  static addressConvNumber(text: string): string {

    if (!text) {
      return '';
    }
    return text.replace(/0/g, '〇')
      .replace(/1/g, '一')
      .replace(/2/g, '二')
      .replace(/3/g, '三')
      .replace(/4/g, '四')
      .replace(/5/g, '五')
      .replace(/6/g, '六')
      .replace(/7/g, '七')
      .replace(/8/g, '八')
      .replace(/9/g, '九')
      .replace(/０/g, '〇')
      .replace(/１/g, '一')
      .replace(/２/g, '二')
      .replace(/３/g, '三')
      .replace(/４/g, '四')
      .replace(/５/g, '五')
      .replace(/６/g, '六')
      .replace(/７/g, '七')
      .replace(/８/g, '八')
      .replace(/９/g, '九');
  }

  static canUseCredit(seko: SekoGetResponse, card_type) {
    if (!seko || !seko.seko_company) {
      return false;
    }
    // セレモ以外は全カード使用できる
    if (seko.seko_company.company_code !== '06644460') {
      return true;
    }
    // セレモはVisaとMasterのみ
    if (card_type === 1 || card_type === 2) {
      return true;
    }
    return false;
  }

}

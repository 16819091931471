import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CalendarOptionsMonth } from 'src/app/components/com-calendar/com-calendar.component';
import { ConfirmDialogComponent } from 'src/app/pages/common/confirm-dialog/confirm-dialog.component';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { LoaderService } from 'src/app/service/loader.service';
import { InvoiceGatherPostRequest } from 'src/app/interfaces/invoice';
import { CommonConstants } from 'src/app/const/const';
import { Calendar } from 'src/app/const/const';
import { Utils } from 'src/app/const/func-util';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-com-monthly-summary',
  templateUrl: './monthly-summary.component.html',
  styleUrls: ['./monthly-summary.component.scss']
})
export class MonthlySummaryComponent implements OnInit {
  login_info = this.sessionSvc.get('staff_login_info');
  date_option_config: CalendarOptionsMonth = new CalendarOptionsMonth();
  sales_month = null;
  processing = false;

  constructor(
    private router: Router,
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    private dialog: MatDialog,
    @Inject(LOCALE_ID) private locale: string,
  ) { }

  ngOnInit(): void {
    if (this.login_info.staff.base.base_type !== CommonConstants.BASE_TYPE_ADMIN) {
      this.loaderSvc.dismiss();
      this.router.navigate(['foc/top/']);
      return;
    }
    this.initControl();
  }

  initControl() {
    const nowMonth = new Date();
    const lastMonth = new Date(formatDate(nowMonth, 'yyyy/MM/01', this.locale));
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    this.date_option_config.minDate = lastMonth;
    this.date_option_config.maxDate = nowMonth;
    this.date_option_config.text = Calendar.JA_TEXT_MONTH;
    this.sales_month = nowMonth;

  }

  monthlyGather() {
    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }
    if (!this.sales_month) {
      const message = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: '対象年月'
      };
      this.sessionSvc.save('message', message);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: '月次の集計処理を行います、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        const postData = new InvoiceGatherPostRequest();
        postData.sales_yymm = formatDate(this.sales_month, 'yyyyMM', this.locale);
        this.processing = true;
        this.loaderSvc.call();
        this.httpClientService.monthlyGather(postData)
          .then(async (response) => {
            Utils.log(response);
            this.sessionSvc.save('message', { type: 'info', title: '集計処理が正常に終了しました。' });
            this.processing = false;
            this.loaderSvc.dismiss();
          })
          .catch(error => {
            this.sessionSvc.save('message', { type: 'error', title: '集計処理が失敗しました。' });
            this.processing = false;
            this.loaderSvc.dismiss();
          });
      }
    });
  }

}

import { Component, OnInit, HostListener, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-fam-soke-manual-sano',
  templateUrl: './soke-manual.component.html',
  styleUrls: ['./soke-manual.component.scss']
})
export class SokeManualSanoComponent implements OnInit, AfterViewInit  {

  @ViewChild('menu') elMenu: ElementRef;
  page_width = 0;
  m_height = 0;
  h_rate = 1.4125;
  m_h_rate = 0.03125;
  p_rate = [
    {p_num: 1, tag: 'p1', menu_p_rate: -0.5275, tag_p_rate: 0.1},
    {p_num: 3, tag: 'p3', menu_p_rate: -0.505, tag_p_rate: 0.675},
    {p_num: 4, tag: 'p4', menu_p_rate: -0.4825, tag_p_rate: 0.75},
    {p_num: 6, tag: 'p6', menu_p_rate: -0.46, tag_p_rate: 0.1},
    {p_num: 8, tag: 'p8', menu_p_rate: -0.4375, tag_p_rate: 0.1},
    {p_num: 9, tag: 'p9', menu_p_rate: -0.415, tag_p_rate: 0.1},
    {p_num: 10, tag: 'p10-1', menu_p_rate: -0.3925, tag_p_rate: 0.1},
    {p_num: 10, tag: 'p10-2', menu_p_rate: -0.37, tag_p_rate: 0.7375},
    {p_num: 12, tag: 'p12', menu_p_rate: -0.3475, tag_p_rate: 0.8125},
    {p_num: 15, tag: 'p15', menu_p_rate: -0.325, tag_p_rate: 0.1},
    {p_num: 16, tag: 'p16-1', menu_p_rate: -0.3025, tag_p_rate: 0.6},
    {p_num: 16, tag: 'p16-2', menu_p_rate: -0.28, tag_p_rate: 1.05},
    {p_num: 18, tag: 'p18', menu_p_rate: -0.2575, tag_p_rate: 0.6875},
    {p_num: 20, tag: 'p20', menu_p_rate: -0.235, tag_p_rate: 0.1},
    {p_num: 22, tag: 'p22', menu_p_rate: -0.2125, tag_p_rate: 0.1},
    {p_num: 23, tag: 'p23', menu_p_rate: -0.19, tag_p_rate: 0.1},
    {p_num: 25, tag: 'p25', menu_p_rate: -0.1675, tag_p_rate: 0.7375},
    {p_num: 26, tag: 'p26', menu_p_rate: -0.145, tag_p_rate: 0.1},
    {p_num: 28, tag: 'p28', menu_p_rate: -0.1225, tag_p_rate: 0.1},
    {p_num: 29, tag: 'p29', menu_p_rate: -0.10375, tag_p_rate: 0.1},
    {p_num: 30, tag: 'p30', menu_p_rate: -0.09875, tag_p_rate: 0.1},
    {p_num: 31, tag: 'p31', menu_p_rate: -0.09375, tag_p_rate: 0.1},
  ];
  pages = [];
  ids =  [];
  constructor() {
  }
  ngOnInit(): void {
    this.ids = [...Array(32)].map((_, i) => (i + 3).toString().padStart(4, '0'));
  }

  ngAfterViewInit() {
    this.calcPosition();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.calcPosition();
  }
  calcPosition() {

    this.page_width = this.elMenu.nativeElement.offsetWidth;
    this.m_height = this.page_width * this.m_h_rate;
    this.pages = this.p_rate.map(value => ({
      tag: value.tag,
      menu_p: (-1) * this.m_height * 22 - 10 + value.menu_p_rate * this.page_width,
      tag_p: (this.page_width * this.h_rate + 10) * (value.p_num - 1) + value.tag_p_rate * this.page_width
    }));

  }
}

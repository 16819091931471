import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/pages/common/confirm-dialog/confirm-dialog.component';
import { SessionService } from 'src/app/service/session.service';
import { StaffService } from 'src/app/service/staff.service';
import { AddressGetRequest } from 'src/app/interfaces/master';
import { HttpClientService } from 'src/app/service/http-client.service';
import { LoaderService } from 'src/app/service/loader.service';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import { StaffGetResponse, StaffPostRequest } from 'src/app/interfaces/staff';
declare var $;

@Component({
  selector: 'app-com-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})

export class StaffComponent implements OnInit {

  Const = CommonConstants;

  login_company = Utils.getLoginCompany();
  companyCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  departCombo = { values: [], clearable: true, showOnFocus: false, forceSelection: false };
  hallCombo = { values: [], clearable: true, showOnFocus: false, forceSelection: false };
  inputDepartCombo = { values: [], clearable: true, showOnFocus: false, forceSelection: false };
  inputHallCombo = { values: [], clearable: true, showOnFocus: false, forceSelection: false };
  editDepartCombo = { values: [], clearable: true, showOnFocus: false, forceSelection: false };
  editHallCombo = { values: [], clearable: true, showOnFocus: false, forceSelection: false };
  company_list = Utils.getCompanyList();
  company_id = null;
  is_admin_base = false;
  company_name = null;
  company_code = null;
  depart_id = null;
  hall_id = null;

  item_list = null;

  item_edit = null;
  edit_type = 1;

  message = null;
  errField = new Map();

  page_per_count = 15;
  pagination = {
    pages: new Array(),
    current: 0
  };
  processing = false;

  constructor(
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    private staffSvc: StaffService,
    private dialog: MatDialog,
  ) {
    this.loaderSvc.call();
  }

  ngOnInit(): void {
    this.initControl();
  }

  initControl() {
    if (this.company_list) {
      if (this.login_company) {
        if (this.login_company.base_type === CommonConstants.BASE_TYPE_COMPANY) {
          this.companyCombo.values = this.company_list.map(value => ({ name: value.base_name, value: value.id, data: value }));
          this.departCombo.values = Utils.GetDepartList(this.login_company);
        } else {
          this.companyCombo.values.push({ name: this.login_company.base_name, value: this.login_company.id, data: this.login_company });

          this.company_list.forEach(value => {
            this.companyCombo.values.push({ name: value.base_name, value: value.id, data: value });
          });
          this.companyCombo.disableSearch = false;
          this.is_admin_base = true;
        }
        this.company_id = this.login_company.id;
        this.company_name = this.login_company.base_name;
        this.company_code = this.login_company.company_code;
      }
    }
    if (this.company_id) {
      this.getStaffList(this.company_id, true);
    } else {
      this.loaderSvc.dismiss();
    }
  }

  calcPagination() {
    this.pagination.pages = new Array();
    this.pagination.current = 0;
    if (!this.item_list || !this.item_list.length) {
      return;
    }
    const count = Math.ceil(this.item_list.length / this.page_per_count);
    for (let i = 1; i <= count; i++) {
      this.pagination.pages.push(i);
    }
    this.pagination.current = 1;
  }

  companyChange(event, departComboEm, hallComboEm) {
    this.processing = true;
    this.departCombo.values = [];
    this.hallCombo.values = [];
    this.depart_id = null;
    this.hall_id = null;
    if (event && event.data) {
      if (event.data.base_type !== this.Const.BASE_TYPE_ADMIN) {
        this.departCombo.values = Utils.GetDepartList(event.data);
      }
      this.company_code = event.data.company_code;
    }
    departComboEm.clear();
    departComboEm.initComponent();
    hallComboEm.clear();
    hallComboEm.initComponent();
    if (event && event.data) {
      if (event.data.base_type === this.Const.BASE_TYPE_ADMIN) {
        this.is_admin_base = true;
      } else {
        this.is_admin_base = false;
      }
      this.loaderSvc.call();
      this.getStaffList(event.data.id, true);
    }
    this.processing = false;
  }

  departChange(event, hallComboEm) {
    if (this.processing) {
      return;
    }
    this.processing = true;
    this.hallCombo.values = [];
    this.hall_id = null;
    if (event && event.data) {
      this.hallCombo.values = Utils.GetHallList(event.data);
    }
    hallComboEm.clear();
    hallComboEm.initComponent();
    if (event && event.data) {
      this.loaderSvc.call();
      this.getStaffList(event.data.id, false);
    } else {
      this.loaderSvc.call();
      this.getStaffList(this.company_id, false);
    }
    this.processing = false;
  }

  hallChange(event) {
    if (this.processing) {
      return;
    }
    if (event && event.data) {
      this.loaderSvc.call();
      this.getStaffList(event.data.id, false);
    } else {
      this.loaderSvc.call();
      this.getStaffList(this.depart_id, false);
    }
  }

  async getStaffList(base_id, save) {
    await this.staffSvc.getData(base_id, true, save).then((response) => {
      if (this.is_admin_base) {
        this.item_list = response.filter(v => v.base.base_type === this.Const.BASE_TYPE_ADMIN);
      } else {
        this.item_list = response;
      }
      this.calcPagination();
      this.loaderSvc.dismiss();
    }).catch(error => {
      this.sessionSvc.save('message', { type: 'error', title: '担当者情報の取得が失敗しました。' });
      this.loaderSvc.dismiss();
    });
  }

  inputDepartChange(event, hallComboEm) {
    if (this.processing) {
      return;
    }
    this.inputHallCombo.values = [];
    this.item_edit.hall_id = null;
    if (event && event.data) {
      this.inputHallCombo.values = Utils.GetHallList(event.data);
    }
    hallComboEm.clear();
    hallComboEm.initComponent();
  }

  showData(event, item = null) {
    this.processing = true;
    this.message = null;
    this.inputDepartCombo.values = [];
    this.inputHallCombo.values = [];
    if (this.login_company.base_type === CommonConstants.BASE_TYPE_COMPANY) {
      this.inputDepartCombo.values = Utils.GetDepartList(this.login_company);
    } else {
      const company = this.company_list.find(v => v.id === this.company_id);
      if (company) {
        this.inputDepartCombo.values = Utils.GetDepartList(company);
      }
    }
    if (!item) {
      this.edit_type = 1;
      this.item_edit = new StaffGetResponse();
      this.item_edit.depart_id = this.depart_id;
      this.item_edit.hall_id = this.hall_id;
    } else {
      this.edit_type = 2;
      if (event.target.classList.contains('operation') || event.target.classList.contains('icon')) {
        return;
      }
      this.item_edit = JSON.parse(JSON.stringify(item));
      const base_type = Utils.getBaseType(this.item_edit.base.id);
      if (base_type === CommonConstants.BASE_TYPE_DEPART) {
        this.item_edit.depart_id = this.item_edit.base.id;
      } else if (base_type === CommonConstants.BASE_TYPE_PLACE_SELF
        || base_type === CommonConstants.BASE_TYPE_PLACE_OTHER) {
        this.item_edit.hall_id = this.item_edit.base.id;
        this.item_edit.depart_id = Utils.getParentBaseId(this.item_edit.base.id);
      }
    }
    if (this.item_edit.depart_id) {
      const depart = this.inputDepartCombo.values.find(v => v.data.id === this.item_edit.depart_id);
      if (depart) {
        this.inputHallCombo.values = Utils.GetHallList(depart.data);
      }
    }

    this.processing = false;
    $('#item-edit').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  async saveData() {
    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }
    this.processing = true;
    if (!this.dataValidate()) {
      this.processing = false;
      return;
    }
    const postData = this.getPostData();
    let unique_err = false;
    this.loaderSvc.call();
    if (postData.id) {
      await this.httpClientService.updateStaff(postData.id, postData)
        .then(async (response) => {
          Utils.log(response);
          if (this.hall_id) {
            await this.getStaffList(this.hall_id, false);
          } else if (this.depart_id) {
            await this.getStaffList(this.depart_id, false);
          } else {
            await this.getStaffList(this.company_id, true);
          }
          this.sessionSvc.save('message', { type: 'info', title: '担当者情報を保存しました。' });
          this.processing = false;
        })
        .catch(error => {
          if (error.non_field_errors) {
            unique_err = true;
            if (error.non_field_errors[0].indexOf('login_id') > 0) {
              this.message = { type: 'warning', title: 'ログインIDがすでに使われています。' };
            } else {
              this.message = { type: 'warning', title: 'FDNコードがすでに使われています。' };
            }
            this.loaderSvc.dismiss();
            this.processing = false;
            this.sessionSvc.clear('message');
            return;
          }
          this.sessionSvc.save('message', { type: 'error', title: '担当者情報の保存が失敗しました。' });
          this.loaderSvc.dismiss();
          this.processing = false;
        });
    } else {
      await this.httpClientService.addStaff(postData)
        .then(async (response) => {
          Utils.log(response);
          if (this.hall_id) {
            await this.getStaffList(this.hall_id, false);
          } else if (this.depart_id) {
            await this.getStaffList(this.depart_id, false);
          } else {
            await this.getStaffList(this.company_id, true);
          }
          this.sessionSvc.save('message', { type: 'info', title: '担当者情報を保存しました。' });
          this.processing = false;
        })
        .catch(error => {
          if (error.non_field_errors) {
            unique_err = true;
            if (error.non_field_errors[0].indexOf('login_id') > 0) {
              this.message = { type: 'warning', title: 'ログインIDがすでに使われています。' };
            } else {
              this.message = { type: 'warning', title: 'FDNコードがすでに使われています。' };
            }
            this.loaderSvc.dismiss();
            this.processing = false;
            this.sessionSvc.clear('message');
            return;
          }
          this.sessionSvc.save('message', { type: 'error', title: '担当者情報の保存が失敗しました。' });
          this.loaderSvc.dismiss();
          this.processing = false;
        });
    }
    if (!unique_err) {
      $('#item-edit').modal('hide');
      setTimeout(() => {
        this.item_edit = null;
      }, 500);
    }

  }

  dataValidate() {
    const msgs = new Array();
    this.message = null;
    if (!this.item_edit.name) {
      msgs.push('氏名');
    }
    if (this.item_edit.login_id) {
      this.item_edit.login_id = this.item_edit.login_id.trim();
    }
    if (!this.item_edit.login_id) {
      msgs.push('ログインID');
    }
    if (!this.item_edit.id && !this.item_edit.password) {
      msgs.push('パスワード');
    }

    if (msgs.length > 0) {
      this.message = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: msgs.join(',')
      };
      return false;
    }

    return true;
  }

  getPostData(): StaffPostRequest {
    const postRequest = new StaffPostRequest();
    postRequest.id = this.item_edit.id;
    if (this.item_edit.hall_id) {
      postRequest.base = this.item_edit.hall_id;
    } else if (this.item_edit.depart_id) {
      postRequest.base = this.item_edit.depart_id;
    } else {
      postRequest.base = this.company_id;
    }
    postRequest.name = this.item_edit.name;
    postRequest.company_code = this.company_code;
    postRequest.login_id = this.item_edit.login_id;
    if (this.item_edit.mail_address) {
      postRequest.mail_address = this.item_edit.mail_address;
    } else {
      postRequest.mail_address = null;
    }
    if (this.item_edit.fdn_code) {
      postRequest.fdn_code = this.item_edit.fdn_code;
    } else {
      postRequest.fdn_code = null;
    }
    postRequest.retired_flg = this.item_edit.retired_flg;
    if (this.item_edit.password) {
      postRequest.password = this.item_edit.password;
    }

    return postRequest;
  }

  deleteData(item) {
    if (!item) {
      return;
    }
    this.sessionSvc.clear('message');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: '担当者情報を削除します、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.loaderSvc.call();
        this.httpClientService.deleteStaff(item.id)
          .then(async (response) => {
            Utils.log(response);
            if (this.hall_id) {
              await this.getStaffList(this.hall_id, false);
            } else if (this.depart_id) {
              await this.getStaffList(this.depart_id, false);
            } else {
              await this.getStaffList(this.company_id, true);
            }
            this.sessionSvc.save('message', { type: 'info', title: '担当者情報を削除しました。' });
          })
          .catch(error => {
            this.sessionSvc.save('message', { type: 'error', title: '担当者情報の削除が失敗しました。' });
            this.loaderSvc.dismiss();
          });
      }
    });
  }

  closeItemEdit() {
    setTimeout(() => {
      this.item_edit = null;
    }, 500);
  }
  pageTo(pagination, page_num) {
    pagination.current = page_num;
  }

  closeMessage() {
    this.message = null;
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClientService } from 'src/app/service/http-client.service';
import { SessionService } from 'src/app/service/session.service';
import { Utils } from 'src/app/const/func-util';
declare var $;

@Component({
  selector: 'app-cus-huho-approve',
  templateUrl: './huho-approve.component.html',
  styleUrls: ['./huho-approve.component.scss']
})
export class HuhoApproveComponent implements OnInit {

  approve_flg = 0;
  company_id = null;
  seko_id = null;
  err_msg = null;
  is_loading = false;
  CONST_REQUIRE = '葬儀番号が正しくありません。';
  CONST_NOTFUOND = 'ご指定の葬儀情報が見つかりません。';
  CONST_APPROVE_ERROR = '承認処理が失敗しました。';
  CONST_ALREDY_COMPLETE = 'すでに承認されております。';
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
  ) { }

  async ngOnInit() {
    this.sessionSvc.clear('custom_seko_info');
    this.sessionSvc.clearCustomerData();
    this.is_loading = true;
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      const paramId = params.get('id');
      if (!paramId) {
        return;
      }
      const paramIds = paramId.split('-');
      if (paramIds.length >= 1) {
        this.company_id = Number(paramIds[0]);
      }
      if (paramIds.length >= 2) {
        this.seko_id = Number(paramIds[1]);
        return;
      }

    });
    if (!this.company_id || !this.seko_id) {
      this.err_msg = this.CONST_REQUIRE;
      this.is_loading = false;
      return;
    }
    await this.getSekoInfo();
    setTimeout(() => {
      $('.ui.checkbox').checkbox();
    }, 100);

  }

  async getSekoInfo() {
    if (!this.seko_id) {
      return;
    }
    this.is_loading = true;
    await this.httpClientService.getSekoByid(this.seko_id).then((sekoResponse) => {
      Utils.log(sekoResponse);
      if (!sekoResponse || sekoResponse.seko_company.id !== Number(this.company_id)) {
        this.err_msg = this.CONST_NOTFUOND;
      }
      if (sekoResponse.fuho_site_admission_ts) {
        this.err_msg = this.CONST_ALREDY_COMPLETE;
      }
      this.is_loading = false;

    }).catch(error => {
      this.err_msg = this.CONST_NOTFUOND;
      this.is_loading = false;
    });
  }
  approveSeko() {
    if (!this.seko_id) {
      return;
    }
    this.httpClientService.approveSeko(this.seko_id).then((sekoResponse) => {
      Utils.log(sekoResponse);
      if (!sekoResponse) {
        this.err_msg = this.CONST_APPROVE_ERROR;
        return;
      }
      this.router.navigate(['fuho/' + sekoResponse.seko_company.id + '-' + sekoResponse.id + '/']);

    }).catch(error => {
      this.err_msg = this.CONST_APPROVE_ERROR;
    });

  }
}

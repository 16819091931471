import { Component, OnInit } from '@angular/core';
import { HttpClientService } from 'src/app/service/http-client.service';
import { SessionService } from 'src/app/service/session.service';
import { LoaderService } from 'src/app/service/loader.service';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import {
  AfGroupGetRequest, AfGroupGetResponse,
  AfContactGetRequest, AfContactGetResponse,
  SekoAfGetResponse, SekoAfPostRequest, AfWishForPost
 } from 'src/app/interfaces/af';
declare var $;

@Component({
  selector: 'app-fam-seko-af',
  templateUrl: './seko-af.component.html',
  styleUrls: ['./seko-af.component.scss']
})
export class SokeSekoAfComponent implements OnInit {
  Const = CommonConstants;
  Utils = Utils;

  seko_info = this.sessionSvc.get('soke_seko_info');
  af_group_list: Array<AfGroupGetResponse> = null;
  af_contact_list: Array<AfContactGetResponse>  = null;
  af_contact_checked_id = null;
  seko_af_edit: SekoAfGetResponse = null;
  message = null;
  is_loading = false;

  constructor(
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    ) { }

  async ngOnInit() {
    if (!this.seko_info.seko_af) {
      this.seko_af_edit = new SekoAfGetResponse();
    } else {
      this.seko_af_edit = JSON.parse(JSON.stringify(this.seko_info.seko_af));
      this.af_contact_checked_id = this.seko_af_edit.contact_way.id;
    }
    if (!this.seko_af_edit.wishes) {
      this.seko_af_edit.wishes = new Array();
    }
    this.is_loading = true;
    await this.getAfGroupList();
    await this.getAfContactList();
    await this.initAfList();
    setTimeout(() => {
      $('.ui.checkbox').checkbox();
    }, 100);
    this.is_loading = false;
  }

  async getAfGroupList() {
    if (!this.seko_info) {
      return;
    }
    const requestData = new AfGroupGetRequest();
    if (this.seko_info.seko_department.base_type === this.Const.BASE_TYPE_DEPART) {
      requestData.department = this.seko_info.seko_department.id;
    } else {
      requestData.department = this.seko_info.seko_department.parent;
    }
    await this.httpClientService.getAfGroupList(requestData).then((response) => {
      Utils.log(response);
      this.af_group_list = response;

    }).catch(error => {
    });
    if (!this.af_group_list.length) {
      requestData.department = this.seko_info.seko_company.id;
      await this.httpClientService.getAfGroupList(requestData).then((response) => {
        Utils.log(response);
        this.af_group_list = response;

      }).catch(error => {
      });

    }
  }

  async getAfContactList() {
    if (!this.seko_info) {
      return;
    }
    const requestData = new AfContactGetRequest();
    requestData.company = this.seko_info.seko_company.id;
    await this.httpClientService.getAfContactList(requestData).then((response) => {
      Utils.log(response);
      this.af_contact_list = response;

    }).catch(error => {
    });
  }

  async initAfList() {
    if (this.af_group_list) {
      for (const af_group of this.af_group_list) {
        for (const af of af_group.after_follows) {
          const wishe = this.seko_af_edit.wishes.find(v => v.af_type.id === af.id);
          if (wishe) {
            af.selected = true;
          } else {
            af.selected = false;
          }
        }
      }
    }
  }

  saveData() {
    if (this.haveActivity()) {
      return;
    }
    $('.input').attr('err-msg', null);
    if (!this.af_contact_checked_id) {
      $('.input.af_contact').attr('err-msg', '*必須項目です');
      return;
    }
    const postData = new SekoAfPostRequest();
    postData.contact_way = this.af_contact_checked_id;
    postData.wishes = new Array();
    postData.seko = this.seko_info.id;
    postData.note = this.seko_af_edit.note;
    for (const af_group of this.af_group_list) {
      for (const af of af_group.after_follows) {
        if (af.selected) {
          const af_wish = new AfWishForPost();
          af_wish.af_type = af.id;
          af_wish.answered_flg = false;
          postData.wishes.push(af_wish);
        }
      }
    }

    if (!this.seko_info.seko_af) {
      this.httpClientService.addSekoAf(postData)
        .then(async (response) => {
          Utils.log(response);
          this.seko_info.seko_af = response;
          this.message =  '保存内容を送信しました。' ;
          this.showPopup();
        })
        .catch(error => {
          this.message =  '内容の保存が失敗しました。' ;
          this.showPopup();
        });
    } else {
      this.httpClientService.updateSekoAf(this.seko_info.id, postData)
        .then(async (response) => {
          Utils.log(response);
          this.seko_info.seko_af = response;
          this.message =  '保存内容を送信しました。' ;
          this.showPopup();
        })
        .catch(error => {
          this.message =  '内容の保存が失敗しました。' ;
          this.showPopup();
        });

    }
  }

  showPopup() {
    $('#message-popup').modal({
      closable: false,
      centered: false,
      detachable: false,
      transition: 'fade',
    }).modal('show');

  }

  closePopup() {
    $('#message-popup').modal('hide');
    setTimeout(() => {
    this.message = null;
    }, 200);
  }

  haveActivity() {
    if (!this.seko_info.seko_af || !this.seko_info.seko_af.wishes || !this.seko_info.seko_af.wishes.length) {
      return false;
    }
    for (const wish of this.seko_info.seko_af.wishes) {
      if (wish.activities && wish.activities.length) {
        return true;
      }
    }
    return false;
  }
}

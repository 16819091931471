
export class FaqGetRequest {
  company: number;
}

export class FaqBase {
  id: number;
  company: number;
  question: string;
  answer: string;
  display_num: number;
}
export class FaqGetResponse extends FaqBase {
}
export class FaqPostRequest extends FaqBase {
}
export class FaqPostResponse extends FaqGetResponse {
}

export class FaqPutRequest extends FaqBase {
}
export class FaqPutResponse extends FaqGetResponse {
}

export class FaqDeleteResponse {
}

import { Component, OnInit, Input } from '@angular/core';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { LoaderService } from 'src/app/service/loader.service';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';

@Component({
  selector: 'app-com-order-cancel',
  templateUrl: './order-cancel.component.html',
  styleUrls: ['./order-cancel.component.scss']
})
export class OrderCancelComponent implements OnInit {
  @Input() entry_id: number;
  Const = CommonConstants;
  Utils = Utils;

  login_info = this.sessionSvc.get('staff_login_info');
  entry = null;
  henrei_count = 0;

  constructor(
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
  ) {
    this.loaderSvc.call();
  }


  ngOnInit(): void {
    this.getData();
  }

  getData() {
    if (!this.entry_id) {
      this.loaderSvc.dismiss();
      return;
    }
    this.httpClientService.getEntry(this.entry_id).then((response) => {
      Utils.log(response);
      this.entry = response;
      this.entry.entry_ts = new Date(this.entry.entry_ts);
      this.loaderSvc.dismiss();

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }

  getHenreiCount() {
    if (!this.entry || !this.entry.details || !this.entry.details.length) {
      return;
    }
    for (const detail of this.entry.details) {
      if (detail.koden && detail.koden.henrei_koden) {
        this.henrei_count++;
      }
      if (detail.kumotsu && detail.kumotsu.henrei_kumotsu) {
        this.henrei_count++;
      }
    }
  }
}


export class AdvertiseGetRequest {
  company: number;
}

export class AdvertiseBase {
  id: number;
  company: number;
  url: string;
  banner_file: string;
  link_file: string;
}
export class AdvertiseGetResponse extends AdvertiseBase {
  banner_file_stream?: any;
  link_file_stream?: any;
  link_file_name?: string;
  begin_date: Date;
  end_date: Date;
  depart_id?: number;
  hall_id?: number;
}
export class AdvertisePostRequest extends AdvertiseBase {
  begin_date: string;
  end_date: string;
}
export class AdvertisePostResponse extends AdvertiseGetResponse {
}

export class AdvertisePutRequest extends AdvertisePostRequest {
}
export class AdvertisePutResponse extends AdvertiseGetResponse {
}

export class AdvertiseDeleteResponse {
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/pages/common/confirm-dialog/confirm-dialog.component';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { LoaderService } from 'src/app/service/loader.service';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import {
  AfContactGetRequest, AfContactGetResponse, AfContactPostRequest
} from 'src/app/interfaces/af';
import { isNullOrUndefined } from 'util';
declare var $;


@Component({
  selector: 'app-com-af-contact',
  templateUrl: './af-contact.component.html',
  styleUrls: ['./af-contact.component.scss']
})
export class AfContactComponent implements OnInit {

  Const = CommonConstants;

  login_company = Utils.getLoginCompany();
  companyCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  company_list = Utils.getCompanyList();
  company_id = null;
  company_name = null;

  af_contact_list: Array<any> = null;
  af_contact_edit: AfContactGetResponse = null;
  edit_type = 1;
  message = null;

  page_per_count = 15;
  pagination = {
    pages: new Array(),
    current: 0
  };
  processing = false;

  constructor(
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    private dialog: MatDialog,
  ) {
    this.loaderSvc.call();
  }

  ngOnInit(): void {
    this.initControl();
  }

  initControl() {
    if (this.company_list) {
      this.companyCombo.values = this.company_list.map(value => ({ name: value.base_name, value: value.id, data: value }));
      if (this.login_company) {
        if (this.login_company.base_type === CommonConstants.BASE_TYPE_COMPANY) {
          this.company_id = this.login_company.id;
          this.company_name = this.login_company.base_name;
        } else {
          this.companyCombo.disableSearch = false;
          this.company_id = this.company_list[0].id;
          this.company_name = this.company_list[0].base_name;
        }
      }
    }
    if (this.company_id) {
      this.getAfContactList(this.company_id);
    } else {
      this.loaderSvc.dismiss();
    }
  }

  async getAfContactList(base_id) {
    this.af_contact_list = null;
    const req = new AfContactGetRequest();
    req.company = base_id;
    if (!this.loaderSvc.isLoading) {
      this.loaderSvc.call();
    }
    await this.httpClientService.getAfContactList(req).then((response) => {
      Utils.log(response);
      this.af_contact_list = response;
      this.calcPagination();
      this.loaderSvc.dismiss();
    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }

  calcPagination() {
    this.pagination.pages = new Array();
    this.pagination.current = 0;
    if (!this.af_contact_list || !this.af_contact_list.length) {
      return;
    }
    const count = Math.ceil(this.af_contact_list.length / this.page_per_count);
    for (let i = 1; i <= count; i++) {
      this.pagination.pages.push(i);
    }
    this.pagination.current = 1;
  }

  pageTo(pagination, page_num) {
    pagination.current = page_num;
  }

  companyChange(event) {
    if (event) {
      this.loaderSvc.call();
      this.getAfContactList(event.value);
      this.company_name = event.name;
    }
  }

  showData(event, af_contact = null) {
    this.message = null;
    if (!af_contact) {
      this.edit_type = 1;
      this.af_contact_edit = new AfContactGetResponse();
      this.af_contact_edit.company = this.company_id;
    } else {
      this.edit_type = 2;
      if (event.target.classList.contains('operation') || event.target.classList.contains('icon')) {
        return;
      }
      this.af_contact_edit = JSON.parse(JSON.stringify(af_contact));
    }
    $('#af-contact-edit').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  async saveData() {
    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }
    this.processing = true;
    if (!this.dataValidate()) {
      this.processing = false;
      return;
    }
    const postData = this.getPostData();
    const login_id_err = false;
    this.loaderSvc.call();
    if (this.af_contact_edit.id) {
      this.httpClientService.updateAfContact(this.af_contact_edit.id, postData)
        .then(async (response) => {
          Utils.log(response);
          this.sessionSvc.save('message', { type: 'info', title: 'AF連絡方法を保存しました。' });
          this.getAfContactList(this.company_id);
          this.processing = false;
        })
        .catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: 'AF連絡方法の保存が失敗しました。' });
          this.loaderSvc.dismiss();
          this.processing = false;
        });
    } else {
      await this.httpClientService.addAfContact(postData)
        .then(async (response) => {
          Utils.log(response);
          this.sessionSvc.save('message', { type: 'info', title: 'AF連絡方法を保存しました。' });
          this.getAfContactList(this.company_id);
          this.processing = false;
        })
        .catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: 'AF連絡方法の保存が失敗しました。' });
          this.loaderSvc.dismiss();
          this.processing = false;
        });
    }
    if (!login_id_err) {
      $('#af-contact-edit').modal('hide');
      setTimeout(() => {
        this.af_contact_edit = null;
      }, 500);
    }

  }

  dataValidate() {
    const msgs = new Array();
    this.message = null;
    if (!this.af_contact_edit.contact_way) {
      msgs.push('連絡方法');
    }
    if (isNullOrUndefined(this.af_contact_edit.display_num)) {
      msgs.push('表示順');
    }

    if (msgs.length > 0) {
      this.message = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: msgs.join(',')
      };
      return false;
    }
    if (Number(this.af_contact_edit.display_num) <= 0) {
      this.message = {
        type: 'warning',
        title: '表示順は１以上の数値を入力してください。'
      };
      return false;
    }

    return true;
  }

  getPostData(): AfContactPostRequest {
    const postRequest = new AfContactPostRequest();
    postRequest.company = this.af_contact_edit.company;
    postRequest.contact_way = this.af_contact_edit.contact_way;
    postRequest.display_num = this.af_contact_edit.display_num;

    return postRequest;
  }

  deleteData(af_contact) {
    if (!af_contact) {
      return;
    }
    this.sessionSvc.clear('message');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: 'AF連絡方法を削除します、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.loaderSvc.call();
        this.httpClientService.deleteAfContact(af_contact.id)
          .then((response) => {
            Utils.log(response);
            this.sessionSvc.save('message', { type: 'info', title: 'AF連絡方法を削除しました。' });
            this.getAfContactList(this.company_id);
          })
          .catch(error => {
            this.sessionSvc.save('message', { type: 'error', title: 'AF連絡方法の削除が失敗しました。' });
            this.loaderSvc.dismiss();
          });
      }
    });
  }

  closeAfContactEdit() {
    setTimeout(() => {
      this.af_contact_edit = null;
    }, 500);
  }

  closeMessage() {
    this.message = null;
  }

}

import { Component, OnInit, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarOptionsDate } from 'src/app/components/com-calendar/com-calendar.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/pages/common/confirm-dialog/confirm-dialog.component';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { LoaderService } from 'src/app/service/loader.service';
import { SekoGetRequest, SekoAnswerPostRequest } from 'src/app/interfaces/seko';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import { formatDate } from '@angular/common';
import { ComDropdownComponent } from 'src/app/components/com-dropdown/com-dropdown.component';
import { ComCalendarComponent } from 'src/app/components/com-calendar/com-calendar.component';
import { AfGroupGetRequest } from 'src/app/interfaces/af';
declare var $;

@Component({
  selector: 'app-com-seko-af',
  templateUrl: './seko-af.component.html',
  styleUrls: ['./seko-af.component.scss']
})
export class SekoAfComponent implements OnInit {

  login_info = this.sessionSvc.get('staff_login_info');
  login_company = Utils.getLoginCompany();
  staff_list = null;
  sekoBaseCombo = { values: [], clearable: true, showOnFocus: false };
  departCombo = { values: [], clearable: true, showOnFocus: false };
  hallCombo = { values: [], clearable: true, showOnFocus: false };
  afStaffCombo = { values: [], clearable: true, showOnFocus: false };
  seko_date_from_config: CalendarOptionsDate = new CalendarOptionsDate();
  seko_date_to_config: CalendarOptionsDate = new CalendarOptionsDate();
  form_data = {
    company_id: null,
    seko_department_id: null,
    hall_id: null,
    soke_name: null,
    seko_date_from: null,
    seko_date_to: null,
    af_staff_id: null,
    unanswerd_inquiry: false,
    unanswerd_af: false
  };
  searched = false;
  seko_list = null;
  seko_list_all = null;
  selected_seko = null;
  selected_inquiry = null;
  answer_edit: SekoAnswerPostRequest = null;
  af_group_list = null;
  company_af_group_list = null;
  all_af_group_list = null;

  message = null;
  message_sub = null;

  page_per_count = 20;
  pagination = {
    pages: new Array(),
    current: 0
  };
  constructor(
    private router: Router,
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    private dialog: MatDialog,
    @Inject(LOCALE_ID) private locale: string,
  ) { }

  ngOnInit() {
    this.initControl();
    this.getCompanyAfGroupList();
    this.getAllAfGroupList();
    setTimeout(() => {
      $('.checkbox').checkbox();
    }, 100);
  }

  initControl() {
    this.staff_list = Utils.getStaffList(this.login_company.id);
    if (this.login_company) {
      this.form_data.company_id = this.login_company.id;
      this.sekoBaseCombo.values = Utils.getSekoBaseList(this.login_company);
      this.departCombo.values = Utils.GetDepartList(this.login_company);
      if (this.login_info.staff.base.base_type !== CommonConstants.BASE_TYPE_COMPANY) {
        this.form_data.seko_department_id = this.login_info.staff.base.id;
      }
      this.hallCombo.values = Utils.GetAllHallList(this.login_company);
    }
    if (this.staff_list) {
      this.afStaffCombo.values = [];
      this.staff_list.forEach(value => {
        if (Utils.getCompanyId(value.base.id) === this.form_data.company_id) {
          this.afStaffCombo.values.push({ name: value.name, value: value.id });
        }
      });
    }
  }

  getCompanyAfGroupList() {
    const requestData = new AfGroupGetRequest();
    requestData.department = this.login_company.id;
    this.httpClientService.getAfGroupList(requestData).then((response) => {
      Utils.log(response);
      this.company_af_group_list = response;

    }).catch(error => {
    });
  }

  getAllAfGroupList() {
    const requestData = new AfGroupGetRequest();
    requestData.department = this.login_company.id;
    this.httpClientService.getAfGroupListAll(requestData).then((response) => {
      Utils.log(response);
      this.all_af_group_list = response;

    }).catch(error => {
    });
  }

  async searchSeko() {
    const request = new SekoGetRequest();
    request.seko_company = this.form_data.company_id;
    request.seko_department = this.form_data.seko_department_id;
    request.hall = this.form_data.hall_id;
    request.soke_name = this.form_data.soke_name;
    request.af_staff = this.form_data.af_staff_id;
    if (this.form_data.seko_date_from) {
      request.seko_date_after = formatDate(this.form_data.seko_date_from, 'yyyy-MM-dd', this.locale);
    }
    if (this.form_data.seko_date_to) {
      request.seko_date_before = formatDate(this.form_data.seko_date_to, 'yyyy-MM-dd', this.locale);
    }
    if (!this.loaderSvc.isLoading) {
      this.loaderSvc.call();
    }
    this.pagination.pages = new Array();
    this.pagination.current = 0;
    await this.httpClientService.getSekoList(request).then((response) => {
      Utils.log(response);
      this.searched = true;
      this.seko_list_all = response;
      if (this.form_data.unanswerd_inquiry) {
        response = response.filter(v => this.getInquiryCountUnanswered(v.inquiries));
      }
      if (this.form_data.unanswerd_af) {
        response = response.filter(v => this.getWishCountUnanswered(v.seko_af));
      }
      this.seko_list = response;
      Utils.formatSekoDateList(this.seko_list);
      this.calcPagination();
      this.loaderSvc.dismiss();

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }
  calcPagination() {
    if (!this.seko_list || !this.seko_list.length) {
      return;
    }
    const count = Math.ceil(this.seko_list.length / this.page_per_count);
    for (let i = 1; i <= count; i++) {
      this.pagination.pages.push(i);
    }
    this.pagination.current = 1;
  }
  pageTo(page_num) {
    this.pagination.current = page_num;
  }

  departChange(event, hallComboEm) {
    this.hallCombo.values = [];
    this.form_data.hall_id = null;
    if (event && event.data) {
      this.hallCombo.values = Utils.GetHallList(event.data);
    }
    hallComboEm.clear();
    hallComboEm.initComponent();
  }

  clearForm(sekoBaseComboEm, ...comboEms: any[]) {

    this.form_data = {
      company_id: this.login_company.id,
      seko_department_id: null,
      hall_id: null,
      soke_name: null,
      seko_date_from: null,
      seko_date_to: null,
      af_staff_id: null,
      unanswerd_inquiry: false,
      unanswerd_af: false
    };
    if (this.login_info.staff.base.base_type === CommonConstants.BASE_TYPE_COMPANY) {
      sekoBaseComboEm.clear();
    } else {
      this.form_data.seko_department_id = this.login_info.staff.base.id;
      sekoBaseComboEm.setValue(this.form_data.seko_department_id);
    }
    for (const comboEm of comboEms) {
      comboEm.clear();
    }

  }

  getPlace(seko) {
    if (!seko || !seko.schedules) {
      return null;
    }
    let ret = null;
    seko.schedules.forEach((schedule) => {
      // if (schedule.schedule.required_flg) {
      if (schedule.schedule) {
        ret = schedule.hall_name;
        return;
      }
    });
    return ret;
  }

  getWishCount(seko_af) {
    if (!seko_af || !seko_af.wishes) {
      return 0;
    }
    return seko_af.wishes.length;

  }
  getWishCountUnanswered(seko_af) {
    if (!seko_af || !seko_af.wishes) {
      return 0;
    }
    return seko_af.wishes.filter(v => !v.answered_flg).length;

  }
  getInquiryCountUnanswered(inquiries) {
    if (!inquiries) {
      return 0;
    }
    return inquiries.filter(v => !v.answers || !v.answers.length).length;

  }

  async showSekoData(event, seko) {
    this.message = null;
    if (!seko) {
      return;
    } else {
      if (event.target.classList.contains('operation') || event.target.classList.contains('icon')) {
        return;
      }
      await this.getAfGroupList(seko);
      this.selected_seko = JSON.parse(JSON.stringify(seko));
    }
    $('#seko').modal({
      allowMultiple: true,
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
    setTimeout(() => {
      $('.ui.checkbox').checkbox();
    }, 100);
  }

  closeSekoData() {
    setTimeout(() => {
      this.selected_seko = null;
    }, 100);
  }

  async getAfGroupList(seko) {
    if (!seko) {
      return;
    }
    const requestData = new AfGroupGetRequest();
    if (Utils.getBaseType(seko.seko_department) === CommonConstants.BASE_TYPE_DEPART) {
      requestData.department = seko.seko_department;
    } else {
      requestData.department = Utils.getParentBaseId(seko.seko_department);
    }
    await this.httpClientService.getAfGroupList(requestData).then((response) => {
      Utils.log(response);
      if (response.length) {
        this.af_group_list = response;
      } else {
        this.af_group_list = this.company_af_group_list;
      }
      if (this.af_group_list && seko.seko_af && seko.seko_af.wishes) {
        for (const af_group of this.af_group_list) {
          for (const af of af_group.after_follows) {
            const wishe = seko.seko_af.wishes.find(v => v.af_type.id === af.id);
            if (wishe) {
              af.selected = true;
            } else {
              af.selected = false;
            }
          }
        }
      }

    }).catch(error => {
    });
  }

  showAnswerInput(inquiry) {
    this.message = null;
    this.message_sub = null;
    if (!inquiry) {
      return;
    } else {
      this.selected_inquiry = JSON.parse(JSON.stringify(inquiry));
      this.answer_edit = new SekoAnswerPostRequest();
    }
    $('#answer-input').modal({
      allowMultiple: true,
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  closeAnswerInput() {
    setTimeout(() => {
      this.selected_inquiry = null;
      this.answer_edit = null;
    }, 100);
  }

  async saveSekoAnswerData() {
    this.message = null;
    if (!this.sekoAnswerValidate()) {
      return;
    }

    $('#answer-input').modal('hide');
    this.loaderSvc.call();
    await this.httpClientService.addSekoAnswer(this.selected_inquiry.id, this.answer_edit)
      .then(async (response) => {
        Utils.log(response);
        await this.searchSeko();
        const seko = this.seko_list_all.find(v => v.id === this.selected_seko.id);
        if (seko) {
          this.selected_seko.inquiries = JSON.parse(JSON.stringify(seko.inquiries));
        }
        this.message = { type: 'info', title: '回答内容を保存しました。' };
      })
      .catch(error => {
        this.message = { type: 'error', title: '回答内容の保存が失敗しました。' };
        this.sessionSvc.clear('message');
        this.loaderSvc.dismiss();
      });
    setTimeout(() => {
      this.selected_inquiry = null;
      this.answer_edit = null;
    }, 100);

  }

  sekoAnswerValidate() {
    const msgs = new Array();
    this.message_sub = null;
    this.answer_edit.answer = (
      this.answer_edit.answer ? this.answer_edit.answer.trim() : this.answer_edit.answer
    );
    if (!this.answer_edit.answer) {
      msgs.push('回答内容');
    }

    if (msgs.length > 0) {
      this.message_sub = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: msgs.join(',')
      };
      return false;
    }

    return true;
  }

  goActivity(seko): void {
    if (!seko || !seko.id) {
      return;
    }
    this.router.navigate(['foc/seko/edit/' + seko.id], {
      queryParams: { tab: 'af-list' },
    });
  }
  closeMessage() {
    this.message = null;
  }
  closeMessageSub() {
    this.message_sub = null;
  }


  exportCsv() {
    const title = '施行番号,葬家名,喪主名,施行日,AF担当者,施行拠点,式場,AF希望,AF希望未回答,フリー問合せ未回答';
    let csv_data = null;
    const af_title = this.all_af_group_list.map(af_group => {
      return af_group.after_follows.map(v => v.name).join(',');
    }).join(',');
    let export_data = title + ',' + af_title + '\n';
    if (this.seko_list) {
      const values = this.seko_list.map(v => {
        const ret_strings = new Array();
        ret_strings.push(v.id);
        ret_strings.push(v.soke_name ? v.soke_name : '');
        ret_strings.push(v.moshu.name ? v.moshu.name : '');
        ret_strings.push(v.seko_date ? formatDate(v.seko_date, 'yyyy/MM/dd', this.locale) : '');
        ret_strings.push(v.af_staff_name ? v.af_staff_name : '');
        ret_strings.push(v.seko_department_name ? v.seko_department_name : '');
        ret_strings.push(this.getPlace(v) ? this.getPlace(v) : '');
        ret_strings.push(this.getWishCount(v.seko_af));
        ret_strings.push(this.getWishCountUnanswered(v.seko_af));
        ret_strings.push(this.getInquiryCountUnanswered(v.inquiries));
        for (const af_group of this.all_af_group_list) {
          for (const af of af_group.after_follows) {
            if (v.seko_af && v.seko_af.wishes) {
              const wishe = v.seko_af.wishes.find(w => w.af_type.id === af.id);
              if (wishe) {
                ret_strings.push('〇');
              } else {
                ret_strings.push('×');
              }
            } else {
              ret_strings.push('×');
            }
          }
        }
        return ret_strings.join(',');
      });
      csv_data = values.join('\n');
      export_data += csv_data;
    }
    const now = formatDate(new Date(), 'yyyyMMddHHmmss', this.locale);
    const file_name = 'AF一覧_' + now + '.csv';
    Utils.exportCsv(file_name, export_data);
  }
}

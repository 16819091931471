import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/service/session.service';
import { CommonConstants } from 'src/app/const/const';
declare var $;

@Component({
  selector: 'app-cus-order-complete',
  templateUrl: './order-complete.component.html',
  styleUrls: ['./order-complete.component.scss']
})
export class OrderCompleteComponent implements OnInit {
  Const = CommonConstants;
  seko_info = this.sessionSvc.get('custom_seko_info');
  entry_no = this.sessionSvc.get('entry_no');
  order_no = this.sessionSvc.get('order_no');
  constructor(
    private router: Router,
    private sessionSvc: SessionService
    ) { }

  ngOnInit(): void {
    if (!this.seko_info) {
      this.router.navigate(['fuho/']);
      return;
    }
    if (!this.entry_no) {
      this.router.navigate(['fuho/' + this.seko_info.seko_company.id + '-' + this.seko_info.id + '/']);
      return;
    }
  }

  goTop() {
    this.router.navigate(['fuho/' + this.seko_info.seko_company.id + '-' + this.seko_info.id + '/']);
  }
}

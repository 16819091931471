import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthFGuard } from './guard/authF.guard';
import { AdminGuard } from './guard/admin.guard';
import { OrderGuard } from './guard/order.guard';
import { AuthSGuard } from './guard/authS.guard';
import { StaffOnlyGuard } from './guard/staff.guard';
import { NotFoundComponent } from './pages/common/not-found/not-found.component';

import { CompanyFrameComponent } from './pages/company/frame.component';
import { CustomerFrameComponent } from './pages/customer/frame.component';
import { FamilyFrameComponent } from './pages/family/frame.component';


const routes: Routes = [
  { path: 'foc', component: CompanyFrameComponent },
  { path: 'foc/login', component: CompanyFrameComponent },
  { path: 'foc/top', component: CompanyFrameComponent, canActivate: [AuthFGuard] },
  { path: 'foc/seko', component: CompanyFrameComponent, canActivate: [AuthFGuard] },
  { path: 'foc/seko/edit/:id', component: CompanyFrameComponent, canActivate: [AuthFGuard] },
  { path: 'foc/seko/edit', component: CompanyFrameComponent, canActivate: [AuthFGuard] },
  { path: 'foc/base', component: CompanyFrameComponent, canActivate: [AuthFGuard, AdminGuard] },
  { path: 'foc/supplier', component: CompanyFrameComponent, canActivate: [AuthFGuard, AdminGuard] },
  { path: 'foc/fuho-sample', component: CompanyFrameComponent, canActivate: [AuthFGuard, AdminGuard] },
  { path: 'foc/chobun-daishi', component: CompanyFrameComponent, canActivate: [AuthFGuard, AdminGuard] },
  { path: 'foc/item-service', component: CompanyFrameComponent, canActivate: [AuthFGuard, AdminGuard] },
  { path: 'foc/staff', component: CompanyFrameComponent, canActivate: [AuthFGuard, AdminGuard] },
  { path: 'foc/monthly/summary', component: CompanyFrameComponent, canActivate: [AuthFGuard, AdminGuard] },
  { path: 'foc/monthly/confirm', component: CompanyFrameComponent, canActivate: [AuthFGuard, AdminGuard] },
  { path: 'foc/sales/summary', component: CompanyFrameComponent, canActivate: [AuthFGuard, AdminGuard] },
  { path: 'foc/invoice', component: CompanyFrameComponent, canActivate: [AuthFGuard, AdminGuard] },
  { path: 'foc/faq', component: CompanyFrameComponent, canActivate: [AuthFGuard, AdminGuard] },
  { path: 'foc/ad', component: CompanyFrameComponent, canActivate: [AuthFGuard, AdminGuard] },
  { path: 'foc/soke-menu', component: CompanyFrameComponent, canActivate: [AuthFGuard, AdminGuard] },
  { path: 'foc/koden/summary', component: CompanyFrameComponent, canActivate: [AuthFGuard, AdminGuard] },
  { path: 'foc/chobun', component: CompanyFrameComponent, canActivate: [AuthFGuard] },
  { path: 'foc/kumotsu', component: CompanyFrameComponent, canActivate: [AuthFGuard] },
  { path: 'foc/koden', component: CompanyFrameComponent, canActivate: [AuthFGuard] },
  { path: 'foc/message', component: CompanyFrameComponent, canActivate: [AuthFGuard] },
  { path: 'foc/henreihin', component: CompanyFrameComponent, canActivate: [AuthFGuard] },
  { path: 'foc/henreihin/order', component: CompanyFrameComponent, canActivate: [AuthFGuard] },
  { path: 'foc/af/group', component: CompanyFrameComponent, canActivate: [AuthFGuard] },
  { path: 'foc/af/contact', component: CompanyFrameComponent, canActivate: [AuthFGuard] },
  { path: 'foc/hoyo/master', component: CompanyFrameComponent, canActivate: [AuthFGuard] },
  { path: 'foc/hoyo/sample', component: CompanyFrameComponent, canActivate: [AuthFGuard] },
  { path: 'foc/hoyo/mail-template', component: CompanyFrameComponent, canActivate: [AuthFGuard] },
  { path: 'foc/seko/af', component: CompanyFrameComponent, canActivate: [AuthFGuard] },
  { path: 'foc/hoyo/guidance', component: CompanyFrameComponent, canActivate: [AuthFGuard] },
  { path: 'foc/hoyo/filter/ki', component: CompanyFrameComponent, canActivate: [AuthFGuard] },
  { path: 'foc/hoyo/filter/bon', component: CompanyFrameComponent, canActivate: [AuthFGuard] },
  { path: 'foc/event/guidance', component: CompanyFrameComponent, canActivate: [AuthFGuard] },
  { path: 'foc/event/filter', component: CompanyFrameComponent, canActivate: [AuthFGuard] },

  { path: 'soke-approve/:id', component: FamilyFrameComponent },
  { path: 'soke', component: FamilyFrameComponent },
  { path: 'soke/login', component: FamilyFrameComponent },
  { path: 'soke/seko', component: FamilyFrameComponent, canActivate: [AuthSGuard] },
  { path: 'soke/homei', component: FamilyFrameComponent, canActivate: [AuthSGuard] },
  { path: 'soke/henrei', component: FamilyFrameComponent, canActivate: [AuthSGuard] },
  { path: 'soke/info', component: FamilyFrameComponent, canActivate: [AuthSGuard] },
  { path: 'soke/change/pass', component: FamilyFrameComponent, canActivate: [AuthSGuard] },
  { path: 'soke/memorial', component: FamilyFrameComponent, canActivate: [AuthSGuard] },
  { path: 'soke/image-share', component: FamilyFrameComponent, canActivate: [AuthSGuard] },
  { path: 'soke/soke-manual/02002020', component: FamilyFrameComponent, canActivate: [AuthSGuard] },
  { path: 'soke/hoyo', component: FamilyFrameComponent, canActivate: [AuthSGuard] },
  { path: 'soke/after-follow', component: FamilyFrameComponent, canActivate: [AuthSGuard] },
  { path: 'soke/inquiry', component: FamilyFrameComponent, canActivate: [AuthSGuard] },
  { path: 'soke/faq', component: FamilyFrameComponent, canActivate: [AuthSGuard] },
  { path: 'soke/userpolicy', component: FamilyFrameComponent },

  { path: '', component: CustomerFrameComponent },
  { path: 'fuho', component: CustomerFrameComponent },
  { path: 'fuho/:id', component: CustomerFrameComponent },
  { path: 'hoyo', component: CustomerFrameComponent },
  { path: 'hoyo/:id', component: CustomerFrameComponent },
  { path: 'fuho-approve/:id', component: CustomerFrameComponent },
  { path: 'chobun/entry1', component: CustomerFrameComponent, canActivate: [OrderGuard] },
  { path: 'chobun/entry2', component: CustomerFrameComponent, canActivate: [OrderGuard] },
  { path: 'kumotsu/entry', component: CustomerFrameComponent, canActivate: [OrderGuard] },
  { path: 'koden/entry1', component: CustomerFrameComponent, canActivate: [OrderGuard] },
  { path: 'koden/entry2', component: CustomerFrameComponent, canActivate: [OrderGuard] },
  { path: 'message/entry', component: CustomerFrameComponent, canActivate: [OrderGuard] },
  { path: 'cart', component: CustomerFrameComponent, canActivate: [OrderGuard] },
  { path: 'order/entry', component: CustomerFrameComponent, canActivate: [OrderGuard] },
  { path: 'order/confirm', component: CustomerFrameComponent, canActivate: [OrderGuard] },
  { path: 'order/payment', component: CustomerFrameComponent, canActivate: [OrderGuard] },
  { path: 'order/complete', component: CustomerFrameComponent, canActivate: [OrderGuard] },
  { path: 'order/:id/receipt', component: CustomerFrameComponent },
  { path: 'inquiry', component: CustomerFrameComponent },
  { path: 'inquiry/funeral', component: CustomerFrameComponent, canActivate: [OrderGuard] },
  { path: 'userpolicy', component: CustomerFrameComponent },
  { path: 'sitepolicy', component: CustomerFrameComponent },
  { path: 'privacypolicy', component: CustomerFrameComponent },
  { path: 'tokushoho', component: CustomerFrameComponent },
  { path: 'howtoshare', component: CustomerFrameComponent },
  { path: '404', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 0],
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClientService } from 'src/app/service/http-client.service';
import { LoaderService } from 'src/app/service/loader.service';
import { Utils } from 'src/app/const/func-util';
declare var $;

@Component({
  selector: 'app-cus-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit {

  message = null;
  message2 = null;
  entry = null;
  entry_id = null;
  atena = null;
  loading = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClientService: HttpClientService,
    private loaderSvc: LoaderService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.entry_id = Number(params.get('id'));
    });
    if (!this.entry_id) {
      this.message = '領収書情報の取得が失敗しました。';
      this.loading = false;
      return;
    }
    this.getEntryData();

  }
  getEntryData() {
    if (!this.entry_id) {
      return;
    }
    this.httpClientService.getEntry(this.entry_id).then((response) => {
      Utils.log(response);
      let receipt_zero = true;
      for (const entry_detail of response.details) {
        if (entry_detail.chobun || entry_detail.kumotsu || entry_detail.koden) {
          receipt_zero = false;
          break;
        }

      }
      if (receipt_zero) {
        this.message = '追悼メッセージの領収書は発行できません。';
        this.loading = false;
        return;

      }
      this.entry = response;
      this.atena = response.entry_name;
      this.loading = false;

    }).catch(error => {
      this.message = '領収書情報の取得が失敗しました。';
      this.loading = false;
    });
  }

  downloadReceipt() {
    $('.input').attr('err-msg', null);
    if (!this.atena) {
      $('.input.atena').attr('err-msg', '*必須項目です');
     return;
    }
    this.loaderSvc.call();
    this.atena = this.atena.replace('　', ' ');
    this.httpClientService.downloadReceipt(this.entry_id, this.atena)
      .then((response) => {
        this.localStreamDownload(response);
        this.message2 = '領収書のダウンロードが完了しました。';
        this.loaderSvc.dismiss();
      }).catch(error => {
        this.message2 = '領収書のダウンロードが失敗しました。';
        this.loaderSvc.dismiss();
      });
  }

  localStreamDownload(stream) {
    const blob = new Blob([stream]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    const file_name = '領収書-' + this.entry_id + '.pdf';

    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveOrOpenBlob(blob, file_name);
    } else {
      link.href = url;
      link.download = file_name;
      link.click();
    }
  }

  isErrorField(element) {
    return !!element.getAttribute('err-msg');
  }
}

import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { CalendarOptionsDate } from 'src/app/components/com-calendar/com-calendar.component';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { LoaderService } from 'src/app/service/loader.service';
import { EventMailGetRequest, EventMailGetResponse } from 'src/app/interfaces/event';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import { formatDate } from '@angular/common';
declare var $;


@Component({
  selector: 'app-com-event-guidance',
  templateUrl: './event-guidance.component.html',
  styleUrls: ['./event-guidance.component.scss']
})
export class EventGuidanceComponent implements OnInit {
  Const = CommonConstants;
  Utils = Utils;
  calendarOptionDate: CalendarOptionsDate = new CalendarOptionsDate();

  login_info = this.sessionSvc.get('staff_login_info');
  login_company = Utils.getLoginCompany();
  form_data = {
    company_id: null,
    send_date_from: null,
    send_date_to: null,
  };
  searched = false;
  event_mail_list: Array<EventMailGetResponse> = null;
  page_per_count = 20;
  pagination = {
    pages: new Array(),
    current: 0
  };
  selected_event_mail = null;

  constructor(
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    @Inject(LOCALE_ID) private locale: string,
  ) { }


  async ngOnInit() {
    if (this.login_company) {
      this.form_data.company_id = this.login_company.id;
    }
  }

  async searchEventMail() {
    this.sessionSvc.clear('message');
    const request = new EventMailGetRequest();
    request.company = this.form_data.company_id;
    if (this.form_data.send_date_from) {
      request.send_date_after = formatDate(this.form_data.send_date_from, 'yyyy-MM-dd', this.locale);
    }
    if (this.form_data.send_date_to) {
      request.send_date_before = formatDate(this.form_data.send_date_to, 'yyyy-MM-dd', this.locale);
    }
    if (!this.loaderSvc.isLoading) {
      this.loaderSvc.call();
    }
    await this.httpClientService.getEventMailList(request).then((response) => {
      Utils.log(response);
      this.searched = true;
      this.event_mail_list = response;
      this.calcPagination();
      this.loaderSvc.dismiss();

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }

  calcPagination() {
    this.pagination.pages = new Array();
    this.pagination.current = 0;
    if (!this.event_mail_list || !this.event_mail_list.length) {
      return;
    }
    const count = Math.ceil(this.event_mail_list.length / this.page_per_count);
    for (let i = 1; i <= count; i++) {
      this.pagination.pages.push(i);
    }
    this.pagination.current = 1;
  }

  pageTo(page_num) {
    this.pagination.current = page_num;
  }

  clearForm(...comboEms: any[]) {

    this.form_data = {
      company_id: this.login_company.id,
      send_date_from: null,
      send_date_to: null,
    };
    for (const comboEm of comboEms) {
      comboEm.clear();
      if ('initComponent' in comboEm) {
        comboEm.initComponent();
      }
    }

  }

  async showItem(event, event_mail) {
    if (!event_mail) {
      return;
    } else {
      if (event.target.classList.contains('operation') || event.target.classList.contains('icon')) {
        return;
      }
      this.selected_event_mail = JSON.parse(JSON.stringify(event_mail));
    }
    $('#event-mail').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
    setTimeout(() => {
      $('.ui.checkbox').checkbox();
    }, 100);
  }

  closeItem() {
    setTimeout(() => {
      this.selected_event_mail = null;
    }, 100);
  }

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor() { }

  // データの取り出し
  get(key, local = false) {
    if (local) {
      return JSON.parse(localStorage.getItem(key));
    } else {
      return JSON.parse(sessionStorage.getItem(key));
    }
  }

  // 削除
  clear(key, local = false): void {
    if (local) {
      localStorage.removeItem(key);
    } else {
      sessionStorage.removeItem(key);
    }
  }

  // 保存
  save(key, val, local = false): void {
    if (local) {
      localStorage.setItem(key, JSON.stringify(val));
    } else {
      sessionStorage.setItem(key, JSON.stringify(val));
    }
  }

  // 葬家データセッションクリア
  clearSokeData(): void {
    sessionStorage.removeItem('soke_seko_info');
  }

  // 顧客入力データセッションクリア
  clearCustomerData(): void {
    sessionStorage.removeItem('chobun_edit');
    sessionStorage.removeItem('kumotsu_edit');
    sessionStorage.removeItem('koden_edit');
    sessionStorage.removeItem('message_edit');
    sessionStorage.removeItem('service_entry');
    sessionStorage.removeItem('entry_no');
  }

  // 全セッションクリア
  clearAll(): void {
    this.clearData();
    sessionStorage.removeItem('staff_login_info');
  }

  // データセッションクリア
  clearData(): void {
    sessionStorage.removeItem('base_list');
    sessionStorage.removeItem('staff_list');
    sessionStorage.removeItem('relation_list');
    sessionStorage.removeItem('hoyo_style_list');
    sessionStorage.removeItem('seko_style_list');
    sessionStorage.removeItem('schedule_list');
    sessionStorage.removeItem('fuho_sample_list');
    sessionStorage.removeItem('hoyo_sample_mst_list');
    sessionStorage.removeItem('service_list');
    sessionStorage.removeItem('tax_list');
    sessionStorage.removeItem('zipcode');
    sessionStorage.removeItem('henrei_param');
    sessionStorage.removeItem('selected_tab');
  }
}

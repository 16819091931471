
export class CompanyChobunDiashiGetRequest {
  company_id: number;
}

export class CompanyChobunDiashiBase {
  company: number;
  daishi: number;

}
export class CompanyChobunDiashiGetResponse extends CompanyChobunDiashiBase {
  id?: number;
}
export class CompanyChobunDiashiPostRequest {
  chobun_daishi_master_ids: Array<number>;
}


import { Base } from '../interfaces/base';
export class LoginPostRequest {
  company_code: string;
  login_id: string;
  password: string;
}

export class LoginPostResponse {
  access_token: string;
}

export class LoginStaffGetResponse {
  id: number;
  login_id: string;
  name: string;
  base: BaseForLogin;
}
export class BaseForLogin extends Base {
  id: number;
  parent: BaseForLogin;
}

export class SokeLoginPostRequest {
  seko: string;
  password: string;
}

export class SokeLoginPostResponse {
  access: string;
  refresh: string;
}

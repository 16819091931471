import { Component, OnInit, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarOptionsDate } from 'src/app/components/com-calendar/com-calendar.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/pages/common/confirm-dialog/confirm-dialog.component';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { LoaderService } from 'src/app/service/loader.service';
import { SekoGetRequest, SekoGetResponse } from 'src/app/interfaces/seko';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import { formatDate } from '@angular/common';
import { ComDropdownComponent } from 'src/app/components/com-dropdown/com-dropdown.component';
import { ComCalendarComponent } from 'src/app/components/com-calendar/com-calendar.component';
declare var $;

@Component({
  selector: 'app-com-koden-summary',
  templateUrl: './koden-summary.component.html',
  styleUrls: ['./koden-summary.component.scss']
})
export class KodenSummaryComponent implements OnInit {
  @ViewChild('companyComboEm', { static: false }) companyComboEm: ComDropdownComponent;

  login_info = this.sessionSvc.get('staff_login_info');
  company_list = Utils.getCompanyList();
  login_company = Utils.getLoginCompany();
  staff_list = null;
  companyCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  departCombo = { values: [], clearable: true, showOnFocus: false };
  hallCombo = { values: [], clearable: true, showOnFocus: false };
  orderStaffCombo = { values: [], clearable: true, showOnFocus: false };
  sekoStaffCombo = { values: [], clearable: true, showOnFocus: false };
  afStaffCombo = { values: [], clearable: true, showOnFocus: false };
  seko_date_from_config: CalendarOptionsDate = new CalendarOptionsDate();
  seko_date_to_config: CalendarOptionsDate = new CalendarOptionsDate();
  form_data = {
    company_id: null,
    seko_department_id: null,
    hall_id: null,
    soke_name: null,
    soke_kana: null,
    kojin_name: null,
    kojin_kana: null,
    moshu_name: null,
    moshu_kana: null,
    seko_date_from: null,
    seko_date_to: null,
    order_staff_id: null,
    seko_staff_id: null,
    af_staff_id: null,
  };
  searched = false;
  seko_list = null;

  page_per_count = 20;
  pagination = {
    pages: new Array(),
    current: 0
  };
  constructor(
    private router: Router,
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    private dialog: MatDialog,
    @Inject(LOCALE_ID) private locale: string,
  ) { }

  ngOnInit() {
    this.initControl();
  }

  initControl() {
    this.staff_list = Utils.getStaffList(this.login_company.id);
    if (this.company_list) {
      this.companyCombo.values = this.company_list.map(value => ({ name: value.base_name, value: value.id, data: value }));
      if (this.login_company) {
        if (this.login_company.base_type === CommonConstants.BASE_TYPE_COMPANY) {
          this.form_data.company_id = this.login_company.id;
          this.departCombo.values = Utils.GetDepartList(this.login_company);
        } else {
          this.companyCombo.disableSearch = false;
          this.form_data.company_id = this.company_list[0].id;
          this.departCombo.values = Utils.GetDepartList(this.company_list[0]);
        }
      }
    }
    if (this.staff_list) {
      this.orderStaffCombo.values = [];
      this.sekoStaffCombo.values = [];
      this.afStaffCombo.values = [];
      this.staff_list.forEach(value => {
        if (Utils.getCompanyId(value.base.id) === this.form_data.company_id) {
          this.orderStaffCombo.values.push({ name: value.name, value: value.id });
          this.sekoStaffCombo.values.push({ name: value.name, value: value.id });
          this.afStaffCombo.values.push({ name: value.name, value: value.id });
        }
      });
    }
  }

  companyChange(event, ...comboEm) {
    if (event) {
      if (this.login_company && this.login_company.base_type === CommonConstants.BASE_TYPE_ADMIN) {
        this.departCombo.values = Utils.GetDepartList(event.data);
        this.hallCombo.values = [];
        this.orderStaffCombo.values = [];
        this.sekoStaffCombo.values = [];
        this.afStaffCombo.values = [];
        if (this.staff_list) {
          this.staff_list.forEach(value => {
            if (Utils.getCompanyId(value.base.id) === this.form_data.company_id) {
              this.orderStaffCombo.values.push({ name: value.name, value: value.id });
              this.sekoStaffCombo.values.push({ name: value.name, value: value.id });
              this.afStaffCombo.values.push({ name: value.name, value: value.id });
            }
          });
        }
        this.form_data.seko_department_id = null;
        this.form_data.hall_id = null;
        this.form_data.order_staff_id = null;
        this.form_data.seko_staff_id = null;
        this.form_data.af_staff_id = null;
        for (const element of comboEm) {
          element.clear();
          element.initComponent();
        }
      }
    }
  }

  departChange(event, hallComboEm) {
    this.hallCombo.values = [];
    this.form_data.hall_id = null;
    if (event && event.data) {
      this.hallCombo.values = Utils.GetHallList(event.data);
    }
    hallComboEm.clear();
    hallComboEm.initComponent();
  }

  searchSeko() {
    const request = new SekoGetRequest();
    request.seko_company = this.form_data.company_id;
    request.seko_department = this.form_data.seko_department_id;
    request.hall = this.form_data.hall_id;
    request.soke_name = this.form_data.soke_name;
    request.soke_kana = this.form_data.soke_kana;
    request.kojin_name = this.form_data.kojin_name;
    request.kojin_kana = this.form_data.kojin_kana;
    request.moshu_name = this.form_data.moshu_name;
    request.moshu_kana = this.form_data.moshu_kana;
    request.order_staff = this.form_data.order_staff_id;
    request.seko_staff = this.form_data.seko_staff_id;
    request.af_staff = this.form_data.af_staff_id;
    if (this.form_data.seko_date_from) {
      request.seko_date_after = formatDate(this.form_data.seko_date_from, 'yyyy-MM-dd', this.locale);
    }
    if (this.form_data.seko_date_to) {
      request.seko_date_before = formatDate(this.form_data.seko_date_to, 'yyyy-MM-dd', this.locale);
    }
    this.loaderSvc.call();
    this.pagination.pages = new Array();
    this.pagination.current = 0;
    this.httpClientService.getSekoList(request).then((response) => {
      Utils.log(response);
      this.searched = true;
      this.seko_list = response;
      Utils.formatSekoDateList(this.seko_list);
      this.calcPagination();
      this.loaderSvc.dismiss();

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }
  calcPagination() {
    if (!this.seko_list || !this.seko_list.length) {
      return;
    }
    const count = Math.ceil(this.seko_list.length / this.page_per_count);
    for (let i = 1; i <= count; i++) {
      this.pagination.pages.push(i);
    }
    this.pagination.current = 1;
  }
  pageTo(page_num) {
    this.pagination.current = page_num;
  }
  clearForm(...comboEms: any[]) {

    this.form_data = {
      company_id: null,
      seko_department_id: null,
      hall_id: null,
      soke_name: null,
      soke_kana: null,
      kojin_name: null,
      kojin_kana: null,
      moshu_name: null,
      moshu_kana: null,
      seko_date_from: null,
      seko_date_to: null,
      order_staff_id: null,
      seko_staff_id: null,
      af_staff_id: null,
    };
    this.hallCombo.values = [];
    if (this.login_company) {
      if (this.login_company.base_type === CommonConstants.BASE_TYPE_COMPANY) {
        this.form_data.company_id = this.login_company.id;
        this.departCombo.values = Utils.GetDepartList(this.login_company);
      } else {
        this.form_data.company_id = this.company_list[0].id;
        this.departCombo.values = Utils.GetDepartList(this.company_list[0]);
      }
      this.companyComboEm.setValue(this.form_data.company_id);
    }
    for (const comboEm of comboEms) {
      comboEm.clear();
      if ('initComponent' in comboEm) {
        comboEm.initComponent();
      }
    }

  }

  getPlace(seko) {
    if (!seko || !seko.schedules) {
      return null;
    }
    let ret = null;
    seko.schedules.forEach((schedule) => {
      // if (schedule.schedule.required_flg) {
      if (schedule.schedule) {
        ret = schedule.hall_name;
        return;
      }
    });
    return ret;
  }

  selectData(seko, event): void {
    if (!seko || !seko.id) {
      return;
    }
    if (event.target.classList.contains('icon')) {
      return;
    }
    this.router.navigate(['foc/seko/edit/' + seko.id], {
      queryParams: { tab: 'homei-list', filter: 'koden' },
    });
  }

}

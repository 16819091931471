import { Component, OnInit } from '@angular/core';
import { HttpClientService } from 'src/app/service/http-client.service';
import { SessionService } from 'src/app/service/session.service';
import { LoaderService } from 'src/app/service/loader.service';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import {
  SekoInquiryGetResponse, SekoInquiryPostRequest
} from 'src/app/interfaces/seko';
declare var $;

@Component({
  selector: 'app-fam-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.scss']
})
export class InquiryComponent implements OnInit {
  Const = CommonConstants;
  Utils = Utils;

  seko_info = this.sessionSvc.get('soke_seko_info');
  inquiry_list: Array<SekoInquiryGetResponse> = null;
  query_text = '';
  requery_text = '';
  query_send_text = '';
  query_type = 1;
  query_group_id = 0;
  message = null;
  is_loading = false;
  next_query_group_id = 1;

  constructor(
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
  ) { }

  async ngOnInit() {
    this.is_loading = true;
    await this.getInquiryList();
    setTimeout(() => {
      $('.ui.checkbox').checkbox();
    }, 100);
    this.is_loading = false;
  }

  async getInquiryList() {
    if (!this.seko_info) {
      return;
    }
    await this.httpClientService.getSekoInquiryList(this.seko_info.id).then((response) => {
      Utils.log(response);
      this.inquiry_list = response;
      if (!this.inquiry_list || !this.inquiry_list.length) {
        this.next_query_group_id = 1;
      } else {
        const query_group_ids: Array<number> = this.inquiry_list.map(v => v.query_group_id);
        this.next_query_group_id = Math.max(...query_group_ids) + 1;
      }

    }).catch(error => {
    });
  }

  showRequeyInput(inquiry) {
    if (!inquiry) {
      return;
    }
    this.query_group_id = inquiry.query_group_id;
    $('#re-query').modal({
      closable: false,
      centered: false,
      detachable: false,
      transition: 'fade',
    }).modal('show');
  }

  cancelRequeyInput() {
    $('.input.requery_text').attr('err-msg', null);
    $('#re-query').modal('hide');
    setTimeout(() => {
      this.requery_text = '';
    }, 200);
  }

  saveDataConfirm(type = 1) {
    this.query_type = type;
    if (type === 1) {
      $('.input.query_text').attr('err-msg', null);
      this.query_text = this.query_text.trim();
      if (!this.query_text) {
        $('.input.query_text').attr('err-msg', '*お問い合わせ内容を入力してくだだい。');
        return;
      }
      this.query_send_text = this.query_text;
    } else {
      $('.input.requery_text').attr('err-msg', null);
      this.requery_text = this.requery_text.trim();
      if (!this.requery_text) {
        $('.input.requery_text').attr('err-msg', '*お問い合わせ内容を入力してくだだい。');
        return;
      }
      this.query_send_text = this.requery_text;
      $('#re-query').modal('hide');

    }
    $('#msg-confirm').modal({
      closable: false,
      centered: false,
      detachable: false,
      transition: 'fade',
    }).modal('show');
  }

  cancelConfirm() {
    $('#msg-confirm').modal('hide');
    setTimeout(() => {
      this.query_send_text = null;
    }, 200);
    if (this.query_type !== 1) {
      $('#re-query').modal({
        closable: false,
        centered: false,
        detachable: false,
        transition: 'fade',
      }).modal('show');
    }
  }

  async saveInquiry() {
    if (!this.query_send_text) {
      return;
    }
    $('#msg-confirm').modal('hide');
    const putData = new SekoInquiryPostRequest();
    putData.query = this.query_send_text;
    if (this.query_type === 1) {
      await this.getInquiryList();
      this.query_group_id = this.next_query_group_id;
    }
    putData.query_group_id = this.query_group_id;

    await this.httpClientService.addSekoInquiry(this.seko_info.id, putData)
      .then((response) => {
        this.message = '送信しました。';
        setTimeout(() => {
          this.query_send_text = '';
          this.query_text = '';
          this.requery_text = '';
        }, 200);
        this.showPopup();
        this.getInquiryList();
      }).catch(error => {
        this.message = '送信失敗しました。';
        this.showPopup();
        this.getInquiryList();
      });
  }

  showPopup() {
    $('#message-popup').modal({
      closable: false,
      centered: false,
      detachable: false,
      transition: 'fade',
    }).modal('show');

  }

  closePopup() {
    $('#message-popup').modal('hide');
    setTimeout(() => {
      this.message = null;
    }, 200);
  }

  isGroup(index) {
    if (!index) {
      return false;
    }
    return this.inquiry_list[index - 1].query_group_id === this.inquiry_list[index].query_group_id;

  }
}

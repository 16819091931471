import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/service/session.service';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { HttpClientService } from 'src/app/service/http-client.service';
import { InquiryPostRequest, InquiryToCompanyPostRequest } from 'src/app/interfaces/inquiry';
import { CommonConstants } from 'src/app/const/const';
import { LoaderService } from 'src/app/service/loader.service';
import { Utils } from 'src/app/const/func-util';

declare var $;

@Component({
  selector: 'app-cus-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.scss']
})
export class InquiryCusComponent implements OnInit {

  Const = CommonConstants;

  seko_info = this.sessionSvc.get('custom_seko_info');
  inquiry = new InquiryPostRequest();
  approve_flg = false;
  send_complete = false;
  message = null;
  inquiry_type = 1;

  constructor(
    private router: Router,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    private httpClientService: HttpClientService,
    private configSvc: RuntimeConfigLoaderService,
  ) { }

  async ngOnInit() {
    if (window.location.pathname.includes('/funeral')) {
      if (!this.seko_info) {
        this.router.navigate(['fuho/']);
        return;
      }
      if (this.seko_info.seko_company.tokusho) {
        this.inquiry.recipient_address = this.seko_info.seko_company.tokusho.mail_address;
      }
    } else {
      this.inquiry_type = 2;
      this.inquiry.recipient_address = this.configSvc.getConfig().inquiry_address;
      if (!this.inquiry.recipient_address) {
        this.send_complete = true;
        this.message = 'お問合せ先メールアドレスが特定できないため、現在ご利用になれません。';
      }
    }
  }

  async getMailAddress() {
    // await this.httpClientService.getTokusho(this.Const.BASE_ID_MSI)
    //   .then((response) => {
    //     this.inquiry.recipient_address = response.mail_address;
    //   }).catch(error => {
    //   });

  }
  sendConfirm() {
    if (!this.approve_flg) {
      return;
    }
    if (!this.invalidData()) {
      return;
    }
    $('#send-confirm').modal({
      closable: false,
      centered: false,
      detachable: false,
      transition: 'fade',
    }).modal('show');

  }
  cancelConfirm() {

    $('#send-confirm').modal('hide');
  }
  sendData() {
    $('#send-confirm').modal('hide');
    this.loaderSvc.call();
    if (this.seko_info) {
      this.inquiry.fuho_num = this.seko_info.seko_company.id + '-' + this.seko_info.id;
    }
    if (this.inquiry_type === 1) {
      const inquiryPost = new InquiryToCompanyPostRequest();
      inquiryPost.name = this.inquiry.sender_name;
      inquiryPost.kana = this.inquiry.sender_kana;
      inquiryPost.tel = this.inquiry.sender_tel;
      inquiryPost.mail_address = this.inquiry.sender_address;
      inquiryPost.content = this.inquiry.mail_body;
      inquiryPost.fuho_num = this.inquiry.fuho_num;
      this.httpClientService.inquiryToCompany(this.seko_info.seko_company.id, inquiryPost)
        .then((response) => {
          this.loaderSvc.dismiss();
          this.send_complete = true;
          this.message = 'お問合せ内容を送信しました。';
        }).catch(error => {
          this.message = 'お問合せ送信が失敗しました。';
          this.loaderSvc.dismiss();
        });

    } else {
      this.httpClientService.sendInquiry(this.inquiry)
        .then((response) => {
          this.loaderSvc.dismiss();
          this.send_complete = true;
          this.message = 'お問合せ内容を送信しました。';
        }).catch(error => {
          this.message = 'お問合せ送信が失敗しました。';
          this.loaderSvc.dismiss();
        });
    }
  }

  invalidData() {
    $('.input').attr('err-msg', null);
    if (!this.inquiry) {
      return false;
    }
    let ret = true;
    if (!this.inquiry.sender_name) {
      $('.input.sender_name').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.inquiry.sender_kana) {
      $('.input.sender_kana').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.inquiry.sender_address) {
      $('.input.sender_address').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.inquiry.mail_body) {
      $('.input.mail_body').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.approve_flg) {
      $('.input.approve_flg').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!ret) {
      return ret;
    }
    const reg = new RegExp(this.Const.MAIL_PATTERN);
    if (!this.inquiry.sender_address.match(reg)) {
      $('.input.sender_address').attr('err-msg', '*メール形式が正しくありません');
      return false;
    }
    return ret;
  }

  isErrorField(element) {
    return !!element.getAttribute('err-msg');
  }


}

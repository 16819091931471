import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/pages/common/confirm-dialog/confirm-dialog.component';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { LoaderService } from 'src/app/service/loader.service';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import {
  HoyoMasterGetRequest, HoyoMasterGetResponse, HoyoMasterPostRequest
} from 'src/app/interfaces/hoyo';
import { HoyoStyleGetResponse } from 'src/app/interfaces/master';
import { isNullOrUndefined } from 'util';
declare var $;

@Component({
  selector: 'app-com-hoyo-master',
  templateUrl: './hoyo-master.component.html',
  styleUrls: ['./hoyo-master.component.scss']
})
export class HoyoMasterComponent implements OnInit {

  Const = CommonConstants;

  login_company = Utils.getLoginCompany();
  hoyo_style_list = this.sessionSvc.get('hoyo_style_list');
  companyCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  styleombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  unitCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  company_list = Utils.getCompanyList();
  company_id = null;
  company_name = null;

  hoyo_master_all: Array<any> = null;
  hoyo_master_list: Array<any> = null;
  hoyo_master_edit: HoyoMasterGetResponse = null;
  edit_type = 1;
  message = null;

  page_per_count = 15;
  pagination = {
    pages: new Array(),
    current: 0
  };
  processing = false;

  constructor(
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    private dialog: MatDialog,
  ) {
    this.loaderSvc.call();
  }

  ngOnInit(): void {
    this.initControl();
  }

  initControl() {
    if (this.company_list) {
      this.companyCombo.values = this.company_list.map(value => ({ name: value.base_name, value: value.id, data: value }));
      if (this.login_company) {
        if (this.login_company.base_type === CommonConstants.BASE_TYPE_COMPANY) {
          this.company_id = this.login_company.id;
          this.company_name = this.login_company.base_name;
        } else {
          this.companyCombo.disableSearch = false;
          this.company_id = this.company_list[0].id;
          this.company_name = this.company_list[0].base_name;
        }
      }
    }
    if (this.hoyo_style_list) {
      this.styleombo.values = this.hoyo_style_list.map(value => ({ name: value.name, value: value.id }));
      this.hoyo_style_list.forEach(value => {value.checked = false; });
    }
    this.unitCombo.values = [
      { name: '日', value: this.Const.TERM_UNIT_DAY },
      { name: '月', value: this.Const.TERM_UNIT_MONTH },
      { name: '年', value: this.Const.TERM_UNIT_YEAR }
    ];
    if (this.company_id) {
      this.getHoyoMasterList(this.company_id);
    } else {
      this.loaderSvc.dismiss();
    }
    setTimeout(() => {
      $('.checkbox').checkbox();
    }, 100);
  }

  async getHoyoMasterList(base_id) {
    this.hoyo_master_all = null;
    const req = new HoyoMasterGetRequest();
    req.company = base_id;
    if (!this.loaderSvc.isLoading) {
      this.loaderSvc.call();
    }
    await this.httpClientService.getHoyoMasterList(req).then((response) => {
      Utils.log(response);
      this.hoyo_master_all = response;
      this.filterHoyoMasterList();
      this.loaderSvc.dismiss();
    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }

  filterHoyoMasterList() {
    this.pagination.pages = new Array();
    this.pagination.current = 0;
    this.hoyo_master_list = new Array();
    if (!this.hoyo_master_all || !this.hoyo_master_all.length) {
      return;
    }
    const style_temp = this.hoyo_style_list.filter( val => val.checked);
    if (style_temp && style_temp.length) {
      this.hoyo_master_list = this.hoyo_master_all.filter(v => {
        const style = this.hoyo_style_list.find( val => val.id === v.style.id);
        if (style && style.checked) {
          return v;
        }
      });
    } else {
      this.hoyo_master_list = this.hoyo_master_all;
    }
    this.calcPagination();
  }
  calcPagination() {
    this.pagination.pages = new Array();
    this.pagination.current = 0;
    if (!this.hoyo_master_list || !this.hoyo_master_list.length) {
      return;
    }
    const count = Math.ceil(this.hoyo_master_list.length / this.page_per_count);
    for (let i = 1; i <= count; i++) {
      this.pagination.pages.push(i);
    }
    this.pagination.current = 1;
  }

  pageTo(pagination, page_num) {
    pagination.current = page_num;
  }

  companyChange(event) {
    if (event) {
      this.loaderSvc.call();
      this.getHoyoMasterList(event.value);
      this.company_name = event.name;
    }
  }

  showData(event, hoyo_master = null) {
    this.message = null;
    if (!hoyo_master) {
      this.edit_type = 1;
      this.hoyo_master_edit = new HoyoMasterGetResponse();
      this.hoyo_master_edit.company = this.company_id;
      this.hoyo_master_edit.style = new HoyoStyleGetResponse();
      if (this.hoyo_style_list && this.hoyo_style_list.length) {
        this.hoyo_master_edit.style.id = this.hoyo_style_list[0].id;
      }
      this.hoyo_master_edit.unit = this.Const.TERM_UNIT_DAY;
    } else {
      this.edit_type = 2;
      if (event.target.classList.contains('operation') || event.target.classList.contains('icon')) {
        return;
      }
      this.hoyo_master_edit = JSON.parse(JSON.stringify(hoyo_master));
    }
    $('#hoyo-master-edit').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  async saveData() {
    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }
    this.processing = true;
    if (!this.dataValidate()) {
      this.processing = false;
      return;
    }
    const postData = this.getPostData();
    this.loaderSvc.call();
    if (this.hoyo_master_edit.id) {
      this.httpClientService.updateHoyoMaster(this.hoyo_master_edit.id, postData)
        .then(async (response) => {
          Utils.log(response);
          this.sessionSvc.save('message', { type: 'info', title: '法要マスタを保存しました。' });
          this.getHoyoMasterList(this.company_id);
          this.processing = false;
        })
        .catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: '法要マスタの保存が失敗しました。' });
          this.loaderSvc.dismiss();
          this.processing = false;
        });
    } else {
      await this.httpClientService.addHoyoMaster(postData)
        .then(async (response) => {
          Utils.log(response);
          this.sessionSvc.save('message', { type: 'info', title: '法要マスタを保存しました。' });
          this.getHoyoMasterList(this.company_id);
          this.processing = false;
        })
        .catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: '法要マスタの保存が失敗しました。' });
          this.loaderSvc.dismiss();
          this.processing = false;
        });
    }
    $('#hoyo-master-edit').modal('hide');
    setTimeout(() => {
      this.hoyo_master_edit = null;
    }, 500);

  }

  dataValidate() {
    const msgs = new Array();
    this.message = null;
    if (isNullOrUndefined(this.hoyo_master_edit.display_num)) {
      msgs.push('表示順');
    }
    if (!this.hoyo_master_edit.style) {
      msgs.push('形式');
    }
    if (!this.hoyo_master_edit.name) {
      msgs.push('名称');
    }
    if (!this.hoyo_master_edit.elapsed_time) {
      msgs.push('経過時間');
    }
    if (!this.hoyo_master_edit.unit) {
      msgs.push('時間単位');
    }

    if (msgs.length > 0) {
      this.message = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: msgs.join(',')
      };
      return false;
    }
    if (Number(this.hoyo_master_edit.display_num) <= 0) {
      this.message = {
        type: 'warning',
        title: '表示順は１以上の数値を入力してください。'
      };
      return false;
    }

    return true;
  }

  getPostData(): HoyoMasterPostRequest {
    const postRequest = new HoyoMasterPostRequest();
    postRequest.company = this.hoyo_master_edit.company;
    postRequest.style = this.hoyo_master_edit.style.id;
    postRequest.name = this.hoyo_master_edit.name;
    postRequest.display_num = this.hoyo_master_edit.display_num;
    postRequest.elapsed_time = this.hoyo_master_edit.elapsed_time;
    postRequest.unit = this.hoyo_master_edit.unit;

    return postRequest;
  }

  deleteData(hoyo_master) {
    if (!hoyo_master) {
      return;
    }
    this.sessionSvc.clear('message');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: '法要マスタを削除します、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.loaderSvc.call();
        this.httpClientService.deleteHoyoMaster(hoyo_master.id)
          .then((response) => {
            Utils.log(response);
            this.sessionSvc.save('message', { type: 'info', title: '法要マスタを削除しました。' });
            this.getHoyoMasterList(this.company_id);
          })
          .catch(error => {
            this.sessionSvc.save('message', { type: 'error', title: '法要マスタの削除が失敗しました。' });
            this.loaderSvc.dismiss();
          });
      }
    });
  }

  closeHoyoMasterEdit() {
    setTimeout(() => {
      this.hoyo_master_edit = null;
    }, 500);
  }

  closeMessage() {
    this.message = null;
  }
  getUnitName(unit) {

    switch (unit) {
      case this.Const.TERM_UNIT_DAY:
        return '日';
      case this.Const.TERM_UNIT_MONTH:
        return '月';
      case this.Const.TERM_UNIT_YEAR:
        return '年';
      default:
        return '';
    }

  }
  loadSample() {
    this.loaderSvc.call();
    this.httpClientService.getHoyoDefaultList().then(async (response) => {
      Utils.log(response);
      if (response && response.length) {
        let ret = true;
        let display_num = 0;
        if (this.hoyo_master_all && this.hoyo_master_all.length) {
          display_num = this.hoyo_master_all[this.hoyo_master_all.length - 1].display_num;
        }
        for (const hoyo_default of response) {
          display_num++;
          const postRequest = new HoyoMasterPostRequest();
          postRequest.company = this.company_id;
          postRequest.style = hoyo_default.hoyo_style.id;
          postRequest.name = hoyo_default.name;
          postRequest.display_num = display_num;
          postRequest.elapsed_time = hoyo_default.elapsed_time;
          postRequest.unit = hoyo_default.unit;
          await this.httpClientService.addHoyoMaster(postRequest)
            .then(async (postPesponse) => {
              Utils.log(postPesponse);
            })
            .catch(error => {
              ret = false;
            });

        }
        if (ret) {
          this.sessionSvc.save('message', { type: 'info', title: '法要サンプルを読込みました。' });
        } else {
          this.sessionSvc.save('message', { type: 'error', title: '法要サンプルの読込が失敗しました。' });
        }
        this.hoyo_style_list.forEach(value => {value.checked = false; });
        this.getHoyoMasterList(this.company_id);
      }
      this.loaderSvc.dismiss();
    }).catch(error => {
      this.loaderSvc.dismiss();
    });

  }
}

import { TaxGetResponse } from '../interfaces/master';
import { SupplierBase } from '../interfaces/supplier';

export class ItemGetRequest {
  base_id: number;
  service_id: number;
  hinban: string;
}

export class ItemBase {
  id?: number;
  base: number;
  service: number;
  hinban: string;
  name: string;
  item_price: number;
  display_num: number;
  fdn_code: string;
}
class ItemSupplier {
  id: number;
  supplier: SupplierBase;
  default_supplier: number;
}
export class ItemGetResponse extends ItemBase {
  begin_date: Date;
  end_date: Date;
  tax: TaxGetResponse;
  item_suppliers: Array<ItemSupplier>;
  image_file?: string;
  selected1?: boolean;
  selected2?: boolean;
}
export class ItemPostRequest extends ItemBase {
  supplier_ids: Array<number>;
  default_supplier: number;
  begin_date: string;
  end_date: string;
  tax: number;
}
export class ItemPostResponse {
}

export class ItemPutRequest extends ItemBase {
}

export class ItemPutResponse {
}

export class ItemDeleteResponse {
}

export class ItemImagePostRequest {
  filename: string;
  file_content: string;
}

import { SokeMenuGetResponse } from '../interfaces/master';

export class BaseGetRequest {
  base_type: number;
}

export class Base {
  base_type: number;
  base_name: string;
  company_code: string;
  zip_code: string;
  prefecture: string;
  address_1: string;
  address_2: string;
  address_3: string;
  tel: string;
  fax: string;
  company_logo_file: string;
  company_map_file: string;
  calc_type: number;
  display_num: number;
  fdn_code: string;
  order_mail_address: string;
}
export class BaseGetResponse extends Base {
  id: number;
  parent: number;
  logo_file?: any;
  map_file?: any;
  tokusho: TokushoForGet;
  focfee: FocFeeForGet;
  soke_menus: Array<SokeMenuGetResponse>;
  sms_account: SmsAccountForGet;
  children: Array<Base>;
}
export class BasePostRequest extends Base {
  id: number;
  parent: number;
}
export class BasePostResponse extends BaseGetResponse {
}
export class BasePutRequest extends Base {
  id: number;
  parent: number;
}
export class BasePutResponse extends BaseGetResponse {
}
export class BaseDeleteResponse {
}

class TokushoBase {
  responsible_name: string;
  mail_address: string;
  from_name: string;
  url: string;
  kumotsu_offer_timing: string;
  koden_offer_timing: string;
  henrei_offer_timing: string;
  business_no: string;

}
export class TokushoForGet extends TokushoBase {
  base_id: number;
  is_new?: boolean;
}
export class TokushoGetResponse extends TokushoBase {
}
export class TokushoPostRequest extends TokushoBase {
}
export class TokushoPostResponse {
}
export class TokushoPutRequest extends TokushoPostRequest {
}
export class TokushoPutResponse {
}
class FocFeeBase {
  monthly_fee: number;
  chobun_fee: number;
  chobun_fee_unit: number;
  kumotsu_fee: number;
  kumotsu_fee_unit: number;
  koden_fee: number;
  koden_fee_unit: number;
  henreihin_fee: number;
  henreihin_fee_unit: number;
  gmo_code: string;
  gmo_code_koden: string;
  gmo_test_flg: boolean;
}
export class FocFeeForGet extends FocFeeBase {
  base_id: number;
  is_new?: boolean;
}

export class FocFeePostRequest extends FocFeeBase {
}

export class FocFeePostResponse {
}
export class FocFeePutRequest extends FocFeePostRequest {
}

export class FocFeePutResponse {
}

class SmsAccountBase {
  token: string;
  client_id: string;
  sms_code: string;

}
export class SmsAccountForGet extends SmsAccountBase {
  base_id: number;
  is_new?: boolean;
}
export class SmsAccountGetResponse extends SmsAccountBase {
}
export class SmsAccountPostRequest extends SmsAccountBase {
}
export class SmsAccountPostResponse {
}
export class SmsAccountPutRequest extends SmsAccountPostRequest {
}
export class SmsAccountPutResponse {
}

import { SekoGetResponse } from './seko';

export class EventMailGetRequest {
  company: number;
  send_date_after: string;
  send_date_before: string;
}
export class EventMailBase {
  event_name: string;
  content: string;
  note: string;
}
export class EventMailGetResponse extends EventMailBase {
  id: number;
  select_ts: Date;
  send_ts: Date;
  targets: Array<EventMailTargetForGet>;
}
export class EventMailPostRequest extends EventMailBase  {
  target_seko_list: Array<number>;
}

export class EventMailPostResponse extends EventMailGetResponse {
}

export class EventMailForGet extends EventMailBase {
  id: number;
  select_ts: Date;
  send_ts: Date;
}
export class EventMailTargetForGet {
  seko: SekoGetResponse;
  id: number;
  event_mail: EventMailForGet;
  send_ts: Date;
}

import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/service/session.service';

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss']
})
export class LoadingModalComponent implements OnInit {

  message = null;
  count = 0;
  constructor(
    private sessionSvc: SessionService,
  ) { }

  ngOnInit() {
    const loading_msg  = this.sessionSvc.get('loading_msg');
    if (!loading_msg) {
      return;
    }
    this.message = loading_msg;
    setInterval( () => {
      const temp = loading_msg + '...';
      this.message = temp.substr(0, loading_msg.length + this.count % 3 + 1);
      this.count++;
    }, 1000);
  }

}

import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { CalendarOptionsDate } from 'src/app/components/com-calendar/com-calendar.component';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { LoaderService } from 'src/app/service/loader.service';
import { EntryGetResquest } from 'src/app/interfaces/order';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-com-sales-summary',
  templateUrl: './sales-summary.component.html',
  styleUrls: ['./sales-summary.component.scss']
})
export class SalesSummaryComponent implements OnInit {

  login_info = this.sessionSvc.get('staff_login_info');
  company_list = Utils.getCompanyList();
  login_company = Utils.getLoginCompany();
  companyCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  date_config: CalendarOptionsDate = new CalendarOptionsDate();
  form_data = {
    company_id: null,
    date_from: null,
    date_to: null,
  };
  entry_list = null;
  kumotsu_henrei_list = null;
  koden_henrei_list = null;
  export_list = null;
  seko_map = new Map();

  constructor(
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    @Inject(LOCALE_ID) private locale: string,
  ) { }

  ngOnInit() {
    this.initControl();
  }

  initControl() {
    if (this.company_list) {
      this.companyCombo.values = this.company_list.map(value => ({ name: value.base_name, value: value.id }));
      if (this.login_company) {
        if (this.login_company.base_type === CommonConstants.BASE_TYPE_COMPANY) {
          this.form_data.company_id = this.login_company.id;
        } else {
          this.companyCombo.clearable = true;
          this.companyCombo.disableSearch = false;
          this.form_data.company_id = this.company_list[0].id;
        }
      }
    }
  }

  getEntries() {
    if (this.form_data.date_from && this.form_data.date_to && this.form_data.date_from > this.form_data.date_to) {
      this.sessionSvc.save('message', { type: 'warning', title: '終了日は開始日より後の日付を入力してください。' });
      return;
    }
    const request = new EntryGetResquest();
    request.seko_company = this.form_data.company_id;
    if (this.form_data.date_from) {
      request.entry_ts_from = formatDate(this.form_data.date_from, 'yyyy-MM-dd', this.locale);
    }
    if (this.form_data.date_to) {
      request.entry_ts_to = formatDate(this.form_data.date_to, 'yyyy-MM-dd', this.locale);
    }
    this.loaderSvc.call();
    this.sessionSvc.save('loading_msg', '集計中');
    this.httpClientService.getEntryList(request).then(async (response) => {
      this.entry_list = response;
      Utils.log('entry_list:', this.entry_list);

      await this.getKumotsuHenrei();
      await this.getKodenHenrei();
      this.export_list = new Array();
      for (const entry of this.entry_list) {
        entry.henrei_flg = false;
        this.export_list.push(entry);
      }
      for (const entry of this.kumotsu_henrei_list) {
        entry.henrei_flg = true;
        this.export_list.push(entry);
      }
      for (const entry of this.koden_henrei_list) {
        entry.henrei_flg = true;
        this.export_list.push(entry);
      }
      Utils.log('export_list:', this.export_list);
      await this.getSekoInfo();
      await this.sortData();
      await this.exportCsv();
      this.loaderSvc.dismiss();

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }
  async getKumotsuHenrei() {
    this.kumotsu_henrei_list = new Array();
    const request = new EntryGetResquest();
    request.seko_company = this.form_data.company_id;
    if (this.form_data.date_from) {
      request.kumotsu_henrei_from = formatDate(this.form_data.date_from, 'yyyy-MM-dd', this.locale);
    }
    if (this.form_data.date_to) {
      request.kumotsu_henrei_to = formatDate(this.form_data.date_to, 'yyyy-MM-dd', this.locale);
    }
    await this.httpClientService.getEntryList(request).then(async (response) => {
      this.kumotsu_henrei_list = response;
      Utils.log('kumotsu_henrei_list:', this.kumotsu_henrei_list);

    }).catch(error => {
    });
  }
  async getKodenHenrei() {
    this.koden_henrei_list = new Array();
    const request = new EntryGetResquest();
    request.seko_company = this.form_data.company_id;
    if (this.form_data.date_from) {
      request.koden_henrei_from = formatDate(this.form_data.date_from, 'yyyy-MM-dd', this.locale);
    }
    if (this.form_data.date_to) {
      request.koden_henrei_to = formatDate(this.form_data.date_to, 'yyyy-MM-dd', this.locale);
    }
    await this.httpClientService.getEntryList(request).then(async (response) => {
      this.koden_henrei_list = response;
      Utils.log('koden_henrei_list:', this.koden_henrei_list);

    }).catch(error => {
    });
  }
  async getSekoInfo() {
    this.seko_map = new Map();
    if (!this.export_list || !this.export_list.length) {
      return;
    }
    for (const entry of this.export_list) {
      if (!this.seko_map.has(entry.seko)) {
        await this.httpClientService.getSekoByid(entry.seko).then((response) => {
          Utils.formatSekoDate(response);
          this.seko_map.set(entry.seko, response);
          entry.seko_info = response;
          Utils.log(response);
        }).catch(error => {
        });
      } else {
        entry.seko_info = this.seko_map.get(entry.seko);
      }
    }
  }

　sortData() {
    if (this.export_list) {
      this.export_list.sort((a, b) => {
        if (a.seko_info.seko_company.id !== a.seko_info.seko_company.id ) {
          return a.seko_info.seko_company.id - b.seko_info.seko_company.id ;
        }
        if (a.seko_info.seko_department.id !== b.seko_info.seko_department.id ) {
          return a.seko_info.seko_department.id - b.seko_info.seko_department.id ;
        }
        if (a.seko !== b.seko ) {
          return a.seko - b.seko ;
        }
        if (a.id !== b.id ) {
          return a.id - b.id ;
        }
      });
    }
  }

  exportCsv() {
    const title = '1.葬儀社ID,2.葬儀社,3.施行部門ID,4.施行部門,5.葬儀番号,6.施行形式,7.葬家名,8.通夜式場,' +
      '9.通夜日,10.通夜開始時刻,11.通夜終了時刻,12.葬儀式場,13.葬儀日,14.葬儀開始時刻,15.葬儀終了時刻,' +
      '16.故人続柄1,17.故人名1,18.故人生年月日1,19.故人逝去日1,20.故人年齢区分1,21.故人年齢1,22.故人続柄2,' +
      '23.故人名2,24.故人生年月日2,25.故人逝去日2,26.故人年齢区分2,27.故人年齢2,28.喪主続柄,29.喪主名,' +
      '30.喪主郵便番号,31.喪主都道府県,32.喪主住所1,33.喪主住所2,34.喪主住所3,35.喪主TEL,36.喪主携帯番号,' +
      '37.喪主メール,38.申込番号,39.申込者名,40.申込日時,41.キャンセル日時,42.サービスID,43.サービス名,' +
      '44.商品ID,45.品番,46.商品名,47.税込単価,48.数量,49.税込金額,50.税抜金額,51.消費税,52.税率,53.軽減税率,' +
      '54.申込者郵便番号,55.申込者都道府県,56.申込者住所1,57.申込者住所2,58.申込者住所3,59.申込者TEL,' +
      '60.申込者メール,61.名札組織,62.名札肩書,63.名札名,64.名札郵便番号,65.名札都道府県,66.名札住所1,' +
      '67.名札住所2,68.名札住所3,69.名札TEL,70.名札連名1,71.名札連名2\n';
    let csv_data = null;
    let export_data = title;
    if (this.export_list) {
      const csv_rows = new Array();
      for (const entry of this.export_list) {
        const seko = entry.seko_info;
        for (const detail of entry.details) {
          // 弔文
          if (detail.chobun && !entry.henrei_flg) {
            let ret_strings = new Array();
            ret_strings = ret_strings.concat(this.getCommonInfo(seko, entry, entry.henrei_flg));
            ret_strings.push(String(CommonConstants.SERVICE_ID_CHOBUN));
            ret_strings.push('弔文');
            ret_strings = ret_strings.concat(this.getServiceInfo(detail, entry));
            csv_rows.push(ret_strings.join(','));
          }
          // 供花・供物
          if (detail.kumotsu && !entry.henrei_flg) {
            let ret_strings = new Array();
            ret_strings = ret_strings.concat(this.getCommonInfo(seko, entry, entry.henrei_flg));
            ret_strings.push(String(CommonConstants.SERVICE_ID_KUMOTSU));
            ret_strings.push('供花・供物');
            ret_strings = ret_strings.concat(this.getServiceInfo(detail, entry));
            csv_rows.push(ret_strings.join(','));
          }
          // 香典
          if (detail.koden && !entry.henrei_flg) {
            let ret_strings = new Array();
            ret_strings = ret_strings.concat(this.getCommonInfo(seko, entry, entry.henrei_flg));
            ret_strings.push(String(CommonConstants.SERVICE_ID_KODEN));
            ret_strings.push('香典');
            ret_strings = ret_strings.concat(this.getServiceInfo(detail, entry));
            csv_rows.push(ret_strings.join(','));
            // 香典システム利用料
            ret_strings = new Array();
            ret_strings = ret_strings.concat(this.getCommonInfo(seko, entry, entry.henrei_flg));
            ret_strings.push(String(CommonConstants.SERVICE_ID_KODEN_COMMISSION));
            ret_strings.push('香典システム利用料');
            ret_strings = ret_strings.concat(this.getKodenCommission(detail, entry));
            csv_rows.push(ret_strings.join(','));
          }
          // 返礼品（供花・供物）
          if (detail.kumotsu && detail.kumotsu.henrei_kumotsu && entry.henrei_flg) {
            let ret_strings = new Array();
            ret_strings = ret_strings.concat(this.getCommonInfo(seko, entry, entry.henrei_flg, detail.kumotsu.henrei_kumotsu));
            ret_strings.push(String(CommonConstants.SERVICE_ID_HENREIHIN_KUMOTSU));
            ret_strings.push('返礼品（供花供物）');
            ret_strings = ret_strings.concat(this.getHenreihinInfo(detail, entry));
            csv_rows.push(ret_strings.join(','));
          }
          // 返礼品（香典）
          if (detail.koden && detail.koden.henrei_koden && entry.henrei_flg) {
            let ret_strings = new Array();
            ret_strings = ret_strings.concat(this.getCommonInfo(seko, entry, entry.henrei_flg, detail.koden.henrei_koden));
            ret_strings.push(String(CommonConstants.SERVICE_ID_HENREIHIN_KODEN));
            ret_strings.push('返礼品（香典）');
            ret_strings = ret_strings.concat(this.getHenreihinInfo(detail, entry));
            csv_rows.push(ret_strings.join(','));
          }
          // 追悼メッセージ
          if (detail.message && !entry.henrei_flg) {
            let ret_strings = new Array();
            ret_strings = ret_strings.concat(this.getCommonInfo(seko, entry, entry.henrei_flg));
            ret_strings.push(String(CommonConstants.SERVICE_ID_MESSAGE));
            ret_strings.push('追悼メッセージ');
            ret_strings = ret_strings.concat(this.getServiceInfo(detail, entry));
            csv_rows.push(ret_strings.join(','));
          }
        }
      }
      csv_data = csv_rows.join('\n');
      export_data += csv_data;
    }
    const now = formatDate(new Date(), 'yyyyMMddHHmmss', this.locale);
    const file_name = '売上集計表_' + now + '.csv';
    Utils.exportCsv(file_name, export_data);
  }

  getCommonInfo(seko, entry, is_henrei, henrei = null): Array<String> {
    const ret_strings = new Array();
    // 共通情報
    ret_strings.push(seko.seko_company.id ? String(seko.seko_company.id) : '');
    ret_strings.push(seko.seko_company.base_name ? seko.seko_company.base_name : '');
    ret_strings.push(seko.seko_department.id ? String(seko.seko_department.id) : '');
    ret_strings.push(seko.seko_department.base_name ? seko.seko_department.base_name : '');
    ret_strings.push(seko.id ? String(seko.id) : '');
    ret_strings.push(seko.seko_style_name ? seko.seko_style_name : '');
    ret_strings.push(seko.soke_name ? seko.soke_name : '');
    const schedule_tsuya = seko.schedules.find(
      schedule => schedule.schedule && schedule.schedule.id === CommonConstants.SCHEDULE_ID_TSUYA
    );
    if (schedule_tsuya) {
      ret_strings.push(schedule_tsuya.hall_name ? schedule_tsuya.hall_name : '');
      ret_strings.push(
        schedule_tsuya.schedule_date ? formatDate(schedule_tsuya.schedule_date, 'yyyy/MM/dd', this.locale) : ''
      );
      ret_strings.push(
        schedule_tsuya.begin_time ? formatDate(schedule_tsuya.begin_time, 'H:mm', this.locale) : ''
      );
      ret_strings.push(
        schedule_tsuya.end_time ? formatDate(schedule_tsuya.end_time, 'H:mm', this.locale) : ''
      );
    } else {
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
    }
    const schedule_kokubetsu = seko.schedules.find(
      schedule => schedule.schedule && schedule.schedule.id === CommonConstants.SCHEDULE_ID_KOKUBETSU
    );
    if (schedule_kokubetsu) {
      ret_strings.push(schedule_kokubetsu.hall_name ? schedule_kokubetsu.hall_name : '');
      ret_strings.push(
        schedule_kokubetsu.schedule_date ? formatDate(schedule_kokubetsu.schedule_date, 'yyyy/MM/dd', this.locale) : ''
      );
      ret_strings.push(
        schedule_kokubetsu.begin_time ? formatDate(schedule_kokubetsu.begin_time, 'H:mm', this.locale) : ''
      );
      ret_strings.push(
        schedule_kokubetsu.end_time ? formatDate(schedule_kokubetsu.end_time, 'H:mm', this.locale) : ''
      );
    } else {
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
    }
    if (seko.kojin && seko.kojin.length > 0) {
      const kojin = seko.kojin[0];
      ret_strings.push(kojin.moshu_kojin_relationship ? kojin.moshu_kojin_relationship : '');
      ret_strings.push(kojin.name ? kojin.name : '');
      ret_strings.push(kojin.birth_date ? formatDate(kojin.birth_date, 'yyyy/MM/dd', this.locale) : '');
      ret_strings.push(kojin.death_date ? formatDate(kojin.death_date, 'yyyy/MM/dd', this.locale) : '');
      ret_strings.push(kojin.age_kbn ? kojin.age_kbn : '');
      ret_strings.push(kojin.age !== null ? String(kojin.age) : '');
    } else {
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
    }
    if (seko.kojin && seko.kojin.length > 1) {
      const kojin = seko.kojin[1];
      ret_strings.push(kojin.moshu_kojin_relationship ? kojin.moshu_kojin_relationship : '');
      ret_strings.push(kojin.name ? kojin.name : '');
      ret_strings.push(kojin.birth_date ? formatDate(kojin.birth_date, 'yyyy/MM/dd', this.locale) : '');
      ret_strings.push(kojin.death_date ? formatDate(kojin.death_date, 'yyyy/MM/dd', this.locale) : '');
      ret_strings.push(kojin.age_kbn ? kojin.age_kbn : '');
      ret_strings.push(kojin.age !== null ? String(kojin.age) : '');
    } else {
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
    }
    ret_strings.push(seko.moshu.kojin_moshu_relationship ? seko.moshu.kojin_moshu_relationship : '');
    ret_strings.push(seko.moshu.name ? seko.moshu.name : '');
    ret_strings.push(seko.moshu.zip_code ? seko.moshu.zip_code : '');
    ret_strings.push(seko.moshu.prefecture ? seko.moshu.prefecture : '');
    ret_strings.push(seko.moshu.address_1 ? seko.moshu.address_1 : '');
    ret_strings.push(seko.moshu.address_2 ? seko.moshu.address_2 : '');
    ret_strings.push(seko.moshu.address_3 ? seko.moshu.address_3 : '');
    ret_strings.push(seko.moshu.tel ? seko.moshu.tel : '');
    ret_strings.push(seko.moshu.mobile_num ? seko.moshu.mobile_num : '');
    ret_strings.push(seko.moshu.mail_address ? seko.moshu.mail_address : '');
    ret_strings.push(entry.id ? String(entry.id) : '');
    ret_strings.push(entry.entry_name ? entry.entry_name : '');
    if (!is_henrei) {
      ret_strings.push(entry.entry_ts ? formatDate(entry.entry_ts, 'yyyy/MM/dd H:mm', this.locale) : '');
    } else {
      ret_strings.push(henrei.created_at ? formatDate(henrei.created_at, 'yyyy/MM/dd H:mm', this.locale) : '');
    }
    ret_strings.push(entry.cancel_ts ? formatDate(entry.cancel_ts, 'yyyy/MM/dd H:mm', this.locale) : '');

    return ret_strings;
  }

  getServiceInfo(detail, entry): Array<String> {
    const ret_strings = new Array();
    let sevice = null;
    // 弔文
    if (detail.chobun) {
      sevice = detail.chobun;
    } else if (detail.kumotsu) {
      sevice = detail.kumotsu;
    } else if (detail.koden) {
      sevice = detail.koden;
    }
    ret_strings.push(detail.item ? String(detail.item) : '');
    ret_strings.push(detail.item_hinban ? detail.item_hinban : '');
    ret_strings.push(detail.item_name ? detail.item_name : '');
    ret_strings.push(String(detail.item_unit_price));
    ret_strings.push(String(detail.quantity));
    ret_strings.push(String(detail.item_unit_price * detail.quantity));
    ret_strings.push(String(detail.item_unit_price * detail.quantity - detail.item_tax - detail.tax_adjust));
    ret_strings.push(String(detail.item_tax + detail.tax_adjust));
    ret_strings.push(String(detail.item_tax_pct));
    ret_strings.push(String(detail.keigen_flg));

    ret_strings.push(entry.entry_zip_code ? entry.entry_zip_code : '');
    ret_strings.push(entry.entry_prefecture ? entry.entry_prefecture : '');
    ret_strings.push(entry.entry_address_1 ? entry.entry_address_1 : '');
    ret_strings.push(entry.entry_address_2 ? entry.entry_address_2 : '');
    ret_strings.push(entry.entry_address_3 ? entry.entry_address_3 : '');
    ret_strings.push(entry.entry_tel ? entry.entry_tel : '');
    ret_strings.push(entry.entry_mail_address ? entry.entry_mail_address : '');

    if (sevice) {
      ret_strings.push(sevice.okurinushi_company ? sevice.okurinushi_company : '');
      ret_strings.push(sevice.okurinushi_title ? sevice.okurinushi_title : '');
      ret_strings.push(sevice.okurinushi_name ? sevice.okurinushi_name : '');
      ret_strings.push(sevice.okurinushi_zip_code ? sevice.okurinushi_zip_code : '');
      ret_strings.push(sevice.okurinushi_prefecture ? sevice.okurinushi_prefecture : '');
      ret_strings.push(sevice.okurinushi_address_1 ? sevice.okurinushi_address_1 : '');
      ret_strings.push(sevice.okurinushi_address_2 ? sevice.okurinushi_address_2 : '');
      ret_strings.push(sevice.okurinushi_address_3 ? sevice.okurinushi_address_3 : '');
      ret_strings.push(sevice.okurinushi_tel ? sevice.okurinushi_tel : '');
      ret_strings.push(sevice.renmei1 ? sevice.renmei1 : '');
      ret_strings.push(sevice.renmei2 ? sevice.renmei2 : '');
    } else {
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
    }
    return ret_strings;
  }

  getKodenCommission(detail, entry): Array<String> {
    const ret_strings = new Array();
    if (detail.koden) {
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('香典システム利用料');
      ret_strings.push(String(detail.koden.koden_commission));
      ret_strings.push(String(1));
      ret_strings.push(String(detail.koden.koden_commission));
      ret_strings.push(String(detail.koden.koden_commission - detail.koden.koden_commission_tax));
      ret_strings.push(String(detail.koden.koden_commission_tax));
      ret_strings.push(String(detail.koden.koden_commission_tax_pct));
      ret_strings.push(String(false));
    } else {
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
    }

    ret_strings.push(entry.entry_zip_code ? entry.entry_zip_code : '');
    ret_strings.push(entry.entry_prefecture ? entry.entry_prefecture : '');
    ret_strings.push(entry.entry_address_1 ? entry.entry_address_1 : '');
    ret_strings.push(entry.entry_address_2 ? entry.entry_address_2 : '');
    ret_strings.push(entry.entry_address_3 ? entry.entry_address_3 : '');
    ret_strings.push(entry.entry_tel ? entry.entry_tel : '');
    ret_strings.push(entry.entry_mail_address ? entry.entry_mail_address : '');

    ret_strings.push('');
    ret_strings.push('');
    ret_strings.push('');
    ret_strings.push('');
    ret_strings.push('');
    ret_strings.push('');
    ret_strings.push('');
    ret_strings.push('');
    ret_strings.push('');
    ret_strings.push('');
    ret_strings.push('');
    return ret_strings;
  }

  getHenreihinInfo(detail, entry): Array<String> {
    const ret_strings = new Array();
    let henrei = null;
    // 供花・供物
    if (detail.kumotsu && detail.kumotsu.henrei_kumotsu) {
      henrei = detail.kumotsu.henrei_kumotsu;
    } else if (detail.koden && detail.koden.henrei_koden) {
      henrei = detail.koden.henrei_koden;
    }
    if (henrei) {
      ret_strings.push(henrei.henreihin ? String(henrei.henreihin) : '');
      ret_strings.push(henrei.henreihin_hinban ? henrei.henreihin_hinban : '');
      ret_strings.push(henrei.henreihin_name ? henrei.henreihin_name : '');
      ret_strings.push(String(henrei.henreihin_price));
      ret_strings.push(String(1));
      ret_strings.push(String(henrei.henreihin_price));
      ret_strings.push(String(henrei.henreihin_price - henrei.henreihin_tax));
      ret_strings.push(String(henrei.henreihin_tax));
      ret_strings.push(String(henrei.henreihin_tax_pct));
      ret_strings.push(String(henrei.keigen_flg));
    } else {
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
      ret_strings.push('');
    }

    ret_strings.push(entry.entry_zip_code ? entry.entry_zip_code : '');
    ret_strings.push(entry.entry_prefecture ? entry.entry_prefecture : '');
    ret_strings.push(entry.entry_address_1 ? entry.entry_address_1 : '');
    ret_strings.push(entry.entry_address_2 ? entry.entry_address_2 : '');
    ret_strings.push(entry.entry_address_3 ? entry.entry_address_3 : '');
    ret_strings.push(entry.entry_tel ? entry.entry_tel : '');
    ret_strings.push(entry.entry_mail_address ? entry.entry_mail_address : '');

    ret_strings.push('');
    ret_strings.push('');
    ret_strings.push('');
    ret_strings.push('');
    ret_strings.push('');
    ret_strings.push('');
    ret_strings.push('');
    ret_strings.push('');
    ret_strings.push('');
    ret_strings.push('');
    ret_strings.push('');
    return ret_strings;
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { LoaderService } from 'src/app/service/loader.service';
import { ComDropdownComponent } from 'src/app/components/com-dropdown/com-dropdown.component';
import { SalesGetRequest } from 'src/app/interfaces/invoice';
import { CommonConstants } from 'src/app/const/const';
import { Utils } from 'src/app/const/func-util';

@Component({
  selector: 'app-com-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  @ViewChild('yearComboEm', { static: false }) yearComboEm: ComDropdownComponent;
  login_info = this.sessionSvc.get('staff_login_info');
  login_company = Utils.getLoginCompany();
  yearCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  fiscal_year = null;

  searched = false;
  sales_list: Array<any> = null;
  sales_list_filter: Array<any> = null;

  page_per_count = 20;
  pagination = {
    pages: new Array(),
    current: 0
  };

  constructor(
    private router: Router,
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
  ) {
    this.loaderSvc.call();
  }

  ngOnInit() {
    if (this.login_info.staff.base.base_type !== CommonConstants.BASE_TYPE_COMPANY) {
      this.loaderSvc.dismiss();
      this.router.navigate(['foc/top/']);
      return;
    }
    this.searchSales();
  }

  searchSales() {
    this.sessionSvc.clear('message');
    const request = new SalesGetRequest();
    request.company = this.login_company.id;
    if (!this.loaderSvc.isLoading) {
      this.loaderSvc.call();
    }
    this.pagination.pages = new Array();
    this.pagination.current = 0;
    this.httpClientService.getSalesList(request).then((response) => {
      Utils.log(response);
      this.searched = true;
      if (response) {
        this.sales_list = response.filter(v => !!v.confirm_ts);
      }
      this.initControl();
      this.loaderSvc.dismiss();

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }

  initControl() {
    this.yearCombo.values = [];
    if (!this.sales_list || !this.sales_list.length) {
      return;
    }
    this.sales_list.sort((a, b) => b.sales_index.sales_yymm - a.sales_index.sales_yymm);
    let years = this.sales_list.map( v => v.sales_index.fiscal_year);
    years = years.filter(function (x, i, self) {
      return self.indexOf(x) === i;
    });
    this.yearCombo.values = years.map( v => ({ name: String(v), value: v }));
    this.yearComboEm.initComponent();
    this.fiscal_year = years[0];
    this.yearComboEm.setValue(this.fiscal_year);
    this.filterSalesList(this.fiscal_year);
  }

  yearChange(event) {
    if (event) {
      this.filterSalesList(event);
    }
  }

  filterSalesList(fiscal_year) {
    this.sales_list_filter = null;
    if (!this.sales_list || !this.sales_list.length) {
      return;
    }
    this.sales_list_filter = this.sales_list.filter( v => v.sales_index.fiscal_year === fiscal_year);
  }

  formatYYMM(sales_yymm: String) {
    return sales_yymm.substring(0, 4) + '年' + sales_yymm.substring(4, 6) + '月';

  }
  downloadPDF(sales_id) {
    this.sessionSvc.clear('message');
    if (sales_id) {
      this.loaderSvc.call();
      this.httpClientService.downloadInvoicePdf(sales_id)
        .then((response) => {
          this.localStreamDownload(response, sales_id);
          this.sessionSvc.save('message', { type: 'info', title: '請求書PDFを出力しました。' });
          this.loaderSvc.dismiss();
        }).catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: '請求書PDFの出力が失敗しました。' });
          this.loaderSvc.dismiss();
        });
    }
  }

  localStreamDownload(stream, sales_id) {
    const blob = new Blob([stream]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    const file_name = '請求書-' + sales_id + '.pdf';

    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveOrOpenBlob(blob, file_name);
    } else {
      link.href = url;
      link.download = file_name;
      link.click();
    }
  }
}

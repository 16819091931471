import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/service/session.service';
import { CommonConstants } from 'src/app/const/const';
import { Utils } from 'src/app/const/func-util';
import { utils } from 'protractor';
declare var $;

@Component({
  selector: 'app-fam-seko',
  templateUrl: './seko.component.html',
  styleUrls: ['./seko.component.scss']
})
export class SokeSekoComponent implements OnInit {

  Const = CommonConstants;
  utils = Utils;
  seko_info = this.sessionSvc.get('soke_seko_info');
  constructor(
    private router: Router,
    private sessionSvc: SessionService
    ) { }

  ngOnInit() {
    if (!this.seko_info) {
      // this.router.navigate(['/soke']);
      return;
    }
    Utils.formatSekoDate(this.seko_info);
  }
  kojinName() {
    if (!this.seko_info || !this.seko_info.kojin) {
      return '';
    }
    return this.seko_info.kojin.map(v => v.name).join(',');
  }
  hallName() {
    if (!this.seko_info || !this.seko_info.schedules) {
      return '';
    }
    const schedule = this.seko_info.schedules.find(v => v.schedule && v.schedule.id === this.Const.SCHEDULE_ID_KOKUBETSU);
    return schedule ? schedule.hall_name : '';
  }
  arrayCount(number: number): any[] {
    return [...Array(number)];
  }
  getData(att) {
    if (!this.seko_info) {
      return '';
    }
    return this.seko_info[att];
  }

}

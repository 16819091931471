import { Component, OnInit, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarOptionsDate } from 'src/app/components/com-calendar/com-calendar.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/pages/common/confirm-dialog/confirm-dialog.component';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { LoaderService } from 'src/app/service/loader.service';
import {
  HoyoGetRequest, HoyoGetResponse
} from 'src/app/interfaces/seko';
import {
  HoyoMasterGetRequest, HoyoMailTemplateGetRequest, HoyoMailPostRequest
} from 'src/app/interfaces/hoyo';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import { formatDate } from '@angular/common';
import { ComDropdownComponent } from 'src/app/components/com-dropdown/com-dropdown.component';
declare var $;

@Component({
  selector: 'app-com-hoyo-filter-ki',
  templateUrl: './hoyo-filter-ki.component.html',
  styleUrls: ['./hoyo-filter-ki.component.scss']
})
export class HoyoFilterKiComponent implements OnInit {
  @ViewChild('hoyoMasterComboEm', { static: false }) hoyoMasterComboEm: ComDropdownComponent;
  Const = CommonConstants;
  Utils = Utils;
  calendarOptionDate: CalendarOptionsDate = new CalendarOptionsDate();

  login_company = Utils.getLoginCompany();
  hoyoMasterCombo = { values: [] };
  departCombo = { values: [], clearable: true, showOnFocus: false };
  form_data = {
    company_id: null,
    hoyo_id: null,
    seko_department_id: null,
    hoyo_planned_date_from: null,
    hoyo_planned_date_to: null,
  };
  searched = false;
  hoyo_list: Array<HoyoGetResponse> = null;
  mail_template_list = null;
  hoyo_mail = new HoyoMailPostRequest();

  page_per_count = 20;
  pagination = {
    pages: new Array(),
    current: 0
  };

  all_check = false;

  message = null;
  processing = false;
  constructor(
    private router: Router,
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    private dialog: MatDialog,
    @Inject(LOCALE_ID) private locale: string,
  ) {
    this.loaderSvc.call();
  }


  async ngOnInit() {
    if (this.login_company) {
      this.form_data.company_id = this.login_company.id;
      this.departCombo.values = Utils.GetDepartList(this.login_company);
      await this.getHoyoMasterList(this.form_data.company_id);
    }
  }

  async getHoyoMasterList(base_id) {
    const req = new HoyoMasterGetRequest();
    req.company = base_id;
    await this.httpClientService.getHoyoMasterList(req).then(async (response) => {
      Utils.log(response);
      if (response && response.length) {
        this.hoyoMasterCombo.values = response.map(value => ({ name: value.name, value: value.id }));
        this.loaderSvc.dismiss();
      }
    }).catch(error => {
      this.loaderSvc.dismiss();
    });
    this.hoyoMasterComboEm.initComponent();
    if (this.hoyoMasterCombo.values.length) {
      this.form_data.hoyo_id = this.hoyoMasterCombo.values[0].value;
      this.hoyoMasterComboEm.setValue(this.form_data.hoyo_id);
    }
  }

  checkItem(event, hoyo: HoyoGetResponse) {
    if (!hoyo) {
      return;
    }
    if (event.target.classList.contains('icon')) {
      return;
    }
    hoyo.selected = !hoyo.selected;
    for (const item of this.hoyo_list) {
      if (!item.selected) {
        this.all_check = false;
        return;
      }
    }
    this.all_check = true;
  }
  checkAllItem() {
    for (const hoyo of this.hoyo_list) {
      hoyo.selected = this.all_check;
    }
  }
  async searchHoyo() {
    this.sessionSvc.clear('message');
    const request = new HoyoGetRequest();
    request.seko_company = this.form_data.company_id;
    request.hoyo = this.form_data.hoyo_id;
    request.seko_department = this.form_data.seko_department_id;
    if (this.form_data.hoyo_planned_date_from) {
      request.hoyo_planned_date_after = formatDate(this.form_data.hoyo_planned_date_from, 'yyyy-MM-dd', this.locale);
    }
    if (this.form_data.hoyo_planned_date_to) {
      request.hoyo_planned_date_before = formatDate(this.form_data.hoyo_planned_date_to, 'yyyy-MM-dd', this.locale);
    }
    if (!this.loaderSvc.isLoading) {
      this.loaderSvc.call();
    }
    await this.httpClientService.getHoyoList(request).then((response) => {
      Utils.log(response);
      this.searched = true;
      this.hoyo_mail.hoyo = null;

      if (response && response.length) {
        this.hoyo_list = response.filter(
          v => (v.seko.seko_contact && v.seko.seko_contact.mobile_num) && v.seko.moshu.mail_flg
          );
        this.hoyo_mail.hoyo = request.hoyo;
      } else {
        this.hoyo_list = new Array();
      }
      Utils.log(this.hoyo_list);
      this.calcPagination();
      this.loaderSvc.dismiss();

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }

  calcPagination() {
    this.pagination.pages = new Array();
    this.pagination.current = 0;
    this.all_check = false;
    if (!this.hoyo_list || !this.hoyo_list.length) {
      return;
    }
    const count = Math.ceil(this.hoyo_list.length / this.page_per_count);
    for (let i = 1; i <= count; i++) {
      this.pagination.pages.push(i);
    }
    this.pagination.current = 1;
  }

  pageTo(page_num) {
    this.pagination.current = page_num;
  }

  clearForm(...comboEms: any[]) {

    this.form_data = {
      company_id: this.login_company.id,
      hoyo_id: this.hoyoMasterCombo.values[0].value,
      seko_department_id: null,
      hoyo_planned_date_from: null,
      hoyo_planned_date_to: null,
    };
    this.hoyoMasterComboEm.setValue(this.form_data.hoyo_id);
    for (const comboEm of comboEms) {
      comboEm.clear();
      if ('initComponent' in comboEm) {
        comboEm.initComponent();
      }
    }

  }

  getPlace(seko) {
    if (!seko || !seko.schedules) {
      return null;
    }
    let ret = null;
    seko.schedules.forEach((schedule) => {
      if (schedule.schedule) {
        ret = schedule.hall_name;
        return;
      }
    });
    return ret;
  }

  async showMailSample() {
    await this.getMailTemplate();
    $('#mail-sample').modal({
      allowMultiple: true,
      closable: false,
      centered: false,
      detachable: false,
      transition: 'fade',
    }).modal('show');
  }

  async getMailTemplate() {
    const request = new HoyoMailTemplateGetRequest();
    request.company = this.form_data.company_id;
    await this.httpClientService.getHoyoMailTemplateList(request).then((response) => {
      Utils.log(response);
      this.mail_template_list = response;

    }).catch(error => {
    });
  }

  select_mail_text(text) {
    this.hoyo_mail.content = text;
    $('#mail-sample').modal('hide');
  }

  saveData() {
    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }
    this.hoyo_mail.content = this.hoyo_mail.content ? this.hoyo_mail.content.trim() : null;
    if (!this.hoyo_mail.content) {
      const message = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: 'メール本文'
      };
      this.sessionSvc.save('message', message);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: 'SMS送信を行います、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.hoyo_mail.target_seko_list = new Array();
        for (const hoyo of this.hoyo_list) {
          if (hoyo.selected) {
            this.hoyo_mail.target_seko_list.push(hoyo.seko.id);
          }
        }
        if (!this.hoyo_mail.target_seko_list.length) {
          return;
        }

        this.processing = true;
        this.loaderSvc.call();
        this.httpClientService.addHoyoMail(this.hoyo_mail)
          .then(async (response) => {
            Utils.log(response);
            await this.searchHoyo();
            if (this.hoyo_mail.target_seko_list.length === response.targets.length) {
              this.sessionSvc.save('message', { type: 'info', title: 'SMSを送信しました。' });
            } else {
              const msg = new Array();
              for (const seko_id of this.hoyo_mail.target_seko_list) {
                if (!response.targets.find(v => v.seko.id === seko_id)) {
                  msg.push(seko_id);
                }
              }

              this.sessionSvc.save('message', {
                type: 'warning', title: '以下の施行番号のSMS送信が失敗しました。携帯電話番号が正しいかご確認ください。',
                msg: msg.join(',')
              });
            }
            this.processing = false;
          })
          .catch(error => {
            this.sessionSvc.save('message', { type: 'error', title: 'SMS送信が失敗しました。' });
            this.processing = false;
            this.loaderSvc.dismiss();
          });
      }
    });
  }

  filtered(hoyo: HoyoGetResponse) {
    if (hoyo.seko.hoyo_mail_targets && hoyo.seko.hoyo_mail_targets.length) {
      if (hoyo.seko.hoyo_mail_targets.find(v => v.hoyo_mail.hoyo === hoyo.hoyo.id)) {
        return '抽出済';
      }
    }
    return '';

  }

  exportCsv() {
    const title = '施行番号,葬家名,故人名,喪主名,連絡先名,逝去日,施行日,法要予定日,式場,抽出済\n';
    let csv_data = null;
    let export_data = title;
    if (this.hoyo_list) {
      const values = this.hoyo_list.map(v => {
        const ret_strings = new Array();
        ret_strings.push(v.seko.id);
        ret_strings.push(v.seko.soke_name ? v.seko.soke_name : '');
        ret_strings.push(v.seko.kojin[0].name ? v.seko.kojin[0].name : '');
        ret_strings.push(v.seko.moshu.name ? v.seko.moshu.name : '');
        ret_strings.push(v.seko.seko_contact && v.seko.seko_contact.name ? v.seko.seko_contact.name : '');
        ret_strings.push(v.seko.death_date ? formatDate(v.seko.death_date, 'yyyy/MM/dd', this.locale) : '');
        ret_strings.push(v.seko.seko_date ? formatDate(v.seko.seko_date, 'yyyy/MM/dd', this.locale) : '');
        ret_strings.push(v.hoyo_planned_date ? formatDate(v.hoyo_planned_date, 'yyyy/MM/dd', this.locale) : '');
        ret_strings.push(this.getPlace(v.seko) ? this.getPlace(v.seko) : '');
        ret_strings.push(this.filtered(v));
        return ret_strings.join(',');
      });
      csv_data = values.join('\n');
      export_data += csv_data;
    }
    const now = formatDate(new Date(), 'yyyyMMddHHmmss', this.locale);
    const file_name = '法要案内抽出一覧（忌法要）_' + now + '.csv';
    Utils.exportCsv(file_name, export_data);
  }

  buttonDisabled() {
    if (!this.hoyo_list || !this.hoyo_list.length) {
      return true;
    }
    let count = 0;
    for (const hoyo of this.hoyo_list) {
      if (hoyo.selected) {
        count++;
      }
    }
    if (!count) {
      return true;
    }
    return false;

  }

}

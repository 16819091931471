
export class CompanyFuhoSampleGetRequest {
  company_id: number;
}

export class CompanyFuhoSampleBase {
  id?: number;
  company: number;
  display_num: number;
  sentence: string;

}
export class CompanyFuhoSampleGetResponse extends CompanyFuhoSampleBase {
}
export class CompanyFuhoSamplePostRequest extends CompanyFuhoSampleBase {
}
export class CompanyFuhoSamplePostResponse {
}

export class CompanyFuhoSamplePutRequest extends CompanyFuhoSampleBase {
}
export class CompanyFuhoSamplePutResponse {
}


export class CompanyFuhoSampleDeleteResponse {
}

import { Injectable } from '@angular/core';

import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AppViewConstants } from 'src/app/const/const';
import { Title, Meta } from '@angular/platform-browser';


@Injectable({
  providedIn: 'root'
})
export class RouterService {
  private currentUrl = '';
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta
  ) {
    router.events.subscribe(event => {
      if (!window.location.hash) {
        window.scroll(0, 0);
      }
      if (event instanceof NavigationStart) {
        this.currentUrl = event.url;
      } else if (event instanceof NavigationEnd) {
        const document = window.document.getElementsByClassName('ui dimmer modals transition visible');
        if (document.length > 0) {
          for (let i = 0; i < document.length; i++) {
            document[i].remove();
          }

        }
        this.updateMeta();
      }
    });
  }
  public getCurrentUrl() {
    return this.currentUrl;
  }

  public getAppView() {
    const path = this.activatedRoute.snapshot.firstChild.routeConfig.path;
    const appView = AppViewConstants[path];
    if (!appView) {
      return null;
    }
    return appView;
  }

  public updateMeta() {
    const path = this.activatedRoute.snapshot.firstChild.routeConfig.path;
    const appView = AppViewConstants[path];
    if (!appView) {
      return;
    }
    this.updateTitle(appView.title);
    this.updateDescription(appView.description);
  }

  public updateTitle(title: string) {
    this.titleService.setTitle(title);
  }

  public updateDescription(description: string) {
    const discriptionTag = this.metaService.getTag('name="description"');
    discriptionTag.content = description;
  }

}

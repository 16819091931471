import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/pages/common/confirm-dialog/confirm-dialog.component';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { LoaderService } from 'src/app/service/loader.service';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import {
  AfGetResponse, AfPostRequest,
  AfGroupGetRequest, AfGroupGetResponse, AfGroupPostRequest
} from 'src/app/interfaces/af';
import { isNullOrUndefined } from 'util';
declare var $;


@Component({
  selector: 'app-com-af-group',
  templateUrl: './af-group.component.html',
  styleUrls: ['./af-group.component.scss']
})
export class AfGroupComponent implements OnInit {

  Const = CommonConstants;

  login_company = Utils.getLoginCompany();
  companyCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  departCombo = { values: [], clearable: true, showOnFocus: false, forceSelection: false };
  inputDepartCombo = { values: [], clearable: true, showOnFocus: false, forceSelection: false };
  company_list = Utils.getCompanyList();
  company_id = null;
  company_name = null;
  depart_id = null;

  af_group_list: Array<any> = null;
  af_group_edit: AfGroupGetResponse = null;
  edit_type = 1;
  select_af_group = null;
  af_list = null;
  af_edit: AfGetResponse = null;
  af_edit_type = 1;

  message = null;
  message_sub = null;

  page_per_count = 15;
  pagination = {
    pages: new Array(),
    current: 0
  };
  processing = false;

  constructor(
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    private dialog: MatDialog,
  ) {
    this.loaderSvc.call();
  }

  ngOnInit(): void {
    this.initControl();
  }

  initControl() {
    if (this.company_list) {
      this.companyCombo.values = this.company_list.map(value => ({ name: value.base_name, value: value.id, data: value }));
      if (this.login_company) {
        if (this.login_company.base_type === CommonConstants.BASE_TYPE_COMPANY) {
          this.company_id = this.login_company.id;
          this.company_name = this.login_company.base_name;
          this.departCombo.values = Utils.GetDepartList(this.login_company);
        } else {
          this.companyCombo.disableSearch = false;
          this.company_id = this.company_list[0].id;
          this.company_name = this.company_list[0].base_name;
          this.departCombo.values = Utils.GetDepartList(this.company_list[0]);
        }
      }
    }
    this.getAfGroupList(this.company_id);
  }

  async getAfGroupList(base_id) {
    const req = new AfGroupGetRequest();
    req.department = base_id;
    if (!this.loaderSvc.isLoading) {
      this.loaderSvc.call();
    }
    await this.httpClientService.getAfGroupListAll(req).then((response) => {
      Utils.log(response);
      this.af_group_list = response;
      this.calcPagination();
      this.loaderSvc.dismiss();
    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }

  calcPagination() {
    this.pagination.pages = new Array();
    this.pagination.current = 0;
    if (!this.af_group_list || !this.af_group_list.length) {
      return;
    }
    const count = Math.ceil(this.af_group_list.length / this.page_per_count);
    for (let i = 1; i <= count; i++) {
      this.pagination.pages.push(i);
    }
    this.pagination.current = 1;
  }

  pageTo(pagination, page_num) {
    pagination.current = page_num;
  }

  companyChange(event, departComboEm) {
    this.departCombo.values = [];
    this.depart_id = null;
    if (event && event.data) {
      this.departCombo.values = Utils.GetDepartList(event.data);
    }
    departComboEm.clear();
    departComboEm.initComponent();
    if (event && event.data) {
      this.getAfGroupList(event.value);
    }
  }

  departChange(event) {
    if (event) {
      this.getAfGroupList(event.value);
    } else {
      this.getAfGroupList(this.company_id);
    }
  }

  showData(event, af_group = null) {
    this.message = null;
    this.inputDepartCombo.values = [];
    if (this.login_company.base_type === CommonConstants.BASE_TYPE_COMPANY) {
      this.inputDepartCombo.values = Utils.GetDepartList(this.login_company);
    } else {
      const company = this.company_list.find(v => v.id === this.company_id);
      if (company) {
        this.inputDepartCombo.values = Utils.GetDepartList(company);
      }
    }
    if (!af_group) {
      this.edit_type = 1;
      this.af_group_edit = new AfGroupGetResponse();
      this.af_group_edit.company = this.company_id;
      this.af_group_edit.department = this.depart_id;
    } else {
      this.edit_type = 2;
      if (event.target.classList.contains('operation') || event.target.classList.contains('icon')) {
        return;
      }
      this.af_group_edit = JSON.parse(JSON.stringify(af_group));
      const base_type = Utils.getBaseType(this.af_group_edit.department);
      if (base_type === CommonConstants.BASE_TYPE_COMPANY) {
        this.af_group_edit.company = this.af_group_edit.department;
        this.af_group_edit.department = null;
      }
    }
    $('#af-group-edit').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  async saveData() {
    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }
    this.processing = true;
    if (!this.dataValidate()) {
      this.processing = false;
      return;
    }
    const postData = this.getPostData();
    const login_id_err = false;
    this.loaderSvc.call();
    if (this.af_group_edit.id) {
      this.httpClientService.updateAfGroup(this.af_group_edit.id, postData)
        .then(async (response) => {
          Utils.log(response);
          this.sessionSvc.save('message', { type: 'info', title: 'AF項目グループ情報を保存しました。' });
          if (this.depart_id) {
            this.getAfGroupList(this.depart_id);
          } else {
            this.getAfGroupList(this.company_id);
          }
          this.processing = false;
        })
        .catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: 'AF項目グループ情報の保存が失敗しました。' });
          this.loaderSvc.dismiss();
          this.processing = false;
        });
    } else {
      await this.httpClientService.addAfGroup(postData)
        .then(async (response) => {
          Utils.log(response);
          this.sessionSvc.save('message', { type: 'info', title: 'AF項目グループ情報を保存しました。' });
          if (this.depart_id) {
            this.getAfGroupList(this.depart_id);
          } else {
            this.getAfGroupList(this.company_id);
          }
          this.processing = false;
        })
        .catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: 'AF項目グループ情報の保存が失敗しました。' });
          this.loaderSvc.dismiss();
          this.processing = false;
        });
    }
    if (!login_id_err) {
      $('#af-group-edit').modal('hide');
      setTimeout(() => {
        this.af_group_edit = null;
      }, 500);
    }

  }

  dataValidate() {
    const msgs = new Array();
    this.message = null;
    if (!this.af_group_edit.name) {
      msgs.push('グループ名');
    }
    if (isNullOrUndefined(this.af_group_edit.display_num)) {
      msgs.push('表示順');
    }

    if (msgs.length > 0) {
      this.message = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: msgs.join(',')
      };
      return false;
    }
    if (Number(this.af_group_edit.display_num) <= 0) {
      this.message = {
        type: 'warning',
        title: '表示順は１以上の数値を入力してください。'
      };
      return false;
    }

    return true;
  }

  getPostData(): AfGroupPostRequest {
    const postRequest = new AfGroupPostRequest();
    if (this.af_group_edit.department) {
      postRequest.department = this.af_group_edit.department;
    } else {
      postRequest.department = this.company_id;
    }
    postRequest.name = this.af_group_edit.name;
    postRequest.display_num = this.af_group_edit.display_num;

    return postRequest;
  }

  deleteData(af_group) {
    if (!af_group) {
      return;
    }
    this.sessionSvc.clear('message');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: 'AF項目グループ情報を削除します、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.loaderSvc.call();
        this.httpClientService.deleteAfGroup(af_group.id)
          .then((response) => {
            Utils.log(response);
            this.sessionSvc.save('message', { type: 'info', title: 'AF項目グループ情報を削除しました。' });
            this.getAfGroupList(this.depart_id);
          })
          .catch(error => {
            this.sessionSvc.save('message', { type: 'error', title: 'AF項目グループ情報の削除が失敗しました。' });
            this.loaderSvc.dismiss();
          });
      }
    });
  }

  closeAfGroupEdit() {
    setTimeout(() => {
      this.af_group_edit = null;
    }, 500);
  }

  closeMessage() {
    this.message = null;
    this.message_sub = null;
  }

  async showAfList(af_group: AfGroupGetResponse) {
    this.message = null;
    this.message_sub = null;
    if (!af_group) {
      return;
    }
    this.select_af_group = af_group;
    this.af_list = af_group.after_follows;
    $('#af-list').modal({
      allowMultiple: true,
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  async getAfList() {
    if (!this.loaderSvc.isLoading) {
      this.loaderSvc.call();
    }
    await this.httpClientService.getAfGroup(this.select_af_group.id).then((response) => {
      Utils.log(response);
      this.af_list = response.after_follows;
      const index = this.af_group_list.findIndex(v => v.id === this.select_af_group.id);
      this.af_group_list.splice(index, 1, response);
      this.loaderSvc.dismiss();

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }

  showAfEdit(event, af = null) {
    this.message = null;
    this.message_sub = null;
    if (!af) {
      this.af_edit_type = 1;
      this.af_edit = new AfGetResponse();
      // dateBeginEm.clear();
      // dateEndEm.clear();
    } else {
      if (event.target.classList.contains('operation') || event.target.classList.contains('icon')) {
        return;
      }
      this.af_edit_type = 2;
      this.af_edit = JSON.parse(JSON.stringify(af));
    }
    // comTaxEm.setValue(this.item_edit.tax);
    $('#af-edit').modal({
      allowMultiple: true,
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  async saveAf() {
    this.message = null;
    this.message_sub = null;
    if (this.processing) {
      return;
    }
    this.processing = true;
    const check = await this.afValidate();
    if (!check) {
      this.processing = false;
      return;
    }
    const postData = this.getAfPostData();

    this.loaderSvc.call();
    if (this.af_edit.id) {
      this.httpClientService.updateAf(this.af_edit.id, postData)
        .then(async (response) => {
          Utils.log(response);
          await this.getAfList();
          this.message = { type: 'info', title: 'AF項目情報を保存しました。' };
          this.processing = false;
        })
        .catch(error => {
          this.message = { type: 'error', title: 'AF項目情報の保存が失敗しました。' };
          this.processing = false;
          this.loaderSvc.dismiss();
          this.sessionSvc.clear('message');
        });
    } else {
      this.httpClientService.addAf(postData)
        .then(async (response) => {
          Utils.log(response);
          await this.getAfList();
          this.message = { type: 'info', title: 'AF項目情報を保存しました。' };
          this.processing = false;
        })
        .catch(error => {
          this.message = { type: 'error', title: 'AF項目情報の保存が失敗しました。' };
          this.loaderSvc.dismiss();
          this.processing = false;
          this.sessionSvc.clear('message');
        });
    }
    $('#af-edit').modal('hide');
    setTimeout(() => {
      this.af_edit = null;
    }, 500);
  }

  async afValidate() {
    const msgs = new Array();
    this.message_sub = null;
    if (!this.af_edit.name) {
      msgs.push('項目名');
    }
    if (isNullOrUndefined(this.af_edit.display_num)) {
      msgs.push('表示順');
    }

    if (msgs.length > 0) {
      this.message_sub = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: msgs.join(',')
      };
      return false;
    }
    if (Number(this.af_edit.display_num) <= 0) {
      this.message_sub = {
        type: 'warning',
        title: '表示順は１以上の数値を入力してください。'
      };
      return false;
    }

    return true;
  }

  closeAfEdit() {
    setTimeout(() => {
      this.af_edit = null;
    }, 500);
  }

  getAfPostData(): AfPostRequest {
    const postRequest = new AfPostRequest();
    postRequest.name = this.af_edit.name;
    postRequest.display_num = this.af_edit.display_num;
    postRequest.af_group = this.select_af_group.id;

    return postRequest;
  }

  deleteAf(af) {
    this.message = null;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: 'AF項目情報を削除します、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.loaderSvc.call();
        this.httpClientService.deleteAf(af.id)
          .then(async (response) => {
            Utils.log(response);
            await this.getAfList();
            this.message = { type: 'info', title: 'AF項目情報を削除しました。' };
          })
          .catch(error => {
            this.loaderSvc.dismiss();
            this.message = { type: 'error', title: 'AF項目情報の削除が失敗しました。' };
          });
      }
    });
  }

}

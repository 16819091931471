import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { MoshuPutRequest } from 'src/app/interfaces/seko';
import { CommonConstants } from 'src/app/const/const';
import { Utils } from 'src/app/const/func-util';
declare var $;

@Component({
  selector: 'app-fam-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  Const = CommonConstants;

  seko_info = this.sessionSvc.get('soke_seko_info');
  password = null;
  password_confirm = null;
  message = null;
  input_type_password = 'password';
  input_type_password_confirm = 'password';

  constructor(
    private sessionSvc: SessionService,
    private httpClientService: HttpClientService,
  ) { }

  ngOnInit(): void {
  }
  chanageType(mode) {
    if (mode === 1) {
      this.input_type_password = this.input_type_password === 'password' ? 'text' : 'password';
    } else {
      this.input_type_password_confirm = this.input_type_password_confirm === 'password' ? 'text' : 'password';
    }
  }

  saveData() {
    if (!this.invalidData()) {
      return;
    }
    const postData = new MoshuPutRequest();
    postData.password = this.password;
    this.httpClientService.updateMoshu(this.seko_info.id, postData)
      .then(async (response) => {
        Utils.log(response);
        this.message =  'パスワードを変更しました。' ;
        $('#message-popup').modal({
          closable: false,
          centered: false,
          detachable: false,
          transition: 'fade',
        }).modal('show');
        this.password = null;
        this.password_confirm = null;
      })
      .catch(error => {
        this.message =  'パスワードの変更が失敗しました。' ;
        $('#message-popup').modal({
          closable: false,
          centered: false,
          detachable: false,
          transition: 'fade',
        }).modal('show');
      });
  }

  invalidData() {
    $('.input').attr('err-msg', null);
    let ret = true;
    if (!this.password) {
      $('.input.password').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.password_confirm) {
      $('.input.password_confirm').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!ret) {
      return ret;
    }
    if (this.password !== this.password_confirm) {
      $('.input.password_confirm').attr('err-msg', '*パスワードが一致しません。');
      return false;
    }
    return ret;
  }

  isErrorField(element) {
    return !!element.getAttribute('err-msg');
  }

  closePopup() {
    $('#message-popup').modal('hide');
    setTimeout(() => {
    this.message = null;
    }, 500);
  }
}

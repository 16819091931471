import { Component, OnInit, Output, EventEmitter, Input, Inject, LOCALE_ID } from '@angular/core';
import { Time } from '@angular/common';
import { formatDate } from '@angular/common';

@Component({
  selector: 'com-time-input',
  templateUrl: './com-time-input.component.html',
  styleUrls: ['./com-time-input.component.scss']
})
export class ComTimeInputComponent implements OnInit {
  @Input() value: Date;
  @Output() valueChange: EventEmitter<Date> = new EventEmitter<Date>();
  displayValue = '';
  constructor(
    @Inject(LOCALE_ID) private locale: string
  ) {
  }
  ngOnInit(): void {
    if (this.value) {
      this.displayValue = formatDate(this.value, 'H:mm', this.locale);
    }
  }
  onFocus(event: any): void {
    if (this.value) {
      this.displayValue = formatDate(this.value, 'H:mm', this.locale);
    } else {
      this.displayValue = '';
    }
  }
  onChange(event: any): void {
    const time = this.formatTime(event.target.value);
    if (time) {
      this.value = new Date('1900-01-01T' + time + '+09:00');
      this.valueChange.emit(this.value);
    } else {
      this.value = null;
      this.valueChange.emit(this.value);
    }
  }
  onBlur(event: any): void {
    if (this.value) {
      this.displayValue = formatDate(this.value, 'H:mm', this.locale);
    }
  }
  // onKeydown(event: KeyboardEvent): void {
  //   if (event.keyCode === 8 || event.keyCode === 46) {
  //     return;
  //   }
  //   if (event.keyCode < 48 || event.keyCode > 57) {
  //     event.preventDefault();
  //   }
  //   if ((event.target as HTMLInputElement).value.length - this.selectionLength > 3) {
  //     event.preventDefault();
  //   }
  // }
  // onKeyup(event: any): void {
  //   this.selectionLength = 0;
  //   this.value = this.formatTime(event.target.value);
  //   this.valueChange.emit(this.value);
  // }
  // onSelect(event: any) {
  //   this.selectionLength = event.target.selectionEnd - event.target.selectionStart
  // }
  formatTime(time) {
    if (!time || !time.length) {
      return '';
    }
    const tmp = ('000' + time).slice(-4);
    const minutes = tmp.slice(-2);
    if (Number(minutes) > 59) {
      return '';
    }
    const hours = tmp.slice(0, 2);
    if (Number(hours) > 23) {
      return '';
    }
    return hours + ':' + minutes;
  }
}

import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { CommonConstants } from 'src/app/const/const';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(
    private router: Router,
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const login_info = this.sessionSvc.get('staff_login_info');
    if (!([CommonConstants.BASE_TYPE_ADMIN, CommonConstants.BASE_TYPE_COMPANY].includes(login_info.staff.base.base_type))) {
      this.router.navigate(['foc/top/']);
      return false;
    }
    this.httpClientService.refreshToken().catch(err => { });
    return true;
  }
}

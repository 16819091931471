import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/service/http-client.service';
import { CacheService } from 'src/app/service/cache.service';
import { AddressGetRequest, AddressGetResponse } from '../interfaces/master';

@Injectable({
  providedIn: 'root'
})
export class ZipcodeService extends CacheService {

  private result_data: AddressGetResponse;
  constructor(
    private httpClientService: HttpClientService
  ) {
    super();
    this.cache_name = 'zipcode';
  }

  async getData(oRequest: AddressGetRequest): Promise<AddressGetResponse> {
    if (!oRequest || !oRequest.zip_code || oRequest.zip_code.length !== 7) { return null; }
    const key = oRequest.zip_code;

    this.result_data = await this.getDataFromCache(key);

    if (!this.result_data) {
      await this.httpClientService.getAddressList(oRequest)
        .then(
          async (response) => {
            if (response && response.length) {
              await this.saveDataToCache(key, response[0]);
              this.result_data = response[0];
            }
          }).catch(error => {
          });
    }
    return this.result_data;
  }
}

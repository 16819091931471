
import { BaseGetResponse } from '../interfaces/base';
export class StaffGetRequest {
}

export class StaffBase {
  id: number;
  name: string;
  company_code: string;
  login_id: string;
  mail_address: string;
  retired_flg: boolean;
  password?: string;
  fdn_code: string;
}

export class StaffGetResponse extends StaffBase {
  base: BaseGetResponse;
  base_name: string;
  depart_id?: number;
  hall_id?: number;
}

export class StaffPostRequest extends StaffBase {
  base: number;
}

export class StaffPostResponse {
}

export class StaffPutRequest extends StaffBase {
  base: number;
}
export class StaffPutResponse {
}


export class StaffDeleteResponse {
}

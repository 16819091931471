import { HoyoStyleGetResponse } from './master';
import { SekoGetResponse } from './seko';

export class HoyoMasterGetRequest {
  company: number;
}
export class HoyoMasterBase {
  company: number;
  name: string;
  elapsed_time: number;
  unit: number;
  display_num: number;
  after_follows: HoyoSampleGetResponse;
}
export class HoyoMasterGetResponse extends HoyoMasterBase {
  id: number;
  style: HoyoStyleGetResponse;
}
export class HoyoMasterPostRequest extends HoyoMasterBase  {
  style: number;
}

export class HoyoMasterPostResponse {
}

export class HoyoMasterPutRequest extends HoyoMasterBase  {
  style: number;
}
export class HoyoMasterPutResponse {
}

export class HoyoMasterDeleteResponse {
}

export class HoyoSampleGetRequest {
  company: number;
}
export class HoyoSampleBase {
  company: number;
  sentence: string;
  display_num: number;
}
export class HoyoSampleGetResponse extends HoyoSampleBase {
  id: number;
}
export class HoyoSamplePostRequest extends HoyoSampleBase  {
  id: number;
}

export class HoyoSamplePostResponse {
}

export class HoyoSamplePutRequest extends HoyoSampleBase  {
}
export class HoyoSamplePutResponse {
}

export class HoyoSampleDeleteResponse {
}
export class HoyoMailTemplateGetRequest {
  company: number;
}
export class HoyoMailTemplateBase {
  company: number;
  sentence: string;
  display_num: number;
}
export class HoyoMailTemplateGetResponse extends HoyoMailTemplateBase {
  id: number;
}
export class HoyoMailTemplatePostRequest extends HoyoMailTemplateBase  {
  id: number;
}

export class HoyoMailTemplatePostResponse {
}

export class HoyoMailTemplatePutRequest extends HoyoMailTemplateBase  {
}
export class HoyoMailTemplatePutResponse {
}

export class HoyoMailTemplateDeleteResponse {
}

export class HoyoMailGetRequest {
  company: number;
  hoyo: number;
  send_date_after: string;
  send_date_before: string;
}
export class HoyoMailBase {
  content: string;
  note: string;
}
export class HoyoMailGetResponse extends HoyoMailBase {
  id: number;
  select_ts: Date;
  send_ts: Date;
  targets: Array<HoyoMailTargetForGet>;
}
export class HoyoMailPostRequest extends HoyoMailBase  {
  hoyo: number;
  target_seko_list: Array<number>;
}

export class HoyoMailPostResponse extends HoyoMailGetResponse {
}

export class HoyoMailForGet extends HoyoMailBase {
  id: number;
  select_ts: Date;
  send_ts: Date;
  hoyo: number;
}
export class HoyoMailTargetForGet {
  seko: SekoGetResponse;
  id: number;
  hoyo_mail: HoyoMailForGet;
  send_ts: Date;
}

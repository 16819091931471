import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CalendarOptionsMonth } from 'src/app/components/com-calendar/com-calendar.component';
import { ConfirmDialogComponent } from 'src/app/pages/common/confirm-dialog/confirm-dialog.component';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { LoaderService } from 'src/app/service/loader.service';
import { SalesGetRequest } from 'src/app/interfaces/invoice';
import { CommonConstants } from 'src/app/const/const';
import { Calendar } from 'src/app/const/const';
import { Utils } from 'src/app/const/func-util';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-com-monthly-confirm',
  templateUrl: './monthly-confirm.component.html',
  styleUrls: ['./monthly-confirm.component.scss']
})
export class MonthlyConfirmComponent implements OnInit {
  login_info = this.sessionSvc.get('staff_login_info');
  date_option_config: CalendarOptionsMonth = new CalendarOptionsMonth();
  sales_month = null;
  processing = false;

  searched = false;
  sales_list: Array<any> = null;

  page_per_count = 20;
  pagination = {
    pages: new Array(),
    current: 0
  };

  constructor(
    private router: Router,
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    private dialog: MatDialog,
    @Inject(LOCALE_ID) private locale: string,
  ) {
    this.loaderSvc.call();
  }

  ngOnInit() {
    if (this.login_info.staff.base.base_type !== CommonConstants.BASE_TYPE_ADMIN) {
      this.loaderSvc.dismiss();
      this.router.navigate(['foc/top/']);
      return;
    }
    this.initControl();
  }

  initControl() {
    this.date_option_config.minDate = new Date('2021/01/01');
    this.date_option_config.maxDate = new Date();
    this.date_option_config.text = Calendar.JA_TEXT_MONTH;
    this.sales_month = new Date();
    this.searchSales(formatDate(this.sales_month, 'yyyyMM', this.locale));
  }

  searchSales(sales_yymm) {
    this.sessionSvc.clear('message');
    const request = new SalesGetRequest();
    request.sales_yymm = sales_yymm;
    if (!this.loaderSvc.isLoading) {
      this.loaderSvc.call();
    }
    this.pagination.pages = new Array();
    this.pagination.current = 0;
    this.httpClientService.getSalesList(request).then((response) => {
      Utils.log(response);
      this.searched = true;
      this.sales_list = response;
      this.calcPagination();
      this.loaderSvc.dismiss();

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }

  calcPagination() {
    if (!this.sales_list || !this.sales_list.length) {
      return;
    }
    const count = Math.ceil(this.sales_list.length / this.page_per_count);
    for (let i = 1; i <= count; i++) {
      this.pagination.pages.push(i);
    }
    this.pagination.current = 1;
  }

  pageTo(page_num) {
    this.pagination.current = page_num;
  }

  yymmChange(event) {
    if (event) {
      this.searchSales(event);
    }
  }
  monthChange(event) {
    if (event) {
      this.searchSales(formatDate(event, 'yyyyMM', this.locale));
    }

  }

  fixSales(sales_id) {
    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: '集計結果の確定を行います、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {

        this.processing = true;
        this.loaderSvc.call();
        this.httpClientService.fixSales(sales_id)
          .then(async (response) => {
            Utils.log(response);
            await this.searchSales(formatDate(this.sales_month, 'yyyyMM', this.locale));
            this.sessionSvc.save('message', { type: 'info', title: '集計結果を確定しました。' });
            this.processing = false;
          })
          .catch(error => {
            this.sessionSvc.save('message', { type: 'error', title: '集計結果の確定処理が失敗しました。' });
            this.processing = false;
            this.loaderSvc.dismiss();
          });
      }
    });
  }

  cancelFixSales(sales_id) {
    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: '確定を取り消します、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {

        this.processing = true;
        this.loaderSvc.call();
        this.httpClientService.cancelFixSales(sales_id)
          .then(async (response) => {
            Utils.log(response);
            await this.searchSales(formatDate(this.sales_month, 'yyyyMM', this.locale));
            this.sessionSvc.save('message', { type: 'info', title: '確定を取り消しました。' });
            this.processing = false;
          })
          .catch(error => {
            this.sessionSvc.save('message', { type: 'error', title: '確定を取消処理が失敗しました。' });
            this.processing = false;
            this.loaderSvc.dismiss();
          });
      }
    });
  }

  downloadPDF(sales_id) {
    this.sessionSvc.clear('message');
    if (sales_id) {
      this.loaderSvc.call();
      this.httpClientService.downloadInvoicePdf(sales_id)
        .then((response) => {
          this.localStreamDownload(response, sales_id);
          this.sessionSvc.save('message', { type: 'info', title: '請求書PDFを出力しました。' });
          this.loaderSvc.dismiss();
        }).catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: '請求書PDFの出力が失敗しました。' });
          this.loaderSvc.dismiss();
        });
    }
  }

  localStreamDownload(stream, sales_id) {
    const blob = new Blob([stream]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    const file_name = '請求書-' + sales_id + '.pdf';

    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveOrOpenBlob(blob, file_name);
    } else {
      link.href = url;
      link.download = file_name;
      link.click();
    }
  }

}

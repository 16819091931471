import { Component, OnInit, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarOptionsDate } from 'src/app/components/com-calendar/com-calendar.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/pages/common/confirm-dialog/confirm-dialog.component';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { LoaderService } from 'src/app/service/loader.service';
import { SekoGetRequest, SekoGetResponse } from 'src/app/interfaces/seko';
import { HoyoMailTemplateGetRequest, HoyoMailPostRequest } from 'src/app/interfaces/hoyo';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import { formatDate } from '@angular/common';
import { ComCalendarComponent } from 'src/app/components/com-calendar/com-calendar.component';
declare var $;

@Component({
  selector: 'app-com-hoyo-filter-bon',
  templateUrl: './hoyo-filter-bon.component.html',
  styleUrls: ['./hoyo-filter-bon.component.scss']
})
export class HoyoFilterBonComponent implements OnInit {
  @ViewChild('bonDateEm', { static: false }) bonDateEm: ComCalendarComponent;
  Const = CommonConstants;
  Utils = Utils;
  calendarOptionDate: CalendarOptionsDate = new CalendarOptionsDate();

  login_company = Utils.getLoginCompany();
  departCombo = { values: [], clearable: true, showOnFocus: false };
  form_data = {
    company_id: null,
    seko_department_id: null,
    bon_date: null,
    is_first: true,
  };
  searched = false;
  seko_list: Array<SekoGetResponse> = null;
  mail_template_list = null;
  hoyo_mail = new HoyoMailPostRequest();

  page_per_count = 20;
  pagination = {
    pages: new Array(),
    current: 0
  };

  all_check = false;

  processing = false;
  constructor(
    private router: Router,
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    private dialog: MatDialog,
    @Inject(LOCALE_ID) private locale: string,
  ) { }


  async ngOnInit() {
    const now = new Date();
    now.setDate(now.getDate() + 1);
    this.form_data.bon_date = new Date(new Date().getFullYear(), 7, 15);
    if (this.form_data.bon_date < now) {
      this.form_data.bon_date.setFullYear(this.form_data.bon_date.getFullYear() + 1);
    }
    if (this.login_company) {
      this.form_data.company_id = this.login_company.id;
      this.departCombo.values = Utils.GetDepartList(this.login_company);
    }
    setTimeout(() => {
      $('.checkbox').checkbox();
    }, 100);
  }

  checkItem(event, seko: SekoGetResponse) {
    if (!seko) {
      return;
    }
    if (event.target.classList.contains('icon')) {
      return;
    }
    seko.selected = !seko.selected;
    for (const item of this.seko_list) {
      if (!item.selected) {
        this.all_check = false;
        return;
      }
    }
    this.all_check = true;
  }
  checkAllItem() {
    for (const seko of this.seko_list) {
      seko.selected = this.all_check;
    }
  }
  async searchSeko() {
    this.sessionSvc.clear('message');
    if (!this.form_data.bon_date) {
      const message = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: '盆日付'
      };
      this.sessionSvc.save('message', message);
      return;
    }
    const request = new SekoGetRequest();
    request.seko_company = this.form_data.company_id;
    request.seko_department = this.form_data.seko_department_id;
    request.seko_style = 1;
    const death_date_before = new Date(
      this.form_data.bon_date.getFullYear(),
      this.form_data.bon_date.getMonth(),
      this.form_data.bon_date.getDate()
    );
    const death_date_after = new Date(
      this.form_data.bon_date.getFullYear(),
      this.form_data.bon_date.getMonth(),
      this.form_data.bon_date.getDate()
    );

    if (this.form_data.is_first) {
      death_date_before.setDate(death_date_before.getDate() - 50);
      death_date_after.setDate(death_date_after.getDate() - 49);
      death_date_after.setFullYear(death_date_after.getFullYear() - 1);
      request.death_date_before = formatDate(death_date_before, 'yyyy-MM-dd', this.locale);
      request.death_date_after = formatDate(death_date_after, 'yyyy-MM-dd', this.locale);
    } else {
      death_date_before.setDate(death_date_after.getDate() - 50);
      death_date_before.setFullYear(death_date_after.getFullYear() - 1);
      request.death_date_before = formatDate(death_date_before, 'yyyy-MM-dd', this.locale);

    }
    if (!this.loaderSvc.isLoading) {
      this.loaderSvc.call();
    }
    await this.httpClientService.getSekoList(request).then((response) => {
      Utils.log(response);
      this.searched = true;

      if (response && response.length) {
        this.seko_list = response.filter(
          v => (v.seko_contact && v.seko_contact.mobile_num) && v.moshu.mail_flg
          );
      } else {
        this.seko_list = new Array();
      }
      Utils.log(this.seko_list);
      this.calcPagination();
      this.loaderSvc.dismiss();

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }

  calcPagination() {
    this.pagination.pages = new Array();
    this.pagination.current = 0;
    this.all_check = false;
    if (!this.seko_list || !this.seko_list.length) {
      return;
    }
    const count = Math.ceil(this.seko_list.length / this.page_per_count);
    for (let i = 1; i <= count; i++) {
      this.pagination.pages.push(i);
    }
    this.pagination.current = 1;
  }

  pageTo(page_num) {
    this.pagination.current = page_num;
  }

  clearForm(...comboEms: any[]) {

    this.form_data = {
      company_id: this.login_company.id,
      seko_department_id: null,
      is_first: true,
      bon_date: null,
    };
    for (const comboEm of comboEms) {
      comboEm.clear();
      if ('initComponent' in comboEm) {
        comboEm.initComponent();
      }
    }
    const now = new Date();
    now.setDate(now.getDate() + 1);
    this.form_data.bon_date = new Date(new Date().getFullYear(), 7, 15);
    if (this.form_data.bon_date < now) {
      this.form_data.bon_date.setFullYear(this.form_data.bon_date.getFullYear() + 1);
    }
    this.bonDateEm.setDate(this.form_data.bon_date);

  }

  getPlace(seko) {
    if (!seko || !seko.schedules) {
      return null;
    }
    let ret = null;
    seko.schedules.forEach((schedule) => {
      if (schedule.schedule) {
        ret = schedule.hall_name;
        return;
      }
    });
    return ret;
  }

  async showMailSample() {
    await this.getMailTemplate();
    $('#mail-sample').modal({
      allowMultiple: true,
      closable: false,
      centered: false,
      detachable: false,
      transition: 'fade',
    }).modal('show');
  }

  async getMailTemplate() {
    const request = new HoyoMailTemplateGetRequest();
    request.company = this.form_data.company_id;
    await this.httpClientService.getHoyoMailTemplateList(request).then((response) => {
      Utils.log(response);
      this.mail_template_list = response;

    }).catch(error => {
    });
  }

  select_mail_text(text) {
    this.hoyo_mail.content = text;
    $('#mail-sample').modal('hide');
  }

  saveData() {
    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }
    this.hoyo_mail.content = this.hoyo_mail.content ? this.hoyo_mail.content.trim() : null;
    if (!this.hoyo_mail.content) {
      const message = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: 'メール本文'
      };
      this.sessionSvc.save('message', message);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: 'SMS送信を行います、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.hoyo_mail.target_seko_list = new Array();
        for (const seko of this.seko_list) {
          if (seko.selected) {
            this.hoyo_mail.target_seko_list.push(seko.id);
          }
        }
        if (!this.hoyo_mail.target_seko_list.length) {
          return;
        }

        this.processing = true;
        this.loaderSvc.call();
        this.httpClientService.addHoyoMail(this.hoyo_mail)
          .then(async (response) => {
            Utils.log(response);
            await this.searchSeko();
            if (this.hoyo_mail.target_seko_list.length === response.targets.length) {
              this.sessionSvc.save('message', { type: 'info', title: 'SMSを送信しました。' });
            } else {
              const msg = new Array();
              for (const seko_id of this.hoyo_mail.target_seko_list) {
                if (!response.targets.find(v => v.seko.id === seko_id)) {
                  msg.push(seko_id);
                }
              }

              this.sessionSvc.save('message', {
                type: 'warning', title: '以下の施行番号のSMS送信が失敗しました。携帯電話番号が正しいかご確認ください。',
                msg: msg.join(',')
              });
            }
            this.processing = false;
          })
          .catch(error => {
            this.sessionSvc.save('message', { type: 'error', title: 'SMS送信が失敗しました。' });
            this.processing = false;
            this.loaderSvc.dismiss();
          });
      }
    });
  }

  exportCsv() {
    const title = '施行番号,葬家名,故人名,喪主名,連絡先名,逝去日,施行日,式場\n';
    let csv_data = null;
    let export_data = title;
    if (this.seko_list) {
      const values = this.seko_list.map(v => {
        const ret_strings = new Array();
        ret_strings.push(v.id);
        ret_strings.push(v.soke_name ? v.soke_name : '');
        ret_strings.push(v.kojin[0].name ? v.kojin[0].name : '');
        ret_strings.push(v.moshu.name ? v.moshu.name : '');
        ret_strings.push(v.seko_contact && v.seko_contact.name ? v.seko_contact.name : '');
        ret_strings.push(v.death_date ? formatDate(v.death_date, 'yyyy/MM/dd', this.locale) : '');
        ret_strings.push(v.seko_date ? formatDate(v.seko_date, 'yyyy/MM/dd', this.locale) : '');
        ret_strings.push(this.getPlace(v) ? this.getPlace(v) : '');
        return ret_strings.join(',');
      });
      csv_data = values.join('\n');
      export_data += csv_data;
    }
    const now = formatDate(new Date(), 'yyyyMMddHHmmss', this.locale);
    const file_name = '法要案内抽出一覧（盆）_' + now + '.csv';
    Utils.exportCsv(file_name, export_data);
  }

  buttonDisabled() {
    if (!this.seko_list || !this.seko_list.length) {
      return true;
    }
    let count = 0;
    for (const seko of this.seko_list) {
      if (seko.selected) {
        count++;
      }
    }
    if (!count) {
      return true;
    }
    return false;

  }

}

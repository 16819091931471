import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/pages/common/confirm-dialog/confirm-dialog.component';
import { SessionService } from 'src/app/service/session.service';
import { ZipcodeService } from 'src/app/service/zipcode.service';
import { AddressGetRequest } from 'src/app/interfaces/master';
import { HttpClientService } from 'src/app/service/http-client.service';
import { LoaderService } from 'src/app/service/loader.service';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import { SupplierGetRequest, SupplierGetResponse, SupplierPostRequest } from 'src/app/interfaces/supplier';
declare var $;

@Component({
  selector: 'app-com-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.scss']
})
export class SupplierComponent implements OnInit {

  Const = CommonConstants;

  login_company = Utils.getLoginCompany();
  companyCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  departCombo = { values: [], clearable: false, showOnFocus: false };
  company_list = Utils.getCompanyList();
  company_id = null;

  item_list = null;

  item_edit: SupplierGetResponse = new SupplierGetResponse();
  edit_type = 1;

  message = null;
  errField = new Map();

  page_per_count = 15;
  pagination = {
    pages: new Array(),
    current: 0
  };

  processing = false;

  constructor(
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    private zipcodeSvc: ZipcodeService,
    private dialog: MatDialog,
  ) {
    this.loaderSvc.call();
  }

  ngOnInit(): void {
    this.initControl();
    this.getData(this.company_id);
  }

  initControl() {
    if (this.company_list) {
      this.companyCombo.values = this.company_list.map(value => ({ name: value.base_name, value: value.id, data: value }));
      if (this.login_company) {
        if (this.login_company.base_type === CommonConstants.BASE_TYPE_COMPANY) {
          this.company_id = this.login_company.id;
        } else {
          this.company_id = this.company_list[0].id;
        }
      }
    }
    $('.ui.radio.checkbox').checkbox();
  }

  getData(company_id) {
    const request = new SupplierGetRequest();
    request.company_id = company_id;
    if (!this.loaderSvc.isLoading) {
      this.loaderSvc.call();
    }
    this.httpClientService.getSupplierList(request).then((response) => {
      Utils.log(response);
      this.item_list = response;
      this.calcPagination();
      this.loaderSvc.dismiss();

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }
  calcPagination() {
    this.pagination.pages = new Array();
    this.pagination.current = 0;
    if (!this.item_list || !this.item_list.length) {
      return;
    }
    const count = Math.ceil(this.item_list.length / this.page_per_count);
    for (let i = 1; i <= count; i++) {
      this.pagination.pages.push(i);
    }
    this.pagination.current = 1;
  }

  companyChange(event) {
    if (!event || !event.data) {
      return;
    }
    this.getData(event.data.id);
  }

  showData(event, item = null) {
    this.message = null;
    if (!item) {
      this.edit_type = 1;
      this.item_edit = new SupplierGetResponse();
      this.item_edit.company = this.company_id;
      this.item_edit.order_mail_send_type = 1;
    } else {
      this.edit_type = 2;
      if (event.target.classList.contains('operation') || event.target.classList.contains('icon')) {
        return;
      }
      this.item_edit = JSON.parse(JSON.stringify(item));
      if (this.item_edit.order_mail_send_flg) {
        this.item_edit.order_mail_send_type = 2;
      } else {
        this.item_edit.order_mail_send_type = 1;
      }
    }
    $('#item-edit').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }
  saveData() {
    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }
    this.processing = true;
    if (!this.dataValidate()) {
      this.processing = false;
      return;
    }
    const postData = this.getPostData();
    this.loaderSvc.call();
    if (postData.id) {
      this.httpClientService.updateSupplier(postData.id, postData)
        .then(async (response) => {
          Utils.log(response);
          this.sessionSvc.save('message', { type: 'info', title: '発注先情報を保存しました。' });
          this.getData(this.company_id);
          this.loaderSvc.dismiss();
          this.processing = false;
        })
        .catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: '発注先情報の保存が失敗しました。' });
          this.loaderSvc.dismiss();
          this.processing = false;
        });
    } else {
      this.httpClientService.addSupplier(postData)
        .then(async (response) => {
          Utils.log(response);
          this.sessionSvc.save('message', { type: 'info', title: '発注先情報を保存しました。' });
          this.getData(this.company_id);
          this.loaderSvc.dismiss();
          this.processing = false;
        })
        .catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: '発注先情報の保存が失敗しました。' });
          this.loaderSvc.dismiss();
          this.processing = false;
        });
    }
    $('#item-edit').modal('hide');

  }
  dataValidate() {
    const msgs = new Array();
    this.message = null;
    if (!this.item_edit.name) {
      msgs.push('発注先名称');
    }
    if (!this.item_edit.tel) {
      msgs.push('電話番号');
    }
    if (!this.item_edit.fax) {
      msgs.push('FAX番号');
    }
    if (!this.item_edit.zip_code) {
      msgs.push('郵便番号');
    }
    if (!this.item_edit.address) {
      msgs.push('住所');
    }
    if (this.item_edit.order_mail_send_type === 2 && !this.item_edit.mail_address) {
      msgs.push('メールアドレス');
    }

    if (msgs.length > 0) {
      this.message = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: msgs.join(',')
      };
      return false;
    }
    if (this.item_edit.mail_address) {
      const reg = new RegExp(CommonConstants.MAIL_PATTERN);
      if (!this.item_edit.mail_address.match(reg)) {
        this.message = { type: 'warning', title: 'メール形式が正しくありません。' };
        return false;
      }
    }

    return true;
  }

  getPostData(): SupplierPostRequest {
    const postRequest = new SupplierPostRequest();
    postRequest.id = this.item_edit.id;
    postRequest.company = this.item_edit.company;
    postRequest.name = this.item_edit.name;
    postRequest.tel = this.item_edit.tel;
    postRequest.fax = this.item_edit.fax;
    postRequest.zip_code = this.item_edit.zip_code;
    postRequest.address = this.item_edit.address;
    if (this.item_edit.order_mail_send_type === 1) {
      postRequest.order_mail_send_flg = false;
    } else {
      postRequest.order_mail_send_flg = true;
    }
    if (this.item_edit.mail_address) {
      postRequest.mail_address = this.item_edit.mail_address;
    } else {
      postRequest.mail_address = null;
    }
    return postRequest;
  }

  zipcodeChange() {
    const request = new AddressGetRequest();
    request.zip_code = this.item_edit.zip_code;
    this.zipcodeSvc.getData(request).then(address => {
      if (address) {
        this.item_edit.address = address.prefecture + address.address_1 + address.address_2;
      }
    }).catch(error => { });
  }

  pageTo(pagination, page_num) {
    pagination.current = page_num;
  }

  closeMessage() {
    this.message = null;
  }

  deleteData(id) {
    this.sessionSvc.clear('message');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: '発注先情報を削除します、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.loaderSvc.call();
        this.httpClientService.deleteSupplier(id)
          .then((response) => {
            Utils.log(response);
            this.sessionSvc.save('message', { type: 'info', title: '発注先情報を削除しました。' });
            this.getData(this.company_id);
          })
          .catch(error => {
            this.sessionSvc.save('message', { type: 'error', title: '発注先情報の削除が失敗しました。' });
            this.loaderSvc.dismiss();
          });
      }
    });
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RuntimeConfigLoaderModule } from 'runtime-config-loader';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthFGuard } from './guard/authF.guard';
import { AdminGuard } from './guard/admin.guard';
import { OrderGuard } from './guard/order.guard';
import { AuthSGuard } from './guard/authS.guard';
import { StaffOnlyGuard } from './guard/staff.guard';

import { RouterService } from './service/router.service';
import { HttpClientService } from './service/http-client.service';
import { LoaderService } from './service/loader.service';
import { SessionService } from './service/session.service';
import { CacheService } from './service/cache.service';
import { BaseService } from './service/base.service';
import { ZipcodeService } from './service/zipcode.service';
import { StaffService } from './service/staff.service';
import { DialogComponent } from './pages/common/dialog/dialog.component';
import { ConfirmDialogComponent } from 'src/app/pages/common/confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from './pages/common/error-dialog/error-dialog.component';
import { LoadingModalComponent } from './pages/common/loading-modal/loading-modal.component';

import { ComCalendarComponent } from './components/com-calendar/com-calendar.component';
import { ComDropdownComponent } from './components/com-dropdown/com-dropdown.component';
import { ComTimeInputComponent } from './components/com-time-input/com-time-input.component';
import { NotFoundComponent } from './pages/common/not-found/not-found.component';
import { SokepolicyComponent } from './pages/common/sokepolicy/sokepolicy.component';

import { CompanyFrameComponent } from './pages/company/frame.component';
import { LoginComponent } from './pages/company/login/login.component';
import { TopComponent } from './pages/company/top/top.component';
import { SekoComponent } from './pages/company/seko/seko.component';
import { SekoEditComponent } from './pages/company/seko/seko-edit/seko-edit.component';
import { ChobunComponent } from './pages/company/chobun/chobun.component';
import { KumotsuComponent } from './pages/company/kumotsu/kumotsu.component';
import { KodenComponent } from './pages/company/koden/koden.component';
import { MessageComponent } from './pages/company/message/message.component';
import { HenreihinComponent } from './pages/company/henreihin/henreihin.component';
import { OrderHenreihinComponent } from './pages/company/order-henreihin/order-henreihin.component';
import { BaseComponent } from './pages/company/base/base.component';
import { SupplierComponent } from './pages/company/supplier/supplier.component';
import { FuhoSampleComponent } from './pages/company/fuho-sample/fuho-sample.component';
import { ChobunDaishiComponent } from './pages/company/chobun-daishi/chobun-daishi.component';
import { ItemServiceComponent } from './pages/company/item-service/item-service.component';
import { StaffComponent } from './pages/company/staff/staff.component';
import { MonthlySummaryComponent } from './pages/company/monthly-summary/monthly-summary.component';
import { MonthlyConfirmComponent } from './pages/company/monthly-confirm/monthly-confirm.component';
import { SalesSummaryComponent } from './pages/company/sales-summary/sales-summary.component';
import { InvoiceComponent } from './pages/company/invoice/invoice.component';
import { AfGroupComponent } from './pages/company/af-group/af-group.component';
import { AfContactComponent } from './pages/company/af-contact/af-contact.component';
import { HoyoMasterComponent } from './pages/company/hoyo-master/hoyo-master.component';
import { HoyoSampleComponent } from './pages/company/hoyo-sample/hoyo-sample.component';
import { HoyoMailTemplateComponent } from './pages/company/hoyo-mail-template/hoyo-mail-template.component';
import { SekoAfComponent } from './pages/company/seko-af/seko-af.component';
import { HoyoFilterKiComponent } from './pages/company/hoyo-filter-ki/hoyo-filter-ki.component';
import { HoyoFilterBonComponent } from './pages/company/hoyo-filter-bon/hoyo-filter-bon.component';
import { HoyoGuidanceComponent } from './pages/company/hoyo-guidance/hoyo-guidance.component';
import { EventGuidanceComponent } from './pages/company/event-guidance/event-guidance.component';
import { EventFilterComponent } from './pages/company/event-filter/event-filter.component';
import { FaqComponent } from './pages/company/faq/faq.component';

import { CustomerFrameComponent } from './pages/customer/frame.component';
import { HuhoComponent } from './pages/customer/huho/huho.component';
import { SitepolicyComponent } from './pages/customer/sitepolicy/sitepolicy.component';
import { HuhoApproveComponent } from './pages/customer/huho-approve/huho-approve.component';
import { Chobun1Component } from './pages/customer/chobun1/chobun1.component';
import { Chobun2Component } from './pages/customer/chobun2/chobun2.component';
import { KumotsuCusComponent } from './pages/customer/kumotsu/kumotsu.component';
import { Koden1Component } from './pages/customer/koden1/koden1.component';
import { Koden2Component } from './pages/customer/koden2/koden2.component';
import { MessageCusComponent } from './pages/customer/message/message.component';
import { CartComponent } from './pages/customer/cart/cart.component';
import { OrderEntryComponent } from './pages/customer/order-entry/order-entry.component';
import { OrderConfirmComponent } from './pages/customer/order-confirm/order-confirm.component';
import { OrderPaymentComponent } from './pages/customer/order-payment/order-payment.component';
import { OrderCompleteComponent } from './pages/customer/order-complete/order-complete.component';
import { OrderCancelComponent } from './pages/company/order-cancel/order-cancel.component';
import { InquiryCusComponent } from './pages/customer/inquiry/inquiry.component';
import { PrivacyPolicyComponent } from './pages/customer/privacy-policy/privacy-policy.component';
import { TokushohoComponent } from './pages/customer/tokushoho/tokushoho.component';
import { ReceiptComponent } from './pages/customer/receipt/receipt.component';
import { HoyoCusComponent } from './pages/customer/hoyo/hoyo.component';
import { UserpolicyComponent } from './pages/customer/userpolicy/userpolicy.component';
import { HowtoShareComponent } from './pages/customer/howto-share/howto-share.component';
import { SokeManualSanoComponent } from './pages/family/soke-manual/02002020/soke-manual.component';

import { FamilyFrameComponent } from './pages/family/frame.component';
import { SokeApproveComponent } from './pages/family/soke-approve/soke-approve.component';
import { SokeLoginComponent } from './pages/family/login/login.component';
import { SokeSekoComponent } from './pages/family/seko/seko.component';
import { HomeiComponent } from './pages/family/homei/homei.component';
import { HenreiComponent } from './pages/family/henrei/henrei.component';
import { InfoComponent } from './pages/family/info/info.component';
import { ChangePasswordComponent } from './pages/family/change-password/change-password.component';
import { MemorialComponent } from './pages/family/memorial/memorial.component';
import { ImageShareComponent } from './pages/family/image-share/image-share.component';
import { HoyoComponent } from './pages/family/hoyo/hoyo.component';
import { SokeSekoAfComponent } from './pages/family/seko-af/seko-af.component';
import { InquiryComponent } from './pages/family/inquiry/inquiry.component';
import { SokeUserpolicyComponent } from './pages/family/soke-userpolicy/soke-userpolicy.component';
import { FaqFamComponent } from './pages/family/faq/faq.component';
import { AdvertiseComponent } from './pages/company/advertise/advertise.component';
import { KodenSummaryComponent } from './pages/company/koden-summary/koden-summary.component';
import { SokeMenuComponent } from './pages/company/soke-menu/soke-menu.component';

@NgModule({
  declarations: [
    AppComponent,
    DialogComponent,
    LoadingModalComponent,
    ConfirmDialogComponent,
    ErrorDialogComponent,
    NotFoundComponent,
    CompanyFrameComponent,
    LoginComponent,
    TopComponent,
    SekoComponent,
    SekoEditComponent,
    ChobunComponent,
    KumotsuComponent,
    BaseComponent,
    SupplierComponent,
    FuhoSampleComponent,
    StaffComponent,
    ChobunDaishiComponent,
    ItemServiceComponent,
    ComCalendarComponent,
    ComTimeInputComponent,
    ComDropdownComponent,
    CustomerFrameComponent,
    FamilyFrameComponent,
    HuhoComponent,
    SitepolicyComponent,
    HuhoApproveComponent,
    Chobun1Component,
    Chobun2Component,
    KumotsuCusComponent,
    CartComponent,
    OrderEntryComponent,
    OrderConfirmComponent,
    OrderPaymentComponent,
    OrderCompleteComponent,
    Koden1Component,
    Koden2Component,
    MessageCusComponent,
    ReceiptComponent,
    KodenComponent,
    MessageComponent,
    HenreihinComponent,
    OrderHenreihinComponent,
    OrderCancelComponent,
    InquiryCusComponent,
    PrivacyPolicyComponent,
    TokushohoComponent,
    SokeApproveComponent,
    SokeLoginComponent,
    SokeSekoComponent,
    HomeiComponent,
    HenreiComponent,
    InfoComponent,
    ChangePasswordComponent,
    MemorialComponent,
    HoyoComponent,
    MonthlySummaryComponent,
    MonthlyConfirmComponent,
    SalesSummaryComponent,
    InvoiceComponent,
    AfGroupComponent,
    AfContactComponent,
    HoyoMasterComponent,
    HoyoSampleComponent,
    HoyoMailTemplateComponent,
    HoyoCusComponent,
    SokeSekoAfComponent,
    InquiryComponent,
    SekoAfComponent,
    UserpolicyComponent,
    SokepolicyComponent,
    SokeUserpolicyComponent,
    HoyoFilterKiComponent,
    HoyoFilterBonComponent,
    HoyoGuidanceComponent,
    EventGuidanceComponent,
    EventFilterComponent,
    FaqComponent,
    FaqFamComponent,
    AdvertiseComponent,
    KodenSummaryComponent,
    SokeMenuComponent,
    HowtoShareComponent,
    ImageShareComponent,
    SokeManualSanoComponent,
  ],
  imports: [
    RuntimeConfigLoaderModule,
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    AppRoutingModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    BrowserAnimationsModule,
    FormsModule,
    DragDropModule,
    ReactiveFormsModule,
    HttpClientModule,
    PdfJsViewerModule,
  ],
  entryComponents: [
    LoadingModalComponent,
    ConfirmDialogComponent,
    ErrorDialogComponent,
  ],
  providers: [
    AuthFGuard,
    AdminGuard,
    OrderGuard,
    AuthSGuard,
    StaffOnlyGuard,
    HttpClientService,
    LoaderService,
    SessionService,
    CacheService,
    BaseService,
    ZipcodeService,
    StaffService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private routerService: RouterService) { }
}

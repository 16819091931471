import { Component, OnInit } from '@angular/core';
import { HttpClientService } from 'src/app/service/http-client.service';
import { SessionService } from 'src/app/service/session.service';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
declare var $;


@Component({
  selector: 'app-fam-hoyo',
  templateUrl: './hoyo.component.html',
  styleUrls: ['./hoyo.component.scss']
})
export class HoyoComponent implements OnInit {
  Const = CommonConstants;
  Utils = Utils;

  seko_info = this.sessionSvc.get('soke_seko_info');
  hoyo_list = null;
  host_url = window.location.origin;


  constructor(
    private sessionSvc: SessionService,
    private httpClientService: HttpClientService,
    ) { }

  ngOnInit() {
    if (this.seko_info && this.seko_info.hoyo_seko && this.seko_info.hoyo_seko.length) {
      Utils.formatSekoDate(this.seko_info);
      this.hoyo_list = this.seko_info.hoyo_seko;
      setTimeout(() => {
        $('.accordion').accordion();
      }, 100);
    }
  }

  downloadHoyoPDF(hoyo) {
    if (hoyo) {
      this.httpClientService.downloadHoyoPdf(hoyo.id)
        .then((response) => {
          const file_name = '法要案内状-' + hoyo.id + '.pdf';
          this.localStreamDownload(response, file_name);
        }).catch(error => {
        });
    }
  }

  localStreamDownload(stream, file_name) {
    const blob = new Blob([stream]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveOrOpenBlob(blob, file_name);
    } else {
      link.href = url;
      link.download = file_name;
      link.click();
    }
  }
}

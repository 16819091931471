import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { ShareImagePostRequest, ShareImagePutRequest } from 'src/app/interfaces/seko';
import { Utils } from 'src/app/const/func-util';
declare var $;

@Component({
  selector: 'app-fam-image-share',
  templateUrl: './image-share.component.html',
  styleUrls: ['./image-share.component.scss']
})
export class ImageShareComponent implements OnInit {
  @ViewChild('imageFileUpload', { static: false }) imageFileUpload: ElementRef;

  seko_info = this.sessionSvc.get('soke_seko_info');
  pop_image_file = null;
  message = null;
  imageItems: Array<any> = null;
  companyImageItems: Array<any> = null;
  shareDeletedItems: Array<any> = null;
  imageCountMax = 10;
  imageFileSizeMax = 10; // MB
  maxDispNum = 0;
  constructor(
    private sessionSvc: SessionService,
    private httpClientService: HttpClientService,
  ) { }

  ngOnInit(): void {
    this.imageItems = new Array();
    this.companyImageItems = new Array();
    for (const share of this.seko_info.share_images) {
      if (share.mode === 1) {
        this.imageItems.push(share);
        this.maxDispNum = share.display_num;
      } else {
        this.companyImageItems.push(share);
      }
    }
  }

  showPopImage(item) {
    if (!item) {
      return;
    }
    this.pop_image_file = item.image_file ? item.image_file : item.file_name;
    $('#pop-image').modal({
      centered: false,
      detachable: false,
      transition: 'fade',
    }).modal('show');
  }
  closePopImage() {
    $('#pop-image').modal('hide');
    setTimeout(() => {
      this.pop_image_file = null;
    }, 500);
  }

  selectFile() {
    const fileUpload = this.imageFileUpload.nativeElement;
    fileUpload.accept = 'image/*';
    fileUpload.multiple = true;

    fileUpload.onchange = () => {
      this.addImage(fileUpload.files);
      fileUpload.value = '';
    };
    fileUpload.click();
  }

  addImage(files) {
    this.sessionSvc.clear('message');
    if (!files || !files.length) {
      return;
    }
    if (files.length + this.imageItems.length > this.imageCountMax) {
      this.message =  '登録可能ファイル数は' + this.imageCountMax + 'ファイルまでです。';
      this.showMessage();
      return;
    }

    for (const file of files) {
      if (!file.type.includes('image')) {
        this.message =  '画像ファイル以外は登録できません。';
        this.showMessage();
        return;
      }
      if (file.size > this.imageFileSizeMax * 1024 * 1024) {
        this.message =  '画一つの画像ファイルサイズは' + this.imageFileSizeMax + 'MBまで登録可能です。';
        this.showMessage();
        return;
      }
    }
    for (const file of files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageItem = {
          id: null,
          image_file: e['target']['result']
        };
        this.imageItems.push(imageItem);
        // this.uploadImage(imageItem);
      };

      reader.readAsDataURL(file);
    }
  }
  deleteImage(index) {
    if (!this.imageItems.length) {
      return;
    }
    const delItems = this.imageItems.splice(index, 1);
    if (delItems.length) {
      if (delItems[0].id) {
        if (!this.shareDeletedItems) {
          this.shareDeletedItems = new Array();
        }
        this.shareDeletedItems.push(delItems[0].id);
      }
    }

  }
  async uploadImage(imageItem) {

    const shareImagePost = new ShareImagePostRequest();
    shareImagePost.file_name = imageItem.image_file;
    shareImagePost.display_num = this.maxDispNum + 1;
    shareImagePost.mode = 1;
    await this.httpClientService.addShareImage(this.seko_info.id, shareImagePost)
      .then((response) => {
        Utils.log(response);
        imageItem.id = response.id;
        imageItem.file_name = response.file_name;
        imageItem.image_file = null;
      })
      .catch(error => {
      });
  }

  async saveShareImage() {
    if (!this.seko_info) {
      return;
    }
    let email_flg = 0;
    let i = 0;
    let save_flg = 0;
    for (const imageItem of this.imageItems) {
      i++;
      if (!imageItem.id) {
        const shareImagePost = new ShareImagePostRequest();
        shareImagePost.file_name = imageItem.image_file;
        shareImagePost.display_num = i;
        shareImagePost.mode = 1;
        await this.httpClientService.addShareImage(this.seko_info.id, shareImagePost)
          .then((response) => {
            Utils.log(response);
            imageItem.id = response.id;
            imageItem.file_name = response.file_name;
            imageItem.display_num = response.display_num;
            imageItem.image_file = null;
            imageItem.mode = response.mode;
            email_flg = 1;
            save_flg = 1;
          })
          .catch(error => {
          });
      } else {
        if (imageItem.display_num === i) {
          continue;
        }
        const shareImagePut = new ShareImagePutRequest();
        shareImagePut.display_num = i;
        await this.httpClientService.updateShareImage(this.seko_info.id, imageItem.id, shareImagePut)
          .then((response) => {
            Utils.log(response);
            imageItem.file_name = response.file_name;
            imageItem.display_num = response.display_num;
            imageItem.image_file = null;
            save_flg = 1;
          })
          .catch(error => {
          });

      }
    }
    if (this.shareDeletedItems) {
      for (const share_id of this.shareDeletedItems) {
        await this.httpClientService.deleteShareImage(this.seko_info.id, share_id)
          .then((response) => {
            Utils.log(response);
            save_flg = 1;
          })
          .catch(error => {
          });
      }
      this.shareDeletedItems = null;
    }
    if (email_flg === 1) {
      this.httpClientService.noticeImaageFileToCompany(this.seko_info.id)
      .then((response) => {
      }).catch(error => {
      });

    }
    if (save_flg === 1) {
      this.message = '保存しました。';
      this.showMessage();
    }

  }
  showMessage() {

    $('#message-popup').modal({
      closable: false,
      centered: false,
      detachable: false,
      transition: 'fade',
    }).modal('show');
  }
  closePopup() {
    $('#message-popup').modal('hide');
    setTimeout(() => {
    this.message = null;
    }, 500);
  }
  async downloadImage(url) {
    if (url) {
      this.httpClientService.downloadImage(url)
        .then((response) => {
          this.localStreamDownload(response, url);
        }).catch(error => {
        });
    }
  }

  localStreamDownload(stream, file_path) {
    const blob = new Blob([stream]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    const file_name = file_path.split('/').pop();

    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveOrOpenBlob(blob, file_name);
    } else {
      link.href = url;
      link.download = file_name;
      link.click();
    }
  }
}


import { SupplierBase } from '../interfaces/supplier';

export class HenreihinGetRequest {
  company_id: number;
  seko_department: number;
  seko_id: number;
  hall_id: number;
  seko_date: string;
  supplier_id: number;
  soke_name: string;
  kojin_name: string;
  moshu_name: string;
  is_cancel: boolean;
  order_status: Array<number>;
}
export class HenreihinGetResponse  {
  seko_id: number;
  entry_id: number;
  detail_id: number;
  hall_name: string;
  henreihin_id: number;
  henreihin_name: string;
  henreihin_price: number;
  henreihin_type: number;
  kojin_name: string;
  moshu_name: string;
  order_status: number;
  seko_date: Date;
  soke_name: string;
  supplier_id: number;
  supplier_name: string;
  cancel_ts: Date;
  selected?: boolean;
}

class HenreihinBase {
  detail_kumotsu: number;
  detail_koden: number;
  henreihin: number;
  henreihin_hinban: string;
  henreihin_name: string;
  henreihin_price: number;
  henreihin_tax: number;
  henreihin_tax_pct: number;
  keigen_flg: boolean;
  supplier: number;
}
export class HenreihinPostRequest extends HenreihinBase {
}

export class HenreihinPostResponse {
}
export class HenreihinPutRequest extends HenreihinBase {
}

export class HenreihinPutResponse {
}

export class HenreihinOrderPostRequest {
  seko: number;
  supplier: number;
  entry_details: Array<number>;
}

export class HenreihinOrderPostResponse {
}

export class OrderHenreiGetRequest {
  company_id: number;
  seko_department: number;
  supplier_id: number;
  order_henrei_id: number;
  order_status: Array<number>;
  order_ts_from: string;
  order_ts_to: string;
}
class OrderHenreiForGetBase {
  seko: number;
  hall_name: string;
  soke_name: string;
  moshu_name: string;
  order_status: number;
  order_note: string;
  supplier_name: string;
}
class OrderHenreiDetail {
  supplier: SupplierBase;
  henreihin_name: string;
  henreihin_price: number;
  order_status: number;
}
export class OrderHenreiGetResponse extends OrderHenreiForGetBase  {
  id: number;
  seko_date: Date;
  order_ts: Date;
  selected?: boolean;
  henrei_koden: Array<OrderHenreiDetail>;
  henrei_kumotsu: Array<OrderHenreiDetail>;
}

export class OrderHenreiOrderStatusPostRequest {
  ids: Array<number>;
  order_status: number;
}

export class OrderHenreiOrderStatusPostResponse {
}

export class OrderHenreiPutRequest {
  order_note: string;
}

export class OrderHenreiPutResponse {
}

export class HenreiSelectStatusPostRequest {
  seko: number;
}

export class HenreiSelectStatusPostResponse {
}

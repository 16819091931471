import { Component, Inject, OnInit, DoCheck, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { RouterService } from 'src/app/service/router.service';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';

declare var $;
@Component({
  selector: 'app-customer-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss']
})
export class CustomerFrameComponent implements OnInit, DoCheck {

  appView = null;
  page_uri = null;
  is_fuho_page = false;
  is_hoyo_page = false;
  entry = this.sessionSvc.get('service_entry');
  seko_info = this.sessionSvc.get('custom_seko_info');
  hoyo_info = this.sessionSvc.get('custom_hoyo_info');
  constructor(
    private sessionSvc: SessionService,
    private httpClientService: HttpClientService,
    private router: Router,
    private routerSvc: RouterService,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: HTMLDocument
  ) { }

  ngOnInit() {
    this.sessionSvc.clear('is_fuho_page');
    this.sessionSvc.clear('is_hoyo_page');
    this.appView = this.routerSvc.getAppView();
    if (!this.appView) {
      return;
    }
    $('#focFavicon').attr('href', 'favicon_cus.ico');
    this.page_uri = encodeURI(window.location.href);
    this.pageTopToggle();
  }

  ngDoCheck() {
    this.is_fuho_page = this.sessionSvc.get('is_fuho_page');
    this.is_hoyo_page = this.sessionSvc.get('is_hoyo_page');
    this.entry = this.sessionSvc.get('service_entry');
    this.seko_info = this.sessionSvc.get('custom_seko_info');
  }
  pageTopToggle() {
    $(function () {
      const pagetop = $('.pagetop');

      $(window).scroll(function () {
        if ($(this).scrollTop() > 500) {
          pagetop.fadeIn();
        } else {
          pagetop.fadeOut();
        }
        const scrollHeight = $(document).innerHeight();
        const scrollPosition = $(window).outerHeight() + $(window).scrollTop();
        const footHeight = $('footer').innerHeight();
        if (scrollHeight - scrollPosition <= footHeight) {
          pagetop.css({
            'position': 'fixed',
            'bottom': footHeight - (scrollHeight - scrollPosition)
          });
        } else {
          pagetop.css({
            'position': 'fixed',
            'bottom': '0px'
          });
        }
      });
      pagetop.click(function () {
        $('body, html').animate({
          scrollTop: 0
        }, 500);
        return false;
      });
    });

  }
  gotoTop() {
    if (this.is_hoyo_page || window.location.pathname.includes('/hoyo')) {
      // this.router.navigate(['hoyo/']);
    } else if (this.is_fuho_page || !this.seko_info || window.location.pathname.includes('/fuho')) {
      // this.router.navigate(['fuho/']);
    }
    if (this.is_hoyo_page || this.is_fuho_page) {
    } else if (this.seko_info) {
      this.router.navigate(['fuho/' + this.seko_info.seko_company.id + '-' + this.seko_info.id + '/']);
    } else if (this.hoyo_info) {
    this.router.navigate(['hoyo/' + this.hoyo_info.hall.id + '-' + this.hoyo_info.id + '/']);
  }
  }
  companyFeneral() {
    if (this.is_hoyo_page || !this.seko_info || window.location.pathname.includes('/hoyo')) {
      return false;
    } else {
      return true;
    }

  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { CommonConstants } from 'src/app/const/const';
import { Utils } from 'src/app/const/func-util';

declare var $;

@Component({
  selector: 'app-cus-hoyo',
  templateUrl: './hoyo.component.html',
  styleUrls: ['./hoyo.component.scss']
})
export class HoyoCusComponent implements OnInit {

  Const = CommonConstants;
  utils = Utils;
  hall_id = null;
  hoyo_id = null;
  hoyo_data = null;
  CONST_REQUIRE = '法要番号をご入力してください。';
  CONST_NOTFUOND = 'ご指定の法要情報が見つかりません。';
  CONST_EXPIRED = 'ご指定の法要情報は掲載終了いたしました。';
  err_msg = null;
  is_loading = false;
  constructor(
    private router: Router,
    private location: Location,
    private sessionSvc: SessionService,
    private activatedRoute: ActivatedRoute,
    private httpClientService: HttpClientService,
    private sanitizer: DomSanitizer,
  ) { }

  async ngOnInit() {
    this.sessionSvc.clear('is_hoyo_page');
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      const paramId = params.get('id');
      if (!paramId) {
        return;
      }
      const paramIds = paramId.split('-');
      if (paramIds.length >= 1) {
        this.hall_id = Number(paramIds[0]);
      }
      if (paramIds.length >= 2) {
        this.hoyo_id = Number(paramIds[1]);
        return;
      }
      if (!this.hall_id || !this.hoyo_id) {
        this.err_msg = this.CONST_REQUIRE;
        return;
      }

    });
    if (!this.hoyo_id) {
      this.location.go('hoyo');
      return;
    }
    await this.getHoyoInfo(this.hoyo_id);
  }

  getHoyo(hallIdEm, hoyoIdEm) {
    this.err_msg = null;
    if (!this.hall_id) {
      this.err_msg = this.CONST_REQUIRE;
      hallIdEm.focus();
      return;
    }
    if (!this.hoyo_id) {
      this.err_msg = this.CONST_REQUIRE;
      hoyoIdEm.focus();
      return;
    }
    this.getHoyoInfo(this.hoyo_id);
  }
  async getHoyoInfo(id) {
    this.is_loading = true;
    await this.httpClientService.getHoyoByid(id).then((response) => {
      Utils.log(response);
      if (!response || response.hall.id !== Number(this.hall_id)) {
        this.err_msg = this.CONST_NOTFUOND;
        this.is_loading = false;
        return false;
      }
      if (!Utils.isBetweenTerm(null, String(response.hoyo_site_end_date))) {
        this.err_msg = this.CONST_EXPIRED;
        this.is_loading = false;
        return false;
      }
      Utils.formatHoyoDate(response);

      this.hoyo_data = response;
      this.sessionSvc.clear('custom_seko_info');
      this.sessionSvc.save('custom_hoyo_info', this.hoyo_data);
      this.sessionSvc.save('is_hoyo_page', true);
      this.is_loading = false;
      this.router.navigate(['hoyo/' + this.hall_id + '-' + this.hoyo_id]);

    }).catch(error => {
      this.err_msg = this.CONST_NOTFUOND;
      this.is_loading = false;
      return false;
    });
    return true;
  }

  zipCode(zip_code: string) {
    if (!zip_code || zip_code.length !== 7) {
      return zip_code;
    }
    return zip_code.substring(0, 3) + '-' + zip_code.substring(3, 7);
  }

}

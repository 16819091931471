
export class CompanyServicePostRequest {
  service_ids: Array<number>;
}

export class ServiceReceptionTermGetRequest {
  department_id: number;
}

class ServiceReceptionTermBase {
  department: number;
  service: number;
  schedule: number;
  limit_hour: number;
  unit: number;
  limit_time: number;
}
export class ServiceReceptionTermEdit extends ServiceReceptionTermBase {
  name: string;
}

export class ServiceReceptionTermGetResponse extends ServiceReceptionTermBase {
  id?: number;
}
export class ServiceReceptionTermPostRequest {
  service_reception_terms: Array<ServiceReceptionTermBase>;
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/pages/common/confirm-dialog/confirm-dialog.component';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { LoaderService } from 'src/app/service/loader.service';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import { CompanyFuhoSampleGetRequest, CompanyFuhoSampleGetResponse, CompanyFuhoSamplePostRequest } from 'src/app/interfaces/fuho-sample';
declare var $;

@Component({
  selector: 'app-com-fuho-sample',
  templateUrl: './fuho-sample.component.html',
  styleUrls: ['./fuho-sample.component.scss']
})
export class FuhoSampleComponent implements OnInit {

  Const = CommonConstants;

  fuho_sample_list = this.sessionSvc.get('fuho_sample_list');
  login_company = Utils.getLoginCompany();
  companyCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  departCombo = { values: [], clearable: false, showOnFocus: false };
  company_list = Utils.getCompanyList();
  company_id = null;

  item_list = null;

  item_edit: CompanyFuhoSampleGetResponse = new CompanyFuhoSampleGetResponse();
  edit_type = 1;

  message = null;
  errField = new Map();

  page_per_count = 5;
  pagination = {
    pages: new Array(),
    current: 0
  };

  fuho_sentence_max_row = 5;
  fuho_sentence_max_row_len = 35;

  processing = false;

  constructor(
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    private dialog: MatDialog,
  ) {
    this.loaderSvc.call();
  }

  ngOnInit(): void {
    this.initControl();
    this.getData(this.company_id);
  }

  initControl() {
    if (this.company_list) {
      this.companyCombo.values = this.company_list.map(value => ({ name: value.base_name, value: value.id, data: value }));
      if (this.login_company) {
        if (this.login_company.base_type === CommonConstants.BASE_TYPE_COMPANY) {
          this.company_id = this.login_company.id;
        } else {
          this.company_id = this.company_list[0].id;
        }
      }
    }
  }

  getData(company_id) {
    const request = new CompanyFuhoSampleGetRequest();
    request.company_id = company_id;
    if (!this.loaderSvc.isLoading) {
      this.loaderSvc.call();
    }
    this.httpClientService.getCompanyFuhoSampleList(request).then((response) => {
      Utils.log(response);
      this.item_list = response;
      this.calcPagination();
      this.loaderSvc.dismiss();

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }
  calcPagination() {
    this.pagination.pages = new Array();
    this.pagination.current = 0;
    if (!this.item_list || !this.item_list.length) {
      return;
    }
    const count = Math.ceil(this.item_list.length / this.page_per_count);
    for (let i = 1; i <= count; i++) {
      this.pagination.pages.push(i);
    }
    this.pagination.current = 1;
  }

  companyChange(event) {
    if (!event || !event.data) {
      return;
    }
    this.getData(event.data.id);
  }

  showData(event, item = null) {
    this.message = null;
    if (!item) {
      this.edit_type = 1;
      this.item_edit = new CompanyFuhoSampleGetResponse();
      this.item_edit.company = this.company_id;
    } else {
      this.edit_type = 2;
      if (event.target.classList.contains('operation') || event.target.classList.contains('icon')) {
        return;
      }
      this.item_edit = JSON.parse(JSON.stringify(item));
    }
    $('#item-edit').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }
  saveData() {
    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }
    this.processing = true;
    if (!this.dataValidate()) {
      this.processing = false;
      return;
    }
    const postData = this.getPostData();
    this.loaderSvc.call();
    if (postData.id) {
      this.httpClientService.updateCompanyFuhoSample(postData.id, postData)
        .then(async (response) => {
          Utils.log(response);
          this.sessionSvc.save('message', { type: 'info', title: '訃報文サンプルを保存しました。' });
          this.getData(this.company_id);
          this.loaderSvc.dismiss();
          this.processing = false;
        })
        .catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: '訃報文サンプルの保存が失敗しました。' });
          this.loaderSvc.dismiss();
          this.processing = false;
        });
    } else {
      this.httpClientService.addCompanyFuhoSample(postData)
        .then(async (response) => {
          Utils.log(response);
          this.sessionSvc.save('message', { type: 'info', title: '訃報文サンプルを保存しました。' });
          this.getData(this.company_id);
          this.loaderSvc.dismiss();
          this.processing = false;
        })
        .catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: '訃報文サンプルの保存が失敗しました。' });
          this.loaderSvc.dismiss();
          this.processing = false;
        });
    }
    $('#item-edit').modal('hide');

  }
  dataValidate() {
    const msgs = new Array();
    this.message = null;
    if (!this.item_edit.display_num) {
      msgs.push('表示順');
    }
    this.item_edit.sentence = this.item_edit.sentence.trim();
    if (!this.item_edit.sentence) {
      msgs.push('訃報文内容');
    }

    if (msgs.length > 0) {
      this.message = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: msgs.join(',')
      };
      return false;
    }

    const sentence_rows = this.item_edit.sentence.split('\n');
    if (sentence_rows.length > this.fuho_sentence_max_row) {
      this.message = { type: 'warning', title: '訃報文は' + this.fuho_sentence_max_row + '行以内で入力してください。' };
      return false;
    }
    for (const sentence_row of sentence_rows) {
      if (sentence_row.length > this.fuho_sentence_max_row_len) {
        this.message = { type: 'warning', title: '訃報文は1行に' + this.fuho_sentence_max_row_len + '文字以内で入力してください。' };
        return false;
      }
    }
    return true;
  }

  getPostData(): CompanyFuhoSamplePostRequest {
    const postRequest = new CompanyFuhoSamplePostRequest();
    postRequest.id = this.item_edit.id;
    postRequest.company = this.item_edit.company;
    postRequest.display_num = this.item_edit.display_num;
    postRequest.sentence = this.item_edit.sentence;
    return postRequest;
  }


  pageTo(pagination, page_num) {
    pagination.current = page_num;
  }

  closeMessage() {
    this.message = null;
  }

  deleteData(id) {
    this.sessionSvc.clear('message');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: '訃報文サンプルを削除します、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.loaderSvc.call();
        this.httpClientService.deleteCompanyFuhoSample(id)
          .then((response) => {
            Utils.log(response);
            this.sessionSvc.save('message', { type: 'info', title: '訃報文サンプルを削除しました。' });
            this.getData(this.company_id);
          })
          .catch(error => {
            this.sessionSvc.save('message', { type: 'error', title: '訃報文サンプルの削除が失敗しました。' });
            this.loaderSvc.dismiss();
          });
      }
    });
  }

  showFuhoSample() {
    for (const fuho_sample of this.fuho_sample_list) {
      fuho_sample.selected = false;
    }
    $('#fuho-sample').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  selectChangeFuhoSample(fuho_sample) {
    fuho_sample.selected = !fuho_sample.selected;
  }

  fuhoSampleSelected() {
    for (const fuho_sample of this.fuho_sample_list) {
      if (fuho_sample.selected) {
        return true;
      }
    }
    return false;
  }


  async addFuhoSample() {
    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }
    this.processing = true;
    let display_num = 0;
    for (const item of this.item_list) {
      display_num = Math.max(display_num, item.display_num);
    }
    $('#fuho-sample').modal('hide');
    this.loaderSvc.call();
    for (const fuhoSample of this.fuho_sample_list) {
      if (!fuhoSample.selected) {
        continue;
      }
      const postRequest = new CompanyFuhoSamplePostRequest();
      postRequest.company = this.company_id;
      postRequest.display_num = display_num + 1;
      postRequest.sentence = fuhoSample.sentence;
      display_num++;
      await this.httpClientService.addCompanyFuhoSample(postRequest)
        .catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: '訃報文サンプルの保存が失敗しました。' });
          this.loaderSvc.dismiss();
          this.processing = false;
        });
    }
    this.getData(this.company_id);
    this.loaderSvc.dismiss();
    this.processing = false;
    this.sessionSvc.save('message', { type: 'info', title: '訃報文サンプルを保存しました。' });


  }
}

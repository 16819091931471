import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { ComDropdownComponent } from 'src/app/components/com-dropdown/com-dropdown.component';
import { EntryPostRequest, EntryDetailForPost, EntryDetailMessageForPost } from 'src/app/interfaces/order';
import { CommonConstants } from 'src/app/const/const';
import { Utils } from 'src/app/const/func-util';
declare var $;

@Component({
  selector: 'app-cus-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageCusComponent implements OnInit {
  @ViewChild('relationComboEm', { static: false }) relationComboEm: ComDropdownComponent;
  Const = CommonConstants;
  seko_info = this.sessionSvc.get('custom_seko_info');
  message_edit: EntryDetailForPost = this.sessionSvc.get('message_edit');
  entry: EntryPostRequest = this.sessionSvc.get('service_entry');
  service = null;
  item = null;

  description = `パソコン、スマートフォンから、故人・遺族に向けて無料でメッセージを送ることができます。
文字数は200文字まで`;
  honbun_max_length = 200;
  relationCombo = {
    values: [],
    clearable: false,
    showOnFocus: false,
    disableSearch: true
  };

  message = null;

  constructor(
    private router: Router,
    private sessionSvc: SessionService,
    private httpClientService: HttpClientService,
  ) { }

  async ngOnInit() {
    if (!this.seko_info) {
      this.router.navigate(['fuho/']);
      return;
    }
    await this.getSekoInfo(this.seko_info.id);
    if (this.seko_info.services) {
      this.service = this.seko_info.services.find(v => v.hall_service.id === this.Const.SERVICE_ID_MESSAGE);
    }
    if (!this.seko_info.services && !this.service) {
      this.router.navigate(['fuho/' + this.seko_info.seko_company.id + '-' + this.seko_info.id + '/']);
      return;
    }
    if (this.seko_info.items) {
      this.item = this.seko_info.items.find(v => v.item.service === this.Const.SERVICE_ID_MESSAGE);
    }
    if (!this.seko_info.items || !this.item) {
      this.router.navigate(['fuho/' + this.seko_info.seko_company.id + '-' + this.seko_info.id + '/']);
      return;
    }
    if (this.entry && this.entry.details) {
      for (const detail of this.entry.details) {
        if (detail.koden) {
          this.message = '※ご香典お預かりサービスと同時に申込することができません。';
          return;
        }
      }
    }
    await this.getRelationship();
    if (!this.message_edit) {
      this.message_edit = new EntryDetailForPost();
      this.message_edit.quantity = 1;
    }
    if (!this.message_edit.message) {
      this.message_edit.message = new EntryDetailMessageForPost();
    }
    this.message_edit.service = this.service.hall_service;
    this.message_edit.item = this.item.item.id;
    this.message_edit.item_hinban = this.item.item.hinban;
    this.message_edit.item_name = this.item.item.name;
    this.message_edit.quantity = 1;
    this.message_edit.item_tax_pct = 0;
    this.message_edit.keigen_flg = false;
    this.message_edit.item_unit_price = 0;
    this.message_edit.item_tax = 0;
  }

  async getSekoInfo(id) {
    await this.httpClientService.getSekoByid(id).then((sekoResponse) => {
      Utils.log(sekoResponse);
      Utils.formatSekoDate(sekoResponse);
      this.seko_info = sekoResponse;

    }).catch(error => {
      return false;
    });
    return true;
  }

  saveData() {
    if (!this.invalidData()) {
      const scrollTo = $('.navigation').offset();
      $('body, html').animate({
        scrollTop: scrollTo.top
      }, 200);
      return;
    }
    if (!this.entry) {
      this.entry = new EntryPostRequest();
    }
    if (!this.entry.details) {
      this.entry.details = new Array();
    }
    if (this.message_edit.index !== undefined
      && this.message_edit.index !== null &&
      this.entry.details[this.message_edit.index]) {
      this.entry.details[this.message_edit.index] = this.message_edit;
    } else {
      this.message_edit.index = this.entry.details.length;
      this.entry.details.push(this.message_edit);
    }
    this.sessionSvc.save('service_entry', this.entry);
    this.sessionSvc.clear('message_edit');
    this.router.navigate(['cart/']);
  }

  async getRelationship() {
    await this.httpClientService.getRelationshipList().then(async (response) => {
      Utils.log(response);
      if (response) {
        this.relationCombo.values = response.map(value => ({ name: value.name, value: value.id, data: value }));
        this.relationComboEm.initComponent();
      }
    }).catch(error => {
    });
  }

  invalidData() {
    $('.input').attr('err-msg', null);
    if (!this.message_edit || !this.message_edit.message) {
      return false;
    }
    let ret = true;
    if (!this.message_edit.message.relation_ship) {
      $('.input.relation_ship').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.message_edit.message.honbun) {
      $('.input.honbun').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (this.message_edit.message.honbun && this.message_edit.message.honbun.length > this.honbun_max_length) {
      $('.input.honbun').attr('err-msg', '*入力可能文字数を超えております');
      ret = false;
    }
    return ret;
  }

  isErrorField(element) {
    return !!element.getAttribute('err-msg');
  }

  back() {
    this.router.navigate(['fuho/' + this.seko_info.seko_company.id + '-' + this.seko_info.id + '/']);
  }

  isExpierd() {
    if (!this.service) {
      return true;
    }
    return this.service.is_expired;
  }

}

import { Component, OnInit, Inject, LOCALE_ID, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/pages/common/confirm-dialog/confirm-dialog.component';
import { HttpClientService } from 'src/app/service/http-client.service';
import { SessionService } from 'src/app/service/session.service';
import { LoaderService } from 'src/app/service/loader.service';
import { BaseService } from 'src/app/service/base.service';
import { ZipcodeService } from 'src/app/service/zipcode.service';
import {
  BaseGetResponse, BasePostRequest, FocFeeForGet,
  TokushoForGet, FocFeePostRequest, TokushoPostRequest,
  SmsAccountForGet, SmsAccountPostRequest
} from 'src/app/interfaces/base';
import { CompanyServicePostRequest } from 'src/app/interfaces/service';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import { AddressGetRequest } from 'src/app/interfaces/master';
import { ComDropdownComponent } from 'src/app/components/com-dropdown/com-dropdown.component';

declare var $;
@Component({
  selector: 'app-com-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {
  @ViewChild('imageFileUpload', { static: false }) imageFileUpload: ElementRef;
  @ViewChild('departCompanyComboEm', { static: false }) departCompanyComboEm: ComDropdownComponent;
  @ViewChild('hallCompanyComboEm', { static: false }) hallCompanyComboEm: ComDropdownComponent;
  @ViewChild('departComboEm', { static: false }) departComboEm: ComDropdownComponent;

  Const = CommonConstants;

  login_company = Utils.getLoginCompany();
  login_info = this.sessionSvc.get('staff_login_info');
  service_list = this.sessionSvc.get('service_list');
  departCompanyCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  hallCompanyCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  departCombo = { values: [], clearable: false, showOnFocus: false };
  company_list = Utils.getCompanyList();
  depart_list = null;
  hall_list = null;
  depart_company_id = null;
  depart_company_code = null;
  hall_company_id = null;
  hall_depart_id = null;
  hall_company_code = null;
  select_base_id = null;

  base_edit: BaseGetResponse = new BaseGetResponse();
  base_edit_type = 1;

  focfee_edit = new FocFeeForGet();

  tokusho_edit = new TokushoForGet();

  sms_account_edit = new SmsAccountForGet();
  order_mail_edit: BaseGetResponse = new BaseGetResponse();

  message = null;
  errField = new Map();

  page_per_count = 15;
  pagination_company = {
    pages: new Array(),
    current: 0
  };
  pagination_depart = {
    pages: new Array(),
    current: 0
  };
  pagination_hall = {
    pages: new Array(),
    current: 0
  };
  processing = false;
  constructor(
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private baseSvc: BaseService,
    private zipcodeSvc: ZipcodeService,
    private loaderSvc: LoaderService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.initControl();
    this.initJSControl();
    this.initService();
  }

  initControl() {
    if (this.company_list && this.company_list.length) {
      this.departCompanyCombo.values = this.company_list.map(value => ({ name: value.base_name, value: value.id, data: value }));
      this.hallCompanyCombo.values = this.company_list.map(value => ({ name: value.base_name, value: value.id, data: value }));
      if (this.login_company) {
        this.order_mail_edit =  JSON.parse(JSON.stringify(this.login_company));
        if (this.login_company.base_type === CommonConstants.BASE_TYPE_COMPANY) {
          this.depart_company_id = this.login_company.id;
          this.depart_company_code = this.login_company.company_code;
          this.hall_company_id = this.login_company.id;
          this.hall_company_code = this.login_company.company_code;
          this.departCombo.values = Utils.GetDepartList(this.login_company);
          this.depart_list = this.login_company.children;
        } else {
          this.calcPagination(this.company_list, this.pagination_company);
          this.departCompanyCombo.disableSearch = false;
          this.hallCompanyCombo.disableSearch = false;
          this.depart_company_id = this.company_list[0].id;
          this.depart_company_code = this.company_list[0].company_code;
          this.hall_company_id = this.company_list[0].id;
          this.hall_company_code = this.company_list[0].company_code;
          this.departCombo.values = Utils.GetDepartList(this.company_list[0]);
          this.depart_list = this.company_list[0].children;
        }
        if (this.departCombo.values && this.departCombo.values.length) {
          this.hall_depart_id = this.departCombo.values[0].data.id;
          this.hall_list = this.departCombo.values[0].data.children;
        }
        this.calcPagination(this.depart_list, this.pagination_depart);
        this.calcPagination(this.hall_list, this.pagination_hall);

      }
    }
  }

  initJSControl() {
    setTimeout(() => {
      $('.tabular.menu .item').tab();
      $('.ui.radio.checkbox').checkbox();
    }, 100);
  }

  initService() {
    for (const service of this.service_list) {
      service.selected = false;
      switch (service.id) {
        case this.Const.SERVICE_ID_CHOBUN:
          service.icon = 'map';
          break;
        case this.Const.SERVICE_ID_KUMOTSU:
          service.icon = 'spa';
          break;
        case this.Const.SERVICE_ID_KODEN:
          service.icon = 'award';
          break;
        case this.Const.SERVICE_ID_HENREIHIN:
          service.icon = 'gift';
          break;
        case this.Const.SERVICE_ID_MESSAGE:
          service.icon = 'comment dots';
          break;
        default:
          service.icon = 'stop';

      }
    }

  }

  async reLoadData() {
    if (!this.loaderSvc.isLoading) {
      this.loaderSvc.call();
    }
    await this.baseSvc.cacheData().then(async ret => {
      if (ret) {
        this.loaderSvc.dismiss();
        await this.restoreData();
      } else {
        this.loaderSvc.dismiss();
      }
    }).catch(() => {
      this.loaderSvc.dismiss();
    });
  }

  async restoreData() {
    this.company_list = Utils.getCompanyList();
    this.login_company = Utils.getLoginCompany();
    this.depart_list = null;
    this.hall_list = null;
    if (this.company_list) {
      this.departCompanyCombo.values = this.company_list.map(value => ({ name: value.base_name, value: value.id, data: value }));
      this.hallCompanyCombo.values = this.company_list.map(value => ({ name: value.base_name, value: value.id, data: value }));
      if (this.login_company) {
        if (this.login_company.base_type === CommonConstants.BASE_TYPE_COMPANY) {
          this.departCombo.values = Utils.GetDepartList(this.login_company);
          this.depart_list = this.login_company.children;
          this.depart_company_id = this.login_company.id;
          this.order_mail_edit =  JSON.parse(JSON.stringify(this.login_company));
        } else {
          if (!this.depart_company_id) {
            this.depart_company_id = this.company_list[0].id;
          }
          if (!this.hall_company_id) {
            this.hall_company_id = this.company_list[0].id;
          }
          const depart_company = this.company_list.find(v => v.id === this.depart_company_id);
          if (depart_company) {
            this.depart_list = depart_company.children;
          }
          const hall_company = this.company_list.find(v => v.id === this.hall_company_id);
          if (hall_company) {
            this.departCombo.values = Utils.GetDepartList(hall_company);
          }
          this.order_mail_edit =  JSON.parse(JSON.stringify(this.company_list[0]));
        }

      }
    }

    this.departCompanyComboEm.initComponent();
    this.departCompanyComboEm.setValue(this.depart_company_id);
    this.hallCompanyComboEm.initComponent();
    this.hallCompanyComboEm.setValue(this.hall_company_id);
    this.departComboEm.initComponent();

    if (this.departCombo.values && this.departCombo.values.length) {
      if (!this.hall_depart_id) {
        this.hall_depart_id = this.departCombo.values[0].data.id;
      }
      const hall_depart = this.departCombo.values.find(v => v.data.id === this.hall_depart_id);
      if (hall_depart) {
        this.hall_list = hall_depart.data.children;
      }
      this.departComboEm.setValue(this.hall_depart_id);
    }
    this.calcPagination(this.company_list, this.pagination_company);
    this.calcPagination(this.depart_list, this.pagination_depart);
    this.calcPagination(this.hall_list, this.pagination_hall);

  }

  departCompanyChange(event) {
    this.depart_list = null;
    if (event && event.data) {
      this.depart_list = event.data.children;
      this.depart_company_code = event.data.company_code;
    }
    this.calcPagination(this.depart_list, this.pagination_depart);
  }

  hallCompanyChange(event, comboEm) {
    comboEm.clear();
    this.departCombo.values = [];
    this.hall_list = null;
    this.hall_depart_id = null;
    if (event && event.data) {
      this.departCombo.values = Utils.GetDepartList(event.data);
      this.hall_company_code = event.data.company_code;
    }
    comboEm.initComponent();
    if (this.departCombo.values && this.departCombo.values.length) {
      this.hall_depart_id = this.departCombo.values[0].data.id;
      this.departComboEm.setValue(this.hall_depart_id);
      this.hall_list = this.departCombo.values[0].data.children;
    }
    this.calcPagination(this.hall_list, this.pagination_hall);
  }

  hallDepartChange(event) {
    this.hall_list = null;
    if (event && event.data) {
      this.hall_list = event.data.children;
    }
    this.calcPagination(this.hall_list, this.pagination_hall);
  }

  calcPagination(list, pagination) {
    pagination.pages = new Array();
    pagination.current = 0;
    if (!list || !list.length) {
      return;
    }
    const count = Math.ceil(list.length / this.page_per_count);
    for (let i = 1; i <= count; i++) {
      pagination.pages.push(i);
    }
    pagination.current = 1;
  }

  pageTo(pagination, page_num) {
    pagination.current = page_num;
  }

  displayFdnCode(fdn_code) {
    if (fdn_code) {
      return fdn_code.replace('bumon.', '').replace('kaijyo.', '');
    }
    return null;
  }
  showBase(event, base = null, base_type = null, parent = null, company_code = null) {
    this.message = null;
    if (!base) {
      this.base_edit_type = 1;
      this.base_edit = new BaseGetResponse();
      this.base_edit.base_type = base_type;
      this.base_edit.parent = parent;
      this.base_edit.company_code = company_code;
    } else {
      this.base_edit_type = 2;
      if (event.target.classList.contains('operation') || event.target.classList.contains('icon')) {
        return;
      }
      this.base_edit = JSON.parse(JSON.stringify(base));
      this.base_edit.fdn_code = this.displayFdnCode(this.base_edit.fdn_code);
    }
    $('#base-edit').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  async saveBase() {
    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }
    this.processing = true;
    const ret = await this.baseValidate();
    if (!ret) {
      this.processing = false;
      return;
    }
    const postData = this.getBasePostData();

    const title = (
      postData.base_type === this.Const.BASE_TYPE_COMPANY ? '葬儀社' : postData.base_type === this.Const.BASE_TYPE_DEPART ? '部門' : '式場'
    );
    let unique_err = false;
    this.loaderSvc.call();
    if (postData.id) {
      await this.httpClientService.updateBase(postData.id, postData)
        .then(async (response) => {
          Utils.log(response);
          await this.reLoadData();
          this.sessionSvc.save('message', { type: 'info', title: title + '情報を保存しました。' });
          this.processing = false;
        })
        .catch(error => {
          if (error.non_field_errors) {
            unique_err = true;
            this.message = { type: 'warning', title: 'FDNコードがすでに使われています。' };
            this.loaderSvc.dismiss();
            this.processing = false;
            this.sessionSvc.clear('message');
            return;
          }
          this.sessionSvc.save('message', { type: 'error', title: title + '情報の保存が失敗しました。' });
          this.processing = false;
          this.loaderSvc.dismiss();
        });
    } else {
      await this.httpClientService.addBase(postData)
        .then(async (response) => {
          Utils.log(response);
          await this.reLoadData();
          this.sessionSvc.save('message', { type: 'info', title: title + '情報を保存しました。' });
          this.processing = false;
        })
        .catch(error => {
          if (error.non_field_errors) {
            unique_err = true;
            this.message = { type: 'warning', title: 'FDNコードがすでに使われています。' };
            this.loaderSvc.dismiss();
            this.processing = false;
            this.sessionSvc.clear('message');
            return;
          }
          this.sessionSvc.save('message', { type: 'error', title: title + '情報の保存が失敗しました。' });
          this.loaderSvc.dismiss();
          this.processing = false;
        });
    }
    if (!unique_err) {
      $('#base-edit').modal('hide');
    }
  }
  async baseValidate() {
    const msgs = new Array();
    this.message = null;
    if (!this.base_edit.company_code) {
      msgs.push('会社コード');
    }
    if (!this.base_edit.base_name) {
      msgs.push('名称');
    }
    if (this.base_edit.base_type !== this.Const.BASE_TYPE_COMPANY && !this.base_edit.display_num) {
      msgs.push('表示順');
    }
    if (!this.base_edit.zip_code) {
      msgs.push('郵便番号');
    }
    if (!this.base_edit.prefecture) {
      msgs.push('都道府県');
    }
    if (!this.base_edit.address_1) {
      msgs.push('住所1');
    }
    if (!this.base_edit.tel) {
      msgs.push('電話番号');
    }
    if (!this.base_edit.fax) {
      msgs.push('FAX番号');
    }
    if (this.base_edit.base_type === this.Const.BASE_TYPE_COMPANY && !this.base_edit.calc_type) {
      msgs.push('税計算区分');
    }

    if (msgs.length > 0) {
      this.message = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: msgs.join(',')
      };
      return false;
    }

    if (this.base_edit_type === 1 && this.base_edit.base_type === this.Const.BASE_TYPE_COMPANY) {
      this.loaderSvc.call();
      await this.baseSvc.cacheData();
      this.loaderSvc.dismiss();
      if (!Utils.checkCompanyCode(this.base_edit.company_code)) {
        this.message = {
          type: 'warning',
          title: '会社コードがすでに使われています。'
        };
        return false;

      }
    }
    if (this.base_edit.order_mail_address) {
      const mail_address = this.base_edit.order_mail_address.split(',');
      const reg = new RegExp(CommonConstants.MAIL_PATTERN);
      for (const address of mail_address) {
        if (!address.match(reg)) {
          this.message = {
            type: 'warning',
            title: 'メール形式が正しくありません。',
            msg: '注文通知先'
          };
          return false;
        }
      }
    }
    return true;
  }

  getBasePostData(): BasePostRequest {
    const postRequest = new BasePostRequest();
    postRequest.id = this.base_edit.id;
    postRequest.base_name = this.base_edit.base_name;
    postRequest.zip_code = this.base_edit.zip_code;
    postRequest.parent = this.base_edit.parent;
    postRequest.base_type = this.base_edit.base_type;
    postRequest.company_code = this.base_edit.company_code;
    postRequest.prefecture = this.base_edit.prefecture;
    postRequest.address_1 = this.base_edit.address_1;
    postRequest.address_2 = this.base_edit.address_2;
    postRequest.address_3 = this.base_edit.address_3;
    postRequest.tel = this.base_edit.tel;
    postRequest.fax = this.base_edit.fax;
    postRequest.calc_type = this.base_edit.calc_type;
    postRequest.display_num = this.base_edit.display_num;
    if (this.base_edit.fdn_code) {
      if (this.base_edit.base_type === this.Const.BASE_TYPE_DEPART) {
        postRequest.fdn_code = 'bumon.' + this.base_edit.fdn_code;
      } else if ([this.Const.BASE_TYPE_PLACE_SELF, this.Const.BASE_TYPE_PLACE_OTHER].includes(this.base_edit.base_type)) {
        postRequest.fdn_code = 'kaijyo.' + this.base_edit.fdn_code;
      } else {
        postRequest.fdn_code = this.base_edit.fdn_code;
      }
    } else if (!this.base_edit.fdn_code) {
      postRequest.fdn_code = null;
    }
    if (this.base_edit.logo_file) {
      postRequest.company_logo_file = this.base_edit.logo_file;
    } else if (!this.base_edit.company_logo_file) {
      postRequest.company_logo_file = null;
    }
    if (this.base_edit.map_file) {
      postRequest.company_map_file = this.base_edit.map_file;
    } else if (!this.base_edit.company_map_file) {
      postRequest.company_map_file = null;
    }
    postRequest.order_mail_address = this.base_edit.order_mail_address;

    return postRequest;
  }

  showService(base) {
    if (!base) {
      return;
    }

    this.select_base_id = base.id;
    for (const service of this.service_list) {
      service.selected = false;
      if (base && base.services) {
        const selected_item = base.services.find(v => v.id === service.id);
        if (selected_item) {
          service.selected = true;
        }
      }
    }
    this.message = null;
    $('#service-edit').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  selectService(service) {
    service.selected = !service.selected;
  }

  async saveService() {
    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }
    this.processing = true;
    const postData = this.getServicePostData();
    $('#service-edit').modal('hide');
    this.loaderSvc.call();
    let ret = true;
    ret = await this.addService(this.select_base_id, postData);
    await this.reLoadData();
    if (ret) {
      this.sessionSvc.save('message', { type: 'info', title: 'サービス情報を保存しました。' });
    } else {
      this.sessionSvc.save('message', { type: 'error', title: 'サービス情報の保存が失敗しました。' });
    }
    this.processing = false;
    this.loaderSvc.dismiss();
  }

  async addService(base_id, postData): Promise<boolean> {
    let ret = true;
    await this.httpClientService.addCompanyService(base_id, postData)
      .then(async (response) => {
      })
      .catch(error => {
        ret = false;
      });
    return ret;

  }

  getServicePostData() {
    const postRequest = new CompanyServicePostRequest();
    postRequest.service_ids = new Array();
    for (const service of this.service_list) {
      if (service.selected) {
        postRequest.service_ids.push(service.id);
      }
    }

    return postRequest;
  }

  showFocFee(base) {
    this.message = null;
    if (!base.focfee) {
      this.focfee_edit = new FocFeeForGet();
      this.focfee_edit.is_new = true;
    } else {
      this.focfee_edit = JSON.parse(JSON.stringify(base.focfee));
      this.focfee_edit.is_new = false;
    }
    this.focfee_edit.base_id = base.id;
    $('#focfee-edit').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  saveFocFee() {
    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }
    this.processing = true;
    if (!this.focFeeValidate()) {
      this.processing = false;
      return;
    }
    const postData = this.getFocFeePostData();

    this.loaderSvc.call();
    let func_name = null;
    if (this.focfee_edit.is_new) {
      func_name = 'addFocFee';
    } else {
      func_name = 'updateFocFee';
    }
    this.httpClientService[func_name](this.focfee_edit.base_id, postData)
      .then(async (response) => {
        Utils.log(response);
        await this.reLoadData();
        this.sessionSvc.save('message', { type: 'info', title: '利用料情報を保存しました。' });
        this.processing = false;
      })
      .catch(error => {
        this.sessionSvc.save('message', { type: 'error', title: '利用料情報の保存が失敗しました。' });
        this.loaderSvc.dismiss();
        this.processing = false;
      });
    $('#focfee-edit').modal('hide');
  }

  focFeeValidate() {
    const msgs = new Array();
    this.message = null;
    if (this.focfee_edit.monthly_fee === undefined || this.focfee_edit.monthly_fee === null) {
      msgs.push('月額利用料');
    }
    if (this.focfee_edit.chobun_fee === undefined || this.focfee_edit.chobun_fee === null) {
      msgs.push('弔文利用料');
    }
    if (this.focfee_edit.kumotsu_fee === undefined || this.focfee_edit.kumotsu_fee === null) {
      msgs.push('供花・供物利用料');
    }
    if (this.focfee_edit.koden_fee === undefined || this.focfee_edit.koden_fee === null) {
      msgs.push('香典利用料');
    }
    if (this.focfee_edit.henreihin_fee === undefined || this.focfee_edit.henreihin_fee === null) {
      msgs.push('返礼品利用料');
    }
    // if (!this.focfee_edit.chobun_fee_unit) {
    //   msgs.push('弔文利用料単位');
    // }
    // if (!this.focfee_edit.kumotsu_fee_unit) {
    //   msgs.push('供花・供物利用料単位');
    // }
    // if (!this.focfee_edit.koden_fee_unit) {
    //   msgs.push('香典利用料単位');
    // }
    // if (!this.focfee_edit.henreihin_fee_unit) {
    //   msgs.push('返礼品利用料単位');
    // }

    if (msgs.length > 0) {
      this.message = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: msgs.join(',')
      };
      return false;
    }


    return true;
  }

  getFocFeePostData(): FocFeePostRequest {
    const postRequest = new FocFeePostRequest();
    postRequest.monthly_fee = this.focfee_edit.monthly_fee;
    postRequest.chobun_fee = this.focfee_edit.chobun_fee;
    postRequest.kumotsu_fee = this.focfee_edit.kumotsu_fee;
    postRequest.koden_fee = this.focfee_edit.koden_fee;
    postRequest.henreihin_fee = this.focfee_edit.henreihin_fee;
    postRequest.gmo_code = this.focfee_edit.gmo_code;
    postRequest.gmo_code_koden = this.focfee_edit.gmo_code_koden;
    postRequest.gmo_test_flg = this.focfee_edit.gmo_test_flg;
    postRequest.chobun_fee_unit = 1;
    postRequest.kumotsu_fee_unit = 1;
    postRequest.koden_fee_unit = 1;
    postRequest.henreihin_fee_unit = 1;

    return postRequest;
  }

  showTokusho(base) {
    this.message = null;
    if (!base.tokusho) {
      this.tokusho_edit = new TokushoForGet();
      this.tokusho_edit.is_new = true;
    } else {
      this.tokusho_edit = JSON.parse(JSON.stringify(base.tokusho));
      this.tokusho_edit.is_new = false;
    }
    this.tokusho_edit.base_id = base.id;
    $('#tokusho-edit').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  async saveTokusho() {

    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }
    this.processing = true;
    if (!this.tokushoValidate()) {
      this.processing = false;
      return;
    }
    let unique_err = false;
    const postData = this.getTokushoPostData();
console.log(postData);
    this.loaderSvc.call();
    let func_name = null;
    if (this.tokusho_edit.is_new) {
      func_name = 'addTokusho';
    } else {
      func_name = 'updateTokusho';
    }
    await this.httpClientService[func_name](this.tokusho_edit.base_id, postData)
      .then(async (response) => {
        Utils.log(response);
        await this.reLoadData();
        this.sessionSvc.save('message', { type: 'info', title: '特商法情報を保存しました。' });
        this.processing = false;
      })
      .catch(error => {
        if (error.from_name) {
          unique_err = true;
          this.message = { type: 'warning', title: 'faximo送信元がすでに使われています。' };
          this.loaderSvc.dismiss();
          this.processing = false;
          this.sessionSvc.clear('message');
          return;
        }
        this.sessionSvc.save('message', { type: 'error', title: '特商法情報の保存が失敗しました。' });
        this.loaderSvc.dismiss();
        this.processing = false;
      });
    if (!unique_err) {
      $('#tokusho-edit').modal('hide');
    }
  }

  tokushoValidate() {
    const msgs = new Array();
    this.message = null;
    if (!this.tokusho_edit.responsible_name) {
      msgs.push('運用責任者');
    }
    if (!this.tokusho_edit.mail_address) {
      msgs.push('メールアドレス');
    }
    if (!this.tokusho_edit.from_name) {
      msgs.push('faximo送信元');
    }
    if (!this.tokusho_edit.url) {
      msgs.push('URL');
    }

    if (msgs.length > 0) {
      this.message = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: msgs.join(',')
      };
      return false;
    }
    const reg = new RegExp(CommonConstants.MAIL_PATTERN);
    if (!this.tokusho_edit.mail_address.match(reg)) {
      msgs.push('メールアドレス');
    }
    if (!this.tokusho_edit.from_name.match(reg)) {
      msgs.push('faximo送信元');
    }


    if (msgs.length > 0) {
      this.message = {
        type: 'warning',
        title: 'メール形式が正しくありません。',
        msg: msgs.join(',')
      };
      return false;
    }


    return true;
  }

  getTokushoPostData(): TokushoPostRequest {
    const postRequest = new TokushoPostRequest();
    postRequest.responsible_name = this.tokusho_edit.responsible_name;
    postRequest.mail_address = this.tokusho_edit.mail_address;
    postRequest.from_name = this.tokusho_edit.from_name;
    postRequest.url = this.tokusho_edit.url;
    postRequest.kumotsu_offer_timing = this.tokusho_edit.kumotsu_offer_timing;
    postRequest.koden_offer_timing = this.tokusho_edit.koden_offer_timing;
    postRequest.henrei_offer_timing = this.tokusho_edit.henrei_offer_timing;
    postRequest.business_no = this.tokusho_edit.business_no;

    return postRequest;
  }

  showSmsAccount(base) {
    this.message = null;
    if (!base.sms_account) {
      this.sms_account_edit = new SmsAccountForGet();
      this.sms_account_edit.is_new = true;
    } else {
      this.sms_account_edit = JSON.parse(JSON.stringify(base.sms_account));
      this.sms_account_edit.is_new = false;
    }
    this.sms_account_edit.base_id = base.id;
    $('#sms-account-edit').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  saveSmsAccount() {

    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }
    this.processing = true;
    if (!this.smsAccountValidate()) {
      this.processing = false;
      return;
    }
    const postData = this.getSmsAccountPostData();

    this.loaderSvc.call();
    let func_name = null;
    if (this.sms_account_edit.is_new) {
      func_name = 'addSmsAccount';
    } else {
      func_name = 'updateSmsAccount';
    }
    this.httpClientService[func_name](this.sms_account_edit.base_id, postData)
      .then(async (response) => {
        Utils.log(response);
        await this.reLoadData();
        this.sessionSvc.save('message', { type: 'info', title: 'SMSアカウント情報を保存しました。' });
        this.processing = false;
      })
      .catch(error => {
        this.sessionSvc.save('message', { type: 'error', title: 'SMSアカウント情報の保存が失敗しました。' });
        this.loaderSvc.dismiss();
        this.processing = false;
      });
    $('#sms-account-edit').modal('hide');
  }

  smsAccountValidate() {
    const msgs = new Array();
    this.message = null;
    if (!this.sms_account_edit.token) {
      msgs.push('トークン');
    }
    if (!this.sms_account_edit.client_id) {
      msgs.push('クライアントID');
    }

    if (msgs.length > 0) {
      this.message = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: msgs.join(',')
      };
      return false;
    }

    return true;
  }

  getSmsAccountPostData(): SmsAccountPostRequest {
    const postRequest = new SmsAccountPostRequest();
    postRequest.token = this.sms_account_edit.token;
    postRequest.client_id = this.sms_account_edit.client_id;
    postRequest.sms_code = this.sms_account_edit.sms_code;

    return postRequest;
  }

  showOrderMail() {
    this.message = null;
    $('#order-mail-edit').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  saveOrderMail() {

    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }
    this.processing = true;
    if (!this.orderMailValidate()) {
      this.processing = false;
      return;
    }
    const postData = this.getOrderMailPostData();

    this.loaderSvc.call();
    this.httpClientService.updateBase(postData.id, postData)
      .then(async (response) => {
        Utils.log(response);
        await this.reLoadData();
        this.sessionSvc.save('message', { type: 'info', title: '注文通知先を保存しました。' });
        this.processing = false;
      })
      .catch(error => {
        this.sessionSvc.save('message', { type: 'error', title: '注文通知先の保存が失敗しました。' });
        this.processing = false;
        this.loaderSvc.dismiss();
      });
    $('#order-mail-edit').modal('hide');
  }

  orderMailValidate() {
    this.message = null;
    if (!this.order_mail_edit.order_mail_address) {
      return true;
    }

    const mail_address = this.order_mail_edit.order_mail_address.split(',');
    const reg = new RegExp(CommonConstants.MAIL_PATTERN);
    for (const address of mail_address) {
      if (!address.match(reg)) {
        this.message = {
          type: 'warning',
          title: 'メール形式が正しくありません。',
        };
        return false;
      }

    }
    return true;
  }

  getOrderMailPostData(): BasePostRequest {
    const postRequest = new BasePostRequest();
    postRequest.id = this.order_mail_edit.id;
    postRequest.order_mail_address = this.order_mail_edit.order_mail_address;

    return postRequest;
  }

  onDragOverImage(event) {
    event.preventDefault();
  }

  onDropImage(event, file_type_id = 1) {
    event.preventDefault();
    if (!event.dataTransfer || !event.dataTransfer.files) {
      return;
    }
    this.addImage(event.dataTransfer.files, file_type_id);
  }

  addImage(files, file_type_id = 1) {
    if (!files || !files.length) {
      return;
    }
    this.message = null;
    if (files.length !== 1) {
      this.message = { type: 'warning', title: '一つのファイルを指定してください。' };
      return;

    }

    if (!files[0].type.includes('image')) {
      this.message = { type: 'warning', title: '画像ファイルを指定してください。' };
      return;
    }
    if (files[0].size > 2 * 1024 * 1024) {
      this.message = { type: 'warning', title: '画像ファイルサイズは2MBまで登録可能です。' };
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      if (file_type_id === 1) {
        this.base_edit.logo_file = e['target']['result'];
        // this.base_edit.logo_file = this.sanitizer.bypassSecurityTrustResourceUrl(String(e['target']['result']));
      } else {
        this.base_edit.map_file = e['target']['result'];
      }
    };

    reader.readAsDataURL(files[0]);

  }

  selectFile(file_type_id = 1) {
    const fileUpload = this.imageFileUpload.nativeElement;
    fileUpload.accept = 'image/*';
    fileUpload.multiple = false;

    fileUpload.onchange = () => {
      this.addImage(fileUpload.files, file_type_id);
      fileUpload.value = '';
    };
    fileUpload.click();
  }

  clearImage(file_type_id = 1) {
    if (file_type_id === 1) {
      this.base_edit.logo_file = null;
      this.base_edit.company_logo_file = null;
    } else {
      this.base_edit.map_file = null;
      this.base_edit.company_map_file = null;
    }
  }

  deleteData(base: BaseGetResponse) {
    this.sessionSvc.clear('message');
    const title = (
      base.base_type === this.Const.BASE_TYPE_COMPANY ? '葬儀社' : base.base_type === this.Const.BASE_TYPE_DEPART ? '部門' : '式場'
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: title + '情報を削除します、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.loaderSvc.call();
        this.httpClientService.deleteBase(base.id)
          .then(async (response) => {
            Utils.log(response);
            await this.reLoadData();
            this.sessionSvc.save('message', { type: 'info', title: title + '情報を削除しました。' });
          })
          .catch(error => {
            this.sessionSvc.save('message', { type: 'error', title: title + '情報の削除が失敗しました。' });
            this.loaderSvc.dismiss();
          });
      }
    });
  }

  zipcodeChange() {
    const request = new AddressGetRequest();
    request.zip_code = this.base_edit.zip_code;
    this.zipcodeSvc.getData(request).then(address => {
      if (address) {
        this.base_edit.prefecture = address.prefecture;
        this.base_edit.address_1 = address.address_1;
        this.base_edit.address_2 = address.address_2;
      }
    }).catch(error => { });
  }

  pasteZipcode(event) {
    if (event.clipboardData) {
      this.base_edit.zip_code = event.clipboardData.getData('text').replace('-', '');
    }
  }

  closeMessage() {
    this.message = null;
  }

  calcTypeName(calc_type: number): string {
    switch (calc_type) {
      case this.Const.CALC_TYPE_FLOOR:
        return '切捨て';
      case this.Const.CALC_TYPE_CEIL:
        return '切上げ';
      case this.Const.CALC_TYPE_ROUND:
        return '四捨五入';
      default:
        return '';
    }
  }
}

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/service/session.service';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { LoaderService } from 'src/app/service/loader.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { EntryPostRequest } from 'src/app/interfaces/order';
import { CommonConstants } from 'src/app/const/const';
import { Utils } from 'src/app/const/func-util';
declare var $;
declare var EpsilonToken: any;

@Component({
  selector: 'app-cus-order-payment',
  templateUrl: './order-payment.component.html',
  styleUrls: ['./order-payment.component.scss']
})
export class OrderPaymentComponent implements OnInit, AfterViewInit {
  Const = CommonConstants;
  utils = Utils;
  card_token_url: string = null;
  seko_info = this.sessionSvc.get('custom_seko_info');
  entry: EntryPostRequest = this.sessionSvc.get('service_entry');
  card_info = { no: null, month: null, year: null, name: null, security_code: null };
  yearCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  year_count = 10;
  monthCombo = {
    values: [
      { value: '01', name: '01' },
      { value: '02', name: '02' },
      { value: '03', name: '03' },
      { value: '04', name: '04' },
      { value: '05', name: '05' },
      { value: '06', name: '06' },
      { value: '07', name: '07' },
      { value: '08', name: '08' },
      { value: '09', name: '09' },
      { value: '10', name: '10' },
      { value: '11', name: '11' },
      { value: '12', name: '12' },
    ],
    clearable: false,
    showOnFocus: false,
    disableSearch: true
  };
  koden_only = false;
  security_view = false;
  message = null;
  message_sub = null;
  processing = false;
  epsilon_contract_Code = null;
  constructor(
    private router: Router,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    private httpClientService: HttpClientService,
    private configSvc: RuntimeConfigLoaderService,
  ) { }

  ngOnInit(): void {
    if (!this.seko_info) {
      this.router.navigate(['fuho/']);
      return;
    }
    if (!this.entry) {
      this.router.navigate(['fuho/' + this.seko_info.seko_company.id + '-' + this.seko_info.id + '/']);
      return;
    }
    if (!this.entry.details || !this.entry.details.length) {
      this.router.navigate(['cart/']);
      return;
    }
    if (this.entry.details[0].koden) {
      this.koden_only = true;
    }
    const start_year = (new Date()).getFullYear();
    for (let i = 0; i < this.year_count; i++) {
      this.yearCombo.values.push({ name: (start_year + i).toString(), value: (start_year + i).toString() });
    }
    this.card_info.month = '01';
    this.card_info.year = start_year.toString();
    if (this.koden_only) {
      this.entry.contract_type = this.Const.EPSILON_CONTRACT_TYPE_KODEN;
      this.epsilon_contract_Code = this.seko_info.seko_company.focfee.gmo_code_koden;
    } else {
      this.entry.contract_type = this.Const.EPSILON_CONTRACT_TYPE_BASIC;
      this.epsilon_contract_Code = this.seko_info.seko_company.focfee.gmo_code;
    }
    if (this.seko_info.seko_company.focfee && this.seko_info.seko_company.focfee.gmo_test_flg) {
      this.card_token_url = this.configSvc.getConfig().card_token_url_test;
    } else {
      this.card_token_url = this.configSvc.getConfig().card_token_url;
    }
  }

  ngAfterViewInit() {
    const script = document.createElement('script');
    script.async = true;
    script.setAttribute('src', this.card_token_url);
    script.setAttribute('charset', 'utf-8');
    const script_code = document.createElement('script');
    script_code.innerHTML = `
    function execTrade ( response ) {
      if( response.resultCode != 000 ){
        document.getElementById('token').value = '';
        document.getElementById('token_error').value = response.resultCode;
        document.getElementById('save_entry').click();
      }else{
        document.getElementById('token').value = response.tokenObject.token;
        document.getElementById('save_entry').click();
      }
    }

    function postForm( calltype ) {
      var cardObj = {};
      document.getElementById('token').value = '';
      document.getElementById('token_error').value = '';
      cardObj.cardno = document.getElementById('cardno').value;
      cardObj.expire = document.getElementById('expire_year').value + document.getElementById('expire_month').value;
      cardObj.securitycode = document.getElementById('securitycode').value;
      cardObj.holdername = document.getElementById('holdername').value;
      EpsilonToken.init('` + this.epsilon_contract_Code + `');
      if( calltype == 'string')
      {
        EpsilonToken.getToken( cardObj , "execTrade" );
      }else{
        EpsilonToken.getToken( cardObj , execTrade );
      }
    }
    `;
    const div = document.getElementById('epsilonScript');
    div.parentNode.insertBefore(script_code, div.nextSibling);
    div.parentNode.insertBefore(script, div.nextSibling);
  }

  async getSekoInfo(id) {
    await this.httpClientService.getSekoByid(id).then((sekoResponse) => {
      Utils.log(sekoResponse);
      Utils.formatSekoDate(sekoResponse);
      this.seko_info = sekoResponse;

    }).catch(error => {
      return false;
    });
    return true;
  }

  async saveData() {
    if (!this.epsilon_contract_Code) {
      this.message = 'お支払い手続きに失敗しました。';
      this.message_sub = '決済取引コードが取得できません。';
      this.processing = false;
      return;
    }
    this.message = null;
    this.message_sub = null;
    if (this.processing) {
      return;
    }
    this.processing = true;
    if (!this.invalidData()) {
      this.processing = false;
      return;
    }
    this.loaderSvc.call();
    this.getToken();
  }

  getToken() {
    const button = document.getElementById('get_token');
    button.click();
  }

  async saveEntry(tokenEm, tokenErrorEm) {
    this.entry.epsilon_token = tokenEm.value;
    if (!this.entry.epsilon_token) {
      Utils.log('epsilon get token error code:', tokenErrorEm.value);
      this.message = 'お支払い手続きに失敗しました。';
      this.message_sub = 'エラーコード：tokenErrorEm.value';
      this.processing = false;
      this.loaderSvc.dismiss();
      return;
    }
    this.entry.payment = this.card_info.no.substring(0, 1);
    await this.httpClientService.addEntry(this.entry)
      .then((response) => {
        this.sessionSvc.clearCustomerData();
        this.loaderSvc.dismiss();
        this.processing = false;
        this.entry.details = null;
        this.entry.epsilon_token = null;
        this.sessionSvc.save('service_entry', this.entry);
        this.sessionSvc.save('entry_no', response.id);
        this.sessionSvc.save('order_no', response.order_number);
        this.router.navigate(['order/complete']);
      }).catch(error => {
        this.message = 'お支払い手続きに失敗しました。';
        if (!error.details) {
          this.message_sub = Utils.getEpsilonErrMsg(error.toString());
        }
        this.loaderSvc.dismiss();
        this.processing = false;
      });

  }
  // // カードトークン取得
  // async getCardToken(epsilon_contract_Code) {
  //   this.entry.epsilon_token = '';
  //   const cardObj = {
  //     cardno: this.card_info.no,
  //     expire: this.card_info.year + this.card_info.month,
  //     securitycode: this.card_info.security_code,
  //     holdername: this.card_info.name
  //   }
  //   return new Promise((resolve, reject) => {
  //     EpsilonToken.init(epsilon_contract_Code);
  //     EpsilonToken.getToken(cardObj, this.execTrade);
  //   });

  // }

  invalidData() {
    $('.input').attr('err-msg', null);
    if (!this.entry) {
      return false;
    }
    let ret = true;
    this.card_info.no = this.card_info.no ? this.card_info.no.trim() : this.card_info.no;
    this.card_info.name = this.card_info.name ? this.card_info.name.trim() : this.card_info.name;
    this.card_info.security_code = this.card_info.security_code ? this.card_info.security_code.trim() : this.card_info.security_code;
    if (!this.card_info.no) {
      $('.input.no').attr('err-msg', '*必須項目です');
      ret = false;
    } else if (!this.card_info.no.match(new RegExp(/^[0-9]*$/))) {
      $('.input.no').attr('err-msg', '*半角数字を入力してください。');
      ret = false;
    } else if (this.card_info.no.length < 14) {
      $('.input.no').attr('err-msg', '*14桁以上を入力してください。');
      ret = false;
    } else if (this.koden_only) {
      if (this.card_info.no.substring(0, 1) !== this.Const.CARD_INDUSTRIAL_VISA &&
        this.card_info.no.substring(0, 1) !== this.Const.CARD_INDUSTRIAL_MASTER) {
        $('.input.no').attr('err-msg', '*VISAとmastercardのみご利用になれます');
        ret = false;
      }
    }

    if (!this.card_info.month || !this.card_info.year) {
      $('.input.limit').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.card_info.name) {
      $('.input.name').attr('err-msg', '*必須項目です');
      ret = false;
    } else if (!this.card_info.name.match(new RegExp(/^[A-Za-z0-9 ]*$/))) {
      $('.input.name').attr('err-msg', '*半角英数字を入力してください。');
      ret = false;
    }
    if (!this.card_info.security_code) {
      $('.input.security_code').attr('err-msg', '*必須項目です');
      ret = false;
    } else if (!this.card_info.security_code.match(new RegExp(/^[0-9]*$/))) {
      $('.input.security_code').attr('err-msg', '*半角数字を入力してください。');
      ret = false;
    } else if (this.card_info.security_code.length < 3) {
      $('.input.security_code').attr('err-msg', '*3桁以上を入力してください。');
      ret = false;
    }
    return ret;
  }
  showSecurityView() {
    this.security_view = true;

  }
  closeSecurityView() {
    this.security_view = false;

  }
  isErrorField(element) {
    return !!element.getAttribute('err-msg');
  }

  back() {
    this.router.navigate(['order/confirm/']);
  }

}

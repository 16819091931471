import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/service/http-client.service';
import { BaseGetResponse } from '../interfaces/base';
import { SessionService } from 'src/app/service/session.service';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  private result_data: BaseGetResponse[];
  constructor(
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService
  ) {
  }

  async getData(): Promise<BaseGetResponse[]> {
    const key = 'base_list';

    this.result_data = this.sessionSvc.get(key);

    if (!this.result_data) {
      await this.httpClientService.getBaseList().then(async (response) => {
        this.sessionSvc.save(key, response);
        this.result_data = response;
      }).catch(error => {
      });
    }
    return this.result_data;
  }

  async cacheData(): Promise<boolean> {
    let ret = true;
    const key = 'base_list';

    await this.httpClientService.getBaseList().then(async (response) => {
      this.sessionSvc.save(key, response);
    }).catch(error => {
      ret = false;
    });
    return ret;
  }

}

import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { ZipcodeService } from 'src/app/service/zipcode.service';
import { AddressGetRequest } from 'src/app/interfaces/master';
import {
  MoshuPutRequest, SekoPostRequest, MoshuForPost, SekoContactForGet, SekoContactForPost
} from 'src/app/interfaces/seko';
import { CommonConstants } from 'src/app/const/const';
import { Utils } from 'src/app/const/func-util';
declare var $;

@Component({
  selector: 'app-fam-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  Const = CommonConstants;

  seko_info = this.sessionSvc.get('soke_seko_info');
  message = null;

  constructor(
    private router: Router,
    private sessionSvc: SessionService,
    private httpClientService: HttpClientService,
    private zipcodeSvc: ZipcodeService,
    @Inject(LOCALE_ID) private locale: string,
  ) { }

  ngOnInit(): void {
    if (!this.seko_info.seko_contact) {
      this.seko_info.seko_contact = new SekoContactForGet();
    }
    setTimeout(() => {
      $('.ui.checkbox').checkbox();
    }, 100);
  }

  dateChange(text) {
    if (isNaN(Date.parse(text))) {
      this.seko_info.fuho_site_end_date = null;
    } else {
      this.seko_info.fuho_site_end_date = new Date(text);
    }
  }

  mailFlgChange(flag) {
    this.seko_info.moshu.mail_flg = !flag;
  }

  saveData() {
    if (!this.invalidData()) {
      return;
    }
    const postData = this.getPostData();
    this.httpClientService.updateMoshu(this.seko_info.id, postData)
      .then(async (response) => {
        Utils.log(response);
        this.message =  '登録内容を保存しました。' ;
        $('#message-popup').modal({
          closable: false,
          centered: false,
          detachable: false,
          transition: 'fade',
        }).modal('show');
      })
      .catch(error => {
        this.message =  '登録内容の保存が失敗しました。' ;
        $('#message-popup').modal({
          closable: false,
          centered: false,
          detachable: false,
          transition: 'fade',
        }).modal('show');
      });
  }

  getPostData(): MoshuPutRequest {
    const postData = new MoshuPutRequest();
    postData.name = this.seko_info.moshu.name;
    postData.zip_code = this.seko_info.moshu.zip_code;
    postData.prefecture = this.seko_info.moshu.prefecture;
    postData.address_1 = this.seko_info.moshu.address_1;
    postData.address_2 = this.seko_info.moshu.address_2;
    postData.address_3 = this.seko_info.moshu.address_3;
    postData.tel = this.seko_info.moshu.tel;
    postData.mobile_num = this.seko_info.moshu.mobile_num;
    postData.mail_address = this.seko_info.moshu.mail_address;
    postData.fuho_site_end_date = formatDate(this.seko_info.fuho_site_end_date, 'yyyy-MM-dd', this.locale);
    postData.mail_flg = this.seko_info.moshu.mail_flg;
    postData.soke_site_del_request_flg = this.seko_info.moshu.soke_site_del_request_flg;

    postData.seko_contact = new SekoContactForPost();
    postData.seko_contact.name = this.seko_info.seko_contact.name;
    postData.seko_contact.zip_code = this.seko_info.seko_contact.zip_code;
    postData.seko_contact.prefecture = this.seko_info.seko_contact.prefecture;
    postData.seko_contact.address_1 = this.seko_info.seko_contact.address_1;
    postData.seko_contact.address_2 = this.seko_info.seko_contact.address_2;
    postData.seko_contact.address_3 = this.seko_info.seko_contact.address_3;
    postData.seko_contact.tel = this.seko_info.seko_contact.tel;
    postData.seko_contact.mobile_num = this.seko_info.seko_contact.mobile_num;
    postData.seko_contact.mail_address = this.seko_info.seko_contact.mail_address;
    return postData;
  }

  invalidData() {
    $('.input').attr('err-msg', null);
    if (!this.seko_info.moshu) {
      return false;
    }
    let ret = true;
    if (!this.seko_info.moshu.zip_code) {
      $('.input.moshu_zip_code').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.seko_info.moshu.prefecture) {
      $('.input.moshu_prefecture').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.seko_info.moshu.address_1) {
      $('.input.moshu_address_1').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.seko_info.moshu.address_2) {
      $('.input.moshu_address_2').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.seko_info.fuho_site_end_date) {
      $('.input.fuho_site_end_date').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.seko_info.seko_contact.name) {
      $('.input.seko_contact_name').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.seko_info.seko_contact.kojin_relationship) {
      $('.input.seko_contact_kojin_relationship').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.seko_info.seko_contact.zip_code) {
      $('.input.seko_contact_zip_code').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.seko_info.seko_contact.prefecture) {
      $('.input.seko_contact_prefecture').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.seko_info.seko_contact.address_1) {
      $('.input.seko_contact_address_1').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.seko_info.seko_contact.address_2) {
      $('.input.seko_contact_address_2').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.seko_info.seko_contact.tel && !this.seko_info.seko_contact.mobile_num) {
      $('.input.seko_contact_tel').attr('err-msg', '*連絡先の電話番号と携帯電話番号のどちらか必須です');
      ret = false;
    }
    if (!this.seko_info.seko_contact.mail_address) {
      $('.input.seko_contact_mail_address').attr('err-msg', '**必須項目です');
      ret = false;
    }

    if (!ret) {
      return ret;
    }
    const reg = new RegExp(this.Const.MAIL_PATTERN);
    if (this.seko_info.moshu.mail_address && !this.seko_info.moshu.mail_address.match(reg)) {
      $('.input.moshu_mail_address').attr('err-msg', '*メール形式が正しくありません');
      ret = false;
    }
    if (!this.seko_info.seko_contact.mail_address.match(reg)) {
      $('.input.seko_contact_mail_address').attr('err-msg', '*メール形式が正しくありません');
      ret = false;
    }
    return ret;
  }

  zipcodeChange() {
    const request = new AddressGetRequest();
    request.zip_code = this.seko_info.moshu.zip_code;
    this.zipcodeSvc.getData(request).then(address => {
      if (address) {
        this.seko_info.moshu.prefecture = address.prefecture;
        this.seko_info.moshu.address_1 = address.address_1;
        this.seko_info.moshu.address_2 = address.address_2;
      }
    }).catch(error => { });
  }


  contactZipcodeChange() {
    const request = new AddressGetRequest();
    request.zip_code = this.seko_info.seko_contact.zip_code;
    this.zipcodeSvc.getData(request).then(address => {
      if (address) {
        this.seko_info.seko_contact.prefecture = address.prefecture;
        this.seko_info.seko_contact.address_1 = address.address_1;
        this.seko_info.seko_contact.address_2 = address.address_2;
      }
    }).catch(error => { });
  }

  isErrorField(element) {
    return !!element.getAttribute('err-msg');
  }

  closePopup() {
    $('#message-popup').modal('hide');
    setTimeout(() => {
    this.message = null;
    }, 500);
  }

}

import { Component, OnInit } from '@angular/core';
import { HttpClientService } from 'src/app/service/http-client.service';
import { SessionService } from 'src/app/service/session.service';
import { LoaderService } from 'src/app/service/loader.service';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import { HenreihinPostRequest, HenreiSelectStatusPostRequest } from 'src/app/interfaces/henreihin';
import { isNullOrUndefined } from 'util';
declare var $;

@Component({
  selector: 'app-fam-henrei',
  templateUrl: './henrei.component.html',
  styleUrls: ['./henrei.component.scss']
})
export class HenreiComponent implements OnInit {
  Const = CommonConstants;
  Utils = Utils;

  seko_info = this.sessionSvc.get('soke_seko_info');
  homei_data_all = null;
  homei_list = null;
  confirm_list = null;
  homei_filter = {
    kumotsu: true, koden: this.haveKoden()
  };
  selected_homei = null;
  henreihin_sum1 = 0;
  henreihin_sum2 = 0;
  message = null;
  item_data_all = null;
  item_list = null;
  item_filter = {
    price_from: null, price_to: null
  };

  host_url = window.location.origin;

  price_from = null;
  price_to = null;

  constructor(
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    ) { }

  async ngOnInit() {
    await this.getHomeichoList();
    this.getItem();
  }

  async getHomeichoList() {
    if (!this.seko_info) {
      return;
    }
    await this.httpClientService.getHomeicho(this.seko_info.id).then((response) => {
      Utils.log(response);
      this.homei_data_all = response;
      this.filterHomeiList();
      Utils.log(this.homei_list);

    }).catch(error => {
    });
  }

  filterHomeiList() {
    this.homei_list = new Array();
    this.confirm_list = new Array();
    this.henreihin_sum1 = 0;
    this.henreihin_sum2 = 0;
    if (!this.homei_data_all || !this.homei_data_all.length) {
      return;
    }
    for (const homei_data of this.homei_data_all) {
      if (homei_data.cancel_ts) {
        continue;
      }
      for (const homei_detail of homei_data.details) {
        if (!homei_detail.kumotsu && !homei_detail.koden) {
          continue;
        }
        let okurinushi_name = '';
        let image_file = null;
        if (homei_detail.kumotsu) {
          okurinushi_name = homei_detail.kumotsu.okurinushi_name;
          if (homei_detail.kumotsu.henrei_kumotsu) {
            image_file = this.host_url + '/media/items/' + this.seko_info.seko_company.id +
              '/' + homei_detail.kumotsu.henrei_kumotsu.henreihin_hinban + '.jpg';
            if (homei_detail.kumotsu.henrei_kumotsu.select_status === this.Const.SELECT_STATUS_ID_CONFIRM) {
              this.henreihin_sum1 += homei_detail.kumotsu.henrei_kumotsu.henreihin_price;
            } else {
              this.henreihin_sum2 += homei_detail.kumotsu.henrei_kumotsu.henreihin_price;
            }
            if (homei_detail.kumotsu.henrei_kumotsu.select_status === this.Const.SELECT_STATUS_ID_SELECTING) {
              this.confirm_list.push({
                entry_name: homei_data.entry_name,
                entry_zip_code: this.zipCode(homei_data.entry_zip_code),
                entry_prefecture: homei_data.entry_prefecture,
                entry_address_1: homei_data.entry_address_1,
                entry_address_2: homei_data.entry_address_2,
                entry_address_3: homei_data.entry_address_3,
                item_name: homei_detail.kumotsu.henrei_kumotsu.henreihin_name,
                item_price: homei_detail.kumotsu.henrei_kumotsu.henreihin_price,
                item_hinban: homei_detail.kumotsu.henrei_kumotsu.henreihin_hinban,
                image_file: image_file,
              });
            }
          }
        } else if (homei_detail.koden) {
          if (homei_detail.koden.henrei_koden) {
            image_file = this.host_url + '/media/items/' + this.seko_info.seko_company.id +
              '/' + homei_detail.koden.henrei_koden.henreihin_hinban + '.jpg';
            if (homei_detail.koden.henrei_koden.select_status === this.Const.SELECT_STATUS_ID_CONFIRM) {
              this.henreihin_sum1 += homei_detail.koden.henrei_koden.henreihin_price;
            } else {
              this.henreihin_sum2 += homei_detail.koden.henrei_koden.henreihin_price;
            }
            if (homei_detail.koden.henrei_koden.select_status === this.Const.SELECT_STATUS_ID_SELECTING) {
              this.confirm_list.push({
                entry_name: homei_data.entry_name,
                entry_zip_code: this.zipCode(homei_data.entry_zip_code),
                entry_prefecture: homei_data.entry_prefecture,
                entry_address_1: homei_data.entry_address_1,
                entry_address_2: homei_data.entry_address_2,
                entry_address_3: homei_data.entry_address_3,
                item_name: homei_detail.koden.henrei_koden.henreihin_name,
                item_price: homei_detail.koden.henrei_koden.henreihin_price,
                item_hinban: homei_detail.koden.henrei_koden.henreihin_hinban,
                image_file: image_file,
              });
            }
          }
        }
        if ((homei_detail.item.service.id === this.Const.SERVICE_ID_CHOBUN) ||
          (!this.homei_filter.kumotsu && homei_detail.item.service.id === this.Const.SERVICE_ID_KUMOTSU) ||
          (!this.homei_filter.koden && homei_detail.item.service.id === this.Const.SERVICE_ID_KODEN) ||
          (homei_detail.item.service.id === this.Const.SERVICE_ID_MESSAGE)) {
          continue;
        }

        this.homei_list.push({
          entry_id: homei_data.id,
          entry_name: homei_data.entry_name,
          entry_zip_code: this.zipCode(homei_data.entry_zip_code),
          entry_prefecture: homei_data.entry_prefecture,
          entry_address_1: homei_data.entry_address_1,
          entry_address_2: homei_data.entry_address_2,
          entry_address_3: homei_data.entry_address_3,
          entry_tel: homei_data.entry_tel,
          okurinushi_name: okurinushi_name,
          entry_detail_id: homei_detail.id,
          service_id: homei_detail.item.service.id,
          service_name: homei_detail.item.service.name,
          item_name: homei_detail.item_name,
          price: homei_detail.item_unit_price,
          koden: homei_detail.koden,
          kumotsu: homei_detail.kumotsu,
          message: homei_detail.message,
          image_file: image_file,
        });

      }
    }
    setTimeout(() => {
      $('.accordion').accordion();
      $('.ui.checkbox').checkbox();
    }, 100);
  }

  imageLoadError(item) {
    item.image_file = null;
  }

  async getItem() {
    this.item_data_all = new Array();
    if (this.seko_info && this.seko_info.items) {
      this.seko_info.items.forEach(value => {
        if (value.item.service === this.Const.SERVICE_ID_HENREIHIN &&
          Utils.isBetweenTerm(value.item.begin_date, value.item.end_date)) {
          value.item.image_file = this.host_url + '/media/items/' + this.seko_info.seko_company.id + '/' + value.item.hinban + '.jpg';
          this.item_data_all.push(value.item);
        }
      });
    }
    Utils.log(this.item_data_all);
  }

  filterItemList() {
    this.item_list = new Array();
    if (!this.item_data_all || !this.item_data_all.length) {
      return;
    }
    for (const item_data of this.item_data_all) {
        if ((isNullOrUndefined(this.item_filter.price_from) || item_data.item_price >= this.item_filter.price_from) &&
          (isNullOrUndefined(this.item_filter.price_to) || item_data.item_price <= this.item_filter.price_to)) {
          this.item_list.push(item_data);
        }
    }
  }

  showItemList(homei) {
    if (!homei) {
      return;
    }
    if (this.getHenreiClassName(homei) === 'grey') {
      return;
    }
    this.selected_homei = homei;
    if (this.item_data_all) {
      let henreihin = null;
      if (homei.koden && homei.koden.henrei_koden) {
        henreihin = homei.koden.henrei_koden;
      } else if (homei.kumotsu && homei.kumotsu.henrei_kumotsu) {
        henreihin = homei.kumotsu.henrei_kumotsu;
      }
      for (const item of this.item_data_all) {
        if (henreihin && henreihin.henreihin === item.id) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      }
    }
    this.filterItemList();
    $('#item-list').modal({
      centered: false,
      detachable: false,
      transition: 'fade',
    }).modal('show');
  }

  selectItem(item) {
    if (!item) {
      return;
    }
    $('#item-list').modal('hide');

    const postData = new HenreihinPostRequest();
    postData.henreihin = item.id;
    postData.henreihin_hinban = item.hinban;
    postData.henreihin_name = item.name;
    postData.henreihin_price = item.item_price;
    postData.henreihin_tax = Utils.calcTax(
      item.item_price, item.tax.tax_pct, this.seko_info.seko_company.calc_type
    );
    postData.henreihin_tax_pct = item.tax.tax_pct;
    postData.keigen_flg = item.tax.keigen_flg;
    let func_name = null;
    let is_add = false;
    if (this.selected_homei.koden) {
      if (this.selected_homei.koden.henrei_koden) {
        if (this.selected_homei.koden.henrei_koden.henreihin === item.id) {
          return;
        }
        func_name = 'updateHenreihinKoden';
        for (const supplier of item.item_suppliers) {
          if (supplier.default_supplier_flg) {
            postData.supplier = supplier.supplier.id;
            break;
          }
        }
      } else {
        is_add = true;
        postData.detail_koden = this.selected_homei.entry_detail_id;
        func_name = 'addHenreihinKoden';
      }
    } else if (this.selected_homei.kumotsu) {
      if (this.selected_homei.kumotsu.henrei_kumotsu) {
        if (this.selected_homei.kumotsu.henrei_kumotsu.henreihin === item.id) {
          return;
        }
        func_name = 'updateHenreihinKumotsu';
        for (const supplier of item.item_suppliers) {
          if (supplier.default_supplier_flg) {
            postData.supplier = supplier.supplier.id;
            break;
          }
        }
      } else {
        is_add = true;
        postData.detail_kumotsu = this.selected_homei.entry_detail_id;
        func_name = 'addHenreihinKumotsu';
      }
    } else {
      return;
    }
    if (is_add) {
      this.httpClientService[func_name](postData)
        .then(async (response) => {
          Utils.log(response);
          await this.getHomeichoList();
        })
        .catch(error => {
        });
    } else {
      this.httpClientService[func_name](this.selected_homei.entry_detail_id, postData)
        .then(async (response) => {
          Utils.log(response);
          await this.getHomeichoList();
        })
        .catch(error => {
        });
    }
    setTimeout(() => {
      this.selected_homei = null;
    }, 500);
  }

  closeItemList() {
    $('#item-list').modal('hide');
    setTimeout(() => {
      this.selected_homei = null;
    }, 500);
  }

  deleteConfirm(homei) {
    if (!homei) {
      return;
    }
    this.selected_homei = homei;
    $('#henrei-confirm').modal({
      closable: false,
      centered: false,
      detachable: false,
      transition: 'fade',
    }).modal('show');
  }
  cancelConfirm() {
    $('#henrei-confirm').modal('hide');
    this.selected_homei = null;
  }

  deleteHenrei() {
    if (!this.selected_homei) {
      return;
    }
    let func_name = null;
    if (this.selected_homei.koden) {
      func_name = 'deleteHenreihinKoden';
    } else if (this.selected_homei.kumotsu) {
      func_name = 'deleteHenreihinKumotsu';
    } else {
      return;
    }
    $('#henrei-confirm').modal('hide');
    this.httpClientService[func_name](this.selected_homei.entry_detail_id)
      .then(async (response) => {
        Utils.log(response);
        await this.getHomeichoList();
      })
      .catch(error => {
        this.message =  '返礼品取消処理が失敗しました。' ;
        $('#message-popup').modal({
          closable: false,
          centered: false,
          detachable: false,
          transition: 'fade',
        }).modal('show');
      });
  }

  showConfirmList() {
    if (!this.homei_data_all) {
      return;
    }
    $('#confirm-list').modal({
      closable: false,
      centered: false,
      detachable: false,
      transition: 'fade',
    }).modal('show');
  }

  confirmHenrei() {
    if (!this.confirm_list || !this.confirm_list.length) {
      return;
    }
    const postData = new HenreiSelectStatusPostRequest();
    postData.seko = this.seko_info.id;
    $('#confirm-list').modal('hide');
    this.httpClientService.confirmHenreihin(postData)
      .then(async (response) => {
        Utils.log(response);
        this.message =  'お申込みを受け付けました。' ;
        $('#message-popup').modal({
          closable: false,
          centered: false,
          detachable: false,
          transition: 'fade',
        }).modal('show');
        await this.getHomeichoList();
      })
      .catch(error => {
        this.message =  'お申込み処理が失敗しました。' ;
        $('#message-popup').modal({
          closable: false,
          centered: false,
          detachable: false,
          transition: 'fade',
        }).modal('show');
      });
  }
  closeConfirmList() {
    $('#confirm-list').modal('hide');
    this.selected_homei = null;
  }

  zipCode(zip_code: string) {
    if (!zip_code || zip_code.length !== 7) {
      return zip_code;
    }
    return zip_code.substring(0, 3) + '-' + zip_code.substring(3, 7);
  }

  getClassName(service_id) {
    if (!service_id) {
      return '';
    }
    switch (service_id) {
      case this.Const.SERVICE_ID_CHOBUN:
        return 'chobun';
      case this.Const.SERVICE_ID_KUMOTSU:
        return 'kumotsu';
      case this.Const.SERVICE_ID_KODEN:
        return 'koden';
      case this.Const.SERVICE_ID_MESSAGE:
        return 'msg';
      default:
        return '';
    }

  }

  getHenreiClassName(homei) {
    if (!homei) {
      return '';
    }
    let henrei = null;
    if (homei.koden) {
      henrei = homei.koden.henrei_koden;
    } else if (homei.kumotsu) {
      henrei = homei.kumotsu.henrei_kumotsu;
    }
    if (!henrei) {
      return 'pink';
    }
    switch (henrei.select_status) {
      case this.Const.SELECT_STATUS_ID_NONE:
        return 'pink';
      case this.Const.SELECT_STATUS_ID_SELECTING:
        return 'blue';
      case this.Const.SELECT_STATUS_ID_CONFIRM:
        return 'grey';
      default:
        return '';
    }

  }

  getHenreiName(homei) {
    if (!homei) {
      return '';
    }
    let henrei = null;
    if (homei.koden) {
      henrei = homei.koden.henrei_koden;
    } else if (homei.kumotsu) {
      henrei = homei.kumotsu.henrei_kumotsu;
    }
    if (!henrei) {
      return '返礼品を送る';
    }
    switch (henrei.select_status) {
      case this.Const.SELECT_STATUS_ID_NONE:
        return '返礼品を送る';
      case this.Const.SELECT_STATUS_ID_SELECTING:
        return '返礼品選択中';
      case this.Const.SELECT_STATUS_ID_CONFIRM:
        if (henrei.order_status === this.Const.ORDER_STATUS_ID_ORDERED || henrei.order_status === this.Const.ORDER_STATUS_ID_CONFIRMED) {
          return '返礼品発注済';
        } else {
          return '返礼品選択済';
        }
      default:
        return '';
    }

  }

  closePopup() {
    $('#message-popup').modal('hide');
    setTimeout(() => {
    this.message = null;
    }, 500);
  }

  haveKoden() {
    if (!this.seko_info || !this.seko_info.services || !this.seko_info.services.length) {
      return false;
    }

    for (const service of this.seko_info.services) {
      if (service.hall_service.id === CommonConstants.SERVICE_ID_KODEN) {
        return true;
      }
    }
    return false;
  }
}

import { Component, OnInit, Inject, LOCALE_ID, ViewChild, ElementRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { formatDate } from '@angular/common';
import { ConfirmDialogComponent } from 'src/app/pages/common/confirm-dialog/confirm-dialog.component';
import { HttpClientService } from 'src/app/service/http-client.service';
import { SessionService } from 'src/app/service/session.service';
import { LoaderService } from 'src/app/service/loader.service';
import { BaseService } from 'src/app/service/base.service';
import { CalendarOptionsDate } from 'src/app/components/com-calendar/com-calendar.component';
import { CompanyServicePostRequest, ServiceReceptionTermGetRequest, ServiceReceptionTermPostRequest } from 'src/app/interfaces/service';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import { ServiceReceptionTermEdit } from 'src/app/interfaces/service';
import { TaxGetResponse } from 'src/app/interfaces/master';
import { ItemGetRequest, ItemGetResponse, ItemImagePostRequest, ItemPostRequest } from 'src/app/interfaces/item';
import { SupplierGetRequest } from 'src/app/interfaces/supplier';
import { isNullOrUndefined } from 'util';
import Encoding from 'encoding-japanese';
declare var $;

@Component({
  selector: 'app-com-item-service',
  templateUrl: './item-service.component.html',
  styleUrls: ['./item-service.component.scss']
})
export class ItemServiceComponent implements OnInit {
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;

  Const = CommonConstants;

  login_company = Utils.getLoginCompany();
  login_info = this.sessionSvc.get('staff_login_info');
  schedule_list = this.sessionSvc.get('schedule_list');
  service_list = null;
  tax_list = this.sessionSvc.get('tax_list');
  companyCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  scheduleCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  unitCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  limitTimeCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  taxCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  calendarOptionDate: CalendarOptionsDate = new CalendarOptionsDate();
  company_list = Utils.getCompanyList();
  service_reception_term_edit: Array<ServiceReceptionTermEdit> = null;
  service_reception_term_list = null;
  base_list = null;
  company_id = null;
  select_base_id = null;
  select_base_name = null;

  item_list = new Array();
  item_list_chobun = new Array();
  item_list_kumotsu = new Array();
  item_list_henreihin = new Array();
  item_edit = null;
  item_edit_type = 1;
  item_tab_index = 1;
  select_item_id = null;
  select_item_name = null;
  select_service_id = null;
  select_service_name = null;

  supplier_list = new Array();
  message = null;
  message_sub = null;
  errField = new Map();

  page_per_count = 15;
  pagination_base = {
    pages: new Array(),
    current: 0
  };
  processing = false;

  host_url = window.location.origin;

  item_editing = false;

  constructor(
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private baseSvc: BaseService,
    private loaderSvc: LoaderService,
    private dialog: MatDialog,
    @Inject(LOCALE_ID) private locale: string,
  ) {
    this.loaderSvc.call();
  }

  async ngOnInit() {
    this.initControl();
    this.initJSControl();
    await this.getBaseData(this.company_id);
    await this.initService();
    this.getSupplierData(this.company_id);
  }

  initControl() {
    if (this.company_list) {
      this.companyCombo.values = this.company_list.map(value => ({ name: value.base_name, value: value.id, data: value }));
      if (this.login_company.base_type === CommonConstants.BASE_TYPE_COMPANY) {
        this.service_list = this.login_company.services;
        this.company_id = this.login_company.id;
      } else {
        this.companyCombo.disableSearch = false;
        this.company_id = this.company_list[0].id;
        this.service_list = this.company_list[0].services;
      }
    }
    this.scheduleCombo.values = this.schedule_list.map(value => ({ name: value.name, value: value.id }));
    this.unitCombo.values = [
      { name: '日', value: this.Const.SERVICE_RECEPTION_UNIT_DAY },
      { name: '時間', value: this.Const.SERVICE_RECEPTION_UNIT_HOUR }
    ];
    for (let i = 0; i < 24; i++) {
      const item = { name: i.toString(), value: i };
      this.limitTimeCombo.values.push(item);
    }
    this.taxCombo.values = this.tax_list.map(value => ({ name: value.tax_pct.toString(), value: value.id }));
  }

  initJSControl() {
    setTimeout(() => {
      $('.tabular.menu .item').tab();
    }, 100);
  }

  async getBaseData(base_id) {
    if (!this.loaderSvc.isLoading) {
      this.loaderSvc.call();
    }
    await this.baseSvc.cacheData().then(async ret => {
      if (ret) {
        this.company_list = Utils.getCompanyList();
        const selected_base = this.company_list.find(v => v.id === base_id);
        const bases = Utils.getAllBaseList(selected_base);
        this.base_list = new Array();
        for (const base of bases) {
          if (base.data.base_type !== this.Const.BASE_TYPE_PLACE_OTHER) {
            this.base_list.push(base.data);
          }

        }
        this.calcPagination(this.base_list, this.pagination_base);
        this.loaderSvc.dismiss();
      } else {
        this.loaderSvc.dismiss();
      }
    }).catch(() => {
      this.loaderSvc.dismiss();
    });
  }

  async getSupplierData(base_id) {
    const request = new SupplierGetRequest();
    request.company_id = base_id;
    this.httpClientService.getSupplierList(request).then((response) => {
      Utils.log(response);
      this.supplier_list = response;
      this.loaderSvc.dismiss();

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }

  initService() {
    for (const service of this.service_list) {
      service.selected = false;
      switch (service.id) {
        case this.Const.SERVICE_ID_CHOBUN:
          service.icon = 'map';
          break;
        case this.Const.SERVICE_ID_KUMOTSU:
          service.icon = 'spa';
          break;
        case this.Const.SERVICE_ID_KODEN:
          service.icon = 'award';
          break;
        case this.Const.SERVICE_ID_HENREIHIN:
          service.icon = 'gift';
          break;
        case this.Const.SERVICE_ID_MESSAGE:
          service.icon = 'comment dots';
          break;
        default:
          service.icon = 'stop';

      }
    }

  }

  calcPagination(list, pagination) {
    pagination.pages = new Array();
    pagination.current = 0;
    if (!list || !list.length) {
      return;
    }
    const count = Math.ceil(list.length / this.page_per_count);
    for (let i = 1; i <= count; i++) {
      pagination.pages.push(i);
    }
    pagination.current = 1;
  }

  pageTo(pagination, page_num) {
    pagination.current = page_num;
  }

  async companyChange(event) {
    if (!event || !event.data) {
      return;
    }
    await this.getBaseData(event.data.id);
    this.service_list = event.data.services;
    this.initService();
    this.getSupplierData(event.data.id);

  }

  showService(base) {
    if (base) {
      this.select_base_id = base.id;
      this.select_base_name = base.base_name;
    } else {
      this.select_base_id = null;
      this.select_base_name = null;
    }
    for (const service of this.service_list) {
      service.selected = false;
      if (base && base.services) {
        const selected_item = base.services.find(v => v.id === service.id);
        if (selected_item) {
          service.selected = true;
        }
      }
    }
    this.message = null;
    $('#service-edit').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  selectService(service) {
    service.selected = !service.selected;
  }

  async saveService() {
    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }
    this.processing = true;
    const postData = this.getServicePostData();
    $('#service-edit').modal('hide');
    this.loaderSvc.call();
    let ret = true;
    if (this.select_base_id) {
      ret = await this.addService(this.select_base_id, postData);
    } else {
      for (const base of this.base_list) {
        if (base.base_type === this.Const.BASE_TYPE_DEPART) {
          ret = await this.addService(base.id, postData);
        }
        if (!ret) {
          break;
        }

      }
    }
    await this.getBaseData(this.company_id);
    if (ret) {
      this.sessionSvc.save('message', { type: 'info', title: 'サービス情報を保存しました。' });
    } else {
      this.sessionSvc.save('message', { type: 'error', title: 'サービス情報の保存が失敗しました。' });
    }
    this.processing = false;
    this.loaderSvc.dismiss();
  }

  async addService(base_id, postData): Promise<boolean> {
    let ret = true;
    await this.httpClientService.addCompanyService(base_id, postData)
      .then(async (response) => {
        if (postData.service_ids.includes(this.Const.SERVICE_ID_KODEN)) {
          this.saveHideItem(base_id, this.Const.SERVICE_ID_KODEN);
        }
        if (postData.service_ids.includes(this.Const.SERVICE_ID_MESSAGE)) {
          this.saveHideItem(base_id, this.Const.SERVICE_ID_MESSAGE);
        }
      })
      .catch(error => {
        ret = false;
      });
    return ret;

  }

  getServicePostData() {
    const postRequest = new CompanyServicePostRequest();
    postRequest.service_ids = new Array();
    for (const service of this.service_list) {
      if (service.selected) {
        postRequest.service_ids.push(service.id);
      }
    }

    return postRequest;
  }

  saveHideItem(base_id, service_id) {
    const request = new ItemGetRequest();
    request.base_id = base_id;
    request.service_id = service_id;
    this.httpClientService.getItemList(request).then((response) => {
      Utils.log(response);
      if (!response || !response.length) {
        const postRequest = new ItemPostRequest();
        postRequest.base = base_id;
        postRequest.service = service_id;
        if (service_id === this.Const.SERVICE_ID_KODEN) {
          postRequest.name = '香典';
        } else {
          postRequest.name = '追悼メッセージ';
        }
        postRequest.item_price = 0;
        postRequest.hinban = null;
        postRequest.tax = 1;
        postRequest.begin_date = formatDate(new Date(), 'yyyy-MM-dd', this.locale);
        postRequest.end_date = '2999-12-31';
        postRequest.fdn_code = null;
        postRequest.display_num = 1;
        this.httpClientService.addItem(postRequest);
      }

    }).catch(error => {
    });

  }

  async showServiceReceptionTerm(base) {
    if (!base) {
      return;
    }
    this.select_base_id = base.id;
    this.select_base_name = base.base_name;
    await this.getServiceReceptionTermList(base.id);
    this.initServiceReceptionTermList();
    for (const service_reception_term of this.service_reception_term_edit) {
      const getServiceReceptionTerm = this.service_reception_term_list.find(v => v.service === service_reception_term.service);
      if (getServiceReceptionTerm) {
        service_reception_term.department = getServiceReceptionTerm.department;
        service_reception_term.schedule = getServiceReceptionTerm.schedule;
        service_reception_term.unit = getServiceReceptionTerm.unit;
        service_reception_term.limit_time = getServiceReceptionTerm.limit_time;
        service_reception_term.limit_hour = getServiceReceptionTerm.limit_hour;
      }
    }
    this.message = null;
    $('#service-limit-edit').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  initServiceReceptionTermList() {
    this.service_reception_term_edit = new Array();
    for (const service of this.service_list) {
      switch (service.id) {
        case this.Const.SERVICE_ID_CHOBUN:
        case this.Const.SERVICE_ID_KUMOTSU:
        case this.Const.SERVICE_ID_KODEN:
        case this.Const.SERVICE_ID_MESSAGE:
          const serviceReceptionTerm = new ServiceReceptionTermEdit();
          serviceReceptionTerm.name = service.name;
          serviceReceptionTerm.department = this.select_base_id;
          serviceReceptionTerm.service = service.id;
          serviceReceptionTerm.schedule = this.schedule_list[0].id;
          serviceReceptionTerm.unit = this.Const.SERVICE_RECEPTION_UNIT_DAY;
          serviceReceptionTerm.limit_time = 0;
          serviceReceptionTerm.limit_hour = null;
          this.service_reception_term_edit.push(serviceReceptionTerm);
          break;
      }
    }
  }

  async getServiceReceptionTermList(base_id) {
    if (!this.loaderSvc.isLoading) {
      this.loaderSvc.call();
    }
    const request = new ServiceReceptionTermGetRequest();
    request.department_id = base_id;
    await this.httpClientService.getServiceReceptionTermList(request).then((response) => {
      Utils.log(response);
      this.service_reception_term_list = response;
      this.loaderSvc.dismiss();

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }

  async saveServiceReceptionTerm() {
    this.sessionSvc.clear('message');
    if (!this.serviceReceptionTermValidate()) {
      return;
    }
    if (!this.select_base_id) {
      return;
    }
    if (this.processing) {
      return;
    }
    this.processing = true;

    const postData = this.getServiceReceptionTermPostData();
    $('#service-limit-edit').modal('hide');
    this.loaderSvc.call();
    let ret = true;
    ret = await this.addServiceReceptionTerm(this.select_base_id, postData);
    if (ret) {
      this.sessionSvc.save('message', { type: 'info', title: 'サービス情報を保存しました。' });
    } else {
      this.sessionSvc.save('message', { type: 'error', title: 'サービス情報の保存が失敗しました。' });
    }
    this.processing = false;
    this.loaderSvc.dismiss();

  }

  serviceReceptionTermValidate() {
    const msgs = new Array();
    this.message = null;
    for (const service_reception_term of this.service_reception_term_edit) {
      if (!service_reception_term.schedule) {
        msgs.push(service_reception_term.name + 'の基準区分');
      }
      if (isNullOrUndefined(service_reception_term.limit_hour)) {
        msgs.push(service_reception_term.name + 'の時間');
      }
      if (!service_reception_term.unit) {
        msgs.push(service_reception_term.name + 'の単位');
      }
      if (service_reception_term.unit === this.Const.SERVICE_RECEPTION_UNIT_DAY && service_reception_term.limit_time === null) {
        msgs.push(service_reception_term.name + 'の締切時刻');
      }
    }

    if (msgs.length > 0) {
      this.message = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: msgs.join(',')
      };
      return false;
    }
    return true;
  }

  getServiceReceptionTermPostData() {
    const postRequest = new ServiceReceptionTermPostRequest();
    postRequest.service_reception_terms = this.service_reception_term_edit;
    return postRequest;
  }

  async addServiceReceptionTerm(base_id, postData): Promise<boolean> {
    let ret = true;
    await this.httpClientService.addServiceReceptionTerm(base_id, postData)
      .then(async (response) => {
      })
      .catch(error => {
        ret = false;
      });
    return ret;

  }

  async showItemList(base) {
    this.message = null;
    this.message_sub = null;
    if (!base) {
      return;
    }
    $('.tabular.menu .item').tab('change tab', 'chobun');
    this.item_tab_index = 1;
    this.select_service_id = this.Const.SERVICE_ID_CHOBUN;
    this.select_service_name = '弔文';
    this.select_base_id = base.id;
    this.select_base_name = base.base_name;
    await this.getItemList(base.id);
    $('#item-list').modal({
      allowMultiple: true,
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  async getItemList(base_id) {
    if (!this.loaderSvc.isLoading) {
      this.loaderSvc.call();
    }
    const request = new ItemGetRequest();
    request.base_id = base_id;
    await this.httpClientService.getItemList(request).then((response) => {
      Utils.log(response);
      for (const item of response) {
        item.image_file = this.host_url + '/media/items/' + this.company_id + '/' + item.hinban + '.jpg';
      }
      this.item_list_chobun = response.filter(v => v.service === this.Const.SERVICE_ID_CHOBUN);
      this.item_list_kumotsu = response.filter(v => v.service === this.Const.SERVICE_ID_KUMOTSU);
      this.item_list_henreihin = response.filter(v => v.service === this.Const.SERVICE_ID_HENREIHIN);
      this.loaderSvc.dismiss();

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }

  showItemEdit(event, item = null, service_id = null) {
    this.message = null;
    this.message_sub = null;
    if (!item) {
      this.item_edit_type = 1;
      this.item_edit = new ItemGetResponse();
      this.item_edit.base = this.select_base_id;
      this.item_edit.service = service_id;
      this.item_edit.tax = new TaxGetResponse();
      this.item_edit.tax.id = 3;
      // dateBeginEm.clear();
      // dateEndEm.clear();
    } else {
      if (event.target.classList.contains('operation') || event.target.classList.contains('icon')) {
        return;
      }
      this.item_edit_type = 2;
      this.item_edit = JSON.parse(JSON.stringify(item));
      // dateBeginEm.setDate(this.item_edit.begin_date);
      // dateEndEm.setDate(this.item_edit.end_date);
    }
    // comTaxEm.setValue(this.item_edit.tax);
    this.item_editing = true;
    $('#item-edit').modal({
      allowMultiple: true,
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  async saveItem() {
    this.message = null;
    this.message_sub = null;
    if (this.processing) {
      return;
    }
    this.processing = true;
    const check = await this.itemValidate();
    if (!check) {
      this.processing = false;
      return;
    }
    const postData = this.getItemPostData();

    let unique_err = false;
    this.loaderSvc.call();
    if (postData.id) {
      await this.httpClientService.updateItem(postData.id, postData)
        .then(async (response) => {
          Utils.log(response);
          await this.getItemList(this.select_base_id);
          this.message = { type: 'info', title: '商品情報を保存しました。' };
          this.processing = false;
        })
        .catch(error => {
          if (error.non_field_errors) {
            unique_err = true;
            if (error.non_field_errors[0].indexOf('hinban') > 0) {
              this.message_sub = { type: 'warning', title: '品番がすでに使われています。' };
            } else {
              this.message_sub = { type: 'warning', title: 'FDNコードがすでに使われています。' };
            }
            this.loaderSvc.dismiss();
            this.processing = false;
            this.sessionSvc.clear('message');
            return;
          }
          this.message = { type: 'error', title: '商品情報の保存が失敗しました。' };
          this.processing = false;
          this.loaderSvc.dismiss();
          this.sessionSvc.clear('message');
        });
    } else {
      await this.httpClientService.addItem(postData)
        .then(async (response) => {
          Utils.log(response);
          await this.getItemList(this.select_base_id);
          this.message = { type: 'info', title: '商品情報を保存しました。' };
          this.processing = false;
        })
        .catch(error => {
          if (error.non_field_errors) {
            unique_err = true;
            if (error.non_field_errors[0].indexOf('hinban') > 0) {
              this.message_sub = { type: 'warning', title: '品番がすでに使われています。' };
            } else {
              this.message_sub = { type: 'warning', title: 'FDNコードがすでに使われています。' };
            }
            this.loaderSvc.dismiss();
            this.processing = false;
            this.sessionSvc.clear('message');
            return;
          }
          this.message = { type: 'error', title: '商品情報の保存が失敗しました。' };
          this.loaderSvc.dismiss();
          this.processing = false;
          this.sessionSvc.clear('message');
        });
    }
    if (!unique_err) {
      $('#item-edit').modal('hide');
      this.item_editing = false;
      setTimeout(() => {
        if (!this.item_editing) {
          this.item_edit = null;
        }
      }, 400);
    }
  }

  async itemValidate() {
    let ret = true;
    const msgs = new Array();
    if (!this.item_edit.hinban) {
      msgs.push('品番');
    }
    if (!this.item_edit.name) {
      msgs.push('名称');
    }
    if (this.item_edit.item_price === undefined || this.item_edit.item_price === null) {
      msgs.push('税込価格');
    }
    if (!this.item_edit.tax) {
      msgs.push('税区分');
    }
    if (!this.item_edit.begin_date) {
      msgs.push('適用開始日');
    }
    if (!this.item_edit.end_date) {
      msgs.push('適用終了日');
    }
    if (isNullOrUndefined(this.item_edit.display_num)) {
      msgs.push('表示順');
    }

    if (msgs.length > 0) {
      this.message_sub = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: msgs.join(',')
      };
      return false;
    }
    if (this.item_edit.begin_date &&
      this.item_edit.end_date &&
      this.item_edit.begin_date > this.item_edit.end_date) {
      this.message_sub = {
        type: 'warning',
        title: '適用終了日は適用開始日より後の日付を入力してください。',
      };
      return false;
    }

    if (!this.item_edit.id) {
      const request = new ItemGetRequest();
      request.base_id = this.item_edit.base;
      request.hinban = this.item_edit.hinban;
      await this.httpClientService.getItemList(request).then((response) => {
        Utils.log(response);
        if (response && response.length) {

          this.message_sub = {
            type: 'warning',
            title: 'すでに同じ品番IDが使用されています。',
          };
          ret = false;
        }

      }).catch(error => {
        this.message_sub = {
          type: 'error',
          title: '品番IDチェック処理が失敗しました。',
        };
        ret = false;
      });
    }
    return ret;
  }

  closeItemEdit() {
    this.item_editing = false;
    setTimeout(() => {
      if (!this.item_editing) {
        this.item_edit = null;
      }
    }, 400);
  }

  getItemPostData(): ItemPostRequest {
    const postRequest = new ItemPostRequest();
    postRequest.id = this.item_edit.id;
    postRequest.base = this.item_edit.base;
    postRequest.service = this.item_edit.service;
    postRequest.hinban = this.item_edit.hinban;
    postRequest.name = this.item_edit.name;
    postRequest.item_price = this.item_edit.item_price;
    postRequest.tax = this.item_edit.tax.id;
    postRequest.begin_date = formatDate(this.item_edit.begin_date, 'yyyy-MM-dd', this.locale);
    postRequest.end_date = formatDate(this.item_edit.end_date, 'yyyy-MM-dd', this.locale);
    if (this.item_edit.fdn_code) {
      postRequest.fdn_code = this.item_edit.fdn_code;
    } else {
      postRequest.fdn_code = null;
    }
    postRequest.display_num = this.item_edit.display_num;

    return postRequest;
  }

  deleteItem(item) {
    this.message = null;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: '商品情報を削除します、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.loaderSvc.call();
        this.httpClientService.deleteItem(item.id)
          .then(async (response) => {
            Utils.log(response);
            await this.getItemList(this.select_base_id);
            this.message = { type: 'info', title: '商品情報を削除しました。' };
          })
          .catch(error => {
            this.loaderSvc.dismiss();
            this.message = { type: 'error', title: '商品情報の削除が失敗しました。' };
          });
      }
    });
  }

  showItemSupplier(item) {
    this.message = null;
    this.message_sub = null;
    if (!item) {
      return;
    }
    this.select_item_id = item.id;
    this.select_item_name = item.name;
    this.restSupplierList();
    if (item.item_suppliers && item.item_suppliers.length) {
      for (const supplier of this.supplier_list) {
        const item_supplier = item.item_suppliers.find(v => v.supplier.id === supplier.id);
        if (item_supplier) {
          supplier.selected = true;
          if (item_supplier.default_supplier_flg) {
            supplier.default = true;
          }
        }
      }

    }
    $('#item-supplier').modal({
      allowMultiple: true,
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  restSupplierList() {
    if (!this.supplier_list || !this.supplier_list.length) {
      return;
    }
    for (const supplier of this.supplier_list) {
      supplier.selected = false;
      supplier.default = false;
    }

  }

  async saveItemSupplier() {
    this.message = null;
    this.message_sub = null;
    if (this.processing) {
      return;
    }
    const postData = this.getItemSupplierPostData();
    if (!postData.default_supplier) {
      this.message_sub = { type: 'warning', title: 'デフォルト発注先は必須項目です。' };
      return;

    }

    this.processing = true;
    this.loaderSvc.call();
    this.httpClientService.updateItem(postData.id, postData)
      .then(async (response) => {
        Utils.log(response);
        await this.getItemList(this.select_base_id);
        this.message = { type: 'info', title: '商品発注先情報を保存しました。' };
        this.processing = false;
      })
      .catch(error => {
        this.message = { type: 'error', title: '商品発注先情報の保存が失敗しました。' };
        this.processing = false;
        this.loaderSvc.dismiss();
        this.sessionSvc.clear('message');
      });
    $('#item-supplier').modal('hide');
  }

  getItemSupplierPostData(): ItemPostRequest {
    const postRequest = new ItemPostRequest();
    postRequest.id = this.select_item_id;
    postRequest.supplier_ids = new Array();
    for (const supplier of this.supplier_list) {
      if (supplier.selected) {
        postRequest.supplier_ids.push(supplier.id);
      }
      if (supplier.default) {
        postRequest.default_supplier = supplier.id;
      }
    }

    return postRequest;
  }

  selectSupplier(event, supplier) {
    if (supplier.default) {
      return;
    }
    supplier.selected = !supplier.selected;
  }

  selectSupplierDefault(event, supplier) {
    for (const supp of this.supplier_list) {
      if (supplier.id === supp.id) {
        supp.default = true;
        supp.selected = true;
      } else {
        supp.default = false;

      }
    }
  }

  exportItem() {
    let items = null;
    switch (this.select_service_id) {
      case this.Const.SERVICE_ID_CHOBUN:
        items = this.item_list_chobun;
        break;
      case this.Const.SERVICE_ID_KUMOTSU:
        items = this.item_list_kumotsu;
        break;
      case this.Const.SERVICE_ID_HENREIHIN:
        items = this.item_list_henreihin;
        break;
      default:
        items = null;
    }
    const title = '品番,商品名,税込価格,税区分ID,税率,適用開始日,適用終了日,FDNコード,表示順\n';
    let csv_data = null;
    let export_data = title;
    if (items) {
      const values = items.map(v => {
        return v.hinban + ',' + v.name + ',' + v.item_price + ',' +
          v.tax.id + ',' + v.tax.tax_pct + ',' + v.begin_date + ',' +
          v.end_date + ',' + (v.fdn_code ? v.fdn_code : '') + ',' + v.display_num;
      });
      csv_data = values.join('\n');
      export_data += csv_data;
    }
    const file_name = '商品一覧(' + this.select_service_name + ')_' + this.select_base_name + '.csv';
    Utils.exportCsv(file_name, export_data);
  }

  importItem() {
    this.message = null;
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.accept = '.csv';
    fileUpload.multiple = false;

    fileUpload.onchange = () => {
      this.detectEncoding(fileUpload.files[0]).subscribe(
        encoding => {
          this.csvFileChange(fileUpload.files, encoding);
          fileUpload.value = '';
        }
      );
    };
    fileUpload.click();
  }
  detectEncoding(file): Observable<string> {
    const result = new Subject<string>();

    const reader = new FileReader();
    reader.onload = (e) => {
      const codes = new Uint8Array(e.target.result as ArrayBuffer);
      const detectedEncoding = Encoding.detect(codes);
      result.next(detectedEncoding);
    };
    reader.readAsArrayBuffer(file);

    return result.asObservable();
  }
  csvFileChange(files, encoding) {
    if (!files || !files.length) {
      return;
    }
    if (!files[0].name.includes('.csv')) {
      this.message = { type: 'warning', title: 'CSVファイルを指定してください。' };
      return;
    }
    const reader = new FileReader();
    if (encoding === 'UTF8') {
      reader.readAsText(files[0]);
    } else {
      reader.readAsBinaryString(files[0]);
    }
    reader.onload = (e) => {
      let csvData = e['target']['result'] as string;
      if (encoding !== 'UTF8') {
        const dataArray = [];
        for (let i = 0; i < csvData.length; i += 1) {
          dataArray.push(csvData.charCodeAt(i));
        }
        const uniArray = Encoding.convert(dataArray, 'UNICODE', encoding);
        csvData = Encoding.codeToString(uniArray);
      }
      const data_list = csvData.replace('\r', '').split('\n').map(v => v.split(','));
      this.saveItemFromCsv(data_list);
    };
  }

  saveItemFromCsv(data_list) {
    if (!this.checkCsvData(data_list)) {
      return;
    }
    this.processing = true;
    this.loaderSvc.call();
    const postData = this.getCsvItemPostData(data_list);
    this.httpClientService.addItems(postData)
      .then(async (response) => {
        Utils.log(response);
        await this.getItemList(this.select_base_id);
        this.message = { type: 'info', title: '商品情報CSVファイルのインポートが完了しました。' };
        this.processing = false;
      })
      .catch(error => {
        this.message = { type: 'error', title: '商品情報CSVファイルのインポートが失敗しました。' };
        this.loaderSvc.dismiss();
        this.processing = false;
        this.sessionSvc.clear('message');
      });

  }

  checkCsvData(data_list) {

    if (!data_list) {
      return false;
    }
    if (data_list.length <= 1) {
      this.message = { type: 'warning', title: 'CSVファイルにデータ行がありません。' };
      return false;
    }
    let row = 1;
    for (const data_row of data_list) {
      if (row === 1) {
        row++;
        continue;
      }
      if (!data_row.length) {
        row++;
        continue;
      }
      if (data_row.length === 1 && !data_row[0]) {
        row++;
        continue;
      }
      if (data_row.length !== 9) {
        this.message = { type: 'warning', title: row + '行目のデータの数（9列）が足りません。' };
        return false;
      }
      let col = 1;
      for (const data_col of data_row) {
        if (col === 5 || col === 8) {
          col++;
          continue;
        }
        if (!data_col) {
          this.message = { type: 'warning', title: row + '行目の' + col + '列のデータが空です。' };
          return false;
        }
        if ((col >= 3 && col <= 4) || col === 9) {
          const num = Number(data_col);
          if (isNaN(num) || (num % 1) > 0) {
            this.message = { type: 'warning', title: row + '行目の' + col + '列のデータが整数値ではありません。' };
            return false;
          }
        }
        if (col >= 6 && col <= 7) {
          const date = new Date(data_col);
          if (date.toString() === 'Invalid Date') {
            this.message = { type: 'warning', title: row + '行目の' + col + '列の日付データが不正です。(YYYY-MM-DD形式)' };
            return false;
          }
        }
        col++;
      }
      const tax_id = Number(data_row[3]);
      if (!this.tax_list.find(v => v.id === tax_id)) {
        this.message = { type: 'warning', title: row + '行目の税区分が存在しない区分です。' };
        return false;
      }


      row++;
    }

    return true;
  }

  getCsvItemPostData(data_list): ItemPostRequest[] {
    const retItems = new Array();
    let row = 1;
    for (const data of data_list) {
      if (row === 1 || data.length !== 9) {
        row++;
        continue;
      }
      const postRequest = new ItemPostRequest();
      postRequest.base = this.select_base_id;
      postRequest.service = this.select_service_id;
      postRequest.hinban = data[0];
      postRequest.name = data[1];
      postRequest.item_price = Number(data[2]);
      postRequest.tax = Number(data[3]);
      postRequest.begin_date = formatDate(data[5], 'yyyy-MM-dd', this.locale);
      postRequest.end_date = formatDate(data[6], 'yyyy-MM-dd', this.locale);
      if (data[7]) {
        postRequest.fdn_code = data[7];
      } else {
        postRequest.fdn_code = null;
      }
      postRequest.display_num = data[8];
      retItems.push(postRequest);
      row++;
    }
    return retItems;
  }

  uploadItemImage() {
    this.message = null;
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.accept = '.jpg';
    fileUpload.multiple = true;

    fileUpload.onchange = () => {
      this.imageFileChange(fileUpload.files);
      fileUpload.value = '';
    };
    fileUpload.click();
  }

  imageFileChange(files) {
    if (!files || !files.length) {
      return;
    }
    this.message = null;
    for (const file of files) {
      if (!file.type.includes('image') || !file.name.includes('.jpg')) {
        this.message = { type: 'warning', title: 'JPGファイル以外は登録できません。' };
        return;
      }
      if (file.size > 1024 * 1024) {
        this.message = { type: 'warning', title: '一つの画像ファイルサイズは1MBまで登録可能です。' };
        return;
      }
    }
    const itemImages = new Array();
    let index = 0;
    const file_count = files.length;
    this.loaderSvc.call();
    for (const file of files) {
      const reader = new FileReader();
      const itemImageData = new ItemImagePostRequest();
      itemImageData.filename = file.name;
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        itemImageData.file_content = e['target']['result'].toString();
        itemImages.push(itemImageData);
        index++;
        if (index === file_count) {
          this.httpClientService.addItemImage(this.company_id, itemImages)
            .then(async (response) => {
              Utils.log(response);
              await this.getItemList(this.select_base_id);
              this.message = { type: 'info', title: '商品画像をアップロードしました。' };
            })
            .catch(error => {
              this.message = { type: 'error', title: '商品画像のアップロードが失敗しました。' };
              this.loaderSvc.dismiss();
              this.sessionSvc.clear('message');
            });
        }
      };

    }

  }

  closeMessage() {
    this.message = null;
    this.message_sub = null;
  }

  chanageItemTab(index) {
    this.item_tab_index = index;
    switch (index) {
      case 1:
        this.select_service_id = this.Const.SERVICE_ID_CHOBUN;
        this.select_service_name = '弔文';
        break;
      case 2:
        this.select_service_id = this.Const.SERVICE_ID_KUMOTSU;
        this.select_service_name = '供花・供物';
        break;
      case 3:
        this.select_service_id = this.Const.SERVICE_ID_HENREIHIN;
        this.select_service_name = '返礼品';
        break;
      default:
        this.select_service_id = null;
        this.select_service_name = null;

    }

  }

  csvExportButtonDisalbled() {
    switch (this.item_tab_index) {
      case 1:
        if (this.item_list_chobun && this.item_list_chobun.length) {
          return false;
        }
        break;
      case 2:
        if (this.item_list_kumotsu && this.item_list_kumotsu.length) {
          return false;
        }
        break;
      case 3:
        if (this.item_list_henreihin && this.item_list_henreihin.length) {
          return false;
        }
        break;
    }
    return true;
  }

  baseTypeName(base_type) {
    switch (base_type) {
      case this.Const.BASE_TYPE_COMPANY:
        return this.Const.BASE_TYPE_NAME_COMPANY;
      case this.Const.BASE_TYPE_DEPART:
        return this.Const.BASE_TYPE_NAME_DEPART;
      case this.Const.BASE_TYPE_PLACE_SELF:
        return this.Const.BASE_TYPE_NAME_PLACE_SELF;
      case this.Const.BASE_TYPE_PLACE_OTHER:
        return this.Const.BASE_TYPE_NAME_PLACE_OTHER;
    }
    return '';
  }

  servicesName(services) {
    if (!services || !services.length) {
      return '';
    }
    return services.map(v => v.name).join(', ');
  }

  canInitServices() {
    if (!this.service_list || !this.service_list.length) {
      return false;
    }
    if (!this.base_list) {
      return true;
    }
    for (const base of this.base_list) {
      if (base.services && base.services.length) {
        if (base.base_type === this.Const.BASE_TYPE_DEPART && base.services && base.services.length) {
          return false;
        }
      }
      return true;
    }
  }

  imageLoadError(item) {
    item.image_file = null;
  }
  imageLoaded(item) {
    item.image_can_load = true;
  }

}

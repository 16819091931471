import { Injectable } from '@angular/core';
import { SessionService } from 'src/app/service/session.service';
import { Utils } from '../const/func-util';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private sessionSvc = new SessionService();
  public cache_name: string;
  constructor(
  ) {
  }

  async getDataFromCache(key: string) {
    if (!this.cache_name) { return; }
    const cache_data = this.sessionSvc.get(this.cache_name);
    if (cache_data && cache_data.length) {
      const data = cache_data.filter(e => e.key === key);
      if (data && data.length) {
        return data[0].value;
      }
    }
    return null;
  }

  async saveDataToCache(key: string, value: any) {
    if (!this.cache_name) { return; }
    let cache_data = this.sessionSvc.get(this.cache_name);
    if (!cache_data) {
      cache_data = new Array();
    }
    cache_data = cache_data.filter(e => e.key !== key);
    cache_data.push({ key, value });
    this.sessionSvc.save(this.cache_name, cache_data);
    Utils.log(key, value);
  }

  hasDataInCache(key: string) {
    if (!this.cache_name) { return false; }
    const cache_data = this.sessionSvc.get(this.cache_name);
    if (cache_data && cache_data.length) {
      const data = cache_data.filter(e => e.key === key);
      if (data && data.length) {
        return true;
      }
    }
    return false;
  }
}

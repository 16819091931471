import { Component, OnInit, DoCheck } from '@angular/core';
import { Router } from '@angular/router';
import { RouterService } from 'src/app/service/router.service';

import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { CommonConstants } from 'src/app/const/const';
import { Utils } from 'src/app/const/func-util';
import { AdvertiseGetRequest } from 'src/app/interfaces/advertise';

declare var $;

@Component({
  selector: 'app-family-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss']
})
export class FamilyFrameComponent implements OnInit, DoCheck {

  login_info = this.sessionSvc.get('soke_login_info');
  seko_info = null;
  appView = null;
  menu_show = false;
  is_loading = false;
  advertise_list = null;
  menu_list = null;
  constructor(
    private sessionSvc: SessionService,
    private httpClientService: HttpClientService,
    private router: Router,
    private routerSvc: RouterService,
  ) { }

  async ngOnInit() {
    this.appView = this.routerSvc.getAppView();
    $('#focFavicon').attr('href', 'favicon_cus.ico');
    this.pageTopToggle();
    setTimeout(() => {
      $('.dropdown').dropdown();
    }, 200);
    if (this.login_info) {
      await this.getSekoInfo(this.login_info.login_id);
    }
  }

  ngDoCheck() {
    this.login_info = this.sessionSvc.get('soke_login_info');
  }

  async getSekoInfo(id) {
    this.is_loading = true;
    await this.httpClientService.getSekoByid(id).then(async (sekoResponse) => {
      Utils.log(sekoResponse);
      if (sekoResponse.moshu.soke_site_del_flg) {
        this.sessionSvc.clearSokeData();
        this.router.navigate(['soke/']);
        this.is_loading = false;
        return false;
      }
      this.seko_info = sekoResponse;
      if (this.seko_info.seko_company.soke_menus.length) {
        this.menu_list = this.seko_info.seko_company.soke_menus;
      } else {
        await this.getSokeMenuList();
      }
      for (const menu of this.menu_list) {
        if (menu.name === 'ログアウト') {
          menu.queryParam = {id: this.seko_info.id};
        }
        menu.url = menu.url.replace(':id', this.seko_info.seko_company.company_code);
      }
      await this.sessionSvc.save('soke_seko_info', sekoResponse);
      await this.getAdvertiseList();
      this.toggleSlider();
      this.is_loading = false;

    }).catch(error => {
      this.sessionSvc.clearSokeData();
      this.router.navigate(['soke/']);
      this.is_loading = false;
      return false;
    });
    return true;
  }
  async getSokeMenuList() {
    await this.httpClientService.getSokeMenuList().then((response) => {
      Utils.log(response);
      this.menu_list = response;

    }).catch(error => {
    });
  }
  async getAdvertiseList() {
    if (!this.seko_info) {
      return;
    }
    if (this.seko_info.seko_department.advertises && this.seko_info.seko_department.advertises.length) {
      this.advertise_list = this.seko_info.seko_department.advertises;
      return;
    }
    if (this.seko_info.seko_department.base_type === CommonConstants.BASE_TYPE_DEPART) {
      this.advertise_list = this.seko_info.seko_company.advertises;
      return;
    }

    const request = new AdvertiseGetRequest();
    request.company = this.seko_info.seko_department.parent;
    await this.httpClientService.getAdvertiseList(request).then((response) => {
      Utils.log(response);
      this.advertise_list = new Array();
      for (const advertise of response) {
        if (advertise.company === this.seko_info.seko_department.parent) {
          this.advertise_list.push(advertise);
        }
      }
      if (!this.advertise_list.length) {
        this.advertise_list = this.seko_info.seko_company.advertises;
      }

    }).catch(error => {
    });
  }
  pageTopToggle() {
    $(function () {
      const pagetop = $('.pagetop');

      $(window).scroll(function () {
        if ($(this).scrollTop() > 200) {
          pagetop.fadeIn();
        } else {
          pagetop.fadeOut();
        }
        const scrollHeight = $(document).innerHeight();
        const scrollPosition = $(window).outerHeight() + $(window).scrollTop();
        const footHeight = $('footer').innerHeight();
        if (scrollHeight - scrollPosition <= footHeight) {
          pagetop.css({
            'position': 'fixed',
            'bottom': footHeight - (scrollHeight - scrollPosition)
          });
        } else {
          pagetop.css({
            'position': 'fixed',
            'bottom': '0px'
          });
        }
      });
      pagetop.click(function () {
        $('body, html').animate({
          scrollTop: 0
        }, 500);
        return false;
      });
    });

  }
  openMenu() {
    this.menu_show = !this.menu_show;
  }
  closeMenu(event) {
    if (event.target.classList.contains('top-menu') ||
      event.target.classList.contains('menu-name') ||
      event.target.classList.contains('menu-area')) {
      return;
    }
    this.menu_show = false;
  }
  gotoTop() {
    if (!this.seko_info) {
      this.router.navigate(['soke/login']);
    } else {
      this.router.navigate(['soke/seko/']);
    }
  }
  toggleSlider() {
    setTimeout(() => {
      $('#ad-banner').flexslider({
        animation: 'slide',
        controlNav: true,
        animationLoop: true,
        slideshow: true,
        slideshowSpeed: 5000,
        animationSpeed: 600,
        smoothHeight: false,
        pauseOnHover: false,
        directionNav: false,
        pauseOnAction: false,
        pausePlay: false,
        prevText: '',
        nextText: ''
      });
    }, 500);
  }
  haveKoden() {
    if (!this.seko_info || !this.seko_info.services || !this.seko_info.services.length) {
      return false;
    }

    for (const service of this.seko_info.services) {
      if (service.hall_service.id === CommonConstants.SERVICE_ID_KODEN) {
        return true;
      }
    }
    return false;
  }
  haveHenrei() {
    if (!this.seko_info || !this.seko_info.services || !this.seko_info.services.length) {
      return false;
    }

    for (const service of this.seko_info.services) {
      if (service.hall_service.id === CommonConstants.SERVICE_ID_HENREIHIN) {
        return true;
      }
    }
    return false;
  }
  getMenuName(soke_menu) {
    if (!soke_menu) {
      return null;
    }
    if (soke_menu.url.includes('/homei') && !this.haveKoden()) {
      return soke_menu.name.replace('・香典', '');
    } else {
      return soke_menu.name;
    }
  }
  showMenu(soke_menu) {
    if (!soke_menu) {
      return false;
    }
    if (soke_menu.url.includes('/henrei') && !this.haveHenrei()) {
      return false;
    } else {
      return true;
    }

  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { CommonConstants } from 'src/app/const/const';
import { Utils } from 'src/app/const/func-util';

declare var $;
@Component({
  selector: 'app-cus-huho',
  templateUrl: './huho.component.html',
  styleUrls: ['./huho.component.scss']
})
export class HuhoComponent implements OnInit {

  Const = CommonConstants;
  utils = Utils;
  seko_info_session = this.sessionSvc.get('custom_seko_info');
  login_info = this.sessionSvc.get('staff_login_info');
  company_id = null;
  seko_id = null;
  seko_data = null;
  place = null;
  video_live_url = null;
  CONST_REQUIRE = '葬儀番号をご入力してください。';
  CONST_NOTFUOND = 'ご指定の葬儀情報が見つかりません。';
  CONST_EXPIRED = 'ご指定の葬儀情報は掲載終了いたしました。';
  err_msg = null;
  wareki_list = null;
  is_loading = false;
  is_kumotsu_have_sogigo_item = false;
  constructor(
    private router: Router,
    private location: Location,
    private sessionSvc: SessionService,
    private activatedRoute: ActivatedRoute,
    private httpClientService: HttpClientService,
    private sanitizer: DomSanitizer,
  ) { }

  async ngOnInit() {
    this.sessionSvc.clear('is_fuho_page');
    this.getWarekiList();
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      const paramId = params.get('id');
      if (!paramId) {
        return;
      }
      const paramIds = paramId.split('-');
      if (paramIds.length >= 1) {
        this.company_id = Number(paramIds[0]);
      }
      if (paramIds.length >= 2) {
        this.seko_id = Number(paramIds[1]);
        return;
      }
      if (!this.company_id || !this.seko_id) {
        this.err_msg = this.CONST_REQUIRE;
        return;
      }

    });
    if (!this.seko_id) {
      this.location.go('fuho');
      return;
    }
    await this.getSekoInfo(this.seko_id);
    this.toggleSlider();
  }
  getWarekiList() {

    this.httpClientService.getWarekiList().then((response) => {
      Utils.log(response);
      this.wareki_list = response;
      for (const wareki of this.wareki_list) {
        wareki.begin_date = new Date(wareki.begin_date);
        wareki.end_date = new Date(wareki.end_date);
      }
    }).catch(error => {
    });
  }
  getSeko(companyIdEm, sekoyIdEm) {
    this.err_msg = null;
    if (!this.company_id) {
      this.err_msg = this.CONST_REQUIRE;
      companyIdEm.focus();
      return;
    }
    if (!this.seko_id) {
      this.err_msg = this.CONST_REQUIRE;
      sekoyIdEm.focus();
      return;
    }
    this.getSekoInfo(this.seko_id);
  }
  async getSekoInfo(id) {
    this.is_loading = true;
    await this.httpClientService.getSekoByid(id).then((sekoResponse) => {
      Utils.log(sekoResponse);
      if (!sekoResponse || sekoResponse.seko_company.id !== Number(this.company_id)) {
        this.err_msg = this.CONST_NOTFUOND;
        this.is_loading = false;
        return false;
      }
      if (!Utils.isBetweenTerm(null, String(sekoResponse.fuho_site_end_date))) {
        this.err_msg = this.CONST_EXPIRED;
        this.is_loading = false;
        return false;
      }
      if (!this.login_info || !this.login_info.staff) {
        if (!sekoResponse.fuho_site_admission_ts) {
          this.err_msg = this.CONST_NOTFUOND;
          this.is_loading = false;
          return false;
        }
      }

      Utils.formatSekoDate(sekoResponse);
      this.seko_data = sekoResponse;
      if (this.seko_info_session && this.seko_info_session.id !== this.seko_data.id) {
        this.sessionSvc.clearCustomerData();
      }
      this.seko_info_session = this.seko_data;
      if (this.seko_data.services) {
        this.seko_data.services.sort((a, b) => a.hall_service.id - b.hall_service.id);
      }
      this.sessionSvc.clear('custom_hoyo_info');
      this.sessionSvc.save('custom_seko_info', this.seko_data);
      this.sessionSvc.save('is_fuho_page', true);
      this.getPlaceInfo();
      this.getVideoLiveInfo();
      this.getSogigoItem();
      this.is_loading = false;
      this.router.navigate(['fuho/' + this.company_id + '-' + this.seko_id]);

    }).catch(error => {
      this.err_msg = this.CONST_NOTFUOND;
      this.is_loading = false;
      return false;
    });
    return true;
  }

  getSogigoItem() {
    this.is_kumotsu_have_sogigo_item = false;
    if (this.seko_data && this.seko_data.items) {
      this.seko_data.items.forEach(value => {
        if (value.item.service === this.Const.SERVICE_ID_KUMOTSU &&
          Utils.isBetweenTerm(value.item.begin_date, value.item.end_date) &&
            value.seko_after_flg) {
              this.is_kumotsu_have_sogigo_item = true;
            }
      });
    }
  }
  getPlaceInfo() {
    if (!this.seko_data || !this.seko_data.schedules) {
      return;
    }
    for (const schedule of this.seko_data.schedules) {
      if (schedule.schedule && schedule.schedule.required_flg) {
        this.place = schedule;
        return;
      }
    }
  }
  getVideoLiveInfo() {
    if (!this.seko_data || !this.seko_data.videos) {
      return;
    }
    for (const video of this.seko_data.videos) {
      let playlist = '';
      if (video.youtube_code.lastIndexOf('/') >= 0) {
        playlist = video.youtube_code.substring(video.youtube_code.lastIndexOf('/') + 1);
      }
      video.youtube_url = this.sanitizer.bypassSecurityTrustResourceUrl(
        video.youtube_code + '?playlist=' + playlist
        );
    }
    for (const video of this.seko_data.videos) {
      if (video.live_begin_ts && !this.isExpired(video.live_end_ts)) {
        this.video_live_url = this.sanitizer.bypassSecurityTrustResourceUrl(video.youtube_code);
        return;
      }
    }
  }
  toggleSlider() {
    setTimeout(() => {
      $('#flexslider-image').flexslider({
        animation: 'fade',
        controlNav: true,
        animationLoop: true,
        slideshow: false,
        slideshowSpeed: 2000,
        animationSpeed: 600,
        smoothHeight: false,
        pauseOnHover: false,
        directionNav: true,
        pauseOnAction: false,
        pausePlay: false,
        prevText: '',
        nextText: ''
      });
    }, 500);
  }
  safeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  isExpired(date: Date) {
    if (!date) {
      return false;
    }
    const now = new Date();
    now.setMinutes(now.getMinutes() - 1);
    return date < now;
  }
  getServiceClass(service) {
    if (!service) {
      return '';
    }
    switch (service.id) {
      case this.Const.SERVICE_ID_CHOBUN:
        return 'chobun';
      case this.Const.SERVICE_ID_KUMOTSU:
        return 'kumotsu';
      case this.Const.SERVICE_ID_KODEN:
        return 'koden';
      case this.Const.SERVICE_ID_MESSAGE:
        return 'message';
      default:
        return '';
    }
  }
  getServiceName(service) {
    if (!service) {
      return '';
    }
    switch (service.id) {
      case this.Const.SERVICE_ID_CHOBUN:
        return '弔文を送る';
      case this.Const.SERVICE_ID_KUMOTSU:
        return '供花・供物を送る';
      case this.Const.SERVICE_ID_KODEN:
        return '香典を送る';
      case this.Const.SERVICE_ID_MESSAGE:
        return 'メッセージを送る';
      default:
        return '';
    }
  }
  goServiceLink(service) {
    if (!service) {
      return;
    }
    switch (service.id) {
      case this.Const.SERVICE_ID_CHOBUN:
        this.sessionSvc.clear('chobun_edit');
        this.router.navigate(['chobun/entry1/']);
        return;
      case this.Const.SERVICE_ID_KUMOTSU:
        this.sessionSvc.clear('kumotsu_edit');
        this.router.navigate(['kumotsu/entry/']);
        return;
      case this.Const.SERVICE_ID_KODEN:
        this.sessionSvc.clear('koden_edit');
        this.router.navigate(['koden/entry1/']);
        return;
      case this.Const.SERVICE_ID_MESSAGE:
        this.sessionSvc.clear('message_edit');
        this.router.navigate(['message/entry/']);
        return;
      default:
        return;
    }
  }
  hasLiveVideo() {
    if (!this.seko_data.videos || !this.seko_data.videos.length) {
      return false;
    }
    for (const video of this.seko_data.videos) {
      if (video.live_begin_ts) {
        return true;
      }
    }
    return false;
  }
  hasCmVideo() {
    if (!this.seko_data.videos || !this.seko_data.videos.length) {
      return false;
    }
    for (const video of this.seko_data.videos) {
      if (!video.live_begin_ts && !this.isExpired(video.delivery_end_ts)) {
        return true;
      }
    }
    return false;
  }
}

import { Component, ElementRef, AfterViewInit, Output, EventEmitter, Input, Inject, LOCALE_ID } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

declare var $: any;
@Component({
  selector: 'com-dropdown',
  templateUrl: './com-dropdown.component.html',
  styleUrls: ['./com-dropdown.component.scss']
})
export class ComDropdownComponent implements AfterViewInit, ControlValueAccessor {
  @Output() htmlElement: EventEmitter<HTMLElement> = new EventEmitter<HTMLElement>();
  @Input() disabled: Boolean = false;
  @Input() showIcon: Boolean = true;
  @Input() settings: DropdownOptions = {};
  @Input() selectedItem: Item;
  @Output() selectedItemChange: EventEmitter<Item> = new EventEmitter<Item>();
  @Input() selectedValue: string;
  @Output() selectedValueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() selectedName: string;
  @Output() selectedNameChange: EventEmitter<string> = new EventEmitter<string>();
  public onChange: any = Function.prototype;
  public onTouched: any = Function.prototype;
  constructor(
    private parentElement: ElementRef,
    @Inject(LOCALE_ID) private locale: string
  ) {
  }
  ngAfterViewInit(): void {
    this.initComponent();
  }
  writeValue(item: Item): void {
    this.selectedItemChange.emit(item);
    this.selectedItem = item;
    this.selectedValueChange.emit(item ? item.value : null);
    this.selectedValue = item ? item.value : null;
    this.selectedNameChange.emit(item ? item.name : null);
    this.selectedName = item ? item.name : null;
  }
  public initComponent(): void {
    this.settings.onChange = (value: string, name: string) => {
      let item = this.settings.values.find(v => String(v.value) === value);
      if (!item) {
        item = this.settings.values.find(v => String(v.name) === name);
      }
      if (!item) {
        if (this.settings.allowAdditions) {
          item = { name: name, value: null };
        }
      }
      this.writeValue(item);
    };
    let is_selected = false;
    for (const value of this.settings.values) {
      if (value.value === this.selectedValue || value.name === this.selectedName) {
        value.selected = true;
        is_selected = true;
      } else {
        value.selected = false;
      }
    }
    if (this.settings.allowAdditions && this.selectedName && !is_selected) {
      this.settings.values.push({ name: this.selectedName, value: this.selectedName, selected: true });
    }
    if (this.settings.forceSelection === undefined) {
      this.settings.forceSelection = false;
    }
    const dropdownElement: HTMLElement = this.parentElement.nativeElement.children[0];
    this.htmlElement.emit(dropdownElement);
    $(dropdownElement).dropdown(this.settings);
  }
  public clear(): void {
    const dropdownElement: HTMLElement = this.parentElement.nativeElement.children[0];
    this.htmlElement.emit(dropdownElement);
    $(dropdownElement).dropdown('clear');
  }
  public registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }
  public registerOnTouched(fn: () => void): void { this.onTouched = fn; }
  onKeyup(event) {
    // this.selectedNameChange.emit(event.target.value);
    // this.selectedName = event.target.value;
  }
  onInputChange(event) {
    const dropdownElement: HTMLElement = this.parentElement.nativeElement.children[0];
    this.htmlElement.emit(dropdownElement);
    $(dropdownElement)
      .dropdown('set value', event.target.value, event.target.value)
      .dropdown('set selected', event.target.value);
  }
  public setValue(value): void {
    const dropdownElement: HTMLElement = this.parentElement.nativeElement.children[0];
    this.htmlElement.emit(dropdownElement);
    $(dropdownElement)
      .dropdown('set selected', value);
  }
  public setName(name): void {
    const dropdownElement: HTMLElement = this.parentElement.nativeElement.children[0];
    this.htmlElement.emit(dropdownElement);
    $(dropdownElement)
      .dropdown('set value', name)
      .dropdown('set selected', name);
    this.selectedNameChange.emit(name ? name : null);
    this.selectedName = name ? name : null;
  }

}

export interface DropdownOptions {
  values?: Array<Item>;
  clearable?: boolean;
  ignoreCase?: boolean;
  disableSearch?: boolean;
  allowAdditions?: boolean;
  allowReselection?: boolean;
  showOnFocus?: boolean;
  forceSelection?: boolean;
  onChange?: Function;
  silent?: boolean;
}
export interface Item {
  name: string;
  value: string;
  selected?: boolean;
  data?: any;
}

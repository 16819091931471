import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/pages/common/confirm-dialog/confirm-dialog.component';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { LoaderService } from 'src/app/service/loader.service';
import { MoshuGetRequest, MoshuGetResponse } from 'src/app/interfaces/seko';
import { EventMailPostRequest } from 'src/app/interfaces/event';
import { AfGroupGetRequest } from 'src/app/interfaces/af';
import { Utils } from 'src/app/const/func-util';
import { formatDate } from '@angular/common';
import { CommonConstants } from 'src/app/const/const';
declare var $;

@Component({
  selector: 'app-com-event-filter',
  templateUrl: './event-filter.component.html',
  styleUrls: ['./event-filter.component.scss']
})
export class EventFilterComponent implements OnInit {
  Const = CommonConstants;
  Utils = Utils;

  login_company = Utils.getLoginCompany();
  login_info = this.sessionSvc.get('staff_login_info');
  prefectureCombo = { values: [], clearable: true, showOnFocus: false };
  form_data = {
    company_id: null,
    prefecture: null,
    address_1: null,
  };
  af_group_list = null;
  searched = false;
  moshu_list: Array<MoshuGetResponse> = null;
  event_mail = new EventMailPostRequest();

  page_per_count = 20;
  pagination = {
    pages: new Array(),
    current: 0
  };

  all_check = false;

  processing = false;
  constructor(
    private router: Router,
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    private dialog: MatDialog,
    @Inject(LOCALE_ID) private locale: string,
  ) {
    this.loaderSvc.call();
  }


  async ngOnInit() {
    if (this.login_company) {
      this.form_data.company_id = this.login_company.id;
      await this.initCombo();
      await this.getAfGroupList();

    }
  }

  async initCombo() {
    this.prefectureCombo.values = [
      { value: '北海道', name: '北海道'},
      { value: '青森県', name: '青森県'},
      { value: '岩手県', name: '岩手県'},
      { value: '宮城県', name: '宮城県'},
      { value: '秋田県', name: '秋田県'},
      { value: '山形県', name: '山形県'},
      { value: '福島県', name: '福島県'},
      { value: '茨城県', name: '茨城県'},
      { value: '栃木県', name: '栃木県'},
      { value: '群馬県', name: '群馬県'},
      { value: '埼玉県', name: '埼玉県'},
      { value: '千葉県', name: '千葉県'},
      { value: '東京都', name: '東京都'},
      { value: '神奈川県', name: '神奈川県'},
      { value: '新潟県', name: '新潟県'},
      { value: '富山県', name: '富山県'},
      { value: '石川県', name: '石川県'},
      { value: '福井県', name: '福井県'},
      { value: '山梨県', name: '山梨県'},
      { value: '長野県', name: '長野県'},
      { value: '岐阜県', name: '岐阜県'},
      { value: '静岡県', name: '静岡県'},
      { value: '愛知県', name: '愛知県'},
      { value: '三重県', name: '三重県'},
      { value: '滋賀県', name: '滋賀県'},
      { value: '京都府', name: '京都府'},
      { value: '大阪府', name: '大阪府'},
      { value: '兵庫県', name: '兵庫県'},
      { value: '奈良県', name: '奈良県'},
      { value: '和歌山県', name: '和歌山県'},
      { value: '鳥取県', name: '鳥取県'},
      { value: '島根県', name: '島根県'},
      { value: '岡山県', name: '岡山県'},
      { value: '広島県', name: '広島県'},
      { value: '山口県', name: '山口県'},
      { value: '徳島県', name: '徳島県'},
      { value: '香川県', name: '香川県'},
      { value: '愛媛県', name: '愛媛県'},
      { value: '高知県', name: '高知県'},
      { value: '福岡県', name: '福岡県'},
      { value: '佐賀県', name: '佐賀県'},
      { value: '長崎県', name: '長崎県'},
      { value: '熊本県', name: '熊本県'},
      { value: '大分県', name: '大分県'},
      { value: '宮崎県', name: '宮崎県'},
      { value: '鹿児島県', name: '鹿児島県'},
      { value: '沖縄県', name: '沖縄県'}
    ];
  }

  async getAfGroupList() {
    if (!this.login_info) {
      this.loaderSvc.dismiss();
      return;
    }
    const requestData = new AfGroupGetRequest();
    if (this.login_info.staff.base.base_type === this.Const.BASE_TYPE_COMPANY) {
      requestData.department = this.login_info.staff.base.id;
    } else if (this.login_info.staff.base.base_type === this.Const.BASE_TYPE_DEPART) {
      requestData.department = this.login_info.staff.base.id;
    } else {
      requestData.department = this.login_info.staff.base.parent.id;
    }
    await this.httpClientService.getAfGroupList(requestData).then((response) => {
      Utils.log(response);
      this.af_group_list = response;
      this.loaderSvc.dismiss();
      if (this.af_group_list.length) {
        this.initAf();
        setTimeout(() => {
          $('.checkbox').checkbox();
        }, 100);
      }

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
    if (this.login_info.staff.base.base_type !== this.Const.BASE_TYPE_COMPANY && !this.af_group_list.length) {
      if (this.login_info.staff.base.base_type === this.Const.BASE_TYPE_DEPART) {
        requestData.department = this.login_info.staff.base.parent.id;
      } else {
        requestData.department = this.login_info.staff.base.parent.parent.id;
      }
      await this.httpClientService.getAfGroupList(requestData).then((response) => {
        Utils.log(response);
        this.af_group_list = response;
        this.loaderSvc.dismiss();
        if (this.af_group_list) {
          this.initAf();
          setTimeout(() => {
            $('.checkbox').checkbox();
          }, 100);
        }

      }).catch(error => {
        this.loaderSvc.dismiss();
      });

    }
  }

  initAf() {
    if (!this.af_group_list) {
      return;
    }
    for (const af_group of this.af_group_list) {
      for (const af of af_group.after_follows) {
        af.selected = false;
      }
    }
  }

  checkItem(event, moshu: MoshuGetResponse) {
    if (!moshu) {
      return;
    }
    if (event.target.classList.contains('icon')) {
      return;
    }
    moshu.selected = !moshu.selected;
    for (const item of this.moshu_list) {
      if (!item.selected) {
        this.all_check = false;
        return;
      }
    }
    this.all_check = true;
  }
  checkAllItem() {
    for (const moshu of this.moshu_list) {
      moshu.selected = this.all_check;
    }
  }
  async searchMoshu() {
    this.sessionSvc.clear('message');

    const request = new MoshuGetRequest();
    request.company = this.form_data.company_id;
    // request.prefecture = this.form_data.prefecture;
    // request.address_1 = this.form_data.address_1;
    request.af_type = new Array();
    if (this.af_group_list) {
      for (const af_group of this.af_group_list) {
        for (const af of af_group.after_follows) {
          if (af.selected) {
            request.af_type.push(af.id);
          }
        }
      }
    }

    if (!this.loaderSvc.isLoading) {
      this.loaderSvc.call();
    }
    await this.httpClientService.getMoshuList(request).then((response) => {
      Utils.log(response);
      this.searched = true;

      if (response && response.length) {
        this.moshu_list = response.filter((v) => {
            let prefecture = null;
            let address_1 = null;
            let mobile_num = null;
            if (v.seko.seko_contact) {
              prefecture = v.seko.seko_contact.prefecture;
              address_1 = v.seko.seko_contact.address_1;
              mobile_num = v.seko.seko_contact.mobile_num;
            }
            return (mobile_num && v.mail_flg &&
                (!this.form_data.prefecture || prefecture.indexOf(this.form_data.prefecture) >= 0) &&
                (!this.form_data.address_1 || address_1.indexOf(this.form_data.address_1) >= 0));
          });
      } else {
        this.moshu_list = new Array();
      }
      Utils.log(this.moshu_list);
      this.calcPagination();
      this.loaderSvc.dismiss();

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }

  calcPagination() {
    this.pagination.pages = new Array();
    this.pagination.current = 0;
    this.all_check = false;
    if (!this.moshu_list || !this.moshu_list.length) {
      return;
    }
    const count = Math.ceil(this.moshu_list.length / this.page_per_count);
    for (let i = 1; i <= count; i++) {
      this.pagination.pages.push(i);
    }
    this.pagination.current = 1;
  }

  pageTo(page_num) {
    this.pagination.current = page_num;
  }

  clearForm(...comboEms: any[]) {

    this.form_data = {
      company_id: this.login_company.id,
      prefecture: null,
      address_1: null,
    };
    for (const comboEm of comboEms) {
      comboEm.clear();
      if ('initComponent' in comboEm) {
        comboEm.initComponent();
      }
    }
    this.initAf();

  }

  saveData() {
    if (this.processing) {
      return;
    }
    if (!this.dataValidate()) {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: 'SMS送信を行います、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.event_mail.target_seko_list = new Array();
        for (const moshu of this.moshu_list) {
          if (moshu.selected) {
            this.event_mail.target_seko_list.push(moshu.seko.id);
          }
        }
        if (!this.event_mail.target_seko_list.length) {
          return;
        }

        this.processing = true;
        this.loaderSvc.call();
        this.httpClientService.addEventMail(this.event_mail)
          .then(async (response) => {
            Utils.log(response);
            await this.searchMoshu();
            if (this.event_mail.target_seko_list.length === response.targets.length) {
              this.sessionSvc.save('message', { type: 'info', title: 'SMSを送信しました。' });
            } else {
              const msg = new Array();
              for (const seko_id of this.event_mail.target_seko_list) {
                if (!response.targets.find(v => v.seko.id === seko_id)) {
                  msg.push(seko_id);
                }
              }

              this.sessionSvc.save('message', {
                type: 'warning', title: '以下の喪主IDのSMS送信が失敗しました。携帯電話番号が正しいかご確認ください。',
                msg : msg.join(',')
              });
            }
            this.processing = false;
          })
          .catch(error => {
            this.sessionSvc.save('message', { type: 'error', title: 'SMS送信が失敗しました。' });
            this.processing = false;
            this.loaderSvc.dismiss();
          });
      }
    });
  }

  dataValidate() {
    const msgs = new Array();
    this.sessionSvc.clear('message');
    this.sessionSvc.clear('message');
    this.event_mail.event_name = this.event_mail.event_name ? this.event_mail.event_name.trim() : null;
    if (!this.event_mail.event_name) {
      msgs.push('イベント名');
    }
    this.event_mail.content = this.event_mail.content ? this.event_mail.content.trim() : null;
    if (!this.event_mail.content) {
      msgs.push('メール本文');
    }

    if (msgs.length > 0) {
      const message = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: msgs.join(',')
      };
      this.sessionSvc.save('message', message);
      return false;
    }

    return true;
  }
  searchButtonDisabled() {
    if (!this.af_group_list || !this.af_group_list.length) {
      return true;
    }
    let count = 0;
    for (const af_group of this.af_group_list) {
      for (const af of af_group.after_follows) {
        if (af.selected) {
          count++;
        }
      }
    }
    if (!count) {
      return true;
    }
    return false;

  }
  buttonDisabled() {
    if (!this.moshu_list || !this.moshu_list.length) {
      return true;
    }
    let count = 0;
    for (const moshu of this.moshu_list) {
      if (moshu.selected) {
        count++;
      }
    }
    if (!count) {
      return true;
    }
    return false;

  }

  exportCsv() {
    const title = 'ID,続柄,氏名,電話番号,住所\n';
    let csv_data = null;
    let export_data = title;
    if (this.moshu_list) {
      const values = this.moshu_list.map(v => {
        const ret_strings = new Array();
        ret_strings.push(v.seko.id);
        if (v.seko.seko_contact) {
          ret_strings.push(v.seko.seko_contact.kojin_relationship ? v.seko.seko_contact.kojin_relationship : '');
          ret_strings.push(v.seko.seko_contact.name ? v.seko.seko_contact.name : '');
          ret_strings.push(v.seko.seko_contact.mobile_num ? v.seko.seko_contact.mobile_num : '');
          ret_strings.push(
            (v.seko.seko_contact.prefecture ? v.seko.seko_contact.prefecture : '') +
            (v.seko.seko_contact.address_1 ? v.seko.seko_contact.address_1 : '') +
            (v.seko.seko_contact.address_2 ? v.seko.seko_contact.address_2 : '') +
            (v.seko.seko_contact.address_3 ? v.seko.seko_contact.address_2 : ''));
        } else {
          ret_strings.push(v.kojin_moshu_relationship ? v.kojin_moshu_relationship : '');
          ret_strings.push(v.name ? v.name : '');
          ret_strings.push(v.mobile_num ? v.mobile_num : '');
          ret_strings.push(
            (v.prefecture ? v.prefecture : '') +
            (v.address_1 ? v.address_1 : '') +
            (v.address_2 ? v.address_2 : '') +
            (v.address_3 ? v.address_2 : ''));
          }
        return ret_strings.join(',');
      });
      csv_data = values.join('\n');
      export_data += csv_data;
    }
    const now = formatDate(new Date(), 'yyyyMMddHHmmss', this.locale);
    const file_name = 'イベント案内抽出一覧_' + now + '.csv';
    Utils.exportCsv(file_name, export_data);
  }

}

import { Component, OnInit, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarOptionsDateTime } from 'src/app/components/com-calendar/com-calendar.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/pages/common/confirm-dialog/confirm-dialog.component';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { ZipcodeService } from 'src/app/service/zipcode.service';
import { LoaderService } from 'src/app/service/loader.service';
import {
  KumotsuGetRequest, KumotsuGetResponse,
  KumotsuOrderStatusPostRequest, KumotsuPutRequest
} from 'src/app/interfaces/order';
import { SupplierGetRequest } from 'src/app/interfaces/supplier';
import { AddressGetRequest } from 'src/app/interfaces/master';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import { formatDate } from '@angular/common';
import { ComDropdownComponent } from 'src/app/components/com-dropdown/com-dropdown.component';
import { ComCalendarComponent } from 'src/app/components/com-calendar/com-calendar.component';
declare var $;

@Component({
  selector: 'app-com-kumotsu',
  templateUrl: './kumotsu.component.html',
  styleUrls: ['./kumotsu.component.scss']
})
export class KumotsuComponent implements OnInit {
  @ViewChild('supplierComboEm', { static: false }) supplierComboEm: ComDropdownComponent;
  @ViewChild('pdfViewerEm') public pdfViewerEm;
  Const = CommonConstants;
  Utils = Utils;
  calendarOptionDateTime: CalendarOptionsDateTime = new CalendarOptionsDateTime();

  host_url = window.location.origin;
  login_info = this.sessionSvc.get('staff_login_info');
  company_list = Utils.getCompanyList();
  login_company = Utils.getLoginCompany();
  companyCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  supplierCombo = { values: [], clearable: true };
  sekoBaseCombo = { values: [], clearable: true };
  supplier_list = null;
  form_data = {
    company_id: null,
    seko_department: null,
    supplier_id: null,
    entry_id: null,
    okurinushi_name: null,
    entry_name: null,
    entry_tel: null,
    renmei: null,
    okurinushi_company: null,
    okurinushi_title: null,
    soke_name: null,
    kojin_name: null,
    moshu_name: null,
    unorder: true,
    ordered: false,
    confirmed: false,
    canceled: true,
    not_canceled: true,
  };
  searched = false;
  kumotsu_list: Array<KumotsuGetResponse> = null;

  page_per_count = 20;
  pagination = {
    pages: new Array(),
    current: 0
  };
  receipt_url = null;
  all_check = false;

  selected_kumotsu: KumotsuGetResponse = null;
  message = null;
  processing = false;
  selected_entry = null;
  fax_pdf_loading = false;
  kumotsu_edit: KumotsuGetResponse = null;
  constructor(
    private router: Router,
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    private dialog: MatDialog,
    private zipcodeSvc: ZipcodeService,
    @Inject(LOCALE_ID) private locale: string,
  ) { }


  ngOnInit() {
    this.initControl();
    this.getSupplier(this.form_data.company_id);
    setTimeout(() => {
      $('.checkbox.all_check').checkbox();
    }, 100);
  }

  initControl() {
    if (this.company_list) {
      this.companyCombo.values = this.company_list.map(value => ({ name: value.base_name, value: value.id, data: value }));
      if (this.login_company) {
        if (this.login_company.base_type === CommonConstants.BASE_TYPE_COMPANY) {
          this.form_data.company_id = this.login_company.id;
          this.sekoBaseCombo.values = Utils.getSekoBaseList(this.login_company);
          if (this.login_info.staff.base.base_type !== CommonConstants.BASE_TYPE_COMPANY) {
            this.form_data.seko_department = this.login_info.staff.base.id;
          }
        } else {
          this.companyCombo.disableSearch = false;
          this.form_data.company_id = this.company_list[0].id;
          this.sekoBaseCombo.values = Utils.getSekoBaseList(this.company_list[0]);
        }
      }
    }
  }

  checkClick(flg_name, ...params: any[]) {

    for (const param of params) {
      if (param) {
        this.form_data[flg_name] = !this.form_data[flg_name];
        return;
      }
    }
  }

  async getSupplier(company_id) {
    this.supplier_list = null;
    this.supplierCombo.values = [];
    const request = new SupplierGetRequest();
    request.company_id = company_id;
    await this.httpClientService.getSupplierList(request).then((response) => {
      Utils.log(response);
      this.supplier_list = response;
      this.supplierCombo.values = response.map(value => ({ name: value.name, value: value.id }));

    }).catch(error => {
    });
    this.form_data.supplier_id = null;
    this.supplierComboEm.initComponent();
  }

  companyChange(event, sekoBaseComboEm) {
    this.sekoBaseCombo.values = [];
    this.form_data.seko_department = null;
    if (event && event.data) {
      this.sekoBaseCombo.values = Utils.getSekoBaseList(event.data);
      this.getSupplier(event.data.id);
    }
    sekoBaseComboEm.clear();
    sekoBaseComboEm.initComponent();
  }

  checkItem(event, kumotsu: KumotsuGetResponse) {
    if (!kumotsu) {
      return;
    }
    if (event.target.classList.contains('icon')) {
      return;
    }
    kumotsu.selected = !kumotsu.selected;
    for (const item of this.kumotsu_list) {
      if (!item.selected) {
        this.all_check = false;
        return;
      }
    }
    this.all_check = true;
  }
  checkAllItem() {
    for (const kumotsu of this.kumotsu_list) {
      kumotsu.selected = this.all_check;
    }
  }
  async searchKumotsu() {
    this.sessionSvc.clear('message');
    const request = this.getRequest();
    if (!this.loaderSvc.isLoading) {
      this.loaderSvc.call();
    }
    this.pagination.pages = new Array();
    this.pagination.current = 0;
    this.httpClientService.getKumotsuList(request).then((response) => {
      Utils.log(response);
      this.searched = true;
      this.kumotsu_list = response;

      if (this.kumotsu_list && this.kumotsu_list.length) {
        for (const kumotsu of this.kumotsu_list) {
          if (kumotsu.entry_detail.entry.entry_ts) {
            kumotsu.entry_detail.entry.entry_ts = new Date(kumotsu.entry_detail.entry.entry_ts);
          }
          if (kumotsu.order_ts) {
            kumotsu.order_ts = new Date(kumotsu.order_ts);
          }
          if (kumotsu.delivery_ts) {
            kumotsu.delivery_ts = new Date(kumotsu.delivery_ts);
          }
        }
      }
      this.calcPagination();
      this.loaderSvc.dismiss();

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }

  getRequest(): KumotsuGetRequest {
    const request = new KumotsuGetRequest();
    request.company_id = this.form_data.company_id;
    request.seko_department = this.form_data.seko_department;
    request.supplier_id = this.form_data.supplier_id;
    request.entry_id = this.form_data.entry_id;
    request.okurinushi_name = this.form_data.okurinushi_name;
    request.entry_name = this.form_data.entry_name;
    request.entry_tel = this.form_data.entry_tel;
    request.renmei = this.form_data.renmei;
    request.okurinushi_company = this.form_data.okurinushi_company;
    request.okurinushi_title = this.form_data.okurinushi_title;
    request.soke_name = this.form_data.soke_name;
    request.kojin_name = this.form_data.kojin_name;
    request.moshu_name = this.form_data.moshu_name;
    request.order_status = new Array();
    if (!!this.form_data.unorder) {
      request.order_status.push(this.Const.ORDER_STATUS_ID_UNORDER);
    }
    if (!!this.form_data.ordered) {
      request.order_status.push(this.Const.ORDER_STATUS_ID_ORDERED);
    }
    if (!!this.form_data.confirmed) {
      request.order_status.push(this.Const.ORDER_STATUS_ID_CONFIRMED);
    }
    if (this.form_data.canceled && !this.form_data.not_canceled) {
      request.is_cancel = true;
    } else if (!this.form_data.canceled && this.form_data.not_canceled) {
      request.is_cancel = false;
    }
    return request;

  }

  calcPagination() {
    if (!this.kumotsu_list || !this.kumotsu_list.length) {
      return;
    }
    const count = Math.ceil(this.kumotsu_list.length / this.page_per_count);
    for (let i = 1; i <= count; i++) {
      this.pagination.pages.push(i);
    }
    this.pagination.current = 1;
  }

  pageTo(page_num) {
    this.pagination.current = page_num;
  }

  clearForm(companyComboEm, sekoBaseComboEm) {

    this.form_data = {
      company_id: null,
      seko_department: null,
      supplier_id: null,
      entry_id: null,
      okurinushi_name: null,
      entry_name: null,
      entry_tel: null,
      renmei: null,
      okurinushi_company: null,
      okurinushi_title: null,
      soke_name: null,
      kojin_name: null,
      moshu_name: null,
      unorder: true,
      ordered: false,
      confirmed: false,
      canceled: true,
      not_canceled: true,
    };
    if (this.login_company) {
      if (this.login_company.base_type === CommonConstants.BASE_TYPE_COMPANY) {
        this.form_data.company_id = this.login_company.id;
        if (this.login_info.staff.base.base_type === CommonConstants.BASE_TYPE_COMPANY) {
          sekoBaseComboEm.clear();
        } else {
          this.form_data.seko_department = this.login_info.staff.base.id;
          sekoBaseComboEm.setValue(this.form_data.seko_department);
        }
      } else {
        this.form_data.company_id = this.company_list[0].id;
        this.sekoBaseCombo.values = Utils.getSekoBaseList(this.company_list[0]);
        sekoBaseComboEm.clear();
        sekoBaseComboEm.initComponent();
      }
      companyComboEm.setValue(this.form_data.company_id);
    }

  }

  downloadPDF(detail_id) {
    this.sessionSvc.clear('message');
    if (detail_id) {
      this.loaderSvc.call();
      this.httpClientService.downloadKumotsuPdf(detail_id)
        .then((response) => {
          this.localStreamDownload(response, detail_id);
          this.sessionSvc.save('message', { type: 'info', title: '発注書PDFを出力しました。' });
          this.loaderSvc.dismiss();
        }).catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: '発注書PDFの出力が失敗しました。' });
          this.loaderSvc.dismiss();
        });
    }
  }

  localStreamDownload(stream, detail_id) {
    const blob = new Blob([stream]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    const file_name = '供花・供物発注書-' + detail_id + '.pdf';

    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveOrOpenBlob(blob, file_name);
    } else {
      link.href = url;
      link.download = file_name;
      link.click();
    }
  }
  /*
    async showSupplier(kumotsu: KumotsuGetResponse) {
      this.message = null;
      if (!kumotsu) {
        return;
      }
      this.supplier_list = null;
      this.loaderSvc.call();
      await this.httpClientService.getItem(kumotsu.entry_detail.item).then((response) => {
        Utils.log(response);
        this.supplier_list = response.item_suppliers.map(v => v.supplier);
        this.loaderSvc.dismiss();

      }).catch(error => {
        this.loaderSvc.dismiss();
      });
      if (!this.supplier_list) {
        return;
      }
      this.restSupplierList();
      this.selected_kumotsu = JSON.parse(JSON.stringify(kumotsu));
      if (kumotsu.supplier) {
        const supplier = this.supplier_list.find(v => v.id === kumotsu.supplier.id);
        if (supplier) {
          supplier.selected = true;
        }

      }
      $('#supplier').modal({
        centered: false,
        closable: false,
        detachable: false,
        transition: 'fade'
      }).modal('show');
    }

    saveKumotsuSupplier() {
      this.message = null;
      this.sessionSvc.clear('message');
      if (this.processing) {
        return;
      }
      let selected_id = 0;
      for (const supplier of this.supplier_list) {
        if (supplier.selected) {
          selected_id = supplier.id;
        }
      }
      if (!selected_id) {
        return;
      }

      this.processing = true;
      const postData = new KumotsuPutRequest();
      postData.supplier = selected_id;

      this.loaderSvc.call();
      this.httpClientService.updateKumotsu(this.selected_kumotsu.entry_detail.id, postData)
        .then(async (response) => {
          Utils.log(response);
          await this.searchKumotsu();
          this.sessionSvc.save('message', { type: 'info', title: '発注先情報を更新しました。' });
          this.processing = false;
        })
        .catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: '発注先情報の更新が失敗しました。' });
          this.processing = false;
          this.loaderSvc.dismiss();
        });
      $('#supplier').modal('hide');
      setTimeout(() => {
        this.selected_kumotsu = null;
      }, 500);
    }

    showDelivery(kumotsu: KumotsuGetResponse) {
      this.message = null;
      if (!kumotsu) {
        return;
      }
      this.selected_kumotsu = JSON.parse(JSON.stringify(kumotsu));
      $('#delivery').modal({
        centered: false,
        closable: false,
        detachable: false,
        transition: 'fade'
      }).modal('show');
    }

    saveDelivery() {
      this.message = null;
      this.sessionSvc.clear('message');
      if (this.processing) {
        return;
      }
      if (!this.selected_kumotsu.delivery_ts) {
        this.message = { type: 'warning', title: '納入日時は必須項目です。' };
        return;
      }

      this.processing = true;
      const postData = new KumotsuPutRequest();
      postData.delivery_ts = formatDate(this.selected_kumotsu.delivery_ts, 'yyyy-MM-ddTHH:mm:ss', this.locale);
      postData.order_note = this.selected_kumotsu.order_note;
      this.loaderSvc.call();
      this.httpClientService.updateKumotsu(this.selected_kumotsu.entry_detail.id, postData)
        .then(async (response) => {
          Utils.log(response);
          await this.searchKumotsu();
          this.sessionSvc.save('message', { type: 'info', title: '納入日時情報を更新しました。' });
          this.processing = false;
        })
        .catch(error => {
          this.sessionSvc.save('message', { type: 'error', title: '納入日時情報の更新が失敗しました。' });
          this.processing = false;
          this.loaderSvc.dismiss();
        });
      $('#delivery').modal('hide');
      setTimeout(() => {
        this.selected_kumotsu = null;
      }, 500);
    }
  */
  restSupplierList() {
    if (!this.supplier_list || !this.supplier_list.length) {
      return;
    }
    for (const supplier of this.supplier_list) {
      supplier.selected = false;
    }

  }

  selectSupplier(supplier) {
    if (!this.canChangeSupplier(this.selected_kumotsu)) {
      return;
    }
    this.restSupplierList();
    supplier.selected = true;
  }

  supplierCanSave() {
    if (!this.supplier_list || !this.supplier_list.length) {
      return false;
    }
    for (const supplier of this.supplier_list) {
      if (supplier.selected) {
        return true;
      }
    }
    return false;

  }

  async showItemEdit(kumotsu: KumotsuGetResponse) {
    this.message = null;
    if (!kumotsu) {
      return;
    }
    this.supplier_list = null;
    this.loaderSvc.call();
    await this.httpClientService.getItem(kumotsu.entry_detail.item).then((response) => {
      Utils.log(response);
      this.supplier_list = response.item_suppliers.map(v => v.supplier);
      this.loaderSvc.dismiss();

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
    if (!this.supplier_list) {
      return;
    }
    this.restSupplierList();
    this.selected_kumotsu = JSON.parse(JSON.stringify(kumotsu));
    if (kumotsu.supplier) {
      const supplier = this.supplier_list.find(v => v.id === kumotsu.supplier.id);
      if (supplier) {
        supplier.selected = true;
      }

    }
    $('#item-edit').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  saveItem() {
    this.message = null;
    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }
    let selected_id = 0;
    for (const supplier of this.supplier_list) {
      if (supplier.selected) {
        selected_id = supplier.id;
      }
    }
    if (!selected_id) {
      this.message = { type: 'warning', title: '発注先は必須項目です。' };
      return;
    }

    this.processing = true;
    const postData = new KumotsuPutRequest();
    postData.supplier = selected_id;
    if (this.selected_kumotsu.delivery_ts) {
      postData.delivery_ts = formatDate(this.selected_kumotsu.delivery_ts, 'yyyy-MM-ddTHH:mm:ss', this.locale);
    } else {
      postData.delivery_ts = null;
    }
    postData.order_note = this.selected_kumotsu.order_note;
    this.loaderSvc.call();
    this.httpClientService.updateKumotsu(this.selected_kumotsu.entry_detail.id, postData)
      .then(async (response) => {
        Utils.log(response);
        await this.searchKumotsu();
        this.sessionSvc.save('message', { type: 'info', title: '発注補足情報を更新しました。' });
        this.processing = false;
      })
      .catch(error => {
        this.sessionSvc.save('message', { type: 'error', title: '発注補足情報の更新が失敗しました。' });
        this.processing = false;
        this.loaderSvc.dismiss();
      });
    $('#item-edit').modal('hide');
    setTimeout(() => {
      this.selected_kumotsu = null;
    }, 500);
  }

  closeDialog() {
    setTimeout(() => {
      this.selected_kumotsu = null;
    }, 500);
  }

  showUrl(entry_id) {
    if (!entry_id) {
      return;
    }
    this.receipt_url = this.host_url + '/order/' + entry_id + '/receipt';
    $('#receipt-url').modal({
      centered: false,
      closable: true,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  onFocus(event) {
    event.target.select();
  }

  saveOrderStatus(order_status) {
    this.sessionSvc.clear('message');
    if (!order_status) {
      return;
    }
    if (this.processing) {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: '発注状況を更新します、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result === true) {
        const postData = new KumotsuOrderStatusPostRequest();
        postData.ids = new Array();
        for (const kumotsu of this.kumotsu_list) {
          if (kumotsu.selected) {
            postData.ids.push(kumotsu.entry_detail.id);
          }
        }
        if (!postData.ids.length) {
          return;
        }
        postData.order_status = order_status;

        this.processing = true;
        this.loaderSvc.call();
        const chk = await this.checkOrderStatus(postData.ids);
        if (!chk) {
          this.sessionSvc.save('message', {
            type: 'warning', title: 'データが更新されております。',
            msg: '最新情報を取得し直してから、再度処理を行ってください。'
          });
          this.processing = false;
          this.loaderSvc.dismiss();
          return;
        }
        await this.httpClientService.updateKumotsuStatus(postData)
          .then(async (response) => {
            Utils.log(response);
            await this.searchKumotsu();
            this.sessionSvc.save('message', { type: 'info', title: '発注状況を更新しました。' });
            this.processing = false;
          })
          .catch(error => {
            this.sessionSvc.save('message', { type: 'error', title: '発注状況の更新が失敗しました。' });
            this.processing = false;
            this.loaderSvc.dismiss();
          });
      }
    });
  }

  async checkOrderStatus(ids: Array<number>): Promise<boolean> {
    let ret = true;
    const request = this.getRequest();
    await this.httpClientService.getKumotsuList(request).then((response) => {
      Utils.log(response);
      for (const id of ids) {
        const kumotsu = response.find(v => v.entry_detail.id === id);
        const kumotsu_old = this.kumotsu_list.find(v => v.entry_detail.id === id);
        if (!kumotsu || !kumotsu_old || kumotsu.entry_detail.entry.cancel_ts ||
          kumotsu.order_status !== kumotsu_old.order_status) {
          ret = false;
          break;
        }
      }

    }).catch(error => {
      ret = false;
    });
    return ret;
  }

  exportCsv() {
    const title = '申込番号,申込者名,送り主名,組織名,役職名,連名１,連名２,葬家名,故人名,' +
      '喪主名,商品名,価格,申込日時,発注先,発注状況,発注日時,納入日時,発注備考,キャンセル\n';
    let csv_data = null;
    let export_data = title;
    if (this.kumotsu_list) {
      const values = this.kumotsu_list.map(v => {
        const ret_strings = new Array();
        ret_strings.push(v.entry_detail.entry.id ? String(v.entry_detail.entry.id) : '');
        ret_strings.push(v.entry_detail.entry.entry_name ? v.entry_detail.entry.entry_name : '');
        ret_strings.push(v.okurinushi_name ? v.okurinushi_name : '');
        ret_strings.push(v.okurinushi_company ? v.okurinushi_company : '');
        ret_strings.push(v.okurinushi_title ? v.okurinushi_title : '');
        ret_strings.push(v.renmei1 ? v.renmei1 : '');
        ret_strings.push(v.renmei2 ? v.renmei2 : '');
        ret_strings.push(v.entry_detail.entry.seko.soke_name ? v.entry_detail.entry.seko.soke_name : '');
        ret_strings.push(
          v.entry_detail.entry.seko.kojin && v.entry_detail.entry.seko.kojin[0].name ? v.entry_detail.entry.seko.kojin[0].name : ''
        );
        ret_strings.push(v.entry_detail.entry.seko.moshu.name ? v.entry_detail.entry.seko.moshu.name : '');
        ret_strings.push(v.entry_detail.item_name ? v.entry_detail.item_name : '');
        ret_strings.push(String(v.entry_detail.item_unit_price * v.entry_detail.quantity));
        ret_strings.push(
          v.entry_detail.entry.entry_ts ? formatDate(v.entry_detail.entry.entry_ts, 'yyyy/MM/dd H:mm', this.locale) : ''
        );
        ret_strings.push(v.supplier ? v.supplier.name : '');
        ret_strings.push(Utils.getOrderStatusName(v.order_status));
        ret_strings.push(v.order_ts ? formatDate(v.order_ts, 'yyyy/MM/dd H:mm', this.locale) : '');
        ret_strings.push(v.delivery_ts ? formatDate(v.delivery_ts, 'yyyy/MM/dd H:mm', this.locale) : '');
        ret_strings.push(v.order_note ? v.order_note : '');
        ret_strings.push(v.entry_detail.entry.cancel_ts ? 'キャンセル済' : '');
        return ret_strings.join(',');
      });
      csv_data = values.join('\n');
      export_data += csv_data;
    }
    const now = formatDate(new Date(), 'yyyyMMddHHmmss', this.locale);
    const file_name = '供花・供物一覧_' + now + '.csv';
    Utils.exportCsv(file_name, export_data);
  }

  baseCheck(kumotsu: KumotsuGetResponse) {
    if (!kumotsu) {
      return false;
    }
    if (this.login_company.base_type === this.Const.BASE_TYPE_ADMIN) {
      return false;
    }
    if (kumotsu.entry_detail.entry.cancel_ts) {
      return false;
    }
    return true;
  }

  canOrder(kumotsu: KumotsuGetResponse) {
    if (!this.baseCheck(kumotsu)) {
      return false;
    }
    if (kumotsu.order_status !== this.Const.ORDER_STATUS_ID_UNORDER) {
      return false;
    }
    return true;
  }

  canChangeSupplier(kumotsu: KumotsuGetResponse) {
    if (!this.baseCheck(kumotsu)) {
      return false;
    }
    if (kumotsu.order_status !== this.Const.ORDER_STATUS_ID_UNORDER) {
      return false;
    }
    return true;
  }

  buttonDisabled(order_status) {
    if (!this.kumotsu_list || !this.kumotsu_list.length) {
      return true;
    }
    let count = 0;
    for (const kumotsu of this.kumotsu_list) {
      if (kumotsu.selected) {
        count++;
        if (!this.baseCheck(kumotsu)) {
          return true;
        }
        if (kumotsu.order_status !== order_status) {
          return true;
        }
      }
    }
    if (!count) {
      return true;
    }
    return false;

  }

  showOrderCancel(entry) {
    if (!entry) {
      return;
    }
    this.selected_entry = entry;
    $('#order-cancel').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  cancelOrder() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: '申込みをキャンセルします、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        $('#order-cancel').modal('hide');
        this.loaderSvc.call();
        this.httpClientService.cancelEntry(this.selected_entry.id)
          .then(async (response) => {
            Utils.log(response);
            this.sessionSvc.save('message', { type: 'info', title: '申込みをキャンセルしました。' });
            this.selected_entry.cancel_ts = new Date();
            this.loaderSvc.dismiss();
            setTimeout(() => {
              this.selected_entry = null;
            }, 500);
          })
          .catch(error => {
            this.loaderSvc.dismiss();
            this.sessionSvc.save('message', { type: 'error', title: '申込みのキャンセル処理が失敗しました。' });
            setTimeout(() => {
              this.selected_entry = null;
            }, 500);
          });
      }
    });
  }

  closeCancelOrder() {
    setTimeout(() => {
      this.selected_entry = null;
    }, 500);
  }

  showSendFax(kumotsu: KumotsuGetResponse) {
    if (!kumotsu) {
      return;
    }
    this.selected_kumotsu = JSON.parse(JSON.stringify(kumotsu));
    this.pdfViewerEm.pdfSrc = '';
    this.pdfViewerEm.refresh();
    this.fax_pdf_loading = true;
    this.httpClientService.downloadKumotsuPdf(this.selected_kumotsu.entry_detail.id)
      .then((response) => {
        this.pdfViewerEm.pdfSrc = response;
        this.pdfViewerEm.refresh();
        this.fax_pdf_loading = false;
      }).catch(error => {
        this.fax_pdf_loading = false;
      });
    $('#send-fax').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  faxPDF() {
    this.sessionSvc.clear('message');
    if (!this.selected_kumotsu) {
      return;
    }
    if (this.processing) {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: '発注書を送信します、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result === true) {

        $('#send-fax').modal('hide');
        this.processing = true;
        this.loaderSvc.call();
        const chk = await this.checkOrderStatus([this.selected_kumotsu.entry_detail.id]);
        if (!chk) {
          this.sessionSvc.save('message', {
            type: 'warning', title: 'データが更新されております。',
            msg: '最新情報を取得し直してから、再度処理を行ってください。'
          });
          setTimeout(() => {
            this.selected_kumotsu = null;
          }, 500);
          this.processing = false;
          this.loaderSvc.dismiss();
          return;
        }
        this.httpClientService.faxKumotsuPdf(this.selected_kumotsu.entry_detail.id)
          .then(async (response) => {
            Utils.log(response);
            await this.searchKumotsu();
            setTimeout(() => {
              this.selected_kumotsu = null;
            }, 500);
            this.sessionSvc.save('message', { type: 'info', title: '発注書を送信しました。' });
            this.processing = false;
          })
          .catch(error => {
            setTimeout(() => {
              this.selected_kumotsu = null;
            }, 500);
            this.sessionSvc.save('message', { type: 'error', title: '発注書の送信が失敗しました。' });
            this.processing = false;
            this.loaderSvc.dismiss();
          });
      }
    });
  }

  faxPDF1(detail_id) {
    this.sessionSvc.clear('message');
    if (!detail_id) {
      return;
    }
    if (this.processing) {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        msg1: '発注書を送信します、よろしいですか？',
      },
      width: '100%',
      maxWidth: '450px',
      maxHeight: '90%'
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result === true) {

        this.processing = true;
        this.loaderSvc.call();
        const chk = await this.checkOrderStatus([detail_id]);
        if (!chk) {
          this.sessionSvc.save('message', {
            type: 'warning', title: 'データが更新されております。',
            msg: '最新情報を取得し直してから、再度処理を行ってください。'
          });
          this.processing = false;
          this.loaderSvc.dismiss();
          return;
        }
        this.httpClientService.faxKumotsuPdf(detail_id)
          .then(async (response) => {
            Utils.log(response);
            await this.searchKumotsu();
            this.sessionSvc.save('message', { type: 'info', title: '発注書を送信しました。' });
            this.processing = false;
          })
          .catch(error => {
            this.sessionSvc.save('message', { type: 'error', title: '発注書の送信が失敗しました。' });
            this.processing = false;
            this.loaderSvc.dismiss();
          });
      }
    });
  }

  showKumotsuData(kumotsu) {
    this.message = null;
    this.kumotsu_edit = JSON.parse(JSON.stringify(kumotsu));
    $('#kumotsu-edit').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
  }

  async saveKumotsuData() {
    this.sessionSvc.clear('message');
    if (!this.kumotsuDataValidate()) {
      return;
    }
    const postData = this.getKumotsuPostData();
    this.loaderSvc.call();
    await this.httpClientService.updateKumotsu(this.kumotsu_edit.entry_detail.id, postData)
      .then(async (response) => {
        Utils.log(response);
        await this.searchKumotsu();
        this.sessionSvc.save('message', { type: 'info', title: '担当者情報を保存しました。' });
      })
      .catch(error => {
        this.sessionSvc.save('message', { type: 'error', title: '担当者情報の保存が失敗しました。' });
        this.loaderSvc.dismiss();
      });

    $('#kumotsu-edit').modal('hide');
    setTimeout(() => {
      this.kumotsu_edit = null;
    }, 500);

  }

  kumotsuDataValidate() {
    const msgs = new Array();
    this.message = null;
    if (!this.kumotsu_edit.okurinushi_name) {
      msgs.push('氏名');
    }
    if (!this.kumotsu_edit.okurinushi_kana) {
      msgs.push('氏名カナ');
    }
    if (!this.kumotsu_edit.okurinushi_zip_code) {
      msgs.push('電話番号');
    }
    if (!this.kumotsu_edit.okurinushi_tel) {
      msgs.push('郵便番号');
    }
    if (!this.kumotsu_edit.okurinushi_prefecture) {
      msgs.push('都道府県');
    }
    if (!this.kumotsu_edit.okurinushi_address_1) {
      msgs.push('市区町村');
    }
    if (!this.kumotsu_edit.okurinushi_address_2) {
      msgs.push('所番地');
    }

    if (msgs.length > 0) {
      this.message = {
        type: 'warning',
        title: '必須項目が入力されておりません。',
        msg: msgs.join(',')
      };
      return false;
    }

    return true;
  }

  getKumotsuPostData(): KumotsuPutRequest {
    const postRequest = new KumotsuPutRequest();
    postRequest.okurinushi_company = this.kumotsu_edit.okurinushi_company;
    postRequest.okurinushi_title = this.kumotsu_edit.okurinushi_title;
    postRequest.okurinushi_company_kana = this.kumotsu_edit.okurinushi_company_kana;
    postRequest.okurinushi_name = this.kumotsu_edit.okurinushi_name;
    postRequest.okurinushi_kana = this.kumotsu_edit.okurinushi_kana;
    postRequest.okurinushi_tel = this.kumotsu_edit.okurinushi_tel;
    postRequest.okurinushi_zip_code = this.kumotsu_edit.okurinushi_zip_code;
    postRequest.okurinushi_prefecture = this.kumotsu_edit.okurinushi_prefecture;
    postRequest.okurinushi_address_1 = this.kumotsu_edit.okurinushi_address_1;
    postRequest.okurinushi_address_2 = this.kumotsu_edit.okurinushi_address_2;
    postRequest.okurinushi_address_3 = this.kumotsu_edit.okurinushi_address_3;
    postRequest.renmei1 = this.kumotsu_edit.renmei1;
    postRequest.renmei_kana1 = this.kumotsu_edit.renmei_kana1;
    postRequest.renmei2 = this.kumotsu_edit.renmei2;
    postRequest.renmei_kana2 = this.kumotsu_edit.renmei_kana2;
    postRequest.note = this.kumotsu_edit.note;

    return postRequest;
  }

  closeKumotsuEdit() {
    setTimeout(() => {
      this.kumotsu_edit = null;
    }, 500);
  }
  closeMessage() {
    this.message = null;
  }
  zipcodeChange() {
    const request = new AddressGetRequest();
    request.zip_code = this.kumotsu_edit.okurinushi_zip_code;
    this.zipcodeSvc.getData(request).then(address => {
      if (address) {
        this.kumotsu_edit.okurinushi_prefecture = address.prefecture;
        this.kumotsu_edit.okurinushi_address_1 = address.address_1;
        this.kumotsu_edit.okurinushi_address_2 = address.address_2;
      }
    }).catch(error => { });
  }

}

import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/service/http-client.service';
import { CacheService } from 'src/app/service/cache.service';
import { StaffGetResponse } from '../interfaces/staff';
import { Utils } from '../const/func-util';


@Injectable({
  providedIn: 'root'
})
export class StaffService extends CacheService {

  private result_data: StaffGetResponse[];
  constructor(
    private httpClientService: HttpClientService
  ) {
    super();
    this.cache_name = 'staff_list';
  }

  async getData(company_id, force = false, save = true): Promise<StaffGetResponse[]> {
    if (!company_id) { return null; }
    const key = company_id;

    this.result_data = null;
    if (!force) {
      this.result_data = await this.getDataFromCache(key);
    }

    if (!this.result_data) {
      await this.httpClientService.getStaffList(company_id)
        .then(
          async (response) => {
            if (response && response.length) {
              if (save) {
                await this.saveDataToCache(key, response);
              } else {
                Utils.log(response);
              }
              this.result_data = response;
            }
          }).catch(error => {
          });
    }
    return this.result_data;
  }
}

import { Component, Inject, OnInit, DoCheck } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { RouterService } from 'src/app/service/router.service';
import { SessionService } from 'src/app/service/session.service';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';


declare var $;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, DoCheck {
  frame_type = this.sessionSvc.get('frame_type');
  constructor(
    private router: Router,
    private location: Location,
    private routerSvc: RouterService,
    private activatedRoute: ActivatedRoute,
    private sessionSvc: SessionService,
    private configSvc: RuntimeConfigLoaderService,
    @Inject(DOCUMENT) private document: HTMLDocument
  ) { }

  ngOnInit() {
    const config = this.configSvc.getConfig();
    this.sessionSvc.save('debug_log', config.debug_log);
  }
  ngDoCheck() {

  }
}

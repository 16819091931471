import { Component, ElementRef, AfterViewInit, Output, EventEmitter, Input, Inject, LOCALE_ID } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { formatDate } from '@angular/common';
import { Calendar } from 'src/app/const/const';

declare var $: any;
@Component({
  selector: 'com-calendar',
  templateUrl: './com-calendar.component.html',
  styleUrls: ['./com-calendar.component.scss']
})
export class ComCalendarComponent implements AfterViewInit, ControlValueAccessor {
  @Output() htmlElement: EventEmitter<HTMLElement> = new EventEmitter<HTMLElement>();
  @Input() showIcon: Boolean = true;
  @Input() readonly: Boolean = false;
  @Input() settings: CalendarOptionsDate = null;
  @Input() value: Date;
  @Output() valueChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() blur: EventEmitter<Date> = new EventEmitter<Date>();
  public onChange: any = Function.prototype;
  public onTouched: any = Function.prototype;
  private selectedDate: Date;
  private disabled_flg: Boolean = false;
  constructor(
    private parentElement: ElementRef,
    @Inject(LOCALE_ID) private locale: string
  ) {
  }
  ngAfterViewInit(): void {
    if (!this.settings) {
      return;
    }
    const options = this.settings.getSettings();
    options.onChange = (date: Date) => {
      this.writeValue(date);
    };
    options.onSelect = (date: Date, mode) => {
      if (mode === 'day' && this.selectedDate) {
        this.writeValue(date);
      }
      if (mode === 'hour') {
        this.writeValue(date);
      }
    };
    const calandarElement: HTMLElement = this.parentElement.nativeElement.children[0];
    this.htmlElement.emit(calandarElement);
    if (options.startCalendar) {
      options.startCalendar = options.startCalendar[0].children[0];
    }
    if (options.endCalendar) {
      options.endCalendar = options.endCalendar[0].children[0];
    }
    if (this.value) {
      options.initialDate = this.value;
    }
    $(calandarElement).calendar(options);
  }
  writeValue(val: Date): void {
    if (val === this.selectedDate) {
      return;
    }
    this.valueChange.emit(val);
    this.selectedDate = val;
  }
  public clear(): void {
    const calandarElement: HTMLElement = this.parentElement.nativeElement.children[0];
    this.htmlElement.emit(calandarElement);
    $(calandarElement).calendar('clear');
  }
  public setDate(value: Date): void {
    const calandarElement: HTMLElement = this.parentElement.nativeElement.children[0];
    this.htmlElement.emit(calandarElement);
    $(calandarElement).calendar('set date', value);
  }
  public disabled(): void {
    this.disabled_flg = true;
  }
  public enabled(): void {
    this.disabled_flg = false;
  }

  onBlur(): void {
    this.blur.emit(this.selectedDate);
  }
  onInput(event): void {
    if (event.target.value.length !== 10) {
      this.selectedDate = null;
      this.valueChange.emit(this.selectedDate);
    }

  }
  public registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }
  public registerOnTouched(fn: () => void): void { this.onTouched = fn; }
}
export class CalendarOptionsDate {
  type?: string;
  startCalendar?: HTMLElement;
  endCalendar?: HTMLElement;
  startMode?: string;
  ampm?: boolean;
  firstDayOfWeek?: number;
  today?: boolean;
  text?: {};
  on?: string;
  minDate?: Date;
  maxDate?: Date;
  formatter?: any;
  monthFirst?: boolean;
  inline?: boolean;
  onChange?: Function;
  onSelect?: Function;
  initialDate?: Date;
  constructor() {
    this.type = 'date';
    this.firstDayOfWeek = 1;
    this.today = true;
    this.text = Calendar.JA_TEXT;
    this.formatter = Calendar.FOMATTER;
    this.minDate = new Date('1800/01/01');
    this.maxDate = new Date('2999/12/31');
    this.ampm = false;
    this.on = 'click';
  }
  public getSettings() {
    return {
      type: this.type,
      startCalendar: this.startCalendar,
      endCalendar: this.endCalendar,
      startMode: this.startMode,
      ampm: this.ampm,
      firstDayOfWeek: this.firstDayOfWeek,
      today: this.today,
      text: this.text,
      on: this.on,
      minDate: this.minDate,
      maxDate: this.maxDate,
      formatter: this.formatter,
      monthFirst: this.monthFirst,
      inline: this.inline,
      initialDate: this.initialDate,
      onChange: this.onChange,
      onSelect: this.onSelect,
    };
  }
}
export class CalendarOptionsDateTime extends CalendarOptionsDate {
  constructor() {
    super();
    this.type = 'datetime';
  }
}
export class CalendarOptionsMonth extends CalendarOptionsDate {
  constructor() {
    super();
    this.type = 'month';
  }
}

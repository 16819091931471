import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';

@Injectable({
  providedIn: 'root',
})
export class AuthSGuard implements CanActivate {
  constructor(
    private router: Router,
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.sessionSvc.clear('message');
    const login_info = this.sessionSvc.get('soke_login_info');
    if (!login_info || !login_info.login_id) {
      this.router.navigate(['soke/login/'], {
        queryParams: { previousUrl: state.url },
      });
      return false;
    }
    this.httpClientService.refreshToken().catch(err => { });
    return true;
  }
}

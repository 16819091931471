import { ServiceGetResponse } from '../interfaces/master';
import { SupplierBase } from '../interfaces/supplier';
import { SekoGetResponse } from '../interfaces/seko';
export class EntryGetResquest {
  seko_company: number;
  entry_ts_from: string;
  entry_ts_to: string;
  kumotsu_henrei_from: string;
  kumotsu_henrei_to: string;
  koden_henrei_from: string;
  koden_henrei_to: string;
}
class EntryBase {
  payment: number;
  entry_name: string;
  entry_name_kana: string;
  entry_zip_code: string;
  entry_prefecture: string;
  entry_address_1: string;
  entry_address_2: string;
  entry_address_3: string;
  entry_tel: string;
  entry_mail_address: string;
  entry_mail_address_confirm: string;
  epsilon_token: string;
  contract_type: number;
  billing_amount: number;
}
export class EntryGetResponse extends EntryBase {
  id: number;
  seko: number;
  details: Array<EntryDetailForGet>;
  entry_ts: Date;
  cancel_ts: Date;
  order_number: number;
}
export class EntryPostRequest extends EntryBase {
  seko: number;
  details: Array<EntryDetailForPost>;
}

export class EntryPostResponse extends EntryGetResponse {
}
class EntryDetailBase {
  item: number;
  item_hinban: string;
  item_name: string;
  item_unit_price: number;
  item_tax: number;
  item_tax_pct: number;
  quantity: number;
  tax_adjust: number;
  keigen_flg: boolean;
}
export class EntryDetailForGet extends EntryDetailBase {
  id: number;
  chobun: EntryDetailChobunForGet;
  koden: EntryDetailKodenForGet;
  kumotsu: EntryDetailKumotsuForGet;
  message: EntryDetailMessageForGet;
}
export class EntryDetailForPost extends EntryDetailBase {
  index?: number;
  service?: ServiceGetResponse;
  chobun: EntryDetailChobunForPost;
  koden: EntryDetailKodenForPost;
  kumotsu: EntryDetailKumotsuForPost;
  message: EntryDetailMessageForPost;
}
export class EntryDetailCommon {
  okurinushi_company: string;
  okurinushi_title: string;
  okurinushi_company_kana: string;
  okurinushi_name: string;
  okurinushi_kana: string;
  okurinushi_zip_code: string;
  okurinushi_prefecture: string;
  okurinushi_address_1: string;
  okurinushi_address_2: string;
  okurinushi_address_3: string;
  okurinushi_tel: string;
  renmei1: string;
  renmei_kana1: string;
  renmei2: string;
  renmei_kana2: string;
  note: string;
}
class EntryDetailChobunBase extends EntryDetailCommon {
  atena: string;
  honbun: string;
  daishi: number;
  printed_flg: boolean;
  chobun_sample?: number;
}
export class EntryDetailChobunForGet extends EntryDetailChobunBase {
}
export class EntryDetailChobunForPost extends EntryDetailChobunBase {
}

class EntryDetailKumotsuBase extends EntryDetailCommon {
  order_status: number;
  order_staff: number;
  order_note: string;
}
export class EntryDetailKumotsuForGet extends EntryDetailKumotsuBase {
  supplier: number;
}
export class EntryDetailKumotsuForPost extends EntryDetailKumotsuBase {
  supplier: number;
}

class EntryDetailKodenBase {
  koden_commission: number;
  koden_commission_tax: number;
  koden_commission_tax_pct: number;
  tax_adjust: number;
  henreihin_fuyo_flg: boolean;
}
export class EntryDetailKodenForGet extends EntryDetailKodenBase {
  henrei_koden: HenreihinKodenForGet;
}
export class EntryDetailKodenForPost extends EntryDetailKodenBase {
  selected_price?: number;
  other_price?: number;
  henrei_koden: HenreihinKodenForPost;
}

class HenreihinKodenBase {
  henreihin: number;
  henreihin_hinban: string;
  henreihin_name: string;
  henreihin_price: number;
  henreihin_tax: number;
  henreihin_tax_pct: number;
  keigen_flg: boolean;
  customer_self_select_flg: boolean;
  select_status: number;
  supplier: number;
}
export class HenreihinKodenForGet extends HenreihinKodenBase {
}
export class HenreihinKodenForPost extends HenreihinKodenBase {
}

class EntryDetailMessageBase {
  relation_ship: string;
  release_status: number;
  honbun: string;
}
export class EntryDetailMessageForGet extends EntryDetailMessageBase {
}
export class EntryDetailMessageForPost extends EntryDetailMessageBase {
}

export class EntryComForGet extends EntryBase {
  id: number;
  entry_ts: Date;
  cancel_ts: Date;
  seko: SekoGetResponse;
}
export class EntryDetailComForGet extends EntryDetailBase {
  id: number;
  entry: EntryComForGet;
}
class EntryGetRequestBase {
  company_id: number;
  entry_id: number;
  seko_department: number;
  okurinushi_name: string;
  entry_name: string;
  entry_tel: string;
  renmei: string;
  okurinushi_company: string;
  okurinushi_title: string;
  soke_name: string;
  kojin_name: string;
  moshu_name: string;
  is_cancel: boolean;
}
export class ChobunGetRequest extends EntryGetRequestBase {
  printed_flg: boolean;
}

export class ChobunGetResponse extends EntryDetailChobunBase {
  entry_detail: EntryDetailComForGet;
}

export class ChobunPutRequest extends EntryDetailChobunBase {
}

export class ChobunPutResponse {
}
export class KumotsuGetRequest extends EntryGetRequestBase {
  supplier_id: number;
  order_status: Array<number>;
}

export class KumotsuGetResponse extends EntryDetailKumotsuBase {
  supplier: SupplierBase;
  order_ts: Date;
  delivery_ts: Date;
  entry_detail: EntryDetailComForGet;
  selected?: boolean;
}

export class KumotsuPutRequest extends EntryDetailKumotsuBase {
  supplier: number;
  delivery_ts: string;
}

export class KumotsuPutResponse {
}

export class KumotsuOrderStatusPostRequest {
  ids: Array<number>;
  order_status: number;
}

export class KumotsuOrderStatusPostResponse {
}

export class KodenGetRequest extends EntryGetRequestBase {
}

export class KodenGetResponse extends EntryDetailKodenBase {
  entry_detail: EntryDetailComForGet;
  henrei_koden: HenreihinKodenForGet;
}

export class MessageGetRequest extends EntryGetRequestBase {
  release_status: Array<number>;
}

export class MessageGetResponse extends EntryDetailMessageBase {
  entry_detail: EntryDetailComForGet;
}

export class MessagePutRequest extends EntryDetailMessageBase {
}

export class MessagePutResponse {
}

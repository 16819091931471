
export class AfGroupGetRequest {
  department: number;
}
export class AfGroupBase {
  company: number;
  department: number;
  name: string;
  display_num: number;
  after_follows: Array<AfGetResponse>;
}
export class AfGroupGetResponse extends AfGroupBase {
  id: number;
}
export class AfGroupPostRequest extends AfGroupBase  {
}

export class AfGroupPostResponse {
}

export class AfGroupPutRequest extends AfGroupBase  {
}
export class AfGroupPutResponse {
}

export class AfGroupDeleteResponse {
}

export class AfGetRequest {
  department: number;
}
export class AfBase {
  name: string;
  display_num: number;
}
export class AfGetResponse extends AfBase {
  id: number;
  selected?: boolean;
}
export class AfPostRequest extends AfBase  {
  af_group: number;
}

export class AfPostResponse {
}

export class AfPutRequest extends AfBase  {
  af_group: number;
}
export class AfPutResponse {
}

export class AfDeleteResponse {
}
export class AfContactGetRequest {
  company: number;
}
export class AfContactBase {
  company: number;
  contact_way: string;
  display_num: number;
}
export class AfContactGetResponse extends AfContactBase {
  id: number;
}
export class AfContactPostRequest extends AfContactBase  {
}

export class AfContactPostResponse {
}

export class AfContactPutRequest extends AfContactBase  {
}
export class AfContactPutResponse {
}

export class AfContactDeleteResponse {
}

export class SekoAfBase {
  note: string;
}
export class SekoAfGetResponse extends SekoAfBase  {
  seko: number;
  contact_way: AfContactGetResponse;
  wishes: Array<AfWishForGet>;
}
export class SekoAfPostRequest extends SekoAfBase  {
  seko: number;
  contact_way: number;
  wishes: Array<AfWishForPost>;
}

export class SekoAfPostResponse {
}
export class SekoAfPutRequest extends SekoAfPostRequest  {
}

export class SekoAfPutResponse {
}
export class AfWishBase {
  answered_flg: boolean;
  proposal_status: number;
  order_status: number;
  order_chance: number;
}
export class AfWishForGet extends AfWishBase {
  id: number;
  af_type: AfGetResponse;
  order_date: Date;
  proposed_flg: boolean;
  activities: Array<AfActivityForGet>;
}
export class AfWishForPost extends AfWishBase  {
  order_date: string;
  af_type: number;
}

export class AfActivityBase {
  activity: string;
}
export class AfActivityForGet extends AfActivityBase {
  id: number;
  activity_ts: Date;
}
export class AfActivityForPost extends AfActivityBase  {
  activity_ts: string;
  af_wish: number;
}

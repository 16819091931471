import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/service/session.service';
import { LoaderService } from 'src/app/service/loader.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { EntryPostRequest } from 'src/app/interfaces/order';
import { CommonConstants } from 'src/app/const/const';
import { Utils } from 'src/app/const/func-util';
declare var $;

@Component({
  selector: 'app-cus-order-confirm',
  templateUrl: './order-confirm.component.html',
  styleUrls: ['./order-confirm.component.scss']
})
export class OrderConfirmComponent implements OnInit {
  Const = CommonConstants;
  seko_info = this.sessionSvc.get('custom_seko_info');
  entry: EntryPostRequest = this.sessionSvc.get('service_entry');
  sum_tax_8 = { price: 0, tax: 0, tax_sum: 0 };
  sum_tax_10 = { price: 0, tax: 0, tax_sum: 0 };
  sum_tax_0 = { price: 0 };
  processing = false;
  message = null;
  constructor(
    private router: Router,
    private loaderSvc: LoaderService,
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService
  ) { }

  ngOnInit(): void {
    if (!this.seko_info) {
      this.router.navigate(['fuho/']);
      return;
    }
    if (!this.entry) {
      this.router.navigate(['fuho/' + this.seko_info.seko_company.id + '-' + this.seko_info.id + '/']);
      return;
    }
    if (!this.entry.details || !this.entry.details.length) {
      this.router.navigate(['cart/']);
      return;
    }
    this.calcSummary();
  }

  calcSummary() {
    this.sum_tax_8.price = 0;
    this.sum_tax_8.tax = 0;
    this.sum_tax_8.tax_sum = 0;
    this.sum_tax_10.price = 0;
    this.sum_tax_10.tax = 0;
    this.sum_tax_10.tax_sum = 0;
    this.sum_tax_0.price = 0;
    if (!this.entry || !this.entry.details || !this.entry.details.length) {
      return;
    }
    for (const detail of this.entry.details) {
      detail.tax_adjust = 0;
      if (detail.item_tax_pct === 8) {
        this.sum_tax_8.price += detail.item_unit_price * detail.quantity;
        this.sum_tax_8.tax_sum += detail.item_tax;
      } else if (detail.item_tax_pct === 10) {
        this.sum_tax_10.price += detail.item_unit_price * detail.quantity;
        this.sum_tax_10.tax_sum += detail.item_tax;
      } else {
        this.sum_tax_0.price += detail.item_unit_price * detail.quantity;
      }
      if (detail.koden) {
        if (detail.koden.koden_commission_tax_pct === 8) {
          this.sum_tax_8.price += detail.koden.koden_commission;
          this.sum_tax_8.tax_sum += detail.koden.koden_commission_tax;
        } else if (detail.koden.koden_commission_tax_pct === 10) {
          this.sum_tax_10.price += detail.koden.koden_commission;
          this.sum_tax_10.tax_sum += detail.koden.koden_commission_tax;
        } else {
          this.sum_tax_0.price += detail.koden.koden_commission;
        }

      }
    }
    this.sum_tax_8.tax = Utils.calcTax(this.sum_tax_8.price, 8, this.seko_info.seko_company.calc_type);
    this.sum_tax_10.tax = Utils.calcTax(this.sum_tax_10.price, 10, this.seko_info.seko_company.calc_type);
    if (this.sum_tax_8.tax !== this.sum_tax_8.tax_sum) {
      for (const detail of this.entry.details) {
        if (detail.item_tax_pct === 8) {
          detail.tax_adjust = this.sum_tax_8.tax - this.sum_tax_8.tax_sum;
          break;
        }
      }
    }
    if (this.sum_tax_10.tax !== this.sum_tax_10.tax_sum) {
      for (const detail of this.entry.details) {
        if (detail.item_tax_pct === 10) {
          detail.tax_adjust = this.sum_tax_10.tax - this.sum_tax_10.tax_sum;
          break;
        }
      }
    }
    this.entry.billing_amount = this.sum_tax_8.price + this.sum_tax_10.price + this.sum_tax_0.price;

  }
  kojinName() {
    if (!this.seko_info || !this.seko_info.kojin) {
      return '';
    }
    return this.seko_info.kojin.map(v => v.name).join(',');
  }
  hallName() {
    if (!this.seko_info || !this.seko_info.schedules) {
      return '';
    }
    const schedule = this.seko_info.schedules.find(v => v.schedule && v.schedule.id === this.Const.SCHEDULE_ID_KOKUBETSU);
    return schedule ? schedule.hall_name : '';
  }
  zipCode(zip_code: string) {
    if (!zip_code || zip_code.length !== 7) {
      return zip_code;
    }
    return zip_code.substring(0, 3) + '-' + zip_code.substring(3, 7);
  }

  back() {
    this.router.navigate(['order/entry/']);
  }
  next() {
    if (!this.entry.billing_amount) {
      this.saveEntry();
    } else {
      this.router.navigate(['order/payment/']);
    }
  }
  saveEntry() {
    if (this.processing) {
      return;
    }
    this.processing = true;
    this.loaderSvc.call();
    this.entry.epsilon_token = null;
    this.entry.contract_type = null;
    this.entry.payment = null;
    this.httpClientService.addEntry(this.entry)
      .then((response) => {
        this.sessionSvc.clearCustomerData();
        this.loaderSvc.dismiss();
        this.processing = false;
        this.entry.details = null;
        this.entry.epsilon_token = null;
        this.sessionSvc.save('service_entry', this.entry);
        this.sessionSvc.save('entry_no', response.id);
        this.router.navigate(['order/complete']);
      }).catch(error => {
        this.message = 'お申込処理が失敗しました。サポートセンターまでご連絡ください。';
        this.loaderSvc.dismiss();
        this.processing = false;
      });

  }
}

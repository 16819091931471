import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingModalComponent } from 'src/app/pages/common/loading-modal/loading-modal.component';
import { SessionService } from 'src/app/service/session.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private dialogRef: MatDialogRef<LoadingModalComponent>;
  private is_loading = new BehaviorSubject<boolean>(false);
  public isLoadingChange = this.is_loading.asObservable();

  constructor(
    private sessionSvc: SessionService,
    private dialog: MatDialog
  ) { }

  public call() {
    this.dialogRef = this.dialog.open(LoadingModalComponent, {
      panelClass: 'overlay-dialog',
    });
    this.is_loading.next(true);
  }

  public dismiss(close_loader = true) {
    this.is_loading.next(false);
    if (close_loader) {
      this.sessionSvc.clear('loading_msg');
      this.dialogRef.close();
    }
  }

  get isLoading(): boolean {
    return this.is_loading.getValue();
  }
}


export class ScheduleResponse {
  id: number;
  name: string;
  required_flg: boolean;
}

export class AddressGetRequest {
  zip_code: string;
}

export class AddressGetResponse {
  id: number;
  zip_code: string;
  prefecture: string;
  address_1: string;
  address_2: string;
}

export class SekoStyleGetResponse {
  id: number;
  name: string;
  wareki_use_flg: boolean;
}

export class HoyoStyleGetResponse {
  id: number;
  name: string;
  wareki_use_flg: boolean;
}

export class HoyoDefaultGetResponse {
  id: number;
  hoyo_style: HoyoStyleGetResponse;
  name: string;
  elapsed_time: number;
  unit: number;
}

export class HoyoSampleMasterGetResponse {
  id: number;
  sentence: string;
}

export class FuhoSampleGetResponse {
  id: number;
  sentence: string;
  selected?: boolean;
}

export class WarekiGetResponse {
  id: number;
  name: string;
  begin_date: Date;
  end_date: Date;
  minus_years: number;
}

export class ChobunDashiGetResponse {
  id: number;
  name: string;
  file_name: string;
  selected?: boolean;
}

export class ServiceGetResponse {
  id: number;
  name: string;
  single_item_flg: boolean;
  cart_flg: boolean;
  payment_flg: boolean;
  selected?: boolean;
  icon?: string;
}

export class TaxGetResponse {
  id: number;
  tax_pct: number;
  keigen_flg: boolean;
}

export class ChobunSampleGetResponse {
  id: number;
  type_id: number;
  name: string;
  sentence: string;
  selected?: boolean;
}

export class RelationshipResponse {
  id: number;
  name: string;
}

export class HenreihinParameterResponse {
  henreihin_omote_default: string;
  henreihin_mizuhiki_default: string;
  henreihin_hoso_default: string;
}

export class KodenParameterResponse {
  koden_commission_tax_pct: number;
  koden_upper_limit: number;
}

export class SokeMenuGetResponse {
  id: number;
  name: string;
  url: string;
  selected?: boolean;
}

import { Component, OnInit, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { CalendarOptionsDate } from 'src/app/components/com-calendar/com-calendar.component';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { LoaderService } from 'src/app/service/loader.service';
import {
  HoyoMasterGetRequest, HoyoMailGetRequest, HoyoMailGetResponse
} from 'src/app/interfaces/hoyo';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import { formatDate } from '@angular/common';
import { ComDropdownComponent } from 'src/app/components/com-dropdown/com-dropdown.component';
declare var $;

@Component({
  selector: 'app-com-hoyo-guidance',
  templateUrl: './hoyo-guidance.component.html',
  styleUrls: ['./hoyo-guidance.component.scss']
})
export class HoyoGuidanceComponent implements OnInit {
  @ViewChild('hoyoMasterComboEm', { static: false }) hoyoMasterComboEm: ComDropdownComponent;
  Const = CommonConstants;
  Utils = Utils;
  calendarOptionDate: CalendarOptionsDate = new CalendarOptionsDate();

  login_company = Utils.getLoginCompany();
  hoyoMasterCombo = { values: [], clearable: true, showOnFocus: false};
  form_data = {
    company_id: null,
    hoyo_id: null,
    send_date_from: null,
    send_date_to: null,
  };
  searched = false;
  hoyo_mail_list: Array<HoyoMailGetResponse> = null;
  page_per_count = 20;
  pagination = {
    pages: new Array(),
    current: 0
  };
  selected_hoyo_mail = null;

  constructor(
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
    @Inject(LOCALE_ID) private locale: string,
  ) {
    this.loaderSvc.call();
  }


  async ngOnInit() {
    if (this.login_company) {
      this.form_data.company_id = this.login_company.id;
      await this.getHoyoMasterList(this.form_data.company_id);
    }
  }

  async getHoyoMasterList(base_id) {
    const req = new HoyoMasterGetRequest();
    req.company = base_id;
    await this.httpClientService.getHoyoMasterList(req).then(async (response) => {
      Utils.log(response);
      if (response && response.length) {
        this.hoyoMasterCombo.values = response.map(value => ({ name: value.name, value: value.id }));
        this.loaderSvc.dismiss();
      }
    }).catch(error => {
      this.loaderSvc.dismiss();
    });
    this.hoyoMasterComboEm.initComponent();
  }

  async searchHoyoMail() {
    this.sessionSvc.clear('message');
    const request = new HoyoMailGetRequest();
    request.company = this.form_data.company_id;
    request.hoyo = this.form_data.hoyo_id;
    if (this.form_data.send_date_from) {
      request.send_date_after = formatDate(this.form_data.send_date_from, 'yyyy-MM-dd', this.locale);
    }
    if (this.form_data.send_date_to) {
      request.send_date_before = formatDate(this.form_data.send_date_to, 'yyyy-MM-dd', this.locale);
    }
    if (!this.loaderSvc.isLoading) {
      this.loaderSvc.call();
    }
    await this.httpClientService.getHoyoMailList(request).then((response) => {
      Utils.log(response);
      this.searched = true;
      this.hoyo_mail_list = response;
      this.calcPagination();
      this.loaderSvc.dismiss();

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }

  calcPagination() {
    this.pagination.pages = new Array();
    this.pagination.current = 0;
    if (!this.hoyo_mail_list || !this.hoyo_mail_list.length) {
      return;
    }
    const count = Math.ceil(this.hoyo_mail_list.length / this.page_per_count);
    for (let i = 1; i <= count; i++) {
      this.pagination.pages.push(i);
    }
    this.pagination.current = 1;
  }

  pageTo(page_num) {
    this.pagination.current = page_num;
  }

  clearForm(...comboEms: any[]) {

    this.form_data = {
      company_id: this.login_company.id,
      hoyo_id: null,
      send_date_from: null,
      send_date_to: null,
    };
    for (const comboEm of comboEms) {
      comboEm.clear();
      if ('initComponent' in comboEm) {
        comboEm.initComponent();
      }
    }

  }

  async showItem(event, hoyo_mail) {
    if (!hoyo_mail) {
      return;
    } else {
      if (event.target.classList.contains('operation') || event.target.classList.contains('icon')) {
        return;
      }
      this.selected_hoyo_mail = JSON.parse(JSON.stringify(hoyo_mail));
    }
    $('#hoyo-mail').modal({
      centered: false,
      closable: false,
      detachable: false,
      transition: 'fade'
    }).modal('show');
    setTimeout(() => {
      $('.ui.checkbox').checkbox();
    }, 100);
  }

  closeItem() {
    setTimeout(() => {
      this.selected_hoyo_mail = null;
    }, 100);
  }

  getPlace(seko) {
    if (!seko || !seko.schedules) {
      return null;
    }
    let ret = null;
    seko.schedules.forEach((schedule) => {
      if (schedule.schedule) {
        ret = schedule.hall_name;
        return;
      }
    });
    return ret;
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/service/session.service';

@Component({
  selector: 'app-cus-tokushoho',
  templateUrl: './tokushoho.component.html',
  styleUrls: ['./tokushoho.component.scss']
})
export class TokushohoComponent implements OnInit {


  seko_info = this.sessionSvc.get('custom_seko_info');

  constructor(
    private router: Router,
    private sessionSvc: SessionService,
  ) { }

  async ngOnInit() {
    if (!this.seko_info) {
      this.router.navigate(['fuho/']);
      return;
    }
    if (!this.seko_info.seko_company.tokusho) {
      this.router.navigate(['fuho/' + this.seko_info.seko_company.id + '-' + this.seko_info.id + '/']);
    }
  }

  zipCode(zip_code: string) {
    if (!zip_code || zip_code.length !== 7) {
      return zip_code;
    }
    return zip_code.substring(0, 3) + '-' + zip_code.substring(3, 7);
  }
}

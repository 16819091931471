import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/pages/common/confirm-dialog/confirm-dialog.component';
import { SessionService } from 'src/app/service/session.service';
import { ZipcodeService } from 'src/app/service/zipcode.service';
import { AddressGetRequest } from 'src/app/interfaces/master';
import { HttpClientService } from 'src/app/service/http-client.service';
import { LoaderService } from 'src/app/service/loader.service';
import { Utils } from 'src/app/const/func-util';
import { CommonConstants } from 'src/app/const/const';
import { CompanyChobunDiashiGetRequest, CompanyChobunDiashiPostRequest } from 'src/app/interfaces/chobun-daishi';
declare var $;

@Component({
  selector: 'app-com-chobun-daishi',
  templateUrl: './chobun-daishi.component.html',
  styleUrls: ['./chobun-daishi.component.scss']
})
export class ChobunDaishiComponent implements OnInit {

  Const = CommonConstants;

  login_company = Utils.getLoginCompany();
  companyCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  company_list = Utils.getCompanyList();
  company_id = null;

  daishi_list = null;
  item_list = null;

  processing = false;

  constructor(
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
    private loaderSvc: LoaderService,
  ) {
    this.loaderSvc.call();
  }

  async ngOnInit() {
    this.initControl();
    await this.getDaishiMaster();
    await this.getData(this.company_id);
  }

  initControl() {
    if (this.company_list) {
      this.companyCombo.values = this.company_list.map(value => ({ name: value.base_name, value: value.id, data: value }));
      if (this.login_company) {
        if (this.login_company.base_type === CommonConstants.BASE_TYPE_COMPANY) {
          this.company_id = this.login_company.id;
        } else {
          this.company_id = this.company_list[0].id;
        }
      }
    }
  }

  async getDaishiMaster() {
    await this.httpClientService.getChobunDiahiList().then((response) => {
      Utils.log(response);
      this.daishi_list = response;
      this.loaderSvc.dismiss();
      this.resetSelectedItems();

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }

  async getData(company_id) {
    const request = new CompanyChobunDiashiGetRequest();
    request.company_id = company_id;
    if (!this.loaderSvc.isLoading) {
      this.loaderSvc.call();
    }
    await this.httpClientService.getCompanyChobunDaishiList(request).then((response) => {
      Utils.log(response);
      this.item_list = response;
      this.setSelectedItems();
      this.loaderSvc.dismiss();

    }).catch(error => {
      this.loaderSvc.dismiss();
    });
  }
  resetSelectedItems() {
    for (const daishi of this.daishi_list) {
      daishi.selected = false;
    }
  }
  setSelectedItems() {
    for (const daishi of this.daishi_list) {
      if (this.item_list) {
        const selected_item = this.item_list.find(v => v.daishi.id === daishi.id);
        if (selected_item) {
          daishi.selected = true;
        } else {
          daishi.selected = false;
        }
      }
    }
  }
  selectItem(item) {
    item.selected = !item.selected;
  }
  companyChange(event) {
    if (!event || !event.data) {
      return;
    }
    this.getData(event.data.id);
  }

  saveData() {
    this.sessionSvc.clear('message');
    if (this.processing) {
      return;
    }
    this.processing = true;
    const postData = this.getPostData();
    this.loaderSvc.call();
    this.httpClientService.addCompanyChobunDaishi(this.company_id, postData)
      .then(async (response) => {
        Utils.log(response);
        this.sessionSvc.save('message', { type: 'info', title: '弔文台紙情報を保存しました。' });
        this.getData(this.company_id);
        this.loaderSvc.dismiss();
        this.processing = false;
      })
      .catch(error => {
        this.sessionSvc.save('message', { type: 'error', title: '弔文台紙情報の保存が失敗しました。' });
        this.loaderSvc.dismiss();
        this.processing = false;
      });
    $('#item-edit').modal('hide');

  }
  getPostData() {
    const postRequest = new CompanyChobunDiashiPostRequest();
    postRequest.chobun_daishi_master_ids = new Array();
    for (const daishi of this.daishi_list) {
      if (daishi.selected) {
        postRequest.chobun_daishi_master_ids.push(daishi.id);
      }
    }

    return postRequest;
  }

}

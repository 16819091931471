import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/service/loader.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClientService } from 'src/app/service/http-client.service';
import { CommonConstants } from 'src/app/const/const';
import { SessionService } from 'src/app/service/session.service';
import { Utils } from 'src/app/const/func-util';

declare var $;
@Component({
  selector: 'app-com-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.scss'],
})
export class TopComponent implements OnInit {
  // データ
  login_company = Utils.getLoginCompany();
  login_info = this.sessionSvc.get('staff_login_info');
  selected_tab = this.sessionSvc.get('selected_tab');
  henreihin_count = { unconfirmed: null, unordered: null };
  kumotsu_count = { unconfirmed: null, unordered: null };
  unprinted_chobun_count = null;
  seko_inquiries_count = { unanswerd_inquiry: null, unanswerd_af: null };
  Const = CommonConstants;
  is_loading = false;
  sekoBaseCombo = { values: [], clearable: true };
  login_base_id = null;
  constructor(
    private location: Location,
    private httpClientService: HttpClientService,
    private loaderSvc: LoaderService,
    private sessionSvc: SessionService,
    private router: Router
  ) {}

  ngOnInit() {
    this.location.go('foc/top');
    if (this.login_info.staff.base.base_type !== this.Const.BASE_TYPE_ADMIN) {
      this.sekoBaseCombo.values = Utils.getSekoBaseList(this.login_company);
      if (this.login_info.staff.base.base_type !== this.Const.BASE_TYPE_COMPANY) {
        this.login_base_id = this.login_info.staff.base.id;
      }
    }
    this.getBase(this.login_base_id ? this.login_base_id : this.login_company.id);
    setTimeout(() => {
      this.initControl();
    }, 100);
  }

  initControl() {
    $('.tabular.menu .item').tab();
    if (this.selected_tab) {
      $('.tabular.menu .item').tab('change tab', this.selected_tab);
    }
  }

  sekoBaseChange(event) {
    this.getBase(event && event.data ? event.data.id : this.login_company.id);

  }

  getBase(base_id) {
    if (!this.login_info || this.is_loading) {
      return;
    }
    this.is_loading = true;
    this.httpClientService.getBase(base_id).then((response) => {
      Utils.log(response);
      this.initCount();
      this.calcCount(response);
      this.is_loading = false;

    }).catch(error => {
      this.is_loading = false;
    });
  }
  initCount() {
    this.henreihin_count.unconfirmed = 0;
    this.henreihin_count.unordered = 0;
    this.kumotsu_count.unconfirmed = 0;
    this.kumotsu_count.unordered = 0;
    this.unprinted_chobun_count = 0;
    this.seko_inquiries_count.unanswerd_inquiry = 0;
    this.seko_inquiries_count.unanswerd_af = 0;
  }

  calcCount(base) {
    if (!base) {
      return;
    }
    this.henreihin_count.unconfirmed += base.henrei_koden_count.unconfirmed + base.henrei_kumotsu_count.unconfirmed;
    this.henreihin_count.unordered += base.henrei_koden_count.unordered + base.henrei_kumotsu_count.unordered;
    this.kumotsu_count.unconfirmed += base.kumotsu_count.unconfirmed;
    this.kumotsu_count.unordered += base.kumotsu_count.unordered;
    this.unprinted_chobun_count += base.unprinted_chobun_count;
    this.seko_inquiries_count.unanswerd_inquiry += base.seko_inquiries_count.unanswerd_inquiry;
    this.seko_inquiries_count.unanswerd_af += base.seko_inquiries_count.unanswerd_af;

    if (base.children) {
      for (const child of base.children) {
        this.calcCount(child);
      }
    }
  }

  selectTab(tab_name) {
    this.sessionSvc.save('selected_tab', tab_name);
  }
}

import { Component, Inject, OnInit, DoCheck, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { RouterService } from 'src/app/service/router.service';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';

declare var $;

@Component({
  selector: 'app-company-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss']
})
export class CompanyFrameComponent implements OnInit, DoCheck, AfterViewInit {

  login_info = null;
  message = null;
  appView = null;
  clearing_msg = false;
  constructor(
    private sessionSvc: SessionService,
    private httpClientService: HttpClientService,
    private router: Router,
    private routerSvc: RouterService,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: HTMLDocument
  ) { }

  ngOnInit() {
    this.appView = this.routerSvc.getAppView();
    if (!this.appView) {
      return;
    }
    this.sessionSvc.clear('message');
    $('#focFavicon').attr('href', 'favicon_com.ico');
  }

  ngDoCheck() {
    this.login_info = this.sessionSvc.get('staff_login_info');
    const message = this.sessionSvc.get('message');
    if (!message) {
      this.clearing_msg = true;
      setTimeout(() => {
        this.message = message;
        this.clearing_msg = false;
      }, 200);

    } else {
      this.message = message;

    }
  }
  ngAfterViewInit() {
    this.initControl();
  }
  logout() {
    this.sessionSvc.clearAll();
    this.router.navigate(['foc/login']);
  }

  initControl() {
    $('.dropdown.top-menu')
      .dropdown();
  }
  closeMessage() {
    if (this.message.force) {
      return;
    }
    this.sessionSvc.clear('message');
    // this.clearing_msg = true;
    // setTimeout(() => {
    //   this.sessionSvc.clear('message');
    //   this.clearing_msg = false;
    // }, 200);
  }

}

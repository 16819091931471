import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { ZipcodeService } from 'src/app/service/zipcode.service';
import { AddressGetRequest } from 'src/app/interfaces/master';
import { EntryPostRequest, EntryDetailCommon } from 'src/app/interfaces/order';
import { CommonConstants } from 'src/app/const/const';
import { Utils } from 'src/app/const/func-util';

declare var $;

@Component({
  selector: 'app-cus-order-entry',
  templateUrl: './order-entry.component.html',
  styleUrls: ['./order-entry.component.scss']
})
export class OrderEntryComponent implements OnInit {

  Const = CommonConstants;

  description = `ご注文完了ページに表示される内容は、プリントアウトするなどして、お手元に残してください。
以下の場合、「てれ葬儀こころ」からのご注文完了メールが受信できないことがあります。
（当サービスでは、メールを正常に配信できたことになり、エラーの判断ができません。）
1.携帯電話・スマートフォンで、迷惑メールの設定をされている場合
　(mail@em.foc.jpn.com)からのメールが受信できるようにご注文前に設定をお願いします。
2.フリーメール（hotmaill.com）（yahoo.co.jp）等をご使用の場合
　迷惑メールと判断され、迷惑メールフォルダに振り分けられる可能性があります。
3.セキュリティソフトまたはウイルス対策ソフトが「拒否」または「削除」している場合
　セキュリティソフトの設定によっては、迷惑メールと判断し、「受信拒否」または「削除」されることがあります。
4.プロバイダの迷惑メールサービスにより、「拒否」または「削除」されている場合
　プロバイダの迷惑メールをブロックするサービスが設定されている場合、「拒否」または「削除」されることがあります。
5.メールボックスの最大容量を超えている場合
　メールボックス内の容量が最大容量をこえている場合は、メールの削除をしてください。`;
  seko_info = this.sessionSvc.get('custom_seko_info');
  entry: EntryPostRequest = this.sessionSvc.get('service_entry');
  approve_flg = false;
  approve_use_flg = false;

  constructor(
    private router: Router,
    private sessionSvc: SessionService,
    private httpClientService: HttpClientService,
    private zipcodeSvc: ZipcodeService,
  ) { }

  ngOnInit(): void {
    if (!this.seko_info) {
      this.router.navigate(['fuho/']);
      return;
    }
    if (!this.entry) {
      this.router.navigate(['fuho/' + this.seko_info.seko_company.id + '-' + this.seko_info.id + '/']);
      return;
    }
    if (!this.entry.details || !this.entry.details.length) {
      this.router.navigate(['cart/']);
      return;
    }
    this.initData();
    setTimeout(() => {
      $('.ui.checkbox').checkbox();
    }, 100);
  }

  initData() {
    if (this.entry.entry_name) {
      return;
    }
    let init_data: EntryDetailCommon = null;
    for (const detail of this.entry.details) {
      if (detail.chobun) {
        init_data = detail.chobun;
        break;
      } else if (detail.kumotsu) {
        init_data = detail.kumotsu;
        break;
      }
    }
    if (init_data) {
      this.entry.entry_name = init_data.okurinushi_name;
      this.entry.entry_name_kana = init_data.okurinushi_kana;
      this.entry.entry_zip_code = init_data.okurinushi_zip_code;
      this.entry.entry_prefecture = init_data.okurinushi_prefecture;
      this.entry.entry_address_1 = init_data.okurinushi_address_1;
      this.entry.entry_address_2 = init_data.okurinushi_address_2;
      this.entry.entry_address_3 = init_data.okurinushi_address_3;
      this.entry.entry_tel = init_data.okurinushi_tel;
    }
  }
  saveData() {
    if (!this.approve_flg || !this.approve_use_flg) {
      return;
    }
    if (!this.invalidData()) {
      const scrollTo = $('.navigation').offset();
      $('body, html').animate({
        scrollTop: scrollTo.top
      }, 200);
      return;
    }
    this.sessionSvc.save('service_entry', this.entry);
    this.router.navigate(['order/confirm']);
  }

  invalidData() {
    $('.input').attr('err-msg', null);
    if (!this.entry) {
      return false;
    }
    let ret = true;
    if (!this.entry.entry_name) {
      $('.input.entry_name').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.entry.entry_name_kana) {
      $('.input.entry_name_kana').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.entry.entry_zip_code) {
      $('.input.entry_zip_code').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.entry.entry_prefecture) {
      $('.input.entry_prefecture').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.entry.entry_address_1) {
      $('.input.entry_address_1').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.entry.entry_address_2) {
      $('.input.entry_address_2').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.entry.entry_tel) {
      $('.input.entry_tel').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.entry.entry_mail_address) {
      $('.input.entry_mail_address').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.entry.entry_mail_address_confirm) {
      $('.input.entry_mail_address_confirm').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.approve_flg) {
      $('.input.approve_flg').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.approve_use_flg) {
      $('.input.approve_use_flg').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!ret) {
      return ret;
    }
    if (this.entry.entry_mail_address !== this.entry.entry_mail_address_confirm) {
      $('.input.entry_mail_address_confirm').attr('err-msg', '*メールアドレスが一致しません');
      return false;
    }
    const reg = new RegExp(this.Const.MAIL_PATTERN);
    if (!this.entry.entry_mail_address.match(reg)) {
      $('.input.entry_mail_address').attr('err-msg', '*メール形式が正しくありません');
      return false;
    }
    return ret;
  }

  zipcodeChange() {
    const request = new AddressGetRequest();
    request.zip_code = this.entry.entry_zip_code;
    this.zipcodeSvc.getData(request).then(address => {
      if (address) {
        this.entry.entry_prefecture = address.prefecture;
        this.entry.entry_address_1 = address.address_1;
        this.entry.entry_address_2 = address.address_2;
      }
    }).catch(error => { });
  }

  back() {
    this.router.navigate(['cart/']);
  }

  isErrorField(element) {
    return !!element.getAttribute('err-msg');
  }

}

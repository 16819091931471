import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cus-howto-share',
  templateUrl: './howto-share.component.html',
  styleUrls: ['./howto-share.component.scss']
})
export class HowtoShareComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
